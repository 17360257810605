import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {SkwApiService} from 'skw-ui-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class DevelopmentServiceService {

  private developmentMode = new BehaviorSubject(false);

  constructor(api: SkwApiService) {

    api.getBootstrap().subscribe(boot => {
      const devEnv = boot.getParameter('dev');
      if (devEnv && devEnv === 'true')
        this.setDevelopmentMode(true);
    });
  }

  public setDevelopmentMode(active: boolean) {
    this.developmentMode.next(active);
  }

  public getDevelopmentMode() {
    return this.developmentMode;
  }
}
