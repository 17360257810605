import {Injectable} from '@angular/core';
import {SkwWebsocketService} from 'skw-ui-messaging';
import {WebsocketEvent} from './websocket-event';
import {Observable, Subject} from 'rxjs';
import {ModuleUpdateEvent} from './module-update-event';
import {SystemHealthStatus} from '../../system-status/header-toolbar-portal/system-health-status';
import {ItemUpdateEvent} from './item-update-event';
import {ErrorNotification} from '../../pages/errors/notification';
import {SequencerUpdateEvent} from './sequencer-update-event';
import {LayerStatsUpdateEvent} from './layer-stats-update-event';

@Injectable({
  providedIn: 'root'
})
export class WebsocketEventService {

  private eventSubject = new Subject<WebsocketEvent<any>>();
  private notificationSubject = new Subject<ErrorNotification>();
  private sequencerSubject = new Subject<SequencerUpdateEvent>();
  private moduleUpdateSubject = new Subject<ModuleUpdateEvent>();
  private itemUpdateSubject = new Subject<ItemUpdateEvent>();
  private layerStatsUpdateSubject = new Subject<LayerStatsUpdateEvent>();
  private systemStatusSubject = new Subject<WebsocketEvent<SystemHealthStatus>>();

  constructor(private websocket: SkwWebsocketService) {
    this.websocket.watch<WebsocketEvent<any>>('/topic/event')
      .subscribe(r => {
        this.eventSubject.next(r);
      });
    this.websocket.watch<ModuleUpdateEvent>('/topic/moduleUpdate')
      .subscribe(r => {
        this.moduleUpdateSubject.next(r);
      });
    this.websocket.watch<ItemUpdateEvent>('/topic/itemUpdate')
      .subscribe(r => {
        this.itemUpdateSubject.next(r);
      });
    this.websocket.watch<LayerStatsUpdateEvent>('/topic/layerStatsUpdate')
      .subscribe(r => {
        this.layerStatsUpdateSubject.next(r);
      });
    this.websocket.watch<WebsocketEvent<SystemHealthStatus>>('/topic/systemStatus')
      .subscribe(r => {
        this.systemStatusSubject.next(r);
      });
    this.websocket.watch<ErrorNotification>('/topic/notifications')
      .subscribe(r => {
        this.notificationSubject.next(r);
      });
    this.websocket.watch<SequencerUpdateEvent>('/topic/sequencer')
      .subscribe(r => {
        this.sequencerSubject.next(r);
      });
  }

  publish(event: WebsocketEvent<any>) {
    this.websocket.publish('/app/event', event);
  }

  onWebsocketEvent(): Observable<WebsocketEvent<any>> {
    return this.eventSubject.asObservable();
  }

  onModuleUpdateEvent(): Observable<ModuleUpdateEvent> {
    return this.moduleUpdateSubject.asObservable();
  }

  onItemUpdateEvent(): Observable<ItemUpdateEvent> {
    return this.itemUpdateSubject.asObservable();
  }

  onLayerStatsUpdateEvent(): Observable<LayerStatsUpdateEvent> {
    return this.layerStatsUpdateSubject.asObservable();
  }

  onSystemStatusEvent(): Observable<WebsocketEvent<any>> {
    return this.systemStatusSubject.asObservable();
  }

  onNotificationEvent(): Observable<ErrorNotification> {
    return this.notificationSubject.asObservable();
  }

  onSequencerEvent(): Observable<SequencerUpdateEvent> {
    return this.sequencerSubject.asObservable();
  }
}
