import {Directive, Input, TemplateRef} from '@angular/core';
import {SkwTranslatableValue} from 'skw-ui-translation';

@Directive({
  selector: '[skwSidebarView]'
})
export class SkwSidebarDirective {

  public _sidebarName;

  @Input() set skwSidebarView(value: SkwTranslatableValue) {
    this._sidebarName = value;
  }

  constructor(public templateRef: TemplateRef<any>) {
  }

}
