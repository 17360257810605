import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ItemFlowPropertyCondition} from '../../condition-model';
import {SkwSelectModel} from 'skw-ui-components';
import {RulesService} from '../../../rules.service';
import {OPERATOR_TYPES} from '../rule-condition-operators';
import {ItemFlowSettingsService} from '../../../../settings/item-flow-settings.service';
import {SkwLanguageService, SkwTranslatableValue} from 'skw-ui-translation';
import {forkJoin, Subscription} from 'rxjs';
import {RuleEditService} from '../../rule-edit.service';

@Component({
  selector: 'app-rule-condition-item-flow-property',
  templateUrl: './rule-condition-item-flow-property.component.html',
  styleUrls: ['./rule-condition-item-flow-property.component.scss']
})
export class RuleConditionItemFlowPropertyComponent implements OnInit, OnDestroy {
  @Input() editMode: boolean;
  @Output() conditionChange = new EventEmitter<ItemFlowPropertyCondition>();
  _condition: ItemFlowPropertyCondition;

  private $translationSubscription: Subscription;
  _attributeValues: SkwSelectModel<string>[] = [];
  _conditionViewValue: (string | SkwTranslatableValue)[]; // separate as we load all value mappings first

  selectedAttribute: string;

  // hardcoded, because we certainly know which operators we allow
  operatorTypes = OPERATOR_TYPES;

  itemFlowAttributes: SkwSelectModel<string>[];
  private $ruleChangedSubscription: Subscription;

  constructor(private rulesService: RulesService,
              private itemFlowSettingsService: ItemFlowSettingsService,
              private translationService: SkwLanguageService,
              private ruleEditService: RuleEditService,
              private cdr: ChangeDetectorRef) {
  }

  @Input()
  set condition(cond: ItemFlowPropertyCondition) {
    this._condition = cond;

    forkJoin(
      this.rulesService.getRuleDefinitionsModel(),
      this.itemFlowSettingsService.loadAll()
    ).subscribe(value => {
      if (typeof this.$translationSubscription !== 'undefined') {
        this.$translationSubscription.unsubscribe();
      }
      this.$translationSubscription = this.translationService.observable()
        .subscribe(lang => {
          this.itemFlowAttributes = value[0].itemFlowAttributes
            .map(attr => {
              const itemFlowSettings = value[1].find(i => attr === i.attributeName);
              const translated = itemFlowSettings ? itemFlowSettings.translations[lang] : attr;
              return {
                value: attr,
                viewValue: translated ? translated : attr
              } as SkwSelectModel<string>;
            });
        });

      // find the selected attribute for the skw-select-input
      this.selectedAttribute = cond.attributeName;
      this.loadAttributeValues();
    });
  }

  setAttributeReference($event: string) {
    this.selectedAttribute = $event;
    this._condition.attributeName = $event;
    this.loadAttributeValues();
  }

  translateProperty(value: string): string {
    if (this.itemFlowAttributes) {
      const translation = this.itemFlowAttributes.find(a => a.value === value);
      return translation ? translation.viewValue : value;
    }
    return undefined;
  }

  ngOnInit(): void {
    this.$ruleChangedSubscription = this.ruleEditService.changeObservable().subscribe(() => {
      this.updateConditionViewValue();
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy(): void {
    if (typeof this.$translationSubscription !== 'undefined') {
      this.$translationSubscription.unsubscribe();
    }
    if (typeof this.$ruleChangedSubscription !== 'undefined') {
      this.$ruleChangedSubscription.unsubscribe();
    }
  }

  valueChange($event: string) {
    this._condition.value = $event;
    this.ruleEditService.emitUpdateRule();
  }

  onInvalid($event: boolean) {
    if ($event) {
      this._condition.invalid = true;
    } else {
      this._condition.invalid = undefined;
    }
  }

  private loadAttributeValues() {
    if (!this.selectedAttribute) {
      return;
    }
    this.rulesService.getItemFlowPropValues(this.selectedAttribute).subscribe(
      value => {
        this._attributeValues = !value ? [] : value.map(r => {
          return {
            value: r,
            viewValue: {key: 'item.attributeValues.' + r, default: r} as SkwTranslatableValue
          } as SkwSelectModel<string>;
        });
        this._attributeValues = [{
          value: undefined,
          viewValue: {key: 'item.attributeValues.null'} as SkwTranslatableValue
        } as SkwSelectModel<string>, ...this._attributeValues];
        this.updateConditionViewValue();
      }
    );
  }

  private updateConditionViewValue() {
    if (!this._attributeValues || !this._condition) {
      return;
    }
    const splitValue = this._condition.value ? this._condition.value.split(',') : undefined;
    if (splitValue) {
      this._conditionViewValue = splitValue
        .map(el => {
          if (!el) {
            return 'item.attributeValues.null';
          }
          return {key: 'item.attributeValues.' + el, default: el};
        });
    } else {
      this._conditionViewValue = [
        !this._condition.value ?
          'item.attributeValues.null' : {key: 'item.attributeValues.' + this._condition.value, default: this._condition.value}
      ];
    }
  }
}
