import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Output,
  QueryList
} from '@angular/core';
import {SkwTabDirective} from '../skw-tab.directive';
import {SkwTabModel} from '../skw-tabs';
import {MatTabChangeEvent} from '@angular/material';

/**
 * This tab group component can be used to create tabs with different content.
 * Use this as parent wrapper container for all your tab templates with the <code>skwTab</code>
 * directive.
 * <p>Example:
 * <pre>
 *   <skw-tab-group>
 *     <ng-template skwTab skwTabLabel="Tab 1">
 *       Hello world from Tab 1
 *     </ng-template>
 *     <ng-template skwTab skwTabLabel="Tab 2">
 *       Hello world from Tab 2
 *     </ng-template>
 *   </skw-tab-group>
 * </pre>
 * All tabs are configured using the {@link SkwTabDirective} on a template element.
 * More info about all tab configuration properties can be found here: {@link SkwTabDirective}.
 */
@Component({
  selector: 'skw-tab-group',
  templateUrl: './skw-tab-group.component.html',
  styleUrls: ['./skw-tab-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SkwTabGroupComponent implements AfterContentInit {
  @ContentChildren(SkwTabDirective) tabs: QueryList<SkwTabDirective>;

  /**
   * Event emitted when the tab selection has changed and returns the active tab index.
   * Note: This emits before {@link selectedTabChange}.
   */
  @Output() selectedIndexChange = new EventEmitter<number>();
  /**
   * Event emitted when the tab selection has changed and returns the current active tab model.
   * Note: This emits after {@link selectedIndexChange}.
   */
  @Output() selectedTabChange = new EventEmitter<SkwTabModel>();

  _tabs: SkwTabModel[] = [];
  _activeTab = 0;

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngAfterContentInit(): void {
    this.tabs.changes.subscribe(() => this.initTabs());
    this.tabs.setDirty();
  }

  onTabChange($event: MatTabChangeEvent) {
    this._activeTab = $event.index;
    this.selectedIndexChange.emit(this._activeTab);
    this.selectedTabChange.emit(this._tabs[this._activeTab]);
  }

  private initTabs() {
    this._tabs = this.tabs.map(t => {
      return {
        label: t.skwTabLabel,
        template: t.templateRef,
        renderMode: t.skwTabRenderMode,
        disabled: t.skwTabDisabled
      };
    });
    this.cdr.markForCheck();
  }
}
