import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SimulationComponent} from './simulation.component';
import {SkwFileUploadModule} from '../../skw-file-upload/skw-file-upload.module';
import {
  SkwBadgeModule,
  SkwButtonModule,
  SkwCardModule,
  SkwCollapseModule,
  SkwDividerModule,
  SkwEditableModule,
  SkwFormModule,
  SkwHintModule,
  SkwInputModule,
  SkwListModule,
  SkwTableModule
} from 'skw-ui-components';
import {SkwMasterDetailLayoutModule, SkwPageLayoutModule, SkwToolbarModule} from 'skw-ui-layout';
import {SkwLanguageModule} from 'skw-ui-translation';
import {SimulationImportComponent} from './simulation-import/simulation-import.component';
import {SimulationControlComponent} from './simulation-control/simulation-control.component';
import {SimulationSpsStatusComponent} from './simulation-sps-status/simulation-sps-status.component';
import {SimulationGeneralSettingsComponent} from './simulation-general-settings/simulation-general-settings.component';
import {SimulationActiveDirective} from './simulation-active.directive';
import {SimulationToolbarComponent} from './simulation-toolbar/simulation-toolbar.component';
import { SimulationSnapshotsComponent } from './simulation-snapshots/simulation-snapshots.component';
import {MatTooltipModule} from '@angular/material';

@NgModule({
  declarations: [
    SimulationComponent,
    SimulationImportComponent,
    SimulationControlComponent,
    SimulationSpsStatusComponent,
    SimulationGeneralSettingsComponent,
    SimulationActiveDirective,
    SimulationToolbarComponent,
    SimulationSnapshotsComponent,
  ],
  imports: [
    CommonModule,
    SkwFileUploadModule,
    SkwCardModule,
    SkwPageLayoutModule,
    SkwButtonModule,
    SkwInputModule,
    SkwFormModule,
    SkwTableModule,
    SkwLanguageModule,
    SkwEditableModule,
    SkwMasterDetailLayoutModule,
    SkwListModule,
    SkwCollapseModule,
    SkwBadgeModule,
    SkwHintModule,
    SkwDividerModule,
    SkwToolbarModule,
    MatTooltipModule,
  ],
  exports: [
    SimulationActiveDirective,
    SimulationToolbarComponent
  ],
})
export class SimulationModule {
}
