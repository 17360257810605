import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BindedOperand} from '../../condition-model';

@Component({
  selector: 'app-rule-operand-binded',
  templateUrl: './rule-operand-binded.component.html',
  styleUrls: ['./rule-operand-binded.component.scss']
})
export class RuleOperandBindedComponent {
  @Input() editMode: boolean;

  @Input() operand: BindedOperand;
  @Output() operandChange: EventEmitter<BindedOperand> = new EventEmitter<BindedOperand>();

  changeOperandValue(value: string) {
    this.operand.variableName = value;
    this.operandChange.emit(this.operand);
  }
}
