/**
 * A bunch of definition and metadata listing available options during the rule creation and editing process.
 */
import {ActionType, EventType, QueryType} from './rule-edit/condition-model';

export class RuleConstants {
  public static readonly CarbodyPropertyCondition = 'CarbodyPropertyCondition';
  public static readonly CarbodyPropertyCheckCondition = 'CarbodyPropertyCheckCondition';
  public static readonly EventPropertyCondition = 'EventPropertyCondition';
  public static readonly ItemFlowPropertyCondition = 'ItemFlowPropertyCondition';
  public static readonly HasFoundFreeSpotCondition = 'HasFoundFreeSpotCondition';
  public static readonly IsModuleLockedCondition = 'IsModuleLockedCondition';
  public static readonly CountFreeModulesCondition = 'CountFreeModulesCondition';
  public static readonly CheckModuleInAreaCondition = 'CheckModuleInAreaCondition';
  public static readonly ConditionImpl = 'ConditionImpl';

  public static readonly SetItemFlowPropertyAction = 'SetItemFlowProperty';
  public static readonly AddAllowedOutletsAction = 'AddAllowedOutlets';
  public static readonly RequestAllowedOutletsAction = 'RequestAllowedOutlets';
  public static readonly SetItemDistanceAction = 'SetItemDistance';
  public static readonly SetTimeDistanceAction = 'SetTimeDistance';
  public static readonly SetTargetAction = 'SetTarget';
  public static readonly FireSetAllowedOutletsAction = 'FireSetAllowedOutlets';
  public static readonly AddPropagationTimeScoreModifierAction = 'AddPropagationTimeScoreModifier';
  public static readonly AddSequentialScoreModifierAction = 'AddSequentialScoreModifier';
  public static readonly AddConsecutiveForbiddenScoreModifierAction = 'AddConsecutiveForbiddenScoreModifier';
  public static readonly AddColorBlockScoreModifierAction = 'AddColorBlockScoreModifier';
  public static readonly SetSubTargetAction = 'SetSubTarget';
  public static readonly StoreAndLockAction = 'StoreAndLock';
  public static readonly ReleaseItemLockAction = 'ReleaseItemLock';
  public static readonly RouteToStorageAction = 'RouteToStorage';
  public static readonly SendJmsMessageAction = 'SendJmsMessage';
  public static readonly AdvancedAction = 'Advanced'; // advanced action
}


export interface RuleDefinitionsModel {
  eventTypes: EventType[];

  // what kind of group conditions do we have?
  coalitionTypes: string[];

  operatorTypes: string[];

  actionTypes: ActionType[];

  queryTypes: QueryType[];

  // Lists all possible MDS Attributes to choose from (from all Entities)
  mdsEntityAttributes: MdsFieldDefinition[];

  itemFlowAttributes: string[];
  identityAttributes: string[];
}

export interface MdsEntityAttribute {
  id: number;
  entityTypeName: string;
  attributeName: string;
}

export interface MdsFieldDefinition extends MdsEntityAttribute {
  simpleField: boolean;
  reference: string;
  description: string;
  startIndex: number;
  stopIndex: number;
}
