import {Injectable} from '@angular/core';
import {SkwApiService} from 'skw-ui-bootstrap';
import {Observable} from 'rxjs';

export class SupplyLineLocks {
  system: string;
  lineName: string;
  moduleIn: string;
  moduleInLocked: boolean;
  moduleOut: string;
  moduleOutLocked: boolean;
  moduleTransition: string;
  moduleTransitionLocked: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class LockSupplyLineService {

  constructor(private api: SkwApiService) {
  }

  getSupplyLines(server: string): Observable<SupplyLineLocks[]> {
    return this.api.get<SupplyLineLocks[]>(`/process/managelocks/${server}/supplyLines`);
  }

  updateSupplyLines(server: string, model: SupplyLineLocks[]): Observable<void> {
    return this.api.post<void>(`/process/managelocks/${server}/supplyLines`, model);
  }

}
