import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {RuleConstants} from '../../rule-definitions-model';

@Component({
  selector: 'app-add-condition-modal',
  templateUrl: './add-condition-modal.component.html',
  styleUrls: ['./add-condition-modal.component.scss']
})
export class AddConditionModalComponent {

  conditionTypes = [
    RuleConstants.CarbodyPropertyCheckCondition,
    RuleConstants.CarbodyPropertyCondition,
    RuleConstants.EventPropertyCondition,
    RuleConstants.ItemFlowPropertyCondition,
    RuleConstants.HasFoundFreeSpotCondition,
    RuleConstants.IsModuleLockedCondition,
    RuleConstants.CountFreeModulesCondition,
    RuleConstants.CheckModuleInAreaCondition,
    RuleConstants.ConditionImpl
  ];

  constructor(public dialogRef: MatDialogRef<AddConditionModalComponent>) {
  }

  chooseCondition(carbodyPropertyCondition: string) {
    this.dialogRef.close(carbodyPropertyCondition);
  }
}
