import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {Action, EventType, LiteralOperand, Operand} from '../../condition-model';
import {RulesService} from '../../../rules.service';
import {Subscription} from 'rxjs';
import {RuleEditService} from '../../rule-edit.service';
import {SkwSelectModel} from 'skw-ui-components';

@Component({
  selector: 'app-rule-action-store-and-lock',
  templateUrl: './rule-action-store-and-lock.component.html',
  styleUrls: ['./rule-action-store-and-lock.component.scss']
})
export class RuleActionStoreAndLockComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() editMode: boolean;
  @Input() triggerContext: EventType;

  @ViewChild('alert') alertTemplate: TemplateRef<any>;

  $subscription: Subscription;

  _action: Action;

  _propValueIdx: number;
  _propValue: string;

  _lockTypeIdx: number;
  _lockTypeValue: string;
  lockTypeValues: SkwSelectModel<LiteralOperand>[];
  operandEquals = (o1: LiteralOperand, o2: LiteralOperand) => !o1 || !o2 ? o1 === o2 : o1.value === o2.value;

  @Input()
  set action(action: Action) {
    this._action = action;

    this._propValueIdx = (action.actionType.parameters as any).reason.ordinal;
    this._propValue = (this._action.operands[this._propValueIdx] as LiteralOperand).value.toString();


    this._lockTypeIdx = (action.actionType.parameters as any).lockType.ordinal;
    this._lockTypeValue = (this._action.operands[this._lockTypeIdx] as LiteralOperand).value.toString();
  }

  @Output() actionChange: EventEmitter<Action> = new EventEmitter<Action>();

  constructor(private rulesService: RulesService,
              private ruleEditService: RuleEditService) {
    this.lockTypeValues = [
      {
        value: {type: 'LiteralOperand', value: 'ITEM_LOCK'} as LiteralOperand,
        viewValue: 'pages.rules.edit.StoreAndLockAction.lockType.ITEM_LOCK'
      },
      {
        value: {type: 'LiteralOperand', value: 'KNR_LOCK'} as LiteralOperand,
        viewValue: 'pages.rules.edit.StoreAndLockAction.lockType.KNR_LOCK'
      },
      {
        value: {type: 'LiteralOperand', value: 'KVS_LOCK'} as LiteralOperand,
        viewValue: 'pages.rules.edit.StoreAndLockAction.lockType.KVS_LOCK'
      },
      {
        value: {type: 'LiteralOperand', value: 'CTRL_LOCK'} as LiteralOperand,
        viewValue: 'pages.rules.edit.StoreAndLockAction.lockType.CTRL_LOCK'
      }
    ];
  }

  ngOnDestroy() {
    this.$subscription.unsubscribe();
    this.ruleEditService.removeAlert(this.alertTemplate);
  }

  ngOnInit() {
    this.$subscription = this.ruleEditService.changeObservable()
      .subscribe(s => {
        this.action = this._action;
      });
  }

  reasonChange($event: string) {
    (this._action.operands[this._propValueIdx] as LiteralOperand).value = $event;
    this.ruleEditService.emitUpdateRule();
    this.actionChange.emit(this._action);
  }


  lockTypeChange(operand: Operand) {
    this._action.operands[this._lockTypeIdx] = operand;
    this.ruleEditService.emitUpdateRule();
    this.actionChange.emit(this._action);
  }

  ngAfterViewInit(): void {
    if (!this.editMode) {
      this.ruleEditService.addAlert(this.alertTemplate);
    }
  }
}
