import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Action, EventType, LiteralOperand, Operand} from '../../condition-model';
import {SkwSelectModel} from 'skw-ui-components';
import {RulesService} from '../../../rules.service';
import {forkJoin, Subscription} from 'rxjs';
import {RuleEditService} from '../../rule-edit.service';
import {ItemFlowSettingsService} from '../../../../settings/item-flow-settings.service';
import {SkwLanguageService} from 'skw-ui-translation';

@Component({
  selector: 'app-rule-action-set-item-flow-property',
  templateUrl: './rule-action-set-item-flow-property.component.html',
  styleUrls: ['./rule-action-set-item-flow-property.component.scss']
})
export class RuleActionSetItemFlowPropertyComponent implements OnInit, OnDestroy {
  @Input() editMode: boolean;
  @Input() triggerContext: EventType;

  $subscription: Subscription;

  private $translationSubscription: Subscription;

  _action: Action;

  _propName: string;
  _propNameIdx: number;
  _propValueIdx: number;

  @Input()
  set action(action: Action) {
    this._action = action;

    this._propNameIdx = (action.actionType.parameters as any).prop_name.ordinal;
    this._propName = (action.operands[this._propNameIdx] as LiteralOperand).value as string;

    this._propValueIdx = (action.actionType.parameters as any).prop_value.ordinal;

    forkJoin(
      this.rulesService.getRuleDefinitionsModel(),
      this.itemFlowSettingsService.loadAll()
    ).subscribe(value => {
      if (typeof this.$translationSubscription !== 'undefined') {
        this.$translationSubscription.unsubscribe();
      }
      this.$translationSubscription = this.translationService.observable()
        .subscribe(lang => {
          this.itemFlowAttributes = value[0].itemFlowAttributes
            .map(attr => {
              const itemFlowSettings = value[1].find(i => attr === i.attributeName);
              const translated = itemFlowSettings ? itemFlowSettings.translations[lang] : attr;
              return {
                value: attr,
                viewValue: translated ? translated : attr
              } as SkwSelectModel<string>;
            });
        });
      this.cdr.detectChanges();
    });
  }


  @Output() actionChange: EventEmitter<Action> = new EventEmitter<Action>();

  itemFlowAttributes: SkwSelectModel<string>[];

  constructor(private rulesService: RulesService,
              private ruleEditService: RuleEditService,
              private itemFlowSettingsService: ItemFlowSettingsService,
              private translationService: SkwLanguageService,
              private cdr: ChangeDetectorRef) {
  }

  setPropName($event: string) {
    this._propName = $event;
    (this._action.operands[this._propNameIdx] as LiteralOperand).value = $event;
    this.ruleEditService.emitUpdateRule();
    this.actionChange.emit(this._action);
  }

  ngOnDestroy() {
    this.$subscription.unsubscribe();
    if (typeof this.$translationSubscription !== 'undefined') {
      this.$translationSubscription.unsubscribe();
    }
  }

  translateProperty(value: string): string {
    if (this.itemFlowAttributes) {
      const translation = this.itemFlowAttributes.find(a => a.value === value);
      return translation ? translation.viewValue : value;
    }
    return undefined;
  }

  ngOnInit() {
    this.$subscription = this.ruleEditService.changeObservable()
      .subscribe(s => {
        this.action = this._action;
      });
  }

  operandChange($event: Operand) {
    this._action.operands[this._propValueIdx] = $event;
    this.ruleEditService.emitUpdateRule();
    this.actionChange.emit(this._action);
  }
}
