import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {
  prepare,
  SkwDialogService,
  SkwErrorHandlerService,
  SkwFilterElement,
  SkwSnackBarService
} from 'skw-ui-components';
import {
  SkwFacetFilter,
  SkwFacetResult,
  SkwFacetSearchFactory,
  SkwFacetSearchService
} from '../../skw-search/skw-facet-search';
import {KvsOrderEntry, KvsOrderListService} from './kvs-order-list.service';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {KvsKvzApprovalDialogComponent} from './kvs-kvz-approval-dialog/kvs-kvz-approval-dialog.component';
import {WebsocketEventService} from '../../api/websocket/websocket-event.service';

@Component({
  selector: 'app-kvs-order-list',
  templateUrl: './kvs-order-list.component.html',
  styleUrls: ['./kvs-order-list.component.scss']
})
export class KvsOrderListComponent implements OnInit, OnDestroy {


  facetSearchObject: SkwFacetSearchFactory<KvsOrderEntry>;
  kvsOrders: KvsOrderEntry[] = []; // filtered
  facets: SkwFacetResult<KvsOrderEntry>[] = [];


  private ordersSubscription: Subscription;
  private facetsSubscription: Subscription;

  loading$ = new BehaviorSubject<boolean>(true);


  searchString: string;
  activatedColumns = ['row', 'system', 'colorCodeOriginal', 'colorDescription', 'lackNr', 'itemId', 'commission',
  'colorCodeKVS', 'colorGroupCode', 'status', 'fmrApproved', 'fssApproved', 'rspApproved', 'kvzApproved', 'tps', 'timestamp'];
  allCount: number;


  constructor(searchService: SkwFacetSearchService,
              public kvsOrderListService: KvsOrderListService,
              private cdr: ChangeDetectorRef,
              private errorHandlerService: SkwErrorHandlerService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private dialogService: SkwDialogService,
              private snackBar: SkwSnackBarService,
              private errorHandler: SkwErrorHandlerService,
              private websocketEventService: WebsocketEventService) {
    this.facetSearchObject = searchService.get<KvsOrderEntry>()
      .addPropertyValue(entry => entry.id, undefined, false)
      .addPropertyValue(entry => entry.system, e => e.system, true, 'pages.kvs.order-list.system')
      .addPropertyValue(entry => entry.itemId ? entry.itemId : '-', undefined, false)
      .addPropertyValue(entry => entry.colorCodeKVS ? entry.colorCodeKVS : '-', undefined, false)
      .addPropertyValue(entry => entry.colorCodeOriginal ? entry.colorCodeOriginal : '-', undefined, false)
      .addPropertyValue(entry => entry.colorGroupCode ? entry.colorGroupCode : '-', undefined, false)
      .addPropertyValue(entry => entry.colorDescription ? entry.colorDescription : '-', undefined, false)
      .addPropertyValue(entry => entry.lackNr ? entry.lackNr : '-', undefined, false)
      .addPropertyValue(entry => entry.commission ? entry.commission : '-', undefined, false)
      .addPropertyValue(entry => entry.fmrApproved, undefined, false)
      .addPropertyValue(entry => entry.fssApproved, undefined, false)
      .addPropertyValue(entry => entry.rspApproved, undefined, false)
      .addPropertyValue(entry => entry.status ? entry.status: '-', undefined, false)
      .addPropertyValue(entry => entry.tps, undefined, false)
      .addPropertyValue(entry => entry.kvzApproved, e => 'pages.kvs.order-list.kvzApproved-' + e.kvzApproved, true, 'pages.kvs.order-list.kvzApproved');

    this.ordersSubscription = this.facetSearchObject.filteredValuesObservable()
      .subscribe(r => {
        // this loading is in combination with detect changes and set timeout a trick to show a loader while the table is rendered
        this.loading$.next(true);
        setTimeout(() => {
          this.kvsOrders = r;
          this.loading$.next(false);
          this.cdr.markForCheck();
        });
      });
    this.facetsSubscription = this.facetSearchObject.facetsObservable()
      .subscribe(r => {
        this.facets = r;
        this.cdr.markForCheck();
      });

    // when the mds attributes change, clear the context cache, since it contains a list of mds attributes
    websocketEventService.onWebsocketEvent().subscribe(event => {
      if (event.name === 'KvsOrderListChanged') {
        this.onRefreshClick();
      }
    });
  }


  ngOnInit() {
    const routeFilter = this.activatedRoute.snapshot.queryParams.filter;
    if (routeFilter) {
      this.searchString = routeFilter;
      this.kvsOrderListService.filter.searchString = routeFilter;
    } else {
      this.searchString = this.kvsOrderListService.filter.searchString;
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: {
          filter: this.searchString
        },
        queryParamsHandling: 'merge'
      });
    }
    this.facetSearchObject.submitSearchString(this.searchString);
    this.facetSearchObject.submitFacetFilter(this.kvsOrderListService.filter.facets);
    this.loadOrderList();
  }

  ngOnDestroy() {
    this.ordersSubscription.unsubscribe();
    this.facetsSubscription.unsubscribe();
  }


  onRefreshClick() {
    this.loadOrderList();
  }

  onFilter($event: any) {
    this.searchString = $event;
    // update query parameters without reloading the page
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        filter: $event
      },
      queryParamsHandling: 'merge'
    });
    this.kvsOrderListService.filter.searchString = $event;
    this.facetSearchObject.submitSearchString($event);

  }

  removeFilter($event: SkwFilterElement) {
    // remove the filter!
    this.kvsOrderListService.filter.filters = this.kvsOrderListService.filter.filters.filter(e => !(e.name === $event.name && e.value === $event.value));
    this.kvsOrderListService.filter.facets =
      this.kvsOrderListService.filter.facets.filter(e => !(e.property.name === $event.name && e.value === $event.value));
    this.facetSearchObject.submitFacetFilter(this.kvsOrderListService.filter.facets);
  }

  onFacetSearch($event: SkwFacetFilter<KvsOrderEntry>[]) {
    this.kvsOrderListService.filter.facets = $event;
    this.facetSearchObject.submitFacetFilter($event);
  }

  private loadOrderList() {

    this.kvsOrderListService.searchKvsOrders()
      .pipe(
        prepare(() => this.loading$.next(true))
        // the loader must be closed after rendering manually, see callback of the facet search object!
      )
      .subscribe(a => {
        this.allCount = a.allCount;
        this.facetSearchObject.submitValues(a.result);
        this.cdr.markForCheck();
      }, error => this.errorHandlerService.handleHttpError(error));
  }

  approveFss(entry: KvsOrderEntry) {
    this.dialogService.componentDialog(KvsKvzApprovalDialogComponent, {
      data: entry.itemId,
      maxWidth: '500px'
    })
      .afterClosed()
      .subscribe(({approve}) => {
          if (approve) {
            this.kvsOrderListService.approveEntry(entry)
              .subscribe(() => {
                this.snackBar.showInfo('pages.kvs.approve-triggered');
              }, error => this.errorHandler.handleHttpError(error));
          }
        }
      );
  }
}
