import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {SkwErrorHandlerService, SkwSnackBarService} from 'skw-ui-components';
import {SkwFileUploadComponent, SkwFileUploadOptions} from '../../../skw-file-upload/file-upload/file-upload.component';
import {SkwApiService} from 'skw-ui-bootstrap';
import {SkwTokenService} from 'skw-ui-authentication';

@Component({
  selector: 'app-simulation-import',
  templateUrl: './simulation-import.component.html',
  styleUrls: ['./simulation-import.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimulationImportComponent implements OnInit {
  @ViewChild('uploader') uploader: SkwFileUploadComponent;

  loading$ = new Subject<boolean>();
  fileReady = false;
  uploadOptions: SkwFileUploadOptions;

  constructor(private errorHandlerService: SkwErrorHandlerService,
              private snackBar: SkwSnackBarService,
              private apiService: SkwApiService,
              private tokenService: SkwTokenService,
              private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.apiService.getBootstrap()
      .subscribe(b => {
        this.uploadOptions = {
          url: `${b.apiProtocol}://${b.apiRootUrl}/discreteSim/import/csv`,
          authToken: `Bearer ${this.tokenService.getToken()}`,
          disableMultipart: false,
          multiple: false,
          allowedFileExtensions: ['csv'],
          maxFileSize: 20 * 1024 * 1024 // 20 MB
        };
        this.cdr.detectChanges();
      });
  }

  onUpload(result: any) {
    if (!result) {
      this.onUploadFailed(result);
      return;
    }
    if (typeof result.inserted === 'undefined') {
      this.onUploadFailed(result);
      return;
    }
    this.snackBar.showInfo('pages.simulation.csv-import-success', {count: result.inserted});

    this.loading$.next(false);
    this.uploader.reset();
    this.cdr.detectChanges();
  }

  onUploadFailed(error: any) {
    this.errorHandlerService.handleHttpError(error);
    this.loading$.next(false);
  }

  doUpload() {
    this.loading$.next(true);
    this.uploader.uploadAll();
    this.fileReady = false;
  }

  fileSelected(fileCount) {
    this.fileReady = fileCount > 0;
    this.cdr.detectChanges();
  }
}
