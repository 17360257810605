import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MfwItem} from 'skw-ui-modules';
import {SkwApiService} from 'skw-ui-bootstrap';
import {SkwErrorHandlerService, SkwSelectModel, SkwSnackBarService} from 'skw-ui-components';

@Component({
  selector: 'app-manual-sub-target-form',
  templateUrl: './manual-sub-target-form.component.html',
  styleUrls: ['./manual-sub-target-form.component.scss']
})
export class ManualSubTargetFormComponent {

  selectedTarget: string;
  @Input() layoutId: string;
  targets: SkwSelectModel<string>[];
  @Output() select = new EventEmitter<any>();

  @Input() set item(value: MfwItem) {
    this._item = value;
    this.loadReachableTargets();
  }

  private _item: MfwItem;

  constructor(private api: SkwApiService,
              private snackBar: SkwSnackBarService,
              private errorHandler: SkwErrorHandlerService) {
  }

  onSelectTarget() {
    if (!this.selectedTarget) {
      return;
    }
    const options = {
      params: {
        itemId: this._item.id
      } as any
    };
    if (this.selectedTarget !== 'NULL') {
      // only add param if not null
      options.params.targetId = this.selectedTarget;
    }
    this.api.post(`/process/assignsubtarget/${this.layoutId}`, undefined, options)
      .subscribe(() => {
        this.snackBar.showInfo('item-modal.subtarget-setting-triggered');
        this.select.emit();
      }, error => {
        console.log(error);
        if (error.error && error.error.message && error.error.message === 'subTarget isn\'t valid anymore') {
          this.snackBar.showInfo('item-modal.subtarget-setting-failed');
          this.loadReachableTargets();
          this.selectedTarget = undefined;
          return;
        }
        this.errorHandler.handleHttpError(error);
      });
  }

  loadReachableTargets() {

    this.api.get<string[]>(`/process/assignsubtarget/${this.layoutId}/reachableTargets`, {
      params: {
        itemId: this._item.id,
        source: 'RB002' // FIXME
      }
    }).subscribe(r => {
      this.targets = r.map(t => {
        return {
          value: t,
          viewValue: t
        } as SkwSelectModel<string>;
      });
      // Add empty option
      this.targets = [{
        viewValue: 'item-modal.empty-sub-target',
        value: 'NULL'
      }, ...this.targets];
    });
  }
}
