import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HomeComponent} from './home.component';
import {SkwLanguageModule} from 'skw-ui-translation';
import {SkwPageLayoutModule} from 'skw-ui-layout';
import {
  SkwBadgeModule,
  SkwButtonModule,
  SkwCardModule,
  SkwHintModule,
  SkwLinkModule,
  SkwLoadingModule,
  SkwTableModule
} from 'skw-ui-components';
import {MatMenuModule} from '@angular/material/menu';
import {SkwAuthorizationModule} from 'skw-ui-authentication';

@NgModule({
  declarations: [HomeComponent],
  imports: [
    CommonModule,
    SkwLanguageModule,
    SkwPageLayoutModule,
    SkwCardModule,
    SkwLoadingModule,
    SkwTableModule,
    SkwButtonModule,
    SkwLinkModule,
    SkwBadgeModule,
    SkwHintModule,
    MatMenuModule,
    SkwAuthorizationModule
  ]
})
export class HomeModule {
}
