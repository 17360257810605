import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {RULE_MODEL, RuleTemplate, RuleTemplateModel, RuleTemplateWizardConfig, WIZARD_DATA} from '../rule-template';
import {Rule, RulesService, RuleType} from '../../rules.service';
import {SkwTranslatableValue} from 'skw-ui-translation';
import {SkwSelectModel} from 'skw-ui-components';
import {MdsFieldDefinition, RuleDefinitionsModel} from '../../rule-definitions-model';
import {map} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {RuleTemplateWizardValidationService} from '../rule-template-wizard-validation.service';
import {Kid14} from '../../../../audi-common/kid14';
import {MdsFieldDefinition as MdsAttribute, MdsService} from '../../../mds/mds.service';

export class RuleTemplateKnrLockModel extends RuleTemplateModel {
  // check condition
  attribute: MdsFieldDefinition;
  attributeValues: string[];
  // store & lock action
  reason: string;
  reevaluateLocks = true;
}

export class RuleTemplateKnrLockWizardConfig implements RuleTemplateWizardConfig {
  templateName: 'knr-lock';
  data: { attributeValue: string };
}

export class RuleTemplateKnrLock implements RuleTemplate<RuleTemplateKnrLockModel> {
  createRule(model: RuleTemplateKnrLockModel, ruleDefinitionsModel: RuleDefinitionsModel): Rule {
    return {
      id: undefined,
      ruleKey: model.ruleKey,
      name: model.name,
      description: model.description,
      trigger: 'ItemNewToSystemEvent',
      type: RuleType.CONTROL,
      enabled: true,
      accessLevel: 'USER',
      priority: model.priority,
      ruleCategory: model.ruleCategory,
      serializedJson: {
        ruleKey: model.ruleKey,
        name: model.name,
        description: model.description,
        trigger: ruleDefinitionsModel.eventTypes.find(a => a.name === 'ItemNewToSystemEvent'),
        enabled: true,
        ruleCategory: model.ruleCategory,
        condition: {
          coalationType: 'AND',
          conditions: [
            {
              entityTypeName: model.attribute.entityTypeName,
              attributeName: model.attribute.attributeName,
              attributeId: model.attribute.id,
              operator: {
                type: model.attributeValues.length > 1 ? 'ContainsOperator' : 'EqualsOperator'
              },
              value: model.attributeValues.join(','),
              type: 'CarbodyPropertyCondition'
            }
          ],
          type: 'ConditionGroupImpl'
        },
        actions: [
          {
            actionType: ruleDefinitionsModel.actionTypes.find(a => a.name === 'StoreAndLock'),
            operands: [
              {
                eventVariableName: '$event',
                attributeName: 'itemId',
                type: 'EventAttributeOperand'
              },
              {
                value: model.reason,
                type: 'LiteralOperand'
              },
              {
                value: 'KNR_LOCK',
                type: 'LiteralOperand'
              }
            ]
          }
        ],
        accessLevel: 'USER',
        priority: model.priority
      },
      created: undefined,
      updated: undefined,
      actions: [
        'SetTarget',
        'StoreAndLock'
      ]
    } as Rule;
  }

  get templateName() {
    return 'knr-lock';
  }

  get componentType() {
    return RuleTemplateKnrLockComponent;
  }
}

@Component({
  selector: 'app-rule-template-knr-lock',
  templateUrl: './rule-template-knr-lock.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RuleTemplateKnrLockComponent {
  mdsEntityAttributeNames$ = this.rulesService.getRuleDefinitionsModel()
    .pipe(map(value => value.mdsEntityAttributes
      .filter(fieldDefinition => value.identityAttributes.includes(`${fieldDefinition.entityTypeName}.${fieldDefinition.attributeName}`))
      .map(fieldDefinition => {
        return {
          value: fieldDefinition,
          viewValue: {
            key: 'pages.mds.entityTypesPrefix.' + fieldDefinition.entityTypeName,
            args: {name: fieldDefinition.attributeName}
          } as SkwTranslatableValue
        } as SkwSelectModel<MdsFieldDefinition>;
      })));

  mdsEntityAttributeValues$ = new Subject<SkwSelectModel<string>[]>();
  allValid = true;
  incompatibleLength = false;
  selectedAttribute: MdsAttribute;

  constructor(@Inject(RULE_MODEL) public templateModel: RuleTemplateKnrLockModel,
              @Inject(WIZARD_DATA) public wizardData: RuleTemplateKnrLockWizardConfig,
              public validationService: RuleTemplateWizardValidationService,
              private rulesService: RulesService,
              private mdsService: MdsService) {
    if (wizardData.data && wizardData.data.attributeValue) {
      // if there is a predefined value
      this.onValueSelect([wizardData.data.attributeValue]);
    }
    // set default attribute
    this.mdsEntityAttributeNames$.subscribe(attributes => {
      this.templateModel.attribute = attributes[0].value;
      this.mdsService.getAttribute(attributes[0].value.id)
        .subscribe(attr => this.selectedAttribute = attr);
    });
    this.templateModel.reevaluateLocks = true;
  }

  // load attribute values after selected attribute has changed
  onAttributeSelect($event: MdsFieldDefinition) {
    console.log($event);
    this.mdsService.getAttribute($event.id)
      .subscribe(attr => this.selectedAttribute = attr);

    this.rulesService.getCarBodyAttributeValuesFromId($event.entityTypeName, $event.id)
      .subscribe(r => {
        if (!r) {
          this.mdsEntityAttributeValues$.next([]);
          return;
        }
        this.mdsEntityAttributeValues$.next(r.map(a => {
          return {
            value: a.value.toString(),
            viewValue: a.viewValue
          } as SkwSelectModel<string>;
        }));
      });
  }

  onValueSelect(value: string[]) {
    const ruleName = value && value.length > 1 && this.templateModel.reason ? this.templateModel.reason.replace(/[\W_]+/g, '') : `${value}`;
    this.templateModel.attributeValues = value;
    this.templateModel.ruleKey = `KNR-Sperre_${ruleName}`;
    this.templateModel.name = `Sperre setzen - KNR_${ruleName}`;
    this.templateModel.description = `Sperre setzen - KNR ${value}`;
    this.templateModel.priority = 0;

    if (this.selectedAttribute && !this.selectedAttribute.isSimpleField) {
      const len = this.selectedAttribute.stopIndex - this.selectedAttribute.startIndex;
      this.incompatibleLength = value.some(kid => kid.length > len);
    } else {
      this.incompatibleLength = false;
    }

    this.allValid = !value.map(kid => new Kid14(kid))
      .some(kid => !kid.isValid())
  }
}
