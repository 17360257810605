import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {EventWithLayout} from '../../pages/dashboard/dashboard.component';
import {WebsocketEventService} from '../../api/websocket/websocket-event.service';
import {Subscription} from 'rxjs';
import {SchedulerInfoComponent} from '../../pages/scheduler/scheduler-info/scheduler-info.component';
import {MfwModuleWithItemsModel} from 'skw-ui-modules';
import {SkwApiService} from 'skw-ui-bootstrap';
import {SkwDialogService, SkwErrorHandlerService, SkwSnackBarService, SkwStatusColor} from 'skw-ui-components';
import {DevelopmentServiceService} from '../../audi-common/development-service.service';
import {SkwSelectModel} from 'skw-ui-components/skw/input/select-input/skw-select-input';
import {SkwAuthorizationService} from "skw-ui-authentication";

@Component({
  selector: 'app-mfw-module-modal',
  templateUrl: './mfw-module-modal.component.html',
  styleUrls: ['./mfw-module-modal.component.scss']
})
export class MfwModuleModalComponent implements OnInit, OnDestroy {

  private $wsEventSubscription: Subscription;

  @ViewChild('schedulerInfo') schedulerInfo: SchedulerInfoComponent;
  @ViewChild('schedulerInfoDev') schedulerInfoDev: SchedulerInfoComponent;
  activeColumns: string[];
  devEnv = false;
  debug: boolean;
  lastEvaluation: any;
  localSequenceData: any;
  showMinDistanceRawData: boolean = false;
  dataSteps: SkwSelectModel<String>[] = [
    {value:'first', viewValue:'first'},
    {value:'last', viewValue:'last'},
    {value:'interims', viewValue:'interims'},
  ];
  dataStep: String = 'first';

  constructor(public dialogRef: MatDialogRef<MfwModuleModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: EventWithLayout,
              private api: SkwApiService,
              private developmentService: DevelopmentServiceService,
              private snackBar: SkwSnackBarService,
              private dialogService: SkwDialogService,
              private errorHandler: SkwErrorHandlerService,
              private wsEventService: WebsocketEventService,
              private changeDetectorRef: ChangeDetectorRef,
              private authorizationService: SkwAuthorizationService) {
  }

  ngOnInit(): void {
    this.developmentService.getDevelopmentMode().subscribe(active => this.devEnv = active);


    this.$wsEventSubscription = this.wsEventService.onModuleUpdateEvent().subscribe(event => {
      try {
        if (['module-update'].includes(event.name)
          && this.data && this.data.config && event.moduleId === this.data.config.id) {
          this.data.model = event.payload;
          if (this.schedulerInfo) { // scheduler info isn't available on all module dialogs
            this.schedulerInfo.triggerUpdateAvailable();
          }
          if (this.schedulerInfoDev) { // scheduler info isn't available on all module dialogs
            this.schedulerInfoDev.triggerUpdateAvailable();
          }
          this.changeDetectorRef.detectChanges();
        }
      } catch (e) {
        console.error(e);
      }
    });
  }

  ngOnDestroy(): void {
    this.$wsEventSubscription.unsubscribe();
  }

  get asMfwModuleWithItemModel() {
    return this.data ? this.data.model as MfwModuleWithItemsModel : undefined;
  }

  triggerModuleReload() {
    this.dialogService.submitDialog('module-modal.reload-submit', 'danger', {
      name: this.data.config.id
    }).subscribe(result => {
      if (result) {
        this.api.post<void>(`/process/module/${this.data.layoutId}/${this.data.config.id}/reload`, null)
          .subscribe(() => this.snackBar.showInfo('module-modal.reload-success', {
            name: this.data.config.id
          }), error => this.errorHandler.handleHttpError(error));
      }
    });
  }

  badgeColor(severity: string): SkwStatusColor {
    const s = severity ? severity.toUpperCase() : '';
    switch (s) {
      case 'ERROR':
        return 'danger';
      case 'ERROR_RECOVERABLE':
      case 'WARN':
        return 'warning';
      default:
        return 'primary';
    }
  }

  loadLastEvaluation() {

    if (!this.data.layoutId || !this.data.config.id)
      return;

    this.api.get<any>(`/process/module/${this.data.layoutId}/${this.data.config.id}/sequenceDebug`,
      { params: { step: `${this.dataStep}`}})
      .subscribe(result => this.lastEvaluation = result,
        error => this.errorHandler.handleHttpError(error))
  }

  loadData() {

    if (!this.data.layoutId || !this.data.config.id)
      return;

    this.api.get<any>(`/process/module/${this.data.layoutId}/${this.data.config.id}/sequenceDebug`)
      .subscribe(result => this.localSequenceData = result,
        error => this.errorHandler.handleHttpError(error))
  }
}
