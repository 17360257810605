import {Injectable} from '@angular/core';
import {SkwApiService} from 'skw-ui-bootstrap';
import {LayerStats} from '../../../api/websocket/layer-stats-update-event';
import {merge, Observable} from 'rxjs';
import {WebsocketEventService} from '../../../api/websocket/websocket-event.service';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LayerStatsService {
  constructor(private apiService: SkwApiService,
              private eventService: WebsocketEventService) {
  }

  public asObservable(): Observable<LayerStats> {
    return merge(
      this.apiService.get<LayerStats>('/layerstats'),
      this.eventService.onLayerStatsUpdateEvent().pipe(map(e => e.payload))
    );
  }
}
