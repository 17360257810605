import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CarbodyPropertyCondition, EventType, HasFoundFreeSpotCondition} from '../../condition-model';
import {nullSafeEquals, SkwSelectModel} from 'skw-ui-components';
import {RulesService} from '../../../rules.service';
import {MdsEntityAttribute} from '../../../rule-definitions-model';
import {OPERATOR_TYPES, OPERATOR_VALUE_MAPPING} from '../rule-condition-operators';

@Component({
  selector: 'app-rule-condition-has-found-free-spot',
  templateUrl: './rule-condition-has-found-free-spot.component.html',
  styleUrls: ['./rule-condition-has-found-free-spot.component.scss']
})
export class RuleConditionHasFoundFreeSpotComponent {
  @Input() editMode: boolean;
  @Output() conditionChange = new EventEmitter<HasFoundFreeSpotCondition>();

  _condition: HasFoundFreeSpotCondition;
  _attributeValues: SkwSelectModel<boolean>[] = [
    {value: true, viewValue: 'pages.rules.edit.HasFoundFreeSpotCondition.found'},
    {value: false, viewValue: 'pages.rules.edit.HasFoundFreeSpotCondition.notFound'},
  ];

  @Input()
  set condition(cond: HasFoundFreeSpotCondition) {
    this._condition = cond;
  }

  setConditionValue($event: boolean) {
    this._condition.hasFound = $event;
  }
}
