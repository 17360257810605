import { Inject, Injectable, Optional } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {WebsocketEventService} from "../../api/websocket/websocket-event.service";
import {KvsOrderListService} from "../../pages/kvs-order-list/kvs-order-list.service";

@Injectable({
  providedIn: 'root',
})
export class KvsNotificationService {
  private $count = new BehaviorSubject<number>(0);

  constructor(
      private websocketEventService: WebsocketEventService,
      private kvsOrderListService: KvsOrderListService
  ) {
    websocketEventService.onWebsocketEvent().subscribe(event => {
      if (event.name === 'KvsOrderListChanged') {
        this.setCount(event.payload);
      }
    });

    kvsOrderListService.getOpenKvzOrderCount().subscribe(count => this.setCount(count));
  }

  /**
   * sets the displayed count to a given value
   * @param count
   */
  setCount(count: number) {
    this.$count.next(count);
  }

  /**
   * This observable (hot!) emits the current number of orders which should be displayed
   * to the user.
   */
  countObservable(): Observable<number> {
    return this.$count.asObservable();
  }
}
