import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SkwSidebarLayoutComponent} from './skw-sidebar-layout/skw-sidebar-layout.component';
import {SkwSidebarDirective} from './skw-sidebar-layout/skw-sidebar.directive';
import {SkwLanguageModule} from 'skw-ui-translation';
import {SkwUtilsModule} from 'skw-ui-components';

const components = [
  SkwSidebarLayoutComponent,
  SkwSidebarDirective,
];

@NgModule({
  declarations: [...components],
  exports: [...components],
  imports: [
    CommonModule,
    SkwLanguageModule,
    SkwUtilsModule
  ]
})
export class SkwSidebarViewModule {
}
