import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CheckModuleInAreaCondition} from '../../condition-model';
import {mapSkwSelectableModel, SkwSelectModel} from 'skw-ui-components';
import {RulesService} from '../../../rules.service';

@Component({
  selector: 'app-rule-condition-check-module-in-area',
  templateUrl: './rule-condition-check-module-in-area.component.html',
  styleUrls: ['./rule-condition-check-module-in-area.component.scss']
})
export class RuleConditionCheckModuleInAreaComponent {
  @Input() editMode: boolean;
  @Output() conditionChange = new EventEmitter<CheckModuleInAreaCondition>();
  _contains: SkwSelectModel<boolean>[] = [
    {value: true, viewValue: 'pages.rules.edit.CheckModuleInAreaCondition.contains-true'},
    {value: false, viewValue: 'pages.rules.edit.CheckModuleInAreaCondition.contains-false'}
  ];
  _areas = this.ruleService.getAreaNames()
    .pipe(mapSkwSelectableModel(m => m));

  constructor(private ruleService: RulesService) {
  }

  _condition: CheckModuleInAreaCondition;

  @Input()
  set condition(cond: CheckModuleInAreaCondition) {
    this._condition = cond;
  }

}
