import {Component, EventEmitter, Input, Output} from '@angular/core';
import {LiteralOperand, LiteralValue} from '../../condition-model';
import {SkwSelectModel} from 'skw-ui-components';

@Component({
  selector: 'app-rule-operand-literal',
  templateUrl: './rule-operand-literal.component.html',
  styleUrls: ['./rule-operand-literal.component.scss']
})
export class RuleOperandLiteralComponent {
  _operand: LiteralOperand;
  lastValue: LiteralValue;

  @Input() editMode: boolean;

  @Input() literalType: string; // "Integer", "String"
  @Input() autocompleteValues: SkwSelectModel<string>[];

  @Input()
  set operand(operand: LiteralOperand) {
    this._operand = operand;
    this.lastValue = operand.value;
  }

  @Output() operandChange: EventEmitter<LiteralOperand> = new EventEmitter<LiteralOperand>();

  changeOperandValue($event: LiteralValue) {
    this.updateValue($event);
  }

  setNull(isNull: boolean) {
    if (isNull) {
      this.lastValue = this._operand.value;
      this.updateValue(undefined);
    } else {
      this.lastValue = (this.lastValue == undefined) ? RuleOperandLiteralComponent.getEmptyValueForType(this.literalType) : this.lastValue; // empty string is not undefined
      this.updateValue(this.lastValue);
    }
  }

  private updateValue(value: LiteralValue) {
    this._operand.value = value;
    this.operandChange.emit(this._operand);
  }

  private static getEmptyValueForType(type: string) {
    switch (type) {
      case 'Integer':
        return 0;
      case 'String':
      case 'All':
        return '';
    }
  }
}
