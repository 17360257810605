import {ChangeDetectionStrategy, Component, Inject, Input} from '@angular/core';
import {MFW_AREA_DEFAULT_CONFIG, MfwAreaConfig} from 'skw-ui-modules';

@Component({
  selector: 'app-operation-mode-icon',
  templateUrl: './operation-mode-icon.component.html',
  styleUrls: ['./operation-mode-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OperationModeIconComponent {
  _operationMode: string;
  @Input() set operationMode(value: string) {
    this._operationMode = !value ? undefined : value.toLocaleLowerCase();
  }

  @Input() showText = false;

  constructor(
    @Inject(MFW_AREA_DEFAULT_CONFIG) public defaultConfig: MfwAreaConfig
  ) {
  }
}

