import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'app-health-state-icon',
  templateUrl: './health-state-icon.component.html',
  styleUrls: ['./health-state-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HealthStateIconComponent {
  _healthState: string;
  @Input() set healthState(value: string) {
    this._healthState = !value ? undefined : value.toLocaleLowerCase();
  }

  @Input() showText = false;
}
