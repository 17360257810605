import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';

// TODO move to skw-angular-libs [SK]
@Component({
  selector: 'skw-interactive-element',
  templateUrl: './interactive-element.component.html',
  styleUrls: ['./interactive-element.component.scss'],
})
export class InteractiveElementComponent {
  @Input() interactive = true;

  @Output() select = new EventEmitter<MouseEvent>();
  @Output() delete = new EventEmitter<MouseEvent>();
  @Input() disabled = false;
  @Input() placeholder = false;
  @Input() deletable = false;
  @Input() selected = false;

  @ViewChild('button') button: ElementRef;

  onDelete($event: MouseEvent) {
    if (this.disabled) {
      return;
    }
    this.delete.emit($event);
    $event.stopPropagation();
  }

  onClick($event: MouseEvent) {
    if (this.disabled) {
      return;
    }
    this.select.emit($event);
    setTimeout(() => {
      this.button.nativeElement.blur();
    }, 300);
  }
}
