import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {SimulationControlService, SimulationServiceStatus, SimulationSettings} from '../simulation-control/simulation-control.service';
import {SkwErrorHandlerService, SkwSnackBarService} from 'skw-ui-components';

@Component({
  selector: 'app-simulation-general-settings',
  templateUrl: './simulation-general-settings.component.html',
  styleUrls: ['./simulation-general-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimulationGeneralSettingsComponent {
  objectKeys = Object.keys;
  private _status: SimulationServiceStatus;
  _settings: SimulationSettings;

  @Input() set status(value: SimulationServiceStatus) {
    this._status = value;
    if (!this._settings && this._status) { // copy to decouple from original object
      this._settings = {...value.settings};
    }
  }

  constructor(private simulationControlService: SimulationControlService,
              private snackBar: SkwSnackBarService,
              private errorHandler: SkwErrorHandlerService) {
  }

  init() {
    // reset settings object with the current status
    this._settings = {...this._status.settings};
  }

  saveSettings() {
    this.simulationControlService.updateSettings(this._settings)
      .subscribe(() => this.snackBar.showInfo('pages.simulation.settings.success-msg'),
        error => this.errorHandler.handleHttpError(error));
  }
}
