import {Injectable} from '@angular/core';
import {SkwApiService} from 'skw-ui-bootstrap';
import {Observable, Subject} from 'rxjs';
import {HttpResponse} from '@angular/common/http';
import {SkwDialogService} from 'skw-ui-components';
import {RuleImportDialogComponent} from './rule-import/rule-import-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class RuleExportImportService {

  constructor(private dialog: SkwDialogService,
              private apiService: SkwApiService) {
  }

  exportRules(ids?: number[]): Observable<HttpResponse<Blob>> {
    const params = !ids ? undefined : {
      selectedRuleIds: ids.map(i => `${i}`)
    };
    return this.apiService.download('/process/managerules/rules/export', undefined, undefined, undefined, params);
  }

  openImportDialog(): Observable<boolean> {
    const result = new Subject<boolean>();
    this.dialog.componentDialog(RuleImportDialogComponent, {
      width: '600px',
      disableClose: true
    })
      .afterClosed()
      .subscribe(r => {
        result.next(r);
        result.complete();
      }, e => result.error(e));
    return result.asObservable();
  }

}
