import {Component, Inject, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SystemHealthStatusService} from '../../../system-status/header-toolbar-portal/system-health-status.service';
import {indicate, SkwErrorHandlerService} from 'skw-ui-components';
import {AcceptItemsServiceService} from './accept-items-service.service';

@Component({
  selector: 'app-accept-items',
  templateUrl: './accept-items.component.html',
  styleUrls: ['./accept-items.component.scss']
})
export class AcceptItemsComponent implements OnInit {

  systemStatus$ = this.systemHealthStatusService.systemObservable(this.system);
  loading$ = new Subject<boolean>();

  constructor(public dialogRef: MatDialogRef<AcceptItemsComponent>,
              private systemHealthStatusService: SystemHealthStatusService,
              private acceptItemsService: AcceptItemsServiceService,
              private errorHandlerService: SkwErrorHandlerService,
              @Inject(MAT_DIALOG_DATA) private system: string) {
  }

  ngOnInit() {
  }

  activate() {
    this.acceptItemsService.updateMode(this.system, true)
      .pipe(indicate(this.loading$))
      .subscribe(() => this.dialogRef.close(),
        error => this.errorHandlerService.handleHttpError(error));
  }

  deactivate() {
    this.acceptItemsService.updateMode(this.system, false)
      .pipe(indicate(this.loading$))
      .subscribe(() => this.dialogRef.close(),
        error => this.errorHandlerService.handleHttpError(error));
  }
}
