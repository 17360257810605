import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'app-transporting-icon',
  templateUrl: './transporting-icon.component.html',
  styleUrls: ['./transporting-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransportingIconComponent {
  _transporting: boolean;
  @Input() set transporting(value: boolean) {
    this._transporting = value;
  }

  @Input() showText = false;
}
