import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SchedulerInfoComponent} from './scheduler-info/scheduler-info.component';
import {
  SkwAlertModule,
  SkwBadgeModule,
  SkwButtonModule,
  SkwIconModule,
  SkwListModule,
  SkwLoadingModule,
  SkwTableModule
} from 'skw-ui-components';
import {SkwLanguageModule} from 'skw-ui-translation';
import {AudiCommonModule} from '../../audi-common/audi-common.module';

@NgModule({
  declarations: [
    SchedulerInfoComponent
  ],
  exports: [
    SchedulerInfoComponent
  ],
    imports: [
        CommonModule,
        SkwButtonModule,
        SkwBadgeModule,
        SkwListModule,
        SkwTableModule,
        SkwIconModule,
        SkwAlertModule,
        SkwLoadingModule,
        SkwLanguageModule,
        AudiCommonModule
    ]
})
export class SchedulerModule {
}
