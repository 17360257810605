import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Action, EventType, Operand} from '../../condition-model';
import {SkwSelectModel} from 'skw-ui-components';
import {RulesService} from '../../../rules.service';
import {Subscription} from 'rxjs';
import {RuleEditService} from '../../rule-edit.service';

@Component({
  selector: 'app-rule-action-request-allowed-outlets',
  templateUrl: './rule-action-request-allowed-outlets.component.html',
  styleUrls: ['./rule-action-request-allowed-outlets.component.scss']
})
export class RuleActionRequestAllowedOutletsComponent implements OnInit, OnDestroy {
  @Input() editMode: boolean;
  @Input() triggerContext: EventType;

  $subscription: Subscription;

  _action: Action;

  @Input()
  set action(action: Action) {
    this._action = action;
  }


  @Output() actionChange: EventEmitter<Action> = new EventEmitter<Action>();


  constructor(private ruleEditService: RuleEditService,
              private cdr: ChangeDetectorRef) {
  }

  ngOnDestroy() {
    this.$subscription.unsubscribe();
  }

  ngOnInit() {
    this.$subscription = this.ruleEditService.changeObservable()
      .subscribe(s => {
        this.action = this._action;
      });
  }

  operandChange($event: Operand) {
    this.ruleEditService.emitUpdateRule();
    this.actionChange.emit(this._action);
  }
}
