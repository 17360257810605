import {Injectable} from '@angular/core';
import {SkwNotificationCenterService} from 'skw-ui-layout';
import {ErrorNotification, ErrorNotificationPage} from './notification';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {SkwApiService} from 'skw-ui-bootstrap';
import {SkwDialogService, SkwErrorHandlerService} from 'skw-ui-components';
import {NotificationCenterComponent} from './notification-center/notification-center.component';
import {INotificationCenterService} from './notification-center/notification-center-service.interface';
import {SkwAuthService} from 'skw-ui-authentication';


@Injectable({
  providedIn: 'root'
})
export class NotificationCenterService implements INotificationCenterService {

  private $moreToLoad = new BehaviorSubject<number>(0);
  private lastCount = 0;

  private static dummyNotifications(severity: string, count: number): ErrorNotification[] {
    const el: ErrorNotification[] = [];
    for (let i = 0; i < count; i++) {
      el.push({severity, unread: true} as ErrorNotification);
    }
    return el;
  }

  constructor(private dialog: SkwDialogService,
              private apiService: SkwApiService,
              private errorHandlerService: SkwErrorHandlerService,
              private authService: SkwAuthService,
              private service: SkwNotificationCenterService) {
    this.service.clickNotificationCenterObservable()
      .subscribe(() => {
        this.openNotificationCenterModal();
      });
    this.authService.authObservable()
      .subscribe(r => {
        if (r) {
          // load notification initially
          this.getNotifications(0);
        }
      });
  }

  openNotificationCenterModal() {
    this.dialog.componentDialog(NotificationCenterComponent, {
      data: this,
      width: '1000px',
      maxWidth: '100%'
    });
    this.service.ringTheBell(0);
  }

  markAsRead(el: ErrorNotification): Observable<boolean> {
    const result = new Subject<boolean>();
    this.apiService.post('/errors/markRead', undefined, {
      params: {
        id: `${el.id}`
      }
    }).subscribe(() => {
      // Simply mark the first entry as read, because we don't really care
      // of the notifications managed by the skw notification service.
      // We are only interested in the unread count:
      //this.service.markAsRead(0);

      result.next(true);
      result.complete();
    }, error => {
      this.errorHandlerService.handleHttpError(error);
      result.next(false);
      result.complete();
    });
    return result.asObservable();
  }

  markAllAsRead(): Observable<boolean> {
    const result = new Subject<boolean>();
    this.apiService.post('/errors/markRead/all', undefined)
      .subscribe(() => {
        //this.service.initNotifications([]); // mark all as read
        result.next(true);
        result.complete();
      }, error => {
        this.errorHandlerService.handleHttpError(error);
        result.next(false);
        result.complete();
      });
    return result.asObservable();
  }

  onNewNotification(event: ErrorNotification) {
    //this.service.pushNotification(event);
    //this.$moreToLoad.next(this.$moreToLoad.getValue() + 1);
    this.getNotifications(0);
    //this.service.ringTheBell(10000);
  }

  getMoreToLoadObservable(): Observable<number> {
    return this.$moreToLoad.asObservable();
  }

  getNotifications(page: number): Observable<ErrorNotificationPage> {
    const result = new Subject<ErrorNotificationPage>();
    // following is a sync between service and backend
    this.apiService.get<ErrorNotificationPage>('/errors/list', {
      params: {
        page: `${page}`,
        size: '10'
      }
    }).subscribe(r => {
      result.next(r);
      result.complete();


      // We don't really use the internal store of the skw notification service
      // so we just submit some dummy notifications to show a valid unread
      // count based on the returned attribute value:
      this.service.initNotifications(NotificationCenterService.dummyNotifications(
        this.service.findHighestOrderSeverity(r.unreadSeverities),
        r.unreadNotifications
      ));

      if (this.lastCount < r.unreadNotifications) {
        this.service.ringTheBell(20000);
      }

      this.lastCount = r.unreadNotifications;
      // reset more to load variable (all loaded)
      this.$moreToLoad.next(0);
    }, error => {
      this.errorHandlerService.handleHttpError(error);
      result.error(error);
    });
    return result.asObservable();
  }

}
