import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {SystemHealthStatusService} from '../header-toolbar-portal/system-health-status.service';
import {HEALTH_STATUS_MAPPING, SystemHealthStatus} from '../header-toolbar-portal/system-health-status';
import {Subscription} from 'rxjs';
import {MatDialogRef} from '@angular/material/dialog';
import {DashboardItemInfo} from '../../pages/home/home-dashboard.service';

@Component({
  selector: 'app-system-health-detail',
  templateUrl: './system-health-detail.component.html',
  styleUrls: ['./system-health-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SystemHealthDetailComponent implements OnInit, OnDestroy {

  private $subscription: Subscription;
  status: SystemHealthStatus[];
  _iconMapping = HEALTH_STATUS_MAPPING;

  constructor(private dialogRef: MatDialogRef<SystemHealthDetailComponent>,
              public statusService: SystemHealthStatusService,
              private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.$subscription = this.statusService.observable()
      .subscribe(v => {
        this.status = v;
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.$subscription.unsubscribe();
  }

  refresh() {
    this.statusService.load();
  }

  close() {
    this.dialogRef.close();
  }
}
