import {Injectable} from '@angular/core';
import {SkwApiService} from 'skw-ui-bootstrap';
import {map, shareReplay} from 'rxjs/operators';
import {Observable} from 'rxjs';

/** Project specific ui settings interface. */
export interface UiSettings {
  MAX_SECONDS_ITEM_ON_MODULE_UNTIL_WARN: UiSetting<number>[];
}

/** General ui setting object from db. */
export class UiSetting<T> {
  id: number;
  settingKey: string;
  value: T;
}

/** General ui settings which are loaded on initial app start and can be retrieved during the whole tab session until reload. */
@Injectable({
  providedIn: 'root'
})
export class UiSettingsService {
  /* Load and cache the ui settings during the whole session! */
  private readonly settings$ =
    this.apiService.get<UiSettings>('/settings/ui/')
      .pipe(shareReplay(1)); // cache this with shareReplay

  constructor(private apiService: SkwApiService) {
  }

  // ==== implement a custom getter method for every setting to decouple database keys and value types from ui code ====

  getMaxSecondsItemOnModuleUntilWarn(): Observable<number> {
    return this.settings$.pipe(map(r => r.MAX_SECONDS_ITEM_ON_MODULE_UNTIL_WARN ? (r.MAX_SECONDS_ITEM_ON_MODULE_UNTIL_WARN[0].value as number) : 1000));
  }
}
