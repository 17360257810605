import {Component, OnInit} from '@angular/core';
import {DevelopmentServiceService} from '../../audi-common/development-service.service';
import {SkwUserService} from 'skw-ui-authentication';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  dev: boolean;
  showOption: boolean;


  constructor(private developmentService: DevelopmentServiceService,
              private userService: SkwUserService) {
  }

  openDevelopmentMode() {
    this.developmentService.setDevelopmentMode(true);
  }

  closeDevelopmentMode() {
    this.developmentService.setDevelopmentMode(false);
  }

  ngOnInit(): void {
    this.developmentService.getDevelopmentMode()
      .subscribe(active => this.dev = active);

    this.userService.getUser().subscribe(
      user => {
        if (!user || !user.username) {
          console.error('Cannot determine, whether or not to show dev options. User is undefined');
          return;
        }
        if (['fhadsf2', 'admin'].includes(user.username.toLowerCase())) {
          this.showOption = true;
        }
      }
    )
  }
}
