import {ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {forkJoin, Observable, Subject} from 'rxjs';
import {MdsFieldDefinition, MdsService} from '../mds.service';
import {
  indicate,
  SkwDialogService,
  SkwErrorHandlerService,
  SkwSelectModel,
  SkwSnackBarService,
  TableSelectRequest
} from 'skw-ui-components';
import {MasterDetailComponent} from 'skw-ui-layout';
import {PurposeSettingsService} from '../../settings/purpose-settings.service';

@Component({
  selector: 'app-mds-carbody',
  templateUrl: './mds-carbody.component.html',
  styleUrls: ['./mds-carbody.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class MdsCarbodyComponent implements OnInit {

  loading$ = new Subject<boolean>();
  detailLoading$ = new Subject<boolean>();
  attributes: MdsFieldDefinition[];
  createNewMode = false;

  // save original name cause name is the id and can be renamed!
  originalAttributeName: string;
  // form model
  attribute: MdsFieldDefinition;

  searchString: string;

  baseAttributes: { [entity: string]: SkwSelectModel<string>[] } = {};
  baseAttributeExamples: any[];
  baseAttributeMaxLength = 0;
  baseAttributeType: string;

  purposes: string[] = [];

  // define all used EntityTypes here
  entities: SkwSelectModel<string>[] = [{
    value: 'CarBody',
    viewValue: 'Stammdaten'
  }, {
    value: 'CarbodyHistory',
    viewValue: 'Bewegungsdaten'
  }];

  get attributeStartIndex(): number {
    return this.attribute ? this.attribute.startIndex + 1 : undefined;
  }

  set attributeStartIndex(value: number) {
    if (this.attribute) {
      const diff = value - this.attribute.startIndex - 1;
      this.attribute.startIndex = value - 1;
      this.attribute.stopIndex += diff;
    }
  }

  get attributeLength(): number {
    return this.attribute ? this.attribute.stopIndex - this.attribute.startIndex : undefined;
  }

  set attributeLength(value: number) {
    if (this.attribute) {
      this.attribute.stopIndex = this.attribute.startIndex + value;
    }
  }

  @ViewChild('masterDetail') layout: MasterDetailComponent<MdsFieldDefinition>;

  constructor(private mdsService: MdsService,
              private snackBar: SkwSnackBarService,
              private errorHandlerService: SkwErrorHandlerService,
              private cdr: ChangeDetectorRef,
              private purposeService: PurposeSettingsService,
              private dialogService: SkwDialogService) {
  }

  ngOnInit() {
    this.loadAttributes();
  }

  onSelect($event: TableSelectRequest) {
    const element: MdsFieldDefinition = $event.last;
    this.createNewMode = false;
    if (!element) {
      this.closeDetail();
    } else {
      this.openDetail(element);
    }
  }

  onNewClick() {
    this.createNewMode = true;
    this.openDetail({
      id: undefined,
      name: undefined,
      entity: 'CarBody',
      sourceField: undefined,
      isSimpleField: true,
      startIndex: 0,
      stopIndex: 0,
      active: false,
      reference: undefined,
      isReferenceField: false
    } as MdsFieldDefinition);
  }

  onSelectEntity($event: string) {
    this.attribute.entity = $event;
    // reset some fields depending on new entity
    this.attribute.sourceField = undefined;
    this.loadBaseAttributes();
    this.baseAttributeExamples = [];
    this.baseAttributeType = undefined;
  }

  onSelectSourceField($event: string) {
    this.attribute.sourceField = $event;
    // reset some fields depending on new entity
    this.loadBaseAttributeExamples($event);
    this.loadBaseAttributeType($event);
  }

  onSave() {
    // validate model (TODO refactoring to do with form component)
    if (!this.attribute.isSimpleField) {
      if (this.attribute.startIndex < 0 || this.attribute.startIndex >= this.attribute.stopIndex
        // if baseAttributeMaxLength === 0 no examples were received and we skip checking the maximum length of the range:
        || (this.attribute.stopIndex > this.baseAttributeMaxLength && this.baseAttributeMaxLength !== 0)) {
        this.snackBar.showError('pages.mds.messages.invalidStartStop');
        return;
      }
    } else {
      this.attribute.startIndex = 0;
      this.attribute.stopIndex = this.baseAttributeMaxLength;
    }
    if (!this.attribute.name) {
      this.snackBar.showError('pages.mds.messages.invalidName');
      return;
    }
    if (!this.attribute.entity) {
      this.snackBar.showError('pages.mds.messages.invalidEntity');
      return;
    }
    if (!this.attribute.sourceField) {
      this.snackBar.showError('pages.mds.messages.invalidSource');
      return;
    }

    if (this.createNewMode) {
      this.mdsService.addAttribute(this.attribute)
        .pipe(indicate(this.detailLoading$))
        .subscribe(() => {
          this.closeDetail();
          this.loadAttributes();
          this.snackBar.showInfo('pages.mds.messages.addSuccess');
        }, error => this.errorHandlerService.handleHttpError(error));
    } else {
      this.mdsService.updateAttribute(this.attribute)
        .pipe(indicate(this.detailLoading$))
        .subscribe(() => {
          this.closeDetail();
          this.loadAttributes();
          this.snackBar.showInfo('pages.mds.messages.changeSuccess');
        }, error => this.errorHandlerService.handleHttpError(error));
    }
  }

  onDelete() {
    this.dialogService.submitDialog('pages.mds.submitDeleteMsg', 'danger', {name: this.originalAttributeName})
      .pipe(indicate(this.detailLoading$))
      .subscribe(result => {
          if (result) {
            this.mdsService.removeAttribute(this.attribute.id)
              .subscribe(() => {
                this.closeDetail();
                this.loadAttributes();
                this.snackBar.showInfo('pages.mds.messages.deleteSuccess');
              }, error => this.errorHandlerService.handleHttpError(error));
            this.closeDetail();
          }
        }
      );
  }

  onAbort() {
    this.closeDetail();
  }

  onRefreshClick() {
    this.loadAttributes();
    this.loadBaseAttributes();
  }

  onFilter($event) {
    this.searchString = $event;
    this.loadAttributes();
  }

  entityViewValue(value: string): string {
    return this.entities.find(e => e.value === value).viewValue;
  }

  private loadAttributes() {
    const entityObservables: Observable<MdsFieldDefinition[]>[] = [];
    this.entities.forEach(entity => {
      entityObservables.push(this.mdsService.listAttributes(entity.value, this.searchString));
    });
    forkJoin<MdsFieldDefinition[]>(...entityObservables)
      .pipe(indicate(this.loading$))
      .subscribe(a => {
        this.attributes = [];
        a.forEach(b => this.attributes.push(...b));
      }, error => this.errorHandlerService.handleHttpError(error));
  }

  private openDetail(element: MdsFieldDefinition) {
    this.attribute = {...element};
    this.originalAttributeName = element.name;
    this.loadBaseAttributes();
    this.loadBaseAttributeExamples(element.sourceField);
    this.loadBaseAttributeType(element.sourceField);
    this.loadPurposes();
    this.layout.openDetail(this.attribute);
  }

  private closeDetail() {
    this.attribute = undefined;
    this.originalAttributeName = undefined;
    this.baseAttributeExamples = [];
    this.baseAttributeMaxLength = 0;
    this.purposes = [];
    this.layout.closeDetail();
  }

  private loadBaseAttributeExamples(baseAttribute: string) {
    if (!baseAttribute) {
      this.baseAttributeExamples = [];
      this.baseAttributeMaxLength = 0;
    } else {
      this.baseAttributeExamples = [];
      this.baseAttributeMaxLength = 0;
      this.mdsService.listBaseAttributesExamples(this.attribute.entity, baseAttribute)
        .subscribe(result => {
          this.baseAttributeExamples = result;
          this.baseAttributeMaxLength = result.map(r => !r ? 0 : r.length)
            .reduce((prev, curr) => Math.max(prev, curr), 0);
        }, error => this.errorHandlerService.handleHttpError(error));
    }
  }

  private loadBaseAttributeType(baseAttribute: string) {
    if (!baseAttribute) {
      // entity not yet selected
      return;
    }
    this.mdsService.getBaseAttributeType(this.attribute.entity, baseAttribute).subscribe(result => {
      this.baseAttributeType = result[0];
      if (this.baseAttributeType !== 'String') {
        this.attribute.isSimpleField = true;
      }
    }, error => this.errorHandlerService.handleHttpError(error));
  }

  private loadBaseAttributes() {
    const entity = this.attribute ? this.attribute.entity : undefined;
    if (!entity || this.baseAttributes[entity]) {
      // already loaded or not required
      return;
    }
    this.mdsService.listBaseAttributes(entity)
      .subscribe(a => {
        this.baseAttributes[entity] = a.map(r => {
          return {
            value: r,
            viewValue: r
          } as SkwSelectModel<string>;
        });
      }, error => this.errorHandlerService.handleHttpError(error));
  }

  private loadPurposes() {
    if (this.attribute) {
      this.purposeService.loadPurposeForAttribute(this.attribute.entity, this.attribute.name)
        .subscribe(r => {
          this.purposes = r;
        });
    } else {
      this.purposes = [];
    }
  }

  onSimpleFieldChange($event: boolean) {
    if (this.attribute.isSimpleField && !$event) {
      // if this is activated now, fill default values
      this.attribute.startIndex = 0;
      this.attribute.stopIndex = this.baseAttributeMaxLength;
    }
    this.attribute.isSimpleField = $event;
  }

  onBooleanFieldChange($event: boolean) {
    this.attribute.isReferenceField = $event;
  }

}
