import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {RuleConstants, RuleDefinitionsModel} from '../rule-definitions-model';
import {AccessLevel, Rule, RulesService} from '../rules.service';
import {
  deepCopy,
  indicate,
  SkwAbstractUnsavedFormComponent,
  SkwDialogService,
  SkwErrorHandlerService,
  SkwPage,
  SkwSelectModel,
  SkwSnackBarService,
  SkwTreeViewModel
} from 'skw-ui-components';
import {ActivatedRoute, Router} from '@angular/router';
import {SkwLanguageService} from 'skw-ui-translation';
import {BehaviorSubject, combineLatest, Observable, Subject, Subscription} from 'rxjs';
import {
  Action,
  AnyCondition,
  CarbodyPropertyCheckCondition,
  CarbodyPropertyCondition,
  CheckModuleInAreaCondition,
  ConditionGroupModel,
  ConditionModel,
  CountFreeModulesCondition,
  EventAttributeOperand,
  EventPropertyCondition,
  EventType,
  HasFoundFreeSpotCondition,
  IsModuleLockedCondition,
  ItemFlowPropertyCondition,
  ListOperand,
  LiteralOperand,
  TupleOperator
} from './condition-model';
import {MasterDetailComponent} from 'skw-ui-layout';
import {AddConditionModalComponent} from './add-condition-modal/add-condition-modal.component';
import {AddActionModalComponent} from './add-action-modal/add-action-modal.component';
import {RuleProcessService} from '../rule-process.service';
import {SkwAudit} from '../../../skw-audit/audit';
import {map} from 'rxjs/operators';
import {RuleEditService} from './rule-edit.service';
import {CopyRuleDialogComponent} from './copy-rule-dialog/copy-rule-dialog.component';

export interface NewConditionModel {
  type: string;
  parent: ConditionGroupModel;
}

@Component({
  selector: 'app-rule-edit',
  templateUrl: './rule-edit.component.html',
  styleUrls: ['./rule-edit.component.scss']
})
export class RuleEditComponent extends SkwAbstractUnsavedFormComponent implements OnInit, OnDestroy, AfterContentChecked {
  ruleDefinitionsModel: RuleDefinitionsModel;

  loading$ = new Subject<boolean>();
  hasPrioAction$ = new BehaviorSubject<boolean>(true);

  private ruleId: number;
  newRule: boolean; // set to true if a new rule should be created and it has not yet been saved
  editMode: boolean;
  rule: Rule;
  selectableRuleCategory: SkwSelectModel<String>[];
  ruleAudit: SkwPage<SkwAudit>;
  accessLevel = AccessLevel;
  private ruleHistory: Rule;
  expertMode: boolean;

  ruleTreeModel: SkwTreeViewModel<AnyCondition | NewConditionModel>;

  @ViewChild('layout') layout: MasterDetailComponent<any>;

  // what component is used for editing?
  currentEditedType: string;
  currentEditedModel: any;

  alerts: TemplateRef<any>[] = [];
  private alertsSubscription: Subscription;

  // no quotation marks are allowed here
  nameValidation: (v: string) => string[] = v => {
    const result = [];
    if (v.includes("\"")) {
      result.push('quotations-found');
    }
    return result;
  };

  get unsavedChanges(): boolean {
    // we assume that there are unsaved changes if the edit mode is currently active
    return this.editMode;
  }

  constructor(private rulesService: RulesService,
              public route: ActivatedRoute,
              private languageService: SkwLanguageService,
              private dialogService: SkwDialogService,
              private ruleProcessService: RuleProcessService,
              private errorHandler: SkwErrorHandlerService,
              private router: Router,
              public ruleEditService: RuleEditService,
              private snackBar: SkwSnackBarService,
              private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    // get editMode
    combineLatest(
      this.route.params,
      this.route.queryParams
    ).subscribe(([params, queryParams]) => {
      this.ruleId = params.id;
      this.newRule = (queryParams.newRule === 'true');

      if (this.newRule) {
        // get a new empty rule
        this.rulesService.getNewEmptyRule()
          .pipe(indicate(this.loading$))
          .subscribe(rule => {
            this.ruleHistory = deepCopy(rule);
            this.rule = rule;
            this.initRuleDisplay(rule);
            this.checkPrioAction();
            this.editMode = true;
          });
      } else {
        // get the rule itself
        this.rulesService
          .getRule(this.ruleId)
          .pipe(indicate(this.loading$))
          .subscribe(rule => {
            this.ruleHistory = deepCopy(rule);
            this.rule = rule;
            this.initRuleDisplay(rule);
            this.checkPrioAction();
          }, error => this.errorHandler.handleHttpError(error));
        this.loadAudit();
      }
    });

    // get definitions for available options
    this.rulesService.getRuleDefinitionsModel().subscribe(value => {
      this.ruleDefinitionsModel = value;
    });

    this.rulesService.listRuleCategories().subscribe(ruleCategoryArray => {
      let ruleCategory = ruleCategoryArray.map(ruleCategory => {
        const skwSelectableRuleCategory: SkwSelectModel<String> = ({
          value: ruleCategory.categoryName,
          viewValue: ruleCategory.categoryName
        });
        return skwSelectableRuleCategory;
      });
      this.selectableRuleCategory = ruleCategory;
    });

  }

  ngAfterContentChecked(): void {
    this.alertsSubscription = this.ruleEditService.alertObservable()
      .subscribe(r => {
        this.alerts = r;
        this.cdr.markForCheck();
      });
  }

  ngOnDestroy(): void {
    if (typeof this.alertsSubscription !== 'undefined') {
      this.alertsSubscription.unsubscribe();
    }
  }

  private loadAudit() {
    this.rulesService
      .getRuleAudit(this.ruleId, 0, 10)
      .subscribe(history => this.ruleAudit = history, error => this.errorHandler.handleHttpError(error));
  }

  private initRuleDisplay(rule: Rule) {
    this.ruleTreeModel = this.getTreeModelForEditing(rule.serializedJson.condition);
  }

  private getTreeModelForEditing(condition: AnyCondition | NewConditionModel): SkwTreeViewModel<AnyCondition | NewConditionModel> {
    if (!condition) {
      return undefined;
    }
    const m = new SkwTreeViewModel<AnyCondition | NewConditionModel>();
    m.state = condition;
    m.children = [];

    // we have a cond group that consists of multiple conditions
    if (condition.type && condition.type === 'ConditionGroupImpl') {
      const conditionGroup = condition as ConditionGroupModel;
      for (const child of conditionGroup.conditions) {
        m.children.push(this.getTreeModelForEditing(child));
      }

      // add the "add new condition" placeholder
      m.children.push({
          state: {
            type: 'NewConditionModel',
            parent: condition
          } as NewConditionModel,
          children: []
        } as SkwTreeViewModel<NewConditionModel>
      );
    }

    return m;
  }

  startEditTrigger() {
    this.currentEditedType = 'trigger';
    this.currentEditedModel = this.rule.serializedJson; // to preserve references when modifying
    this.layout.openDetail(this.rule.serializedJson);
  }

  createCondition(parent: ConditionGroupModel) {
    this.dialogService.componentDialog(AddConditionModalComponent)
      .afterClosed().subscribe(value => {
      let newCondition;

      if (value === RuleConstants.CarbodyPropertyCondition) {
        newCondition = {
          type: RuleConstants.CarbodyPropertyCondition,
          entityTypeName: '',
          attributeName: '',
          operator: {
            type: 'EqualsOperator'
          } as TupleOperator,
          value: ''
        } as CarbodyPropertyCondition;

      } else if (value === RuleConstants.CarbodyPropertyCheckCondition) {
        newCondition = {
          type: RuleConstants.CarbodyPropertyCheckCondition,
          entityTypeName: '',
          attributeName: '',
          operator: {
            type: 'NotEqualsOperator'
          } as TupleOperator
        } as CarbodyPropertyCheckCondition;

      } else if (value === RuleConstants.EventPropertyCondition) {
        newCondition = {
          type: RuleConstants.EventPropertyCondition,
          attributeName: '',
          operator: {
            type: 'EqualsOperator'
          } as TupleOperator,
          value: ''
        } as EventPropertyCondition;

      } else if (value === RuleConstants.ItemFlowPropertyCondition) {
        newCondition = {
          type: RuleConstants.ItemFlowPropertyCondition,
          attributeName: '',
          operator: {
            type: 'EqualsOperator'
          } as TupleOperator,
          value: ''
        } as ItemFlowPropertyCondition;

      } else if (value === RuleConstants.HasFoundFreeSpotCondition) {
        newCondition = {
          type: RuleConstants.HasFoundFreeSpotCondition,
          hasFound: true
        } as HasFoundFreeSpotCondition;

      } else if (value === RuleConstants.IsModuleLockedCondition) {
        newCondition = {
          type: RuleConstants.IsModuleLockedCondition,
          locked: true,
          moduleName: ''
        } as IsModuleLockedCondition;

      } else if (value === RuleConstants.CountFreeModulesCondition) {
        newCondition = {
          type: RuleConstants.CountFreeModulesCondition,
          tag: 'WFBSpot',
          number: 0,
          lessOrEquals: true
        } as CountFreeModulesCondition;

      } else if (value === RuleConstants.CheckModuleInAreaCondition) {
        newCondition = {
          type: RuleConstants.CheckModuleInAreaCondition,
          area: undefined,
          contains: true
        } as CheckModuleInAreaCondition;

      } else if (value === RuleConstants.ConditionImpl) {
        newCondition = {
          type: RuleConstants.ConditionImpl,
          leftOperand: {
            type: 'LiteralOperand',
            value: ''
          } as LiteralOperand,
          rightOperand: {
            type: 'LiteralOperand',
            value: ''
          } as LiteralOperand,
          operator: {
            type: 'EqualsOperator'
          } as TupleOperator
        } as ConditionModel;

      } else {
        console.error('Unknown ConditionType', value);
        return;
      }

      // add condition to tree
      parent.conditions.push(newCondition);

      // update tree model
      this.initRuleDisplay(this.rule);
      this.startEditCondition(newCondition);
    });
  }

  createConditionGroup(parent: ConditionGroupModel) {
    const newCondition = {
      type: 'ConditionGroupImpl',
      coalationType: 'AND',
      conditions: []
    } as ConditionGroupModel;

    parent.conditions.push(newCondition);

    // update tree model
    this.initRuleDisplay(this.rule);

    this.startEditCondition(newCondition);
  }

  hasConditions() {
    // if this model has min. 1 condition under root AND condition group
    return this.rule && this.rule.serializedJson && this.rule.serializedJson.condition
      && this.rule.serializedJson.condition.conditions.length > 0;
  }

  startEditCondition(condition: AnyCondition) {
    this.currentEditedType = condition.type;
    this.currentEditedModel = condition;
    this.layout.openDetail(condition);
  }

  deleteCondition(condition: AnyCondition, parent: AnyCondition) {
    if (!parent) {
      // this is the root node => delete directly from rule
      this.rule.serializedJson.condition = undefined;
    } else if (parent.type === 'ConditionImpl') {
      // should not occur, because they don't have deletable children!
    } else if (parent.type === 'ConditionGroupImpl') {
      const groupModel = parent as ConditionGroupModel;
      groupModel.conditions = groupModel.conditions
        .filter(e => JSON.stringify(e) !== JSON.stringify(condition));
    }
    // trigger tree reload
    this.initRuleDisplay(this.rule);
    this.closeEditSidebar();
  }

  createAction() {
    if (!this.rule.serializedJson.actions) {
      this.rule.serializedJson.actions = [];
    }

    this.dialogService.componentDialog(AddActionModalComponent, {
      data: this.rule
    }).afterClosed().subscribe(value => {
      let newAction;

      if (value === RuleConstants.SetItemFlowPropertyAction) {
        newAction = {
          actionType: this.ruleDefinitionsModel.actionTypes.find(a => a.name === RuleConstants.SetItemFlowPropertyAction),
          operands: [
            {
              type: 'EventAttributeOperand',
              eventVariableName: '$event',
              attributeName: 'itemId'
            } as EventAttributeOperand,
            {type: 'LiteralOperand', value: ''} as LiteralOperand,
            {type: 'LiteralOperand', value: ''} as LiteralOperand
          ],
          invalid: false
        } as Action;
        this.expertMode = false;
      } else if (value === RuleConstants.AddAllowedOutletsAction) {
        newAction = {
          actionType: this.ruleDefinitionsModel.actionTypes.find(a => a.name === RuleConstants.AddAllowedOutletsAction),
          operands: [
            {
              type: 'EventAttributeOperand',
              eventVariableName: '$event',
              attributeName: 'itemId'
            } as EventAttributeOperand,
            {type: 'LiteralOperand', value: ''} as LiteralOperand
          ],
          invalid: false
        } as Action;
        this.expertMode = false;
      } else if (value === RuleConstants.RequestAllowedOutletsAction) {
        newAction = {
          actionType: this.ruleDefinitionsModel.actionTypes.find(a => a.name === RuleConstants.RequestAllowedOutletsAction),
          operands: [
            {
              type: 'EventAttributeOperand',
              eventVariableName: '$event',
              attributeName: 'itemId'
            } as EventAttributeOperand
          ],
          invalid: false
        } as Action;
        this.expertMode = false;
      } else if (value === RuleConstants.SetItemDistanceAction) {
        newAction = {
          actionType: this.ruleDefinitionsModel.actionTypes.find(a => a.name === RuleConstants.SetItemDistanceAction),
          operands: [
            {
              type: 'EventAttributeOperand',
              eventVariableName: '$event',
              attributeName: 'itemId'
            } as EventAttributeOperand,
            {type: 'LiteralOperand', value: ''} as LiteralOperand
          ],
          invalid: false
        } as Action;
        this.expertMode = false;
      } else if (value === RuleConstants.SetTimeDistanceAction) {
        newAction = {
          actionType: this.ruleDefinitionsModel.actionTypes.find(a => a.name === RuleConstants.SetTimeDistanceAction),
          operands: [
            {
              type: 'EventAttributeOperand',
              eventVariableName: '$event',
              attributeName: 'itemId'
            } as EventAttributeOperand,
            {type: 'LiteralOperand', value: ''} as LiteralOperand
          ],
          invalid: false
        } as Action;
        this.expertMode = false;
      } else if (value === RuleConstants.SetTargetAction) {
        newAction = {
          actionType: this.ruleDefinitionsModel.actionTypes.find(a => a.name === RuleConstants.SetTargetAction),
          operands: [
            {
              type: 'EventAttributeOperand',
              eventVariableName: '$event',
              attributeName: 'itemId'
            } as EventAttributeOperand,

            {type: 'LiteralOperand', value: ''} as LiteralOperand,
            {type: 'LiteralOperand', value: false} as LiteralOperand,
            {type: 'LiteralOperand', value: ''} as LiteralOperand
          ],
          invalid: false
        } as Action;
        this.expertMode = false;
      } else if (value === RuleConstants.SetSubTargetAction) {
        newAction = {
          actionType: this.ruleDefinitionsModel.actionTypes.find(a => a.name === RuleConstants.SetSubTargetAction),
          operands: [
            {
              type: 'EventAttributeOperand',
              eventVariableName: '$event',
              attributeName: 'itemId'
            } as EventAttributeOperand,
            {type: 'LiteralOperand', value: ''} as LiteralOperand
          ],
          invalid: false
        } as Action;
        this.expertMode = false;
      } else if (value === RuleConstants.StoreAndLockAction) {
        newAction = {
          actionType: this.ruleDefinitionsModel.actionTypes.find(a => a.name === RuleConstants.StoreAndLockAction),
          operands: [
            // itemId
            {
              type: 'EventAttributeOperand',
              eventVariableName: '$event',
              attributeName: 'itemId'
            } as EventAttributeOperand,
            // reason
            {type: 'LiteralOperand', value: ''} as LiteralOperand,
            // lockType
            {type: 'LiteralOperand', value: 'ITEM_LOCK'} as LiteralOperand
          ],
          invalid: false
        } as Action;
        this.expertMode = false;
      } else if (value === RuleConstants.ReleaseItemLockAction) {
        newAction = {
          actionType: this.ruleDefinitionsModel.actionTypes.find(a => a.name === RuleConstants.ReleaseItemLockAction),
          operands: [
            {
              type: 'EventAttributeOperand',
              eventVariableName: '$event',
              attributeName: 'itemId'
            } as EventAttributeOperand,
            // lockId
            {type: 'LiteralOperand', value: ''} as LiteralOperand,
            // lockType
            {type: 'LiteralOperand', value: ''} as LiteralOperand,
            // releaseNote
            {type: 'LiteralOperand', value: ''} as LiteralOperand
          ],
          invalid: false
        } as Action;
        this.expertMode = false;
      } else if (value === RuleConstants.RouteToStorageAction) {
        newAction = {
          actionType: this.ruleDefinitionsModel.actionTypes.find(a => a.name === RuleConstants.RouteToStorageAction),
          operands: [
            {
              type: 'EventAttributeOperand',
              eventVariableName: '$event',
              attributeName: 'itemId'
            } as EventAttributeOperand
          ],
          invalid: false
        } as Action;
        this.expertMode = false;
      } else if (value === RuleConstants.FireSetAllowedOutletsAction) {
        newAction = {
          actionType: this.ruleDefinitionsModel.actionTypes.find(a => a.name === RuleConstants.FireSetAllowedOutletsAction),
          operands: [
            {
              type: 'EventAttributeOperand',
              eventVariableName: '$event',
              attributeName: 'itemId'
            } as EventAttributeOperand
          ],
          invalid: false
        } as Action;
        this.expertMode = false;
      } else if (value === RuleConstants.AddSequentialScoreModifierAction) {
        newAction = {
          actionType: this.ruleDefinitionsModel.actionTypes.find(a => a.name === RuleConstants.AddSequentialScoreModifierAction),
          operands: [
            {type: 'LiteralOperand', value: 'PerlenkettenNr'} as LiteralOperand,
            {type: 'LiteralOperand', value: ''} as LiteralOperand
          ],
          invalid: false
        } as Action;
        this.expertMode = false;
      } else if (value === RuleConstants.AddColorBlockScoreModifierAction) {
        newAction = {
          actionType: this.ruleDefinitionsModel.actionTypes.find(a => a.name === RuleConstants.AddColorBlockScoreModifierAction),
          operands: [
            {type: 'LiteralOperand', value: ''} as LiteralOperand,
            {type: 'LiteralOperand', value: ''} as LiteralOperand,
            {type: 'LiteralOperand', value: '99'} as LiteralOperand,
            {type: 'ListOperand', value: []} as ListOperand,
            {type: 'LiteralOperand', value: false} as LiteralOperand,
            {type: 'LiteralOperand', value: '3'} as LiteralOperand,
            {type: 'LiteralOperand', value: false} as LiteralOperand
          ],
          invalid: false
        } as Action;
        this.expertMode = false;
      } else if (value === RuleConstants.AddPropagationTimeScoreModifierAction) {
        newAction = {
          actionType: this.ruleDefinitionsModel.actionTypes.find(a => a.name === RuleConstants.AddPropagationTimeScoreModifierAction),
          operands: [
            {type: 'LiteralOperand', value: ''} as LiteralOperand,
            {type: 'LiteralOperand', value: ''} as LiteralOperand
          ],
          invalid: false
        } as Action;
        this.expertMode = false;
      } else if (value === RuleConstants.AddConsecutiveForbiddenScoreModifierAction) {
        newAction = {
          actionType: this.ruleDefinitionsModel.actionTypes.find(a => a.name === RuleConstants.AddConsecutiveForbiddenScoreModifierAction),
          operands: [
            {type: 'LiteralOperand', value: ''} as LiteralOperand,
            {type: 'LiteralOperand', value: ''} as LiteralOperand,
            {type: 'LiteralOperand', value: ''} as LiteralOperand,
            {type: 'LiteralOperand', value: ''} as LiteralOperand
          ],
          invalid: false
        } as Action;
        this.expertMode = false;
      } else if (value === RuleConstants.SendJmsMessageAction) {
        newAction = {
          actionType: this.ruleDefinitionsModel.actionTypes.find(a => a.name === RuleConstants.SendJmsMessageAction),
          operands: [
            {type: 'LiteralOperand', value: ''} as LiteralOperand,
            {
              type: 'EventAttributeOperand',
              eventVariableName: '$event',
              attributeName: 'itemId'
            } as EventAttributeOperand
          ],
          invalid: false
        } as Action;
        this.expertMode = false;
      } else if (value === RuleConstants.AdvancedAction) {
        newAction = {
          actionType: this.ruleDefinitionsModel.actionTypes[1] // just take the first
        } as Action;
        this.expertMode = true;
      } else {
        console.error('Unknown ActionType', value);
        this.expertMode = false;
        return;
      }

      this.rule.serializedJson.actions.push(newAction);
      this.startEditAction(newAction);
      this.checkPrioAction();
    });
  }

  startEditAction(action: Action) {
    this.currentEditedType = 'action';
    this.currentEditedModel = action;
    this.layout.openDetail(action);
  }

  deleteAction(action: Action) {
    this.rule.serializedJson.actions = this.rule.serializedJson.actions
      .filter(e => JSON.stringify(e) !== JSON.stringify(action));
    this.closeEditSidebar();
    this.checkPrioAction();
  }

  saveRule() {
    // a rule must contain a name and description
    if (!this.rule.serializedJson.name
      || !this.rule.serializedJson.description) {
      this.snackBar.showInfo('pages.rules.edit.empty-name-error');
      return;
    }

    // a rule must have a rule key
    if (!this.rule.serializedJson.ruleKey) {
      this.snackBar.showInfo('pages.rules.edit.empty-ruleKey-error');
      return;
    }

    // a rule key must not have any whitespaces
    if (!this.rule.serializedJson.ruleKey.match('^\\S+$')) {
      this.snackBar.showInfo('pages.rules.edit.ruleKey-whitespace-error');
      return;
    }

    // name and description must not contain quotes
    if (this.rule.serializedJson.name.includes("\"")
      || this.rule.serializedJson.description.includes("\"")
      || this.rule.serializedJson.ruleKey.includes("\"")) {
      this.snackBar.showInfo('pages.rules.edit.no-quotes-error');
      return;
    }

    // a rule must have a priority
    if (this.rule.serializedJson.priority === undefined) {
      this.snackBar.showInfo('pages.rules.edit.empty-priority-error');
      return;
    }

    // a rule should have a category
    if (this.rule.serializedJson.ruleCategory === undefined) {
      this.snackBar.showInfo('pages.rules.edit.empty-ruleCategory-error');
      return;
    }

    // all conditions must not be invalid
    if (this.checkConditionInvalid(this.rule.serializedJson.condition)) {
      this.snackBar.showInfo('pages.rules.edit.some-condition-errors');
      return;
    }

    // all actions must be valid
    if (this.checkActionsInvalid(this.rule.serializedJson.actions)) {
      this.snackBar.showInfo('pages.rules.edit.some-action-errors');
      return;
    }

    // a rule must contain minimum 1 action
    if (!this.rule.serializedJson.actions
      || this.rule.serializedJson.actions.length === 0) {
      this.snackBar.showInfoAndAction('pages.rules.edit.empty-actions-error', 'pages.rules.edit.empty-actions-error-fix')
        .subscribe(() => this.createAction());
      return;
    }

    if (this.newRule) {
      this.rulesService.addRule(this.rule)
        .pipe(indicate(this.loading$))
        .subscribe((result) => {
          this.newRule = false; // not a new rule anymore
          this.layout.closeDetail();
          this.editMode = false;
          this.router.navigateByUrl('/pages/rules');
          this.reevaluateLocks(result.id);
        }, error => this.errorHandler.handleHttpError(error));

    } else {
      this.rulesService.updateRule(this.ruleId, this.rule)
        .pipe(indicate(this.loading$))
        .subscribe(() => {
          this.layout.closeDetail();
          this.editMode = false;
          this.loadAudit();
        }, error => this.errorHandler.handleHttpError(error));
      this.releaseRuleLocks();
      this.reevaluateLocks(this.ruleId);
    }

    this.ruleHistory = deepCopy(this.rule);
    this.expertMode = false;
  }

  /**
   * Recursively check all conditions or condition groups if any conditions is invalid.
   */
  private checkConditionInvalid(condition: any) {
    if (condition.invalid) {
      return true;
    }
    if (condition.conditions) {
      for (const c of condition.conditions) {
        if (this.checkConditionInvalid(c)) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Recursively check all conditions or condition groups if any conditions is invalid.
   */
  private checkActionsInvalid(actions: any[]) {
    if (!actions || actions.length == 0) {
      return false;
    }
    for (const a of actions) {
      console.log((a as Action).actionType.name + ' invalid => ' + (a as Action).invalid)
      //console.log('Action: ' + a)
      if (a.invalid === true) {
        return true;
      }
    }
    return false;
  }

  resetRule() {
    // because there may be changes that don't update right away
    this.closeEditSidebar();
    this.expertMode = false;

    // rollback rule
    this.rule = deepCopy(this.ruleHistory);
    this.initRuleDisplay(this.rule);
  }

  abortEdit() {
    if (this.newRule) {
      this.router.navigateByUrl('/pages/rules');
      return;
    }
    this.resetRule();
    this.editMode = false;
    this.expertMode = false;
  }

  deleteRule() {
    this.ruleProcessService.deleteRules([this.rule])
      .pipe(indicate(this.loading$))
      .subscribe(deleted => {
        if (deleted) {
          this.router.navigateByUrl('/pages/rules');
          this.snackBar.showInfo('pages.rules.successfullyDeleted');
        }
      }, error => this.errorHandler.handleHttpError(error));
  }

  private closeEditSidebar() {
    this.layout.closeDetail();
    this.currentEditedType = undefined;
    this.currentEditedModel = undefined;
  }

  routeBack() {
    this.router.navigateByUrl('/pages/rules');
  }

  /**
   * In some cases the user can additionally release all locks set by this rule.
   * We have to check first whether the rule is a locking rule with
   * RulesService.hasReleaseLocksOption and then open a dialog to let the user
   * decide whether the locks should be released or not.
   */
  private releaseRuleLocks() {
    // The rule is NOW disabled but WAS enabled before editing it:
    if (!this.rule.serializedJson.enabled && this.ruleHistory && this.ruleHistory.serializedJson.enabled) {
      // The rule has a lock action defined:
      if (RulesService.hasReleaseLocksOption(this.rule)) {
        this.dialogService.yesNoDialog('pages.rules.releaseLocksOption', 'pages.rules.releaseLocksOptionHeader')
          .subscribe(value => {
            if (value) {
              this.rulesService.releaseRuleLocks([this.ruleId])
                .subscribe(() => {
                  this.snackBar.showInfo('pages.rules.successfullyReleasedLocks');
                }, error => this.errorHandler.handleHttpError(error));
            }
          });
      }
    }
  }

  private reevaluateLocks(ruleId: number) {
    // The rule is NOW enabled
    if (this.rule.serializedJson.enabled) {
      // The rule has a lock action defined:
      if (RulesService.hasReleaseLocksOption(this.rule) && RulesService.hasNewToSystemEvent(this.rule)) {
        this.dialogService.yesNoDialog(`pages.reevaluate-lock-modal.text`,
          'pages.reevaluate-lock-modal.header').subscribe(result => {
          if (result) {
            this.rulesService.reevaluateLock(ruleId).subscribe(
              next => this.snackBar.showInfo(`Karossensperren wurden hinzugefügt.`),
                  error => this.errorHandler.handleHttpError(error)
            );
          }
        });
      }
    }
  }

  duplicateRule() {

    this.rulesService.getUniqueRuleKey(this.ruleId).subscribe(([key]) => {
      this.dialogService.componentDialog(CopyRuleDialogComponent, {
        data: {ruleKey: key, originalRuleKey: this.ruleId},
        maxWidth: '500px'
      })
        .afterClosed()
        .subscribe(({copyRule, ruleKey}) => {
            if (copyRule) {
              this.rulesService.copyRule(this.ruleId, ruleKey)
                .subscribe((r) => {
                  this.router.navigateByUrl(`/pages/rules/${r.id}`);
                  this.snackBar.showInfo('pages.rules.successfullyCopiedEdit');
                }, error => this.errorHandler.handleHttpError(error));
            }
          }
        );
    }, error => this.errorHandler.handleHttpError(error));
  }

  /**
   * Returns true if the current edited rule has a trigger selected which allows the specification of conditions.
   */
  hasConditionsAllowed(): Observable<boolean> {
    return this.rulesService.getRuleDefinitionsModel()
      .pipe(map(context => context.eventTypes.find(e => e.name === this.rule.serializedJson.trigger.name).conditionsAllowed));
  }

  onTriggerChange(serializedJson: any, $event: EventType) {
    serializedJson.trigger = $event;
    if (!this.hasConditionsAllowed()) {
      // clear all conditions if there aren't allowed now
      serializedJson.condition.conditions = [];
    }
    // clear actions as they are possibly not allowed here
    serializedJson.actions = [];
    this.checkPrioAction();
  }

  onSelectEntity($event: String) {
    this.rule.serializedJson.ruleCategory = $event.toString();
    this.rule.ruleCategory = $event.toString();
  }

  checkPrioAction() {
    this.hasPrioAction$.next(this.rulesService.hasPrioAction(this.rule));
  }
}
