import {Injectable} from '@angular/core';
import {SkwApiService} from 'skw-ui-bootstrap';
import {Observable, of} from 'rxjs';
import {tap} from 'rxjs/operators';
import {SkwLanguageService} from 'skw-ui-translation';
import {SkwAuthService} from 'skw-ui-authentication';

export class ItemFlowSettings {
  id: number;
  attributeName: string;
  translations: { [language: string]: string };
}

@Injectable({
  providedIn: 'root'
})
export class ItemFlowSettingsService {

  private cachedResult: ItemFlowSettings[];

  constructor(private apiService: SkwApiService,
              private authService: SkwAuthService,
              private translationService: SkwLanguageService) {
    this.authService.authObservable().subscribe(r => {
      // initially load item flow attributes if this service is injected
      if (r) {
        this.loadAll().subscribe();
      }
    });
  }

  loadAll(): Observable<ItemFlowSettings[]> {
    if (typeof this.cachedResult !== 'undefined') {
      return of(this.cachedResult);
    }
    return this.apiService.get<ItemFlowSettings[]>('/settings/itemFlow')
      .pipe(
        tap(v => this.cachedResult = v),
        tap(v => {
          // We now dynamically create some translation strings with the retrieved
          // item flow settings. You can simply use them with our translation pipe
          // anywhere with e.g. 'item-flow-attributes.locked' | translation
          const translations = {};
          v.forEach(attr => {
            Object.keys(attr.translations).forEach(lang => {
              if (!translations[lang]) {
                translations[lang] = {};
              }
              translations[lang][attr.attributeName] = attr.translations[lang];
            });
          });
          Object.keys(translations).forEach(lang => {
            this.translationService.addTranslation(lang, {
              'item-flow-attributes': translations[lang]
            });
          });
        })
      );
  }

  invalidateCache() {
    this.cachedResult = undefined;
  }

}
