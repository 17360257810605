import {SkwSelectModel} from 'skw-ui-components';

export const OPERATOR_VALUE_MAPPING: { [name: string]: boolean } = {
  EqualsOperator: false,
  NotEqualsOperator: false,
  GreaterOperator: false,
  LesserOperator: false,
  ContainsOperator: true,
  ContainsNotOperator: true
};

export const OPERATOR_TYPES: SkwSelectModel<string>[] = [
  {
    value: 'EqualsOperator',
    viewValue: 'pages.rules.detail.logicElements.EqualsOperator'
  },
  {
    value: 'NotEqualsOperator',
    viewValue: 'pages.rules.detail.logicElements.NotEqualsOperator'
  },
  {
    value: 'GreaterOperator',
    viewValue: 'pages.rules.detail.logicElements.GreaterOperatorDesc'
  },
  {
    value: 'LesserOperator',
    viewValue: 'pages.rules.detail.logicElements.LesserOperatorDesc'
  },
  {
    value: 'ContainsOperator',
    viewValue: 'pages.rules.detail.logicElements.ContainsOperator'
  },
  {
    value: 'ContainsNotOperator',
    viewValue: 'pages.rules.detail.logicElements.ContainsNotOperator'
  }
];

// specific operator text mapping for CarbodyPropertyCheckCondition
export const CHECK_OPERATOR_TYPES: SkwSelectModel<string>[] = [
  {
    value: 'NotEqualsOperator',
    viewValue: 'pages.rules.edit.CarbodyPropertyCheckCondition.NotEqualsOperator'
  },
  {
    value: 'EqualsOperator',
    viewValue: 'pages.rules.edit.CarbodyPropertyCheckCondition.EqualsOperator'
  },
];
