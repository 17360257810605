import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SkwSearchFacetsComponent} from './skw-search-facets/skw-search-facets.component';
import {SkwFacetSearchService} from './skw-facet-search';
import {SkwLanguageModule} from 'skw-ui-translation';
import {SkwInputModule} from 'skw-ui-components';

@NgModule({
  declarations: [SkwSearchFacetsComponent],
  exports: [SkwSearchFacetsComponent],
  imports: [
    CommonModule,
    SkwLanguageModule,
    SkwInputModule
  ],
  providers: [SkwFacetSearchService]
})
export class SkwSearchModule {
}
