import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SkwPageLayoutModule} from 'skw-ui-layout';
import {SkwLanguageModule} from 'skw-ui-translation';
import {
  SkwButtonModule,
  SkwCardModule,
  SkwCollapseModule,
  SkwDialogModule,
  SkwFormModule,
  SkwIconModule,
  SkwInputModule,
  SkwLoadingModule,
  SkwTableModule
} from 'skw-ui-components';
import {RuleImportDialogComponent} from './rule-import/rule-import-dialog.component';
import {RouterModule} from '@angular/router';
import {SkwFileUploadModule} from '../../../skw-file-upload/skw-file-upload.module';
import {MatExpansionModule} from '@angular/material';

@NgModule({
  declarations: [
    RuleImportDialogComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SkwPageLayoutModule,
    SkwLanguageModule,
    SkwCardModule,
    SkwFormModule,
    SkwInputModule,
    SkwButtonModule,
    SkwTableModule,
    SkwFileUploadModule,
    SkwCollapseModule,
    MatExpansionModule,
    SkwIconModule,
    SkwDialogModule,
    SkwLoadingModule
  ]
})
export class RuleExportImportModule {
}
