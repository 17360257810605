import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {SkwLayoutHeaderToolbar} from 'skw-ui-layout/skw/industry-layout/layout/header/header.portal';
import {SystemHealthStatus} from './system-health-status';
import {SystemHealthDetailComponent} from '../system-health-detail/system-health-detail.component';
import {SystemHealthStatusService} from './system-health-status.service';
import {Subscription} from 'rxjs';
import {SkwDialogService} from 'skw-ui-components';

@Component({
  selector: 'app-header-toolbar-portal',
  templateUrl: './header-toolbar-portal.component.html',
  styleUrls: ['./header-toolbar-portal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderToolbarPortalComponent implements OnInit, OnDestroy, SkwLayoutHeaderToolbar {

  status: SystemHealthStatus[] = [];
  $statusSubscription: Subscription;

  constructor(private modal: SkwDialogService,
              private systemHealthStatusService: SystemHealthStatusService,
              private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.$statusSubscription = this.systemHealthStatusService.observable()
      .subscribe(s => {
        this.status = s;
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy() {
    this.$statusSubscription.unsubscribe();
  }

  onAttached(): any {
  }

  openHealthModal() {
    this.modal.componentDialog(SystemHealthDetailComponent);
  }
}
