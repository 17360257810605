import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-kvs-kvz-approval-dialog',
  templateUrl: './kvs-kvz-approval-dialog.component.html',
  styleUrls: ['./kvs-kvz-approval-dialog.component.scss']
})
export class KvsKvzApprovalDialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<KvsKvzApprovalDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: string) { }

  ngOnInit() {
  }

  close(approve: boolean) {

    this.dialogRef.close({
      approve: approve
    });
  }
}
