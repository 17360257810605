import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {MfwItem, MfwItemParameter, MfwItemParameterGroup, MfwItemSimpleParameter, MfwLock} from 'skw-ui-modules';
import {ItemProcessService} from '../item-process.service';
import {Subject, Subscription} from 'rxjs';
import {WebsocketEventService} from '../../../api/websocket/websocket-event.service';
import {ItemFlowSettingsService} from '../../../pages/settings/item-flow-settings.service';
import {SkwErrorHandlerService, SkwSnackBarService} from 'skw-ui-components';
import {SkwLanguageService} from 'skw-ui-translation';

@Component({
  selector: 'app-mfw-item-modal',
  templateUrl: './mfw-item-modal.component.html',
  styleUrls: ['./mfw-item-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MfwItemModalComponent implements OnDestroy {

  id: string;
  layoutId: string;
  shortInfo: string;
  item: MfwItem;
  parameters: MfwItemParameter[];
  priorized = false;
  $loading = new Subject<boolean>();

  private $subscription: Subscription;

  constructor(public dialogRef: MatDialogRef<MfwItemModalComponent>,
              private itemProcessService: ItemProcessService,
              private itemFlowSettingsService: ItemFlowSettingsService, // important to inject here because we need to instantiate it
              private eventService: WebsocketEventService,
              private cdr: ChangeDetectorRef,
              private snackBarService: SkwSnackBarService,
              private errorHandler: SkwErrorHandlerService,
              private languageService: SkwLanguageService,
              @Inject(MAT_DIALOG_DATA) public data: { item: MfwItem, layoutId: string }) {
    this.layoutId = data.layoutId;
    if (data) {
      this.inflateItem(data.item);
    }

    this.$subscription = this.eventService.onItemUpdateEvent()
      .subscribe(event => {
        if (event.itemId === this.id) {
          try {
            this.inflateItem(event.payload);
            this.cdr.detectChanges();
          } catch (err) {
            console.error(`[ItemModal][${event}] Failed to update item: ` + err)
          }
        }
      });
  }

  private inflateItem(item: MfwItem) {
    this.id = item.id;
    this.shortInfo = item.shortInfo;
    this.parameters = item.parameters;
    this.item = item;

    const flowProps = this.parameters.find(p => (p as MfwItemParameterGroup).name === 'ItemFlow');
    if (flowProps) {
      const priorityAttr = (flowProps as MfwItemParameterGroup).parameters.find(a => (a as MfwItemSimpleParameter).key === 'priority');
      if (priorityAttr && (priorityAttr as MfwItemSimpleParameter).value === 'true') {
        this.priorized = true;
      } else {
        this.priorized = false;
      }
    }
  }

  unlockItem($event: MfwLock) {
    this.itemProcessService.doUnlockItem(this.layoutId, this.id, $event, this.$loading);
  }

  relockItem($event: MfwLock) {
    this.itemProcessService.doRelockItem(this.layoutId, this.id, $event, this.$loading);
  }

  closeModal() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.$subscription.unsubscribe();
  }

  navigatedToRule() {
    this.closeModal();
  }

  prioritize() {
    this.priorized = !this.priorized;
    this.itemProcessService.doPrioritizeItem(this.layoutId, this.id, this.priorized, this.$loading)
      .subscribe((result) =>
          this.languageService.get('pages.search.prio-change-triggered').subscribe(text =>
            this.snackBarService.showInfo(text)
          ),
        (error) => this.errorHandler.handleHttpError(error));
  }
}
