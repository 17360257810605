import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ModuleSizeDirective} from './module-size.directive';
import {ModulePosDirective} from './module-pos.directive';
import {DashboardComponent} from './dashboard.component';
import {DashboardToolbarComponent} from './dashboard-toolbar/dashboard-toolbar.component';
import {BackgroundDrawerComponent} from './background-drawer/background-drawer.component';
import {SequencerModule} from '../sequencer/sequencer.module';
import {MfwSystemModule} from 'skw-ui-modules';
import {
  SkwAlertModule,
  SkwBadgeModule,
  SkwButtonModule,
  SkwCardModule,
  SkwDialogModule,
  SkwFormModule,
  SkwIconModule,
  SkwInputModule,
  SkwLoadingModule,
  SkwStatusModule,
  SkwTableModule
} from 'skw-ui-components';
import {SkwLanguageModule} from 'skw-ui-translation';
import {SkwToolbarModule} from 'skw-ui-layout';
import {SkwAuthorizationModule} from 'skw-ui-authentication';
import {MatMenuModule} from '@angular/material';
import {SimulationModule} from '../simulation/simulation.module';
import {SettingsStatusComponent} from './settings-status/settings-status.component';
import {LayerStatsComponent} from './layer-stats/layer-stats.component';
import {SnapshotModule} from '../snapshot/snapshot.module';
import {EmergencyTransferalDialogComponent} from './emergency-transferal-dialog/emergency-transferal-dialog.component';
import {MatDividerModule} from '@angular/material/divider';
import {MatTooltipModule} from '@angular/material/tooltip';

const COMPONENTS = [
  ModuleSizeDirective,
  ModulePosDirective,
  DashboardComponent,
  DashboardToolbarComponent,
  BackgroundDrawerComponent,
  SettingsStatusComponent,
  LayerStatsComponent,
  EmergencyTransferalDialogComponent
];

@NgModule({
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
  imports: [
    CommonModule,
    SequencerModule,
    MfwSystemModule,
    SkwLoadingModule,
    SkwAlertModule,
    SkwButtonModule,
    SkwLanguageModule,
    SkwToolbarModule,
    SkwAuthorizationModule,
    MatMenuModule,
    SkwIconModule,
    SimulationModule,
    SkwCardModule,
    SkwStatusModule,
    SnapshotModule,
    SkwDialogModule,
    MatDividerModule,
    SkwFormModule,
    SkwInputModule,
    MatTooltipModule,
    SkwBadgeModule,
    SkwTableModule
  ],
  entryComponents: [EmergencyTransferalDialogComponent]
})
export class SystemDashboardModule {
}
