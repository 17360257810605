import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {SkwFileUploadComponent, SkwFileUploadOptions} from '../../../../skw-file-upload/file-upload/file-upload.component';
import {SkwApiService} from 'skw-ui-bootstrap';
import {SkwTokenService} from 'skw-ui-authentication';
import {indicate, SkwDialogService, SkwErrorHandlerService, SkwSnackBarService} from 'skw-ui-components';
import {Rule} from '../../rules.service';
import {MatDialogRef} from '@angular/material';
import {RuleImportService} from './rule-import.service';

export class RuleImportResult {
  importId: string;
  created: Rule[];
  updated: Rule[];
  untouched: Rule[];
  systemRules: Rule[];
  systemIdMatches: Boolean;
  systemId: String;
}

@Component({
  selector: 'app-rule-import-dialog',
  templateUrl: './rule-import-dialog.component.html',
  styleUrls: ['./rule-import-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RuleImportDialogComponent implements OnInit {
  @ViewChild('uploader') uploader: SkwFileUploadComponent;
  loading$ = new Subject<boolean>();
  fileReady = false;
  uploadOptions: SkwFileUploadOptions;
  importResult: RuleImportResult;
  /** Delete rules which are not im import file. */
  deleteUntouchedRules = false;

  // separate lists because they changes depending on deleteUntouchedRules flag:
  untouchedRules = [];
  deletedRules = [];

  constructor(private apiService: SkwApiService,
              private cdr: ChangeDetectorRef,
              private dialog: SkwDialogService,
              private ruleImportService: RuleImportService,
              private snackBar: SkwSnackBarService,
              private errorHandlerService: SkwErrorHandlerService,
              private dialogRef: MatDialogRef<RuleImportDialogComponent>,
              private tokenService: SkwTokenService) {
  }

  ngOnInit(): void {
    this.apiService.getBootstrap()
      .subscribe(b => {
        this.uploadOptions = {
          url: `${b.apiProtocol}://${b.apiRootUrl}/process/managerules/rules/prepareImport`,
          authToken: `Bearer ${this.tokenService.getToken()}`,
          disableMultipart: false,
          multiple: false,
          allowedFileExtensions: ['json'],
          maxFileSize: 20 * 1024 * 1024 // 20 MB
        };
        this.cdr.detectChanges();
      });
  }

  onUpload(result: RuleImportResult) {
    if (!result) {
      this.onUploadFailed(result);
      return;
    }
    if (!result.importId) {
      this.onUploadFailed(result);
      return;
    }
    this.loading$.next(false);
    this.uploader.reset();
    this.importResult = result;
    this.renderImportResult(this.deleteUntouchedRules);
    this.cdr.detectChanges();
  }

  onUploadFailed(error: any) {
    this.errorHandlerService.handleRequestError(error);
    this.uploader.reset();
    this.loading$.next(false);
  }

  doUpload() {
    this.loading$.next(true);
    this.uploader.uploadAll();
    this.fileReady = false;
  }

  processImport() {
    if (this.deleteUntouchedRules && this.deletedRules.length > 0) {
      // Let the user submit if there are rules deleted
      this.dialog.yesNoDialog('pages.rules.exportImport.deletion-warning', undefined, 'danger',
        {count: this.deletedRules.length})
        .subscribe(r => {
          if (r) {
            this.doProcessImport();
          }
        });
      return;
    }

    this.doProcessImport();
  }

  private doProcessImport() {
    this.ruleImportService.importRules(this.importResult.importId, this.deleteUntouchedRules)
      .pipe(indicate(this.loading$))
      .subscribe(() => {
        this.snackBar.showInfo('pages.rules.exportImport.successfully-imported');
        this.importResult = undefined;
        this.dialogRef.close(true);
      }, error => {
        this.errorHandlerService.handleHttpError(error);
        this.importResult = undefined;
      });
  }

  fileSelected(fileCount) {
    this.fileReady = fileCount > 0;
    this.cdr.detectChanges();
  }

  abort() {
    this.dialogRef.close(false);
  }

  back() {
    this.importResult = undefined;
    this.loading$.next(false);
  }

  renderImportResult($event: boolean) {
    if ($event) {
      this.deletedRules = [...this.importResult.untouched];
      this.untouchedRules = [...this.importResult.systemRules];
    } else {
      this.deletedRules = [];
      this.untouchedRules = [...this.importResult.untouched, ...this.importResult.systemRules];
    }
  }
}
