import {Action, ListOperand, LiteralOperand} from '../condition-model';

/**
 * Find index of an action parameter in action JSON. Returns -1 if something fails.
 * Usage: this.outletIdx = getActionParamIndex(action, 'outlet');
 * @param action action JSON object
 * @param param parameter name
 */
export function getActionParamIndex(action: Action, param: string): number {
  if (!action) {
    return -1;
  }
  const parameter = (action.actionType.parameters as any)[param];
  return typeof parameter === 'undefined' ? -1 : parameter.ordinal;
}

/**
 * Find a value for a parameter by index in an action.
 * Usage: this.outletValue = getActionParamValue(action, 2) as string;
 * @param action action JSON object
 * @param index index of param found by {@link getActionParamIndex}
 */
export function getActionParamValue<K extends ListOperand | LiteralOperand>(action: Action, index: number): any {
  if (!action) {
    return undefined;
  }
  const operand = action.operands[index] as K;
  return typeof operand === 'undefined' ? undefined : operand.value;
}
