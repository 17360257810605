import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Action, EventType} from '../../condition-model';
import {RulesService} from '../../../rules.service';
import {Subscription} from 'rxjs';
import {RuleEditService} from '../../rule-edit.service';

@Component({
  selector: 'app-rule-action-route-to-storage',
  templateUrl: './rule-action-route-to-storage.component.html',
  styleUrls: ['./rule-action-route-to-storage.component.scss']
})
export class RuleActionRouteToStorageComponent implements OnInit, OnDestroy {
  @Input() editMode: boolean;
  @Input() triggerContext: EventType;

  $subscription: Subscription;
  @Output() actionChange: EventEmitter<Action> = new EventEmitter<Action>();

  constructor(private rulesService: RulesService,
              private ruleEditService: RuleEditService,
              private cdr: ChangeDetectorRef) {
  }

  _action: Action;

  @Input()
  set action(action: Action) {
    this._action = action;
  }

  ngOnDestroy() {
    this.$subscription.unsubscribe();
  }

  ngOnInit() {
    this.$subscription = this.ruleEditService.changeObservable()
      .subscribe(s => {
        this.action = this._action;
      });
  }
}
