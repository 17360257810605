import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-sequence-info-item',
  templateUrl: './sequence-info-item.component.html',
  styleUrls: ['./sequence-info-item.component.scss']
})
export class SequenceInfoItemComponent {

  @Input()
  move: any;

  @Output()
  selected = new EventEmitter<any>();

  constructor() {
  }

  selectMove(move: any) {
    this.selected.emit(move);
  }
}
