import {Injectable} from '@angular/core';
import {SkwApiService} from 'skw-ui-bootstrap';
import {forkJoin, Observable, of, ReplaySubject} from 'rxjs';
import {
  SkwDialogService,
  SkwFilterElement,
  SkwPage,
  SkwSelectModel,
  SkwSnackBarService,
  SkwTreeViewModel
} from 'skw-ui-components';
import {SkwLanguageService} from 'skw-ui-translation';
import {map, share} from 'rxjs/operators';
import {MdsService} from '../mds/mds.service';
import {RuleConstants, RuleDefinitionsModel} from './rule-definitions-model';
import {WebsocketEventService} from '../../api/websocket/websocket-event.service';
import {LiteralOperand, LiteralValue} from './rule-edit/condition-model';
import {SkwFacetFilter} from '../../skw-search/skw-facet-search';
import {SkwAudit} from '../../skw-audit/audit';

export class RuleSearchResult {
  result: Rule[];
  allCount: number;
}

export class Rule {
  id: number;
  ruleKey: string;
  name: string;
  description: string;
  trigger: string;
  actions: string[]; // display only attribute
  type: RuleType;
  enabled: boolean;
  serializedJson: any;
  accessLevel: AccessLevel;
  priority: number;
  created: Date;
  updated: Date;
  markedForDeletion: boolean;
  deleteAfter: Date;
  ruleCategory: string;
}

export class RuleCategory {
  id: number;
  categoryName: string;
}


export enum RuleType {
  CONTROL,
  MUST,
  SHOULD,
  UNRECOGNIZED
}

export enum AccessLevel {
  SYSTEM = 'SYSTEM',
  USER = 'USER'
}

export var PrioActions = [
  RuleConstants.SetTargetAction,
  RuleConstants.SetSubTargetAction,
  RuleConstants.SetItemFlowPropertyAction,
  RuleConstants.FireSetAllowedOutletsAction
];

export class NodeModel {
  constructor(public text: string) {
  }
}

/** Simple service to access the rules api. Doesn't contain any business logic! */
@Injectable({
  providedIn: 'root'
})
export class RulesService {

  cachedRuleDefinitionsModel: RuleDefinitionsModel;
  cachedRuleDefinitionsModelObservable: Observable<RuleDefinitionsModel>;

  // current rule overview filter
  filter = {
    searchString: undefined,
    facets: undefined,
    filters: []
  } as {
    searchString: string,
    facets: SkwFacetFilter<any>[],
    filters: SkwFilterElement[],
  };

  constructor(private api: SkwApiService,
              private mdsService: MdsService,
              private snackBar: SkwSnackBarService,
              private languageService: SkwLanguageService,
              private websocketEventService: WebsocketEventService,
              private dialogService: SkwDialogService) {

    // when the mds attributes change, clear the context cache, since it contains a list of mds attributes
    websocketEventService.onWebsocketEvent().subscribe(event => {
      if (event.name === 'InvalidateRuleEditingContextEvent') {
        // clear ruleDefinitionsModel (and observable, so it is fetched again)
        this.cachedRuleDefinitionsModel = undefined;
        this.cachedRuleDefinitionsModelObservable = undefined;
      }
    });
  }

  /**
   * This method implements the process logic in which cases the user
   * can additionally release locks when deleting/disabling a rule.
   */
  public static hasReleaseLocksOption(rule: Rule): boolean {
    return !!rule.serializedJson.actions
      .find(a => a.actionType.name === 'StoreAndLock');
  }

  public static hasNewToSystemEvent(rule: Rule): boolean {
    return rule.serializedJson.trigger.name === 'ItemNewToSystemEvent';
  }

  /**
   * This method implements the process logic in which cases the user
   * can additionally release locks when deleting/disabling a rule.
   */
  public static hasReleaseLocksOptions(rules: Rule[]): boolean {
    let result = false;
    for (const r of rules) {
      if (RulesService.hasReleaseLocksOption(r)) {
        result = true;
        break;
      }
    }
    return result;
  }

  getRule(id: number): Observable<Rule> {
    return this.api.get<Rule>(`/process/managerules/rules/${id}`);
  }

  getRuleByRuleKey(id: string): Observable<Rule> {
    return this.api.get<Rule>(`/process/managerules/rules/byKey/${id}`);
  }

  getRuleNamesByRuleCategory(id: number): Observable<Rule[]> {
    return this.api.get<Rule[]>(`/process/managerules/rules/byCategoryId/${id}`);
  }

  getCountLocked(ids: number[]): Observable<number> {
    return this.api.get<number>(`/process/managerules/rules/countLocked`, {
      params: {
        ruleIds: ids.map(r => `${r}`)
      }
    });
  }

  addRule(model: Rule): Observable<{ id: number }> {
    return this.api.post<{ id: number }>('/process/managerules/rules', model);
  }

  addRuleCategory(model: RuleCategory): Observable<{ id: number }> {
    return this.api.post<{id: number}>('/process/managerules/ruleCategories', model);
  }

  updateRule(id: number, model: Rule): Observable<void> {
    return this.api.put<void>(`/process/managerules/rule/${id}`, model);
  }

  updateRuleCategory(id: number, model: RuleCategory): Observable<void> {
    return this.api.put<void>(`/process/managerules/ruleCategories/${id}`, model);
  }

  removeRules(ruleIds: number[]): Observable<void> {
    return this.api.delete<void>(`/process/managerules/rule/`, {
      params: {ruleIds: ruleIds.map(id => `${id}`)}
    });
  }

  removeRuleCategories(ruleCategoryIds: number[]): Observable<void> {
    return this.api.delete<void>(`/process/managerules/ruleCategory/`, {
      params: {ruleCategoryIds: ruleCategoryIds.map(id => `${id}`)}
    });
  }

  releaseRuleLocks(ruleIds: number[]): Observable<void> {
    return this.api.post<void>(`/process/managerules/rule/releaseLocks`, null, {
      params: {ruleIds: ruleIds.map(id => `${id}`)}
    });
  }

  listRules(searchString?: string): Observable<RuleSearchResult> {
    const options = !searchString ? undefined : {params: {searchString}};
    return this.api.get<RuleSearchResult>('/process/managerules/rules/search', options);
  }

  listRuleCategories(): Observable<RuleCategory[]> {
    return this.api.get<RuleCategory[]>('/process/managerules/ruleCategories');
  }

  setEnabled(ruleIds: number[], checked: boolean) {
    return this.api.post<void>(`/process/managerules/rules/enabled`, null, {
      params: {
        enabled: checked + '',
        ruleIds: ruleIds.map(r => `${r}`)
      }
    });
  }

  reevaluateLock(ruleId: number): Observable<any> {
    return this.api.post(`/process/managerules/rule/${ruleId}/reevaluateLocks`, null);
  }

  reevaluateLocks(ruleIds: number[]) {
    return this.api.post(`/process/managerules/rules/reevaluateLocks`, ruleIds);
  }

  copyRules(ruleIds: number[]) {
    return this.api.post<Rule[]>(`/process/managerules/rules/copy`, null, {
      params: {
        ruleIds: ruleIds.map(r => `${r}`)
      }
    });
  }

  copyRule(ruleId: number, key: string) {
    return this.api.post<Rule>(`/process/managerules/rule/${ruleId}/copy/${key}`, null);
  }

  getUniqueRuleKey(ruleId: number) {
    return this.api.get<String[]>(`/process/managerules/rule/${ruleId}/uniqueKey`);
  }

  getRuleDefinitionsModel(): Observable<RuleDefinitionsModel> {
    if (this.cachedRuleDefinitionsModel) { // if we have the data
      return of(this.cachedRuleDefinitionsModel);

    } else if (this.cachedRuleDefinitionsModelObservable) { // if we are currently fetching the data
      return this.cachedRuleDefinitionsModelObservable;

    } else { // if we just woke up and want to fetch the data
      this.cachedRuleDefinitionsModelObservable = new Observable((observer) => {
          this.api.get<RuleDefinitionsModel>('/process/managerules/editing/context').subscribe(value => {
            this.cachedRuleDefinitionsModel = value;
            observer.next(value);
            observer.complete();
          }, error => {
            observer.error(error);
            this.cachedRuleDefinitionsModelObservable = undefined;
          });
        }
      ).pipe(share<RuleDefinitionsModel>()); // share <-- multicast to all subscribers
      return this.cachedRuleDefinitionsModelObservable;
    }
  }

  getNewEmptyRule(): Observable<Rule> {
    return this.api.get<Rule>('/process/managerules/editing/newEmptyRule');
  }

  getEventAttributeValues(attributeName: string): Observable<LiteralOperand[]> {
    return this.api.get<LiteralOperand[]>('/process/managerules/editing/event/attributeValues', {
      params: {
        attributeName
      }
    });
  }

  getCarBodyAttributeValues(entityTypeName: string, attributeName: string): Observable<SkwSelectModel<LiteralValue>[]> {
    return this.api.get<SkwSelectModel<LiteralValue>[]>('/process/managerules/editing/attributeValues/carbody', {
      params: {
        attributeName,
        entityTypeName
      }
    });
  }

  getCarBodyAttributeValuesFromId(entityTypeName: string, attributeId: number): Observable<SkwSelectModel<LiteralValue>[]> {
    return this.api.get<SkwSelectModel<LiteralValue>[]>('/process/managerules/editing/attributeValues/carbody', {
      params: {
        attributeId: attributeId.toString(),
        entityTypeName
      }
    });
  }

  getItemFlowPropValues(attributeName: string): Observable<string[]> {
    return this.api.get<string[]>('/process/managerules/editing/attributeValues/itemFlow', {
      params: {
        attributeName
      }
    });
  }

  getLockableModuleNames(): Observable<string[]> {
    return this.api.get<string[]>('/process/managerules/editing/attributeValues/lockableModuleNames');
  }

  getAreaNames(): Observable<string[]> {
    return this.api.get<string[]>('/process/managerules/editing/attributeValues/areas');
  }

  getViaSubTargets(): Observable<string[]> {
    return this.api.get<string[]>('/process/managerules/editing/attributeValues/viaSubTargets');
  }

  getRules(): Observable<SkwSelectModel<string>[]> {
    return this.api.get<SkwSelectModel<string>[]>('/process/managerules/editing/attributeValues/rules');
  }


  getTreeModel(condition: any): Observable<SkwTreeViewModel<NodeModel>> {
    const result = new ReplaySubject<SkwTreeViewModel<NodeModel>>();
    // console.log(condition);
    const m = new SkwTreeViewModel<NodeModel>();
    m.children = [];

    if (!condition) {
      return result.asObservable();
    }

    // we have a condition group that consists of multiple conditions
    if (condition.type && condition.type === 'ConditionGroupImpl') {
      this.languageService.get('pages.rules.detail.logicElements.' + condition.coalitionType)
        .subscribe(coalitionType => {
          m.state = {text: coalitionType};
          const observables = [];
          for (const child of condition.conditions) {
            // console.log(child);
            observables.push(this.getTreeModel(child));
          }
          forkJoin(observables).subscribe(results => {
            results.forEach(r => m.children.push(r));
            result.next(m);
            result.complete();
          });
        });
    } else {
      // single line condition

      // in case of GetEntityAttribute query check if there are code-mappings and display them
      if (condition.leftOperand.query.name === 'GetEntityAttribute') {
        forkJoin(
          this.getOperatorAsString(condition.operator),
          this.getCodeMappedOperandAsString(condition.rightOperand, condition.leftOperand.params[0].value,
            condition.leftOperand.params[1].value)
        ).subscribe(data => {
          // console.log(data);
          m.state = {
            text: this.getOperandAsString(condition.leftOperand) + ' ' + data[0] + ' '
              + data[1]
          };
          result.next(m);
          result.complete();
        });
      } else {
        this.getOperatorAsString(condition.operator).subscribe(operator => {
          m.state = {
            text: this.getOperandAsString(condition.leftOperand) + ' ' + operator + ' '
              + this.getOperandAsString(condition.rightOperand)
          };
          result.next(m);
          result.complete();
        });
      }
    }

    return result.asObservable();
  }

  getCodeMappedOperandAsString(operand: any, entityTypeName: string, attributeName: string): Observable<string> {
    if (operand.value) {
      if (Array.isArray(operand.value)) {
        const mappedStrings: Observable<string>[] = [];
        for (const e of operand.value) {
          mappedStrings.push(this.getCodeMappedValueAsString(e, entityTypeName, attributeName));
        }
        return forkJoin(mappedStrings).pipe(map(res => res.join(', ')));
      } else {
        return this.getCodeMappedValueAsString(operand.value, entityTypeName, attributeName);
      }
    }
  }

  getCodeMappedValueAsString(e: any, entityTypeName: string, fieldName: string): Observable<string> {
    if (typeof e === 'string') {
      return this.mdsService.getMappingForAttributeValue(entityTypeName, fieldName, e).pipe(map(mapping => {
        // console.log(mapping);
        if (mapping.name) {
          return mapping.name + '[\"' + e + '\"]';
        } else {
          return '\"' + mapping.value + '\"';
        }
      }));
    } else {
      return e.toString();
    }
  }

  getOperandAsString(operand: any): string {
    let result = '';
    if (!operand) {
      return result;
    }

    if (operand.value === null) {
      return 'null';
    }

    if (operand.value) {
      if (Array.isArray(operand.value)) {
        const stringArray = [];
        for (const e of operand.value) {
          stringArray.push(this.getValueAsString(e));
        }
        return stringArray.join(', ');
      } else {
        return this.getValueAsString(operand.value);
      }
    }

    if (operand.query) {
      result += operand.query.providerName + '.' + operand.query.name + ' ';
      if (operand.params) {
        if (Array.isArray(operand.params)) {
          const stringArray = [];
          for (const e of operand.params) {
            stringArray.push(this.getParamAsString(e));
          }
          result += '(' + stringArray.join(', ') + ') ';
        } else {
          result += '(' + this.getParamAsString(operand.params) + ') ';
        }
      }
      return result;
    }

    return result;
  }

  getOperatorAsString(operator: any): Observable<string> {
    return this.languageService.get('pages.rules.detail.logicElements.' + operator.type);
  }

  getValueAsString(e: any): string {
    if (typeof e === 'string') {
      return '\"' + e + '\"';
    } else {
      return e.toString();
    }
  }

  getParamAsString(param: any): string {
    if (param.value) {
      return this.getValueAsString(param.value);
    }
    if (param.variableName) {
      return param.variableName;
    }
    if (param.eventVariableName) {
      return param.eventVariableName + '.' + param.attributeName;
    }
    if (param.value) {
      return param.value;
    }
    return '';
  }

  getRuleAudit(id: number, page: number, size: number): Observable<SkwPage<SkwAudit>> {
    return this.api.get<SkwPage<SkwAudit>>(`/process/managerules/audit/${id}`, {
      params: {
        page: `${page}`,
        size: `${size}`
      }
    });
  }

  // returns true if the rule contains any action that is affected by priority
  hasPrioAction(rule: Rule): boolean {
    return rule.serializedJson.actions.filter(action => PrioActions.indexOf(action.actionType.name) !== -1).length > 0;
  }

}
