import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Action, EventType, LiteralOperand} from '../../condition-model';
import {Observable, Subscription} from 'rxjs';
import {RulesService} from '../../../rules.service';
import {RuleEditService} from '../../rule-edit.service';
import {SkwSelectModel} from 'skw-ui-components';

@Component({
  selector: 'app-rule-action-release-item-lock',
  templateUrl: './rule-action-release-item-lock.component.html',
  styleUrls: ['./rule-action-release-item-lock.component.scss']
})
export class RuleActionReleaseItemLockComponent implements OnInit, OnDestroy {

  @Input() editMode: boolean;
  @Input() triggerContext: EventType;

  $subscription: Subscription;

  _action: Action;

  _releaseNoteIdx: number;
  _releaseNoteValue: string;

  _referencedRuleIdx: number;
  _referencedRuleValue: string;

  _selectableRules: Observable<SkwSelectModel<string>[]>;

  @Input()
  set action(action: Action) {
    this._action = action;

    this._releaseNoteIdx = (action.actionType.parameters as any).releaseNote.ordinal;
    this._releaseNoteValue = (this._action.operands[this._releaseNoteIdx] as LiteralOperand).value.toString();

    this._referencedRuleIdx = (action.actionType.parameters as any).referencedRule.ordinal;
    this._referencedRuleValue = (this._action.operands[this._referencedRuleIdx] as LiteralOperand).value as string;
  }

  @Output() actionChange: EventEmitter<Action> = new EventEmitter<Action>();

  constructor(private rulesService: RulesService,
              private ruleEditService: RuleEditService,
              private cdr: ChangeDetectorRef) {
  }

  ngOnDestroy() {
    this.$subscription.unsubscribe();
  }

  ngOnInit() {
    this._selectableRules = this.rulesService.getRules();
    this.$subscription = this.ruleEditService.changeObservable()
      .subscribe(s => {
        this.action = this._action;
      });
  }

  releaseNoteChange($event: string) {
    (this._action.operands[this._releaseNoteIdx] as LiteralOperand).value = $event;
    this.ruleEditService.emitUpdateRule();
    this.actionChange.emit(this._action);
  }

  referencedRuleChange($event: string) {
    (this._action.operands[this._referencedRuleIdx] as LiteralOperand).value = $event;
    this.ruleEditService.emitUpdateRule();
    this.actionChange.emit(this._action);
  }

}
