import {NgModule} from '@angular/core';
import {PagesComponent} from './pages.component';
import {PagesRoutingModule} from './pages-routing.module';
import {CommonModule} from '@angular/common';
import {SkwLayoutModule} from 'skw-ui-layout';
import {SkwButtonModule, SkwCardModule} from 'skw-ui-components';
import {WebsocketEventModule} from '../api/websocket/websocket-event.module';
import {HomeModule} from './home/home.module';
import {CarBodySearchModule} from './carbodysearch/carbodysearch.module';
import {MatTooltipModule} from '@angular/material';
import {SettingsModule} from './settings/settings.module';
import {SystemDashboardModule} from './dashboard/system-dashboard.module';
import {SystemModesModule} from './system-modes/system-modes.module';
import {SimulationModule} from './simulation/simulation.module';
import {RulesModule} from './rules/rules.module';
import {TranslationcheckComponent} from './translationcheck/translationcheck.component';
import {MdsModule} from './mds/mds.module';
import {SkwLanguageModule} from 'skw-ui-translation';
import {SkwSidebarViewModule} from '../skw-sidebar-view/skw-sidebar-view.module';
import {KvsOrderListModule} from './kvs-order-list/kvs-order-list.module';

@NgModule({
  declarations: [
    PagesComponent,
    TranslationcheckComponent
  ],
  imports: [
    CommonModule,
    HomeModule,
    PagesRoutingModule,
    RulesModule,
    SkwCardModule,
    SkwLayoutModule,
    WebsocketEventModule,
    CarBodySearchModule,
    MatTooltipModule,
    SettingsModule,
    SystemDashboardModule,
    SystemModesModule,
    SimulationModule,
    MdsModule,
    SkwLanguageModule,
    SkwSidebarViewModule,
    SkwButtonModule,
    KvsOrderListModule
  ],
  exports: [],
})
export class PagesModule {
}
