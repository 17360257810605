import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RuleEditComponent} from './rule-edit/rule-edit.component';
import {RuleConditionComponent} from './rule-edit/rule-condition/rule-condition.component';
import {RuleOperandLiteralComponent} from './rule-edit/rule-operand/rule-operand-literal/rule-operand-literal.component';
import {RuleOperandComponent} from './rule-edit/rule-operand/rule-operand.component';
import {RuleOperandQueryComponent} from './rule-edit/rule-operand/rule-operand-query/rule-operand-query.component';
import {RuleOperandList} from './rule-edit/rule-operand/rule-operand-list/rule-operand-list.component';
import {RuleTriggerComponent} from './rule-edit/rule-trigger/rule-trigger.component';
import {RuleConditionGroupComponent} from './rule-edit/rule-condition-group/rule-condition-group.component';
import {InteractiveElementComponent} from './rule-edit/interactive-element/interactive-element.component';
import {RuleActionComponent} from './rule-edit/rule-action/rule-action.component';
import {RuleOperandEventattributeComponent} from './rule-edit/rule-operand/rule-operand-eventattribute/rule-operand-eventattribute.component';
import {RuleOperandBindedComponent} from './rule-edit/rule-operand/rule-operand-binded/rule-operand-binded.component';
import {RuleConditionCarBodyPropertyComponent} from './rule-edit/rule-condition/rule-condition-car-body-property/rule-condition-car-body-property.component';
import {RuleConditionCarBodyPropertyCheckComponent} from './rule-edit/rule-condition/rule-condition-car-body-property-check/rule-condition-car-body-property-check.component';
import {AddConditionModalComponent} from './rule-edit/add-condition-modal/add-condition-modal.component';
import {AddActionModalComponent} from './rule-edit/add-action-modal/add-action-modal.component';
import {RuleConditionEventPropertyComponent} from './rule-edit/rule-condition/rule-condition-event-property/rule-condition-event-property.component';
import {RuleConditionItemFlowPropertyComponent} from './rule-edit/rule-condition/rule-condition-item-flow-property/rule-condition-item-flow-property.component';
import {RuleConditionCheckModuleInAreaComponent} from './rule-edit/rule-condition/rule-condition-check-module-in-area/rule-condition-check-module-in-area.component';
import {RuleActionSetItemFlowPropertyComponent} from './rule-edit/rule-action/rule-action-set-item-flow-property/rule-action-set-item-flow-property.component';
import {RuleActionAddAllowedOutletsComponent} from './rule-edit/rule-action/rule-action-add-allowed-outlets/rule-action-add-allowed-outlets.component';
import {RuleActionSetTargetComponent} from './rule-edit/rule-action/rule-action-set-target/rule-action-set-target.component';
import {RuleActionFireSetAllowedOutletsComponent} from './rule-edit/rule-action/rule-action-fire-set-allowed-outlets/rule-action-fire-set-allowed-outlets.component';
import {RuleActionAddSequentialScoreModifierComponent} from './rule-edit/rule-action/rule-action-add-sequential-score-modifier/rule-action-add-sequential-score-modifier.component';
import {RuleActionAddPropagationTimeScoreModifierComponent} from './rule-edit/rule-action/rule-action-add-propagation-time-score-modifier/rule-action-add-propagation-time-score-modifier.component';
import {RuleActionRouteToStorageComponent} from './rule-edit/rule-action/rule-action-route-to-storage/rule-action-route-to-storage.component';
import {RuleConditionHasFoundFreeSpotComponent} from './rule-edit/rule-condition/rule-condition-has-found-free-spot/rule-condition-has-found-free-spot.component';
import {RuleValueInputComponent} from './rule-edit/rule-condition/value-input/rule-value-input.component';
import {RuleActionSetSubTargetComponent} from './rule-edit/rule-action/rule-action-set-sub-target/rule-action-set-sub-target.component';
import {RuleActionStoreAndLockComponent} from './rule-edit/rule-action/rule-action-store-and-lock/rule-action-store-and-lock.component';
import {RuleActionAddConsecutiveForbiddenScoreModifierComponent} from './rule-edit/rule-action/rule-action-add-consecutive-forbidden-score-modifier/rule-action-add-consecutive-forbidden-score-modifier.component';
import {RuleActionSendJmsMessageComponent} from './rule-edit/rule-action/rule-action-send-jms-message/rule-action-send-jms-message.component';
import {RuleConditionIsModuleLockedComponent} from './rule-edit/rule-condition/rule-condition-is-module-locked/rule-condition-is-module-locked.component';
import {RuleConditionCountFreeModulesComponent} from './rule-edit/rule-condition/rule-condition-count-free-modules/rule-condition-count-free-modules.component';
import {RuleActionReleaseItemLockComponent} from './rule-edit/rule-action/rule-action-release-item-lock/rule-action-release-item-lock.component';
import {DeleteRuleDialogComponent} from './rule-edit/delete-rule-dialog/delete-rule-dialog.component';
import {RulesComponent} from './rules.component';
import {SkwMasterDetailLayoutModule, SkwPageLayoutModule} from 'skw-ui-layout';
import {
  SkwAlertModule,
  SkwBadgeModule,
  SkwButtonModule,
  SkwCardModule,
  SkwDialogModule,
  SkwDividerModule,
  SkwEditableModule,
  SkwFormModule,
  SkwHintModule,
  SkwIconModule,
  SkwInputModule,
  SkwListModule,
  SkwLoadingModule,
  SkwTableModule,
  SkwTreeViewModule
} from 'skw-ui-components';
import {SkwAuthorizationModule} from 'skw-ui-authentication';
import {SkwLanguageModule} from 'skw-ui-translation';
import {MatDividerModule, MatMenuModule, MatTooltipModule} from '@angular/material';
import {RuleExportImportModule} from './export-import/rule-export-import.module';
import {RuleActionAddColorBlockScoreModifierComponent} from './rule-edit/rule-action/rule-action-add-color-block-score-modifier/rule-action-add-color-block-score-modifier.component';
import {RuleImportDialogComponent} from './export-import/rule-import/rule-import-dialog.component';
import {SkwSidebarViewModule} from '../../skw-sidebar-view/skw-sidebar-view.module';
import {SkwSearchModule} from '../../skw-search/skw-search.module';
import {SkwAuditModule} from '../../skw-audit/skw-audit.module';
import {RuleTemplatesModule} from './rule-templates/rule-templates.module';
import {MatTabsModule} from '@angular/material/tabs';
import {RuleCategoryComponent} from './rule-edit/rule-category/rule-category.component';
import {RuleActionSetItemDistanceComponent} from './rule-edit/rule-action/rule-action-set-item-distance/rule-action-set-item-distance.component';
import {RulePriorityPipe} from './rule-priority.pipe';
import {RuleActionSetTimeDistanceComponent} from './rule-edit/rule-action/rule-action-set-time-distance/rule-action-set-time-distance.component';
import {CopyRuleDialogComponent} from './rule-edit/copy-rule-dialog/copy-rule-dialog.component';
import {
  ChangeRuleActiveStateDialogComponent
} from './rule-edit/change-rule-active-state-dialog/change-rule-active-state-dialog.component';
import {
  RuleActionRequestAllowedOutletsComponent
} from "./rule-edit/rule-action/rule-action-request-allowed-outlets/rule-action-request-allowed-outlets.component";
import {ReleaseRuleLocksDialogComponent} from "./rule-edit/release-rule-locks-dialog/release-rule-locks-dialog.component";
import {
    RuleActionPrioritizeItemComponent
} from "./rule-edit/rule-action/rule-action-prioritize-item/rule-action-prioritize-item.component";

@NgModule({
  declarations: [
    RulesComponent,
    RuleEditComponent,
    RuleConditionComponent,
    RuleOperandLiteralComponent,
    RuleOperandComponent,
    RuleOperandQueryComponent,
    RuleOperandList,
    RuleTriggerComponent,
    RuleConditionGroupComponent,
    InteractiveElementComponent,
    RuleActionComponent,
    RuleOperandEventattributeComponent,
    RuleOperandBindedComponent,
    RuleConditionCarBodyPropertyComponent,
    RuleConditionCarBodyPropertyCheckComponent,
    AddConditionModalComponent,
    AddActionModalComponent,
    RuleConditionEventPropertyComponent,
    RuleConditionItemFlowPropertyComponent,
    RuleConditionCheckModuleInAreaComponent,
    RuleActionSetItemFlowPropertyComponent,
    RuleActionAddAllowedOutletsComponent,
    RuleActionRequestAllowedOutletsComponent,
    RuleActionSetItemDistanceComponent,
    RuleActionSetTimeDistanceComponent,
    RuleActionSetTargetComponent,
    RuleActionFireSetAllowedOutletsComponent,
    RuleActionAddSequentialScoreModifierComponent,
    RuleActionAddPropagationTimeScoreModifierComponent,
    RuleActionRouteToStorageComponent,
    RuleConditionHasFoundFreeSpotComponent,
    RuleValueInputComponent,
    RuleActionSetSubTargetComponent,
    RuleActionStoreAndLockComponent,
    RuleActionAddConsecutiveForbiddenScoreModifierComponent,
    RuleActionSendJmsMessageComponent,
    RuleConditionIsModuleLockedComponent,
    RuleConditionCountFreeModulesComponent,
    RuleActionReleaseItemLockComponent,
    RuleActionPrioritizeItemComponent,
    RuleActionAddColorBlockScoreModifierComponent,
    DeleteRuleDialogComponent,
    ReleaseRuleLocksDialogComponent,
    CopyRuleDialogComponent,
    RuleCategoryComponent,
    RulePriorityPipe,
    ChangeRuleActiveStateDialogComponent
  ],
    imports: [
        CommonModule,
        SkwPageLayoutModule,
        SkwButtonModule,
        SkwAuthorizationModule,
        SkwCardModule,
        SkwTableModule,
        SkwLanguageModule,
        SkwInputModule,
        SkwIconModule,
        SkwFormModule,
        SkwEditableModule,
        SkwMasterDetailLayoutModule,
        SkwTreeViewModule,
        SkwAlertModule,
        SkwListModule,
        SkwHintModule,
        SkwDialogModule,
        MatTooltipModule,
        RuleExportImportModule,
        MatMenuModule,
        SkwBadgeModule,
        MatDividerModule,
        SkwLoadingModule,
        SkwSidebarViewModule,
        SkwSearchModule,
        SkwAuditModule,
        SkwDividerModule,
        RuleTemplatesModule,
        MatTabsModule
    ],
  entryComponents: [
    AddConditionModalComponent,
    AddActionModalComponent,
    DeleteRuleDialogComponent,
    ReleaseRuleLocksDialogComponent,
    CopyRuleDialogComponent,
    RuleImportDialogComponent,
    RuleCategoryComponent,
    ChangeRuleActiveStateDialogComponent
  ]
})
export class RulesModule {
}
