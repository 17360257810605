import {BehaviorSubject, combineLatest, forkJoin, Observable, of} from 'rxjs';
import {SkwLanguageService, SkwTranslatableValue} from 'skw-ui-translation';
import {Injectable} from '@angular/core';
import {map, switchMap, take} from 'rxjs/operators';

@Injectable()
export class SkwFacetSearchService {

  constructor(private translationService: SkwLanguageService) {
  }

  get<T>(): SkwFacetSearchFactory<T> {
    return new SkwFacetSearchFactory<T>(this.translationService);
  }
}

export class SkwFacetFilter<T> {
  property: SkwFacetProperty<T>;
  value: string | number | boolean;
}

export class SkwFacetProperty<T> {
  value: (t: T) => any[];
  viewValue: (t: T) => SkwTranslatableValue[];
  facet: boolean;
  name: SkwTranslatableValue;
}

export class SkwFacetResult<T> {
  property: SkwFacetProperty<T>;
  values: SkwFacetResultValue[];
}

export class SkwFacetResultValue {
  value: string | number | boolean;
  viewValue: SkwTranslatableValue;
  matches: number;
}

export class SkwFacetSearchFactory<T> {
  private idGenerator = 1; // incremented number to generate random ids
  private properties: SkwFacetProperty<T>[] = [];

  private values = new BehaviorSubject<T[]>([]);
  private searchObjects: Observable<SkwFacetSearchObject<T>[]> = combineLatest(
    this.translationService.observable(), // only used to trigger translation on lang change
    this.values
  ).pipe(
    map(([lang, values]) => {
      return values.map(e => this.build(e));
    })
  );

  private filterString = new BehaviorSubject('');
  private facetFilter = new BehaviorSubject<SkwFacetFilter<T> []>([]);

  private filteredValues: Observable<T[]> =
    combineLatest(
      this.searchObjects,
      this.filterString,
      this.facetFilter
    ).pipe(
      switchMap(([values, filterString, facetFilter]) => {
        return !values.length ? of([] as T[]) : combineLatest(values.map(v => v.matches(filterString, facetFilter)))
          .pipe(
            map(r => r
              .filter(([match, value]) => match === true)
              .map(([match, value]) => value)
            )
          );
      })
    );

  private facets: Observable<SkwFacetResult<T>[]> = this.filteredValues
    .pipe(
      map(values => {
        return this.properties
          .filter(p => p.facet)
          .map(p => {
            const result = {
              property: p,
              values: []
            } as SkwFacetResult<T>;
            values.forEach(v => {
              const value = p.value(v);
              const viewValues = p.viewValue(v);
              for (let i = 0; i < value.length; i++) {
                const val = value[i];
                const propValue = result.values.find(e => e.value === val);
                if (!propValue) {
                  result.values.push({
                    value: val,
                    viewValue: viewValues[i],
                    matches: 1
                  });
                } else {
                  propValue.matches += 1;
                }
              }
            });
            return result;
          });
      })
    );

  constructor(private translationService: SkwLanguageService) {
  }

  addPropertyValue(value: (element: T) => string | number | boolean, translatableViewValue?: (element: T) => SkwTranslatableValue,
                   facet?: boolean, name?: SkwTranslatableValue) {
    return this.addPropertyValues(t => [value(t)], translatableViewValue ? t => [translatableViewValue(t)] : undefined,
      facet, name);
  }

  addPropertyValues(values: (element: T) => (string | number | boolean)[], translatableViewValues?: (element: T) => SkwTranslatableValue[],
                    facet = true, name?: SkwTranslatableValue) {
    const propertyName = typeof name === 'undefined' ? `skw-facet-property-${this.idGenerator++}` : name;
    if (this.properties.find(p => p.name === propertyName)) {
      throw new Error(`The facet property ${propertyName} is already configured! All property names must be unique!`);
    }

    this.properties.push({
      value: values,
      viewValue: !translatableViewValues ? e => values(e).map(v => `${v}`) : translatableViewValues,
      facet,
      name: propertyName
    });
    return this;
  }

  submitSearchString(value: string) {
    this.filterString.next(value);
  }

  submitFacetFilter(facets: SkwFacetFilter<T>[]) {
    this.facetFilter.next(facets);
  }

  submitFacetFilterValue(propertyName: SkwTranslatableValue, value: string | number | boolean) {
    this.submitFacetFilter([
      {
        property: this.properties.find(p => p.name === propertyName),
        value: value
      }
    ]);
  }

  submitValues(elements: T[]): void {
    this.values.next(elements);
  }

  filteredValuesObservable(): Observable<T[]> {
    return this.filteredValues;
  }

  facetsObservable(): Observable<SkwFacetResult<T>[]> {
    return this.facets;
  }

  private build(element: T): SkwFacetSearchObject<T> {
    const result: Observable<string> = forkJoin(
      this.properties
        .map(p => p.viewValue(element))
        .reduce((acc, val) => acc.concat(val), [])
        .map((v) => this.translationService.get(v))
    ).pipe(map((r: string[]) => r.join(' ')));
    return new SkwFacetSearchObject<T>(result, element);
  }
}

export class SkwFacetSearchObject<T> {
  constructor(private searchString: Observable<string>, private element: T) {
  }

  matches(queryString: string, facetFilter: SkwFacetFilter<T> []): Observable<[boolean, T]> {
    const queryTokens = !queryString ? [] : queryString
      .trim()
      .split(' ')
      .map(e => e.trim())
      .filter(e => !!e)
      .map(e => e.toLocaleLowerCase());
    return this.searchString
      .pipe(
        take(1), // hot to cold observable
        map(string => {
          // check whether rule match a part of the search string
          if (!queryTokens || !queryTokens.length) {
            return true;
          }
          for (const searchToken of queryTokens) {
            if (string.toLocaleLowerCase().indexOf(searchToken) !== -1) {
              return true;
            }
          }
          return false;
        }),
        map(r => {
          // AND check all facet conditions are fulfilled
          if (!facetFilter || !facetFilter.length) {
            return r; // if no facet filter is active => no need to check anything
          }
          return r && facetFilter
            .filter(facet => {
              const value = facet.property.value(this.element); // current element property values
              return value.indexOf(facet.value) === -1; // true => required value not found!
            }).length === 0;
        }),
        map(match => [match, this.element] as [boolean, T])
      );
  }
}
