import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderToolbarPortalComponent} from './header-toolbar-portal/header-toolbar-portal.component';
import {SystemHealthStatusComponent} from './system-health-status/system-health-status.component';
import {SystemHealthDetailComponent} from './system-health-detail/system-health-detail.component';
import {SkwBadgeModule, SkwButtonModule, SkwDialogModule, SkwFormModule, SkwListModule, SkwLoadingModule} from 'skw-ui-components';
import {SkwPageLayoutModule} from 'skw-ui-layout';
import {SkwLanguageModule} from 'skw-ui-translation';
import { ErrorButtonComponent } from './error-button/error-button.component';
import {AudiCommonModule} from "../audi-common/audi-common.module";

@NgModule({
  declarations: [HeaderToolbarPortalComponent, SystemHealthStatusComponent, SystemHealthDetailComponent, ErrorButtonComponent],
    imports: [
        CommonModule,
        SkwListModule,
        SkwPageLayoutModule,
        SkwLanguageModule,
        SkwBadgeModule,
        SkwButtonModule,
        SkwLoadingModule,
        SkwDialogModule,
        SkwFormModule,
        AudiCommonModule
    ],
  exports: [HeaderToolbarPortalComponent],
  entryComponents: [
    SystemHealthDetailComponent
  ]
})
export class SystemStatusModule {
}
