import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from '@angular/core';
import {SchedulerService} from '../scheduler.service';
import {Subject} from 'rxjs';
import {indicate} from 'skw-ui-components';

@Component({
    selector: 'app-scheduler-info',
    templateUrl: './scheduler-info.component.html',
    styleUrls: ['./scheduler-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SchedulerInfoComponent {

    private _system: string;
    private _scheduler: string;

    loading$ = new Subject<boolean>();

    _state: any;
    _updateAvailable = 0;

    // only used to not repeat template table
    _tables = [
        ['pages.schedulerInfo.sourceModules', 'sourceModules'],
        ['pages.schedulerInfo.targetModules', 'targetModules']
    ];

    constructor(private service: SchedulerService,
                private cdr: ChangeDetectorRef) {
    }

    @Input()
    set scheduler(scheduler: string) {
        this._scheduler = scheduler;
        this._load();
    }

    @Input()
    set system(system: string) {
        this._system = system;
        this._load();
    }

    triggerUpdateAvailable() {
        this._updateAvailable++;
        this.cdr.detectChanges();
    }

    _load() {
        if (!this._system || !this._scheduler) {
            return;
        }
        this.service.getSchedulerState(this._system, this._scheduler)
            .pipe(indicate(this.loading$))
            .subscribe(r => {
                this._state = r;
                this._state.sourceModules = this._state.sourceModules.sort((m1, m2) => m1.name.localeCompare(m2.name));
                this._state.targetModules = this._state.targetModules.sort((m1, m2) => m1.name.localeCompare(m2.name));
                this._updateAvailable = 0;
                this.cdr.detectChanges();
            });
    }

}
