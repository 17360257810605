import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {RulesService} from '../../rules.service';

/**
 * This is a simple submit dialog for delete a rule which
 * allows the user to do some additional actions e.g. release
 * some locks.
 */
@Component({
  selector: 'app-copy-rule-dialog',
  templateUrl: './copy-rule-dialog.component.html',
  styleUrls: ['./copy-rule-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CopyRuleDialogComponent {

  _ruleKey: string;

  constructor(private dialogRef: MatDialogRef<CopyRuleDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { ruleKey: string, ruleId: number },
              private ruleService: RulesService) {
    this._ruleKey = data.ruleKey;
  }

  close(value: boolean) {
    this.dialogRef.close({
      copyRule: value,
      ruleKey: this._ruleKey
    });
  }

}
