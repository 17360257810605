import {Component, EventEmitter, Input, Output} from '@angular/core';
import {OPERATOR_VALUE_MAPPING} from '../rule-condition-operators';
import {SkwSelectModel} from 'skw-ui-components';

@Component({
  selector: 'app-rule-value-input',
  templateUrl: './rule-value-input.component.html',
  styleUrls: ['./rule-value-input.component.scss']
})
export class RuleValueInputComponent {

  private readonly COMMA_SEPARATOR = ',';

  @Input() operator: string;
  @Input() attributeValues: SkwSelectModel<string>[];
  @Output() invalidChange = new EventEmitter<boolean>();

  _singleValue: string;
  _arrayValue: string[];

  @Input() set commaSeparatedValue(value: string) {
    this._singleValue = value;
    this._arrayValue = !value || !value.trim() ? [] : value.split(this.COMMA_SEPARATOR);
  }

  @Output() commaSeparatedValueChange = new EventEmitter<string>();

  operatorValueMapping = OPERATOR_VALUE_MAPPING;

  valueChange($event: string | string[]) {
    const s = typeof $event === 'string' ? $event : (Array.isArray($event) ? $event.join(this.COMMA_SEPARATOR) : $event);
    this.commaSeparatedValueChange.emit(s);
  }
}
