import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ConditionModel} from '../condition-model';
import {SkwSelectModel} from 'skw-ui-components';
import {RulesService} from '../../rules.service';

@Component({
  selector: 'app-rule-condition',
  templateUrl: './rule-condition.component.html',
  styleUrls: ['./rule-condition.component.scss']
})
export class RuleConditionComponent {
  @Input() editMode: boolean;
  @Input() condition: ConditionModel;
  @Output() conditionChange = new EventEmitter<ConditionModel>();
  operatorTypes: SkwSelectModel<string>[];

  constructor(private rulesService: RulesService) {
    // load operatorTypes
    rulesService.getRuleDefinitionsModel().subscribe(value => {
      this.operatorTypes = value.operatorTypes.map(ot => {
        return {
          value: ot,
          viewValue: 'pages.rules.detail.logicElements.' + ot
        } as SkwSelectModel<string>;
      });
    });
  }

}
