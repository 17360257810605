import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {SkwFilterElement} from 'skw-ui-components';
import {SkwFacetFilter, SkwFacetResult, SkwFacetResultValue} from '../skw-facet-search';
import {SkwTranslatableValue} from "skw-ui-translation";

@Component({
  selector: 'skw-search-facets',
  templateUrl: './skw-search-facets.component.html',
  styleUrls: ['./skw-search-facets.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SkwSearchFacetsComponent<T> {
  /**
   * This is the main output callback which should be used to propagate clicked filters.
   */
  @Output() facetFiltersChange = new EventEmitter<SkwFacetFilter<T>[]>();
  /**
   * This filters are the general {@link SkwFilterElement}s which can be used to bind this facet component
   * to a filter badge display e.g. skw-search-card. This is a simple two-way bound value which is updated
   * by this component but isn't used to display the facets.
   */
  @Output() filtersChange = new EventEmitter<SkwFilterElement[]>();
  /**
   * This filters are the general {@link SkwFilterElement}s which can be used to bind this facet component
   * to a filter badge display e.g. skw-search-card. This is a simple two-way bound value which is updated
   * by this component but isn't used to display the facets.
   */
  @Input() filters: SkwFilterElement[] = [];
  private _activeFacetFilters: SkwFacetFilter<T>[] = [];

  /**
   * Set the current filter state to the facets. This can also be retrieved by the facet implementation.
   * @param value filters to be set
   */
  @Input() set facetFilters(value: SkwFacetFilter<T>[]) {
    this._activeFacetFilters = !value ? [] : value;
  }

  _facets: SkwFacetResult<T>[];

  /**
   * Set the current facet state. This can easily filled with the facet implementation.
   * @param value facets to be displayed
   */
  @Input() set facets(value: SkwFacetResult<T>[]) {
    this._facets = value;
  }

  /**
   * Reset all checked facet values of a property by name.
   * @param propertyName the property to be reset
   */
  onResetPropertyFilter(propertyName: SkwTranslatableValue) {
    this.filters = this.filters.filter(e => !(e.name === propertyName));
    this._activeFacetFilters = this._activeFacetFilters.filter(e => !(e.property.name === propertyName));
    this.filtersChange.emit(this.filters);
    this.facetFiltersChange.emit(this._activeFacetFilters);
  }

  onFacetValueToggle(propertyName: SkwTranslatableValue, value: string | number | boolean, checked: boolean) {
    const property = this._facets.find(f => f.property.name === propertyName);
    let resultValue = property.values.find(v => v.value === value);
    if (typeof resultValue === 'undefined') {
      resultValue = {
        value: value,
        viewValue: undefined,
        matches: 0
      };
    }
    this.onFacetToggle(property, resultValue, checked);
  }

  onFacetToggle(facet: SkwFacetResult<T>, value: SkwFacetResultValue, checked: boolean) {
    if (checked) {
      this._activeFacetFilters.push({
        value: value.value,
        property: facet.property
      });
      this.filters = [...this.filters, {
        name: facet.property.name,
        viewName: facet.property.name,
        value: value.value,
        viewValue: value.viewValue
      } as SkwFilterElement];
    } else {
      // remove the filter!
      this.filters = this.filters.filter(e => !(e.name === facet.property.name && e.value === value.value));
      this._activeFacetFilters =
        this._activeFacetFilters.filter(e => !(e.property.name === facet.property.name && e.value === value.value));
    }
    this.filtersChange.emit(this.filters);
    this.facetFiltersChange.emit(this._activeFacetFilters);
  }

  isFacetChecked(facet: SkwFacetResult<T>, value: SkwFacetResultValue): boolean {
    return !!this._activeFacetFilters.find(e => e.property.name === facet.property.name && e.value === value.value);
  }

}
