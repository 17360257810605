import {
  ActionType,
  BindedOperand,
  EventAttributeOperand,
  LiteralOperand,
  Operand,
  ParameterType,
  QueryType
} from './condition-model';
import {deepCopy} from 'skw-ui-components';

export abstract class ParameterizedComponent {
  protected parameterDefaultValues = {
    String: {
      type: 'LiteralOperand',
      value: ''
    } as LiteralOperand,
    Integer: { // FIXME we need support for int values
      type: 'LiteralOperand',
      value: ''
    } as LiteralOperand,
    Object: {
      type: 'EventAttributeOperand',
      attributeName: 'not set',
      eventVariableName: '$event'
    } as EventAttributeOperand,
    Event: {
      type: 'BindedOperand',
      variableName: 'not set'
    } as BindedOperand,
  };

  extractAndSortParameters(query: QueryType | ActionType): ParameterType[] {
    const result: ParameterType[] = [];
    for (const p in query.parameters) {
      result.push(query.parameters[p]);
    }
    return result.sort((a, b) => a.ordinal - b.ordinal);
  }

  fillOperandsWithDefaultValues(operands: Operand[], parameters: ParameterType[]) {
    for (let i = 0; i < parameters.length; i++) {
      if (!operands[i]) {
        const defaultType = (this.parameterDefaultValues)[parameters[i].type];
        if (!defaultType) {
          console.error(`DefaultType for ${parameters[i].type} not defined!`);
        }
        operands[i] = deepCopy(defaultType);
      }
    }
  }

}
