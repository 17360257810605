import {Component, EventEmitter, Input, Output} from '@angular/core';
import {
  BindedOperand,
  EventAttributeOperand,
  EventType,
  ListOperand,
  LiteralOperand,
  Operand,
  QueryOperand,
  QueryType
} from '../condition-model';
import {deepCopy, SkwSelectModel} from 'skw-ui-components';
import {RulesService} from '../../rules.service';
import {ParameterizedComponent} from '../parameterized-component';

@Component({
  selector: 'app-rule-operand',
  templateUrl: './rule-operand.component.html',
  styleUrls: ['./rule-operand.component.scss']
})
export class RuleOperandComponent extends ParameterizedComponent {
  @Input() editMode: boolean;

  @Input() triggerContext: EventType;
  @Input() autocompleteValues: SkwSelectModel<string>[];

  /**
   * What kind of Operator is allowed? They can be selected by return type.
   */
  @Input()
  set returnType(returnType: string) {
    this._returnType = returnType;
    // filter the model of selectable Operands to the list of allowed returnTypes

    const allowedOperands = this.returnTypeToOperandMap[returnType] as string[];
    if (allowedOperands == undefined) {
      console.error('No Components defined for returnType', returnType);
      this.availableOperands = [];
    } else {
      this.availableOperands = allowedOperands.map(v => {
          return {
            value: v,
            viewValue: 'pages.rules.operandTypes.' + v + '.name'
          };
        }
      );
    }
  }

  @Input() operand: Operand;
  @Output() operandChange: EventEmitter<Operand> = new EventEmitter<Operand>();

  _returnType: string;
  availableOperands: SkwSelectModel<string> [];

  returnTypeToOperandMap = {
    'String': [
      'LiteralOperand', 'EventAttributeOperand'
    ],
    'Integer': [
      'LiteralOperand'
    ],
    'Boolean': [
      'LiteralOperand'
    ],
    'Event': [
      'BindedOperand'
    ],
    'Object': [
      'EventAttributeOperand'
    ],
    'All': [
      'LiteralOperand', 'EventAttributeOperand', 'ListOperand', 'QueryOperand'
    ]
  };

  private operandTypeMap = {
    'EventAttributeOperand': {
      type: 'EventAttributeOperand',
      eventVariableName: '$event',
      attributeName: ''
    } as EventAttributeOperand,
    'ListOperand': {
      type: 'ListOperand',
      value: []
    } as ListOperand,
    'LiteralOperand': {
      type: 'LiteralOperand',
      value: ''
    } as LiteralOperand
    // QueryOperand added after loading queryType definitions in constructor... not pretty, but works a bit.
  };

  private previousOperands = {};

  private queryTypes: QueryType[];


  constructor(private rulesService: RulesService) {
    super();

    // load queryTypes
    rulesService.getRuleDefinitionsModel().subscribe(value => {
      let queryType = value.queryTypes[0];

      // generate default operand values for this queryType
      let sortedParameters = this.extractAndSortParameters(queryType);
      const result: Operand[] = [];
      this.fillOperandsWithDefaultValues(result, sortedParameters);

      // set default queryType for creation
      this.operandTypeMap['QueryOperand'] = {
        type: 'QueryOperand',
        query: value.queryTypes[0],
        params: result
      } as QueryOperand;
    });
  }

  changeOperand($event: string) {
    // save previous operator type values
    this.previousOperands[this.operand.type] = this.operand;

    if (this.previousOperands[$event]) {
      // restore previous value if present
      this.operand = this.previousOperands[$event];
    } else {
      this.operand = deepCopy(this.operandTypeMap[$event]);
    }

    this.operandChange.emit(this.operand);
  }
}
