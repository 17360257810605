import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SkwTabGroupComponent} from './skw-tab-group/skw-tab-group.component';
import {SkwTabDirective} from './skw-tab.directive';
import {MatTabsModule} from '@angular/material';
import {SkwLanguageModule} from 'skw-ui-translation';

const COMPONENTS = [SkwTabGroupComponent, SkwTabDirective];

@NgModule({
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
  imports: [
    CommonModule,
    MatTabsModule,
    SkwLanguageModule
  ]
})
export class SkwTabsModule {
}
