import {Component, OnInit} from '@angular/core';
import {SkwLayoutHeaderToolbar} from 'skw-ui-layout/skw/industry-layout/layout/header/header.portal';

@Component({
  selector: 'app-error-button',
  templateUrl: './error-button.component.html',
  styleUrls: ['./error-button.component.scss']
})
export class ErrorButtonComponent implements OnInit, SkwLayoutHeaderToolbar {

  constructor() {
  }

  ngOnInit() {
  }

  onAttached(): any {
  }

}
