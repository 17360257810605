import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {TargetingSettingsService} from '../../settings/targeting-settings.service';

@Component({
  selector: 'app-settings-status',
  templateUrl: './settings-status.component.html',
  styleUrls: ['./settings-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsStatusComponent {

  constructor(public targetingSettingsService: TargetingSettingsService) {
  }

}
