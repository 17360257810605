import {Component, EventEmitter, Input, Output} from '@angular/core';
import {EventPropertyCondition, EventType, EventTypeAttribute} from '../../condition-model';
import {nullSafeEquals, SkwSelectModel} from 'skw-ui-components';
import {RulesService} from '../../../rules.service';
import {OPERATOR_TYPES} from '../rule-condition-operators';

@Component({
  selector: 'app-rule-condition-event-property',
  templateUrl: './rule-condition-event-property.component.html',
  styleUrls: ['./rule-condition-event-property.component.scss']
})
export class RuleConditionEventPropertyComponent {
  @Input() editMode: boolean;
  @Output() conditionChange = new EventEmitter<EventPropertyCondition>();

  _condition: EventPropertyCondition;
  _attributeValues: SkwSelectModel<string>[] = [];
  _hasAttributeError: boolean;

  private _triggerContext: EventType;

  @Input()
  set triggerContext(value: EventType) {
    this._triggerContext = value;
    this.calcAvailableAttributes();
  }

  @Input()
  set condition(cond: EventPropertyCondition) {
    this._condition = cond;
    this.calcAvailableAttributes();
  }

  selectedAttribute: EventTypeAttribute;

  // hardcoded, because we certainly know which operators we allow
  operatorTypes = OPERATOR_TYPES;

  eventAttributes: SkwSelectModel<EventTypeAttribute>[];

  equalsFunction = nullSafeEquals<EventTypeAttribute>((o1, o2) => (o1.name === o2.name));

  constructor(private rulesService: RulesService) {
  }

  setAttributeReference($event: EventTypeAttribute) {
    this.selectedAttribute = $event;
    this.loadAttributeValues();
    this._condition.attributeName = $event.name;
    this.validateCondition();
  }

  onInvalid($event: boolean) {
    if ($event) {
      this._condition.invalid = true;
    } else {
      this._condition.invalid = undefined;
    }
  }

  private validateCondition() {
    // check whether trigger has itemId to reference car body property
    this.rulesService.getRuleDefinitionsModel().subscribe(value => {
      if (!this._triggerContext) {
        this._hasAttributeError = true;
        return;
      }
      const trigger = value.eventTypes.find(t => t.name === this._triggerContext.name);
      if (!trigger) {
        this._hasAttributeError = true;
        return;
      }
      this._hasAttributeError = !this._triggerContext
        || this.eventAttributes.length === 0
        || this._condition && this._condition.attributeName && !trigger.supportedAttributes[this._condition.attributeName];
    });
  }

  private loadAttributeValues() {
    if (!this.selectedAttribute) {
      return;
    }
    this.rulesService.getEventAttributeValues(this.selectedAttribute.name)
      .subscribe(r => {
        if (!r) {
          this._attributeValues = [];
          return;
        }
        this._attributeValues = r.map(a => {
          return {
            value: a.value.toString(),
            viewValue: a.value.toString()
          };
        });
      });
  }

  private calcAvailableAttributes() {
    if (!this._triggerContext) {
      return;
    }
    this.rulesService.getRuleDefinitionsModel().subscribe(value => {
      const trigger = value.eventTypes.find(t => t.name === this._triggerContext.name);

      this.eventAttributes = Object.values(trigger.supportedAttributes)
        .map(attr => {
          return {
            value: attr,
            viewValue: `pages.rules.eventParameters.${attr.name}`
          } as SkwSelectModel<EventTypeAttribute>;
        });

      if (this._condition) {
        // find the selected attribute for the skw-select-input
        const selectModel: SkwSelectModel<EventTypeAttribute> =
          this.eventAttributes.find(x => x.value.name === this._condition.attributeName);
        this.selectedAttribute = selectModel ? selectModel.value : undefined;
        this.loadAttributeValues();
      }
      this.validateCondition();
    });
  }
}
