import {Pipe, PipeTransform} from '@angular/core';
import {Rule, RulesService} from './rules.service';

@Pipe({
  name: 'rulePriority',
  pure: true
})
export class RulePriorityPipe implements PipeTransform {

  constructor(private rulesService: RulesService) {
  }

  transform(rule: Rule): any {
    return this.rulesService.hasPrioAction(rule) ? rule.priority : '-';
  }

}
