import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {
  indicate,
  SkwErrorHandlerService,
  SkwSelectModel,
  SkwSnackBarService,
  TableSelectRequest
} from 'skw-ui-components';
import {SkwApiService} from 'skw-ui-bootstrap';
import {MasterDetailComponent} from 'skw-ui-layout';
import {SkwLanguageService} from 'skw-ui-translation';

export class AdvancedSetting {
  id: number;
  attributeKey: string;
  value: string | number;
}

export class PerModuleSetting {
  module: string;
  setting: string;
  value: string | number;
}

@Component({
  selector: 'app-advanced-settings',
  templateUrl: './advanced-settings.component.html',
  styleUrls: ['./advanced-settings.component.scss']
})
export class AdvancedSettingsComponent implements OnInit {


  $loading = new BehaviorSubject(true);
  dirty = false;
  advancedSettings: { [key: string]: string };
  waitTimeOut = 60;
  itemReadyTimeOut = 30;
  jamDetection = 30;
  perModuleSettings: PerModuleSetting[] = [];
  activeColumns: string[] = ['module', 'setting', 'value'];
  @ViewChild('masterDetail') layout: MasterDetailComponent<PerModuleSetting>;
  createNewMode: boolean;
  perModuleSetting: PerModuleSetting;
  availableSettings = [{
    value: 'pls.rb-monitoring.wait-for-transport-timeout',
    viewValue: 'Transportbeginn'
  }, {
    value: 'pls.rb-monitoring.item-ready-timeout',
    viewValue: 'Belegtmarkierung'
  }, {
    value: 'pls.rb-monitoring.jam-detection',
    viewValue: 'Rückstauerkennung'
  }] as SkwSelectModel<string>[];

  constructor(public apiService: SkwApiService,
              private translationService: SkwLanguageService,
              private cdr: ChangeDetectorRef,
              private errorHandler: SkwErrorHandlerService,
              private snackBar: SkwSnackBarService) {
  }

  ngOnInit() {
    this.reloadAdvancedSettings();
  }

  reloadAdvancedSettings() {
    this.apiService.get<{ [key: string]: string }>('/settings/advanced/')
      .pipe(indicate(this.$loading))
      .subscribe(result => this.extractSettings(result));

  }

  saveAdvancedSettings() {
    this.advancedSettings['pls.rb-monitoring.wait-for-transport-timeout'] = '' + this.waitTimeOut;
    this.advancedSettings['pls.rb-monitoring.item-ready-timeout'] = '' + this.itemReadyTimeOut;
    this.advancedSettings['pls.rb-monitoring.jam-detection'] = '' + this.jamDetection;
    this.apiService.post<{ [key: string]: string }>('/settings/advanced/', this.advancedSettings)
      .pipe(indicate(this.$loading))
      .subscribe(result => {
        this.extractSettings(result);
      });
  }

  extractSettings(result: { [key: string]: string }) {
    if (!result['pls.rb-monitoring.wait-for-transport-timeout']) {
      result['pls.rb-monitoring.wait-for-transport-timeout'] = '' + this.waitTimeOut;
    }
    if (!result['pls.rb-monitoring.item-ready-timeout']) {
      result['pls.rb-monitoring.item-ready-timeout'] = '' + this.itemReadyTimeOut;
    }
    if (!result['pls.rb-monitoring.jam-detection']) {
      result['pls.rb-monitoring.jam-detection'] = '' + this.jamDetection;
    }
    this.waitTimeOut = +result['pls.rb-monitoring.wait-for-transport-timeout'];
    this.itemReadyTimeOut = +result['pls.rb-monitoring.item-ready-timeout'];
    this.jamDetection = +result['pls.rb-monitoring.jam-detection'];

    this.advancedSettings = result;

    this.perModuleSettings = Object.keys(result).reduce((newArray, key) => {
      if (key.indexOf('rb-monitoring') === -1)
        return newArray;

      var lastSegment = key.substring(key.lastIndexOf('.') + 1);
      if (!lastSegment.match(/\d{3}..\d{3}/))
        return newArray;

      newArray.push({
        module: lastSegment,
        setting: key.substring(0, key.lastIndexOf('.')),
        value: result[key]
      } as PerModuleSetting)
      return newArray;
    }, [] as PerModuleSetting[]);
  }

  addPerModuleSetting() {

    this.createNewMode = true;
    this.openDetail(new PerModuleSetting());
  }

  onSelect($event: TableSelectRequest) {
    const element: PerModuleSetting = $event.last;
    this.createNewMode = false;
    if (!element) {
      this.closeDetail();
    } else {
      this.openDetail(element);
    }
  }

  private openDetail(element: PerModuleSetting) {
    this.perModuleSetting = {...element};
    this.layout.openDetail(this.perModuleSetting);
  }

  private closeDetail() {
    this.perModuleSetting = undefined;
    this.layout.closeDetail();
  }

  onSave() {
    if (this.createNewMode) {
      this.perModuleSettings.push({...this.perModuleSetting});
    } else {
      const current = this.perModuleSettings.find(setting => setting.module === this.perModuleSetting.module && setting.setting === this.perModuleSetting.setting);
      current.setting = this.perModuleSetting.setting;
      current.value = this.perModuleSetting.value;
    }
    this.perModuleSettings = [...this.perModuleSettings];

    this.advancedSettings[this.perModuleSetting.setting + '.' + this.perModuleSetting.module] = '' + this.perModuleSetting.value;
    this.closeDetail();
    this.dirty = true;
  }

  onAbort() {
    this.closeDetail();
  }

  onDelete() {
    var elem = this.perModuleSettings.find(el => el.module === this.perModuleSetting.module && el.setting === this.perModuleSetting.setting)
    var index = this.perModuleSettings.indexOf(elem);
    if (index !== -1) {
      this.perModuleSettings.splice(index, 1);
      this.advancedSettings[elem.setting + '.' + elem.module] = undefined;
    }
    this.perModuleSettings = [...this.perModuleSettings];
    this.closeDetail();
    this.dirty = true;
  }
}
