import {ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {Subject} from 'rxjs';
import {Rule, RuleCategory, RulesService} from '../../rules.service';
import {
  indicate,
  SkwDialogService,
  SkwErrorHandlerService,
  SkwSnackBarService,
  TableSelectRequest
} from 'skw-ui-components';
import {MasterDetailComponent} from 'skw-ui-layout';
import {SkwLanguageService} from 'skw-ui-translation'
import {Router} from '@angular/router';

@Component({
  selector: 'app-rule-category',
  templateUrl: './rule-category.component.html',
  styleUrls: ['./rule-category.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class RuleCategoryComponent implements OnInit {

  loading$ = new Subject<boolean>();
  detailLoading$ = new Subject<boolean>();
  ruleCategories: RuleCategory[];
  createNewMode = false;

  attribute: RuleCategory | undefined;

  @ViewChild('masterDetail') layout: MasterDetailComponent<RuleCategory>;

  constructor(private rulesService: RulesService,
              private cdr: ChangeDetectorRef,
              private errorHandlerService: SkwErrorHandlerService,
              private snackBar: SkwSnackBarService,
              private dialogService: SkwDialogService,
              private translateService: SkwLanguageService,
              private router: Router) {

  }

  onRefreshClick() {
    this.loadRuleCategories();
  }

  onNewClick() {
    this.createNewMode = true;
    this.openDetail({
      id: undefined,
      categoryName: undefined
    } as RuleCategory);
  }

  ngOnInit(): void {
    this.loadRuleCategories();
  }

  private loadRuleCategories() {
    this.rulesService.listRuleCategories()
      .pipe(indicate(this.loading$))
      .subscribe(ruleCats => {
        this.ruleCategories = ruleCats;
        this.cdr.detectChanges();
      }, error => this.errorHandlerService.handleHttpError(error));
  }


  onSelect($event: TableSelectRequest) {
    const element: RuleCategory = $event.last;
    this.createNewMode = false;
    if (!element) {
      this.closeDetail();
    } else {
      this.openDetail(element);
    }

  }

  private closeDetail() {
    this.attribute = undefined;
    this.layout.closeDetail();
  }


  private openDetail(element: RuleCategory) {
    this.attribute = {...element}
    this.layout.openDetail(this.attribute);
  }

  private checkName(categoryName: string): boolean {
    let result = this.ruleCategories.find(element => element.categoryName.toLowerCase() === categoryName.toLowerCase());
    // if there is no category with same name, return true
    return !result
  }

  onSave() {
    // control if name is empty or attribute hasn't defined yet
    if (this.attribute.categoryName === undefined || this.attribute.categoryName.length === 0) {
      this.snackBar.showError('pages.rules.ruleCategory.emptyCategoryNameError')
      return;
    }

    // control if name is already there
    if (this.checkName(this.attribute.categoryName) === false) {
      this.snackBar.showError('pages.rules.ruleCategory.sameCategoryNameError')
      return;
    }

    if (this.createNewMode) {
      this.rulesService.addRuleCategory(this.attribute)
        .subscribe(() => {
          this.closeDetail();
          this.loadRuleCategories();
          //this.cdr.detectChanges();
          this.snackBar.showInfo('pages.mds.messages.addSuccess')
        }, error => this.errorHandlerService.handleHttpError(error));
    } else {
      this.rulesService.updateRuleCategory(this.attribute.id, this.attribute)
        .subscribe(() => {
          this.closeDetail();
          this.loadRuleCategories();
          this.snackBar.showInfo('pages.rules.ruleCategory.ruleCategoryUpdated')
        }, error => this.errorHandlerService.handleHttpError(error))
    }
  }

  onAbort() {
    this.closeDetail();
  }

  onDelete() {

    // if rule name is Weitere, you can't delete this one
    if (this.attribute.categoryName === 'Weitere') {
      this.snackBar.showError('pages.rules.ruleCategory.ruleCategoryDeletionNotAllowed');
      return;
    }

    // get rules which in this category and show to user in dialog
    this.rulesService.getRuleNamesByRuleCategory(this.attribute.id)
      .subscribe(ruleList => {
        this.createConfirmationDialog(ruleList)
      }, error => this.errorHandlerService.handleHttpError(error))

  }

  createConfirmationDialog(ruleList: Rule[]) {
    let mainText: string = '';
    let ruleCount;
    if (ruleList.length) {
      ruleCount = ruleList.length.toString();
      mainText = 'pages.rules.ruleCategory.deleteConfirmWithRules';
    } else {
      mainText = `pages.rules.ruleCategory.deleteConfirm`;
    }


    this.dialogService.yesNoDialog(mainText, 'pages.rules.ruleCategory.delete-category-heading', 'warning', {
      ruleNumber: ruleCount,
      ruleCategoryName: this.attribute.categoryName
    })
      .subscribe(val => {
        if (val) {
          this.startDeleteRuleCategory()
        } else {
          this.closeDetail();
        }
      })

  }

  startDeleteRuleCategory() {

    const result = new Subject<boolean>();
    const ruleCategoryIds: number[] = [this.attribute.id];
    this.rulesService.removeRuleCategories(ruleCategoryIds)
      .subscribe(() => {
        result.next(true);
        result.complete();
        this.closeDetail();
        this.loadRuleCategories();
        this.snackBar.showInfo('pages.rules.ruleCategory.successfullyDeleted');
      }, error => result.error(error));

  }

  routeBack() {
    this.router.navigateByUrl('/pages/rules');
  }

}
