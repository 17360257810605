 import {Injectable} from '@angular/core';
import {SkwApiService} from 'skw-ui-bootstrap';

export class ProportionEntry {
  target: string;
  currentCount: number;
  plannedProportion: number
}

export class ProportionCountsResponse {
  counts: { [key: string]: { [key: string]: ProportionEntry } };
}

@Injectable({
  providedIn: 'root'
})
export class ProportionCountsService {

  constructor(private apiService: SkwApiService) {
  }

  getProportionCounts() {
    return this.apiService.get<ProportionCountsResponse>('/proportions/counts');
  }

  resetProportionCounts(attributeValue: string) {
    this.apiService.post('/proportions/reset', null, {params: {attributeValue: attributeValue}});
  }
}
