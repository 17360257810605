import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {KvsOrderListComponent} from './kvs-order-list.component';
import {SkwButtonModule, SkwCardModule, SkwDialogModule, SkwTableModule} from 'skw-ui-components';
import {SkwPageLayoutModule} from 'skw-ui-layout';
import {MatTooltipModule} from '@angular/material/tooltip';
import {SkwSidebarViewModule} from '../../skw-sidebar-view/skw-sidebar-view.module';
import {SkwSearchModule} from '../../skw-search/skw-search.module';
import {SkwLanguageModule} from 'skw-ui-translation';
import {MatMenuModule} from '@angular/material/menu';
import {SkwAuthorizationModule} from 'skw-ui-authentication';
import { KvsKvzApprovalDialogComponent } from './kvs-kvz-approval-dialog/kvs-kvz-approval-dialog.component';

@NgModule({
  declarations: [
    KvsOrderListComponent,
    KvsKvzApprovalDialogComponent
  ],
  imports: [
    CommonModule,
    SkwButtonModule,
    SkwPageLayoutModule,
    MatTooltipModule,
    SkwCardModule,
    SkwSidebarViewModule,
    SkwSearchModule,
    SkwTableModule,
    SkwLanguageModule,
    MatMenuModule,
    SkwAuthorizationModule,
    SkwDialogModule
  ],
  entryComponents: [
    KvsKvzApprovalDialogComponent
  ]
})
export class KvsOrderListModule { }
