import { Injectable } from '@angular/core';
import {SkwFacetFilter} from '../../skw-search/skw-facet-search';
import {SkwFilterElement} from 'skw-ui-components';
import {Observable} from 'rxjs';
import {RuleSearchResult} from '../rules/rules.service';
import {SkwApiService} from 'skw-ui-bootstrap';
import {WebsocketEventService} from '../../api/websocket/websocket-event.service';

export class KvsOrderEntry {
  id: number;
  row: number;
  system: string;
  colorDescription: string; //KvsName
  colorCodeKVS: string; //KvsFarbCodeN56
  colorCodeOriginal: string; //KvsFarbCode
  fmrApproved: boolean; //FrgFmr
  fssApproved: boolean; //FrgFss
  kvzApproved: boolean; //FrgKvz
  rspApproved: boolean; //FrgRsp
  colorGroupCode: string; //Farbgruppencode
  itemId: string; //Kid14
  commission: string; //Kommissionsnummer
  lackNr: string; //Decklackcode
  status: string; // Status
  tps: string; //TPS
  error: boolean; //Error
  errorMessage: string;
  timestamp: Date;
  timeCreated: Date;
  timeUpdated: Date;
}

export class KvsOrderSearchResult {
  result: KvsOrderEntry[];
  allCount: number;
}

@Injectable({
  providedIn: 'root'
})
export class KvsOrderListService {


  // current rule overview filter
  filter = {
    searchString: undefined,
    facets: undefined,
    filters: []
  } as {
    searchString: string,
    facets: SkwFacetFilter<any>[],
    filters: SkwFilterElement[],
  };

  constructor(private api: SkwApiService) { }

  searchKvsOrders(searchString?: string): Observable<KvsOrderSearchResult> {
    const options = !searchString ? undefined : {params: {searchString}};
    return this.api.get<KvsOrderSearchResult>('/process/kvs/orders/search', options);

  }

  approveEntry(entry: KvsOrderEntry) {
    return this.api.post<any>(`/process/kvs/orders/${entry.itemId}/approve`, null);
  }

  getOpenKvzOrderCount(): Observable<number>{
    return this.api.get<number>(`/process/kvs/orders/count`, {});
  }
}
