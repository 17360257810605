import {Component, Input, OnInit} from '@angular/core';
import {PurposeSetting, PurposeSettingsService} from '../purpose-settings.service';
import {SkwAutocompleteModel, SkwDialogService, SkwErrorHandlerService, SkwSelectModel} from 'skw-ui-components';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {ItemFlowSettings} from '../item-flow-settings.service';
import {SkwTranslatableValue} from 'skw-ui-translation';

@Component({
  selector: 'app-display-attr-setting',
  templateUrl: './display-attr-setting.component.html',
  styleUrls: ['./display-attr-setting.component.scss'],
})
export class DisplayAttrSettingComponent implements OnInit {
  readonly CAR_BODY_ENTITY: string = 'CarBody';
  readonly ITEM_FLOW_ENTITY: string = 'ItemFlow';

  _purposes: PurposeSetting[];
  _carBodyPurposes: PurposeSetting[];
  _itemFlowPurposes: PurposeSetting[];

  _carBodyAttributes: SkwAutocompleteModel<string>[];
  _itemFlowAttributes: SkwAutocompleteModel<string>[];

  @Input() set carBodyAttributes(value: string[]) {
    if (!value) {
      return;
    }
    this._carBodyAttributes = value.map(v => {
      return {
        value: v,
        viewValue: v
      } as SkwAutocompleteModel<string>;
    });
  }

  @Input() set itemFlowAttributes(value: ItemFlowSettings[]) {
    if (!value) {
      return;
    }
    this._itemFlowAttributes = value.map(v => {
      return {
        value: v.attributeName,
        viewValue: {key: `item-flow-attributes.${v.attributeName}`, default: v.attributeName} as SkwTranslatableValue
      } as SkwSelectModel<string>;
    });
  }

  @Input() purposeName: string;

  constructor(private service: PurposeSettingsService,
              private dialogService: SkwDialogService,
              private errorHandler: SkwErrorHandlerService) {
  }

  sortCarBodyAttr($event: CdkDragDrop<PurposeSetting[]>) {
    moveItemInArray(this._carBodyPurposes, $event.previousIndex, $event.currentIndex);
    this._purposes = [...this._carBodyPurposes, ...this._itemFlowPurposes];
    this.service.updatePurposeOrder(this._purposes)
      .subscribe(() => {
        this.service.invalidateCache();
      }, error => this.handleHttpError(error));
  }

  sortItemFlowAttr($event: CdkDragDrop<PurposeSetting[]>) {
    moveItemInArray(this._itemFlowPurposes, $event.previousIndex, $event.currentIndex);
    this._purposes = [...this._carBodyPurposes, ...this._itemFlowPurposes];
    this.service.updatePurposeOrder(this._purposes)
      .subscribe(() => {
        this.service.invalidateCache();
      }, error => this.handleHttpError(error));
  }

  addCarBodyAttr() {
    const r = {
      entity: this.CAR_BODY_ENTITY,
      attributeName: undefined,
      purposeKey: this.purposeName,
      ordinal: this._carBodyPurposes.length
    } as PurposeSetting;
    const offset = this._carBodyPurposes.length;
    this._purposes.splice(offset, 0, r);
    this._carBodyPurposes.push(r);
  }

  addItemFlowAttr() {
    const r = {
      entity: this.ITEM_FLOW_ENTITY,
      attributeName: undefined,
      purposeKey: this.purposeName,
      ordinal: this._itemFlowPurposes.length + this._carBodyPurposes.length
    } as PurposeSetting;
    this._purposes.push(r);
    this._itemFlowPurposes.push(r);
  }

  updateAttr(p: PurposeSetting) {
    if (!p.attributeName) {
      return; // invalid input
    }
    if (p.id) {
      this.service.updatePurpose(p.id, p)
        .subscribe(() => {
          this.service.invalidateCache();
        }, error => this.handleHttpError(error));
    } else {
      this.service.addPurpose(p)
        .subscribe(r => {
          p.id = r.id;
          this.service.invalidateCache();
        }, error => this.handleHttpError(error));
    }
  }

  removeCarBodyAttr(index: number, p: PurposeSetting) {
    this.dialogService.submitDialog('pages.settings.submit-delete-attr', 'danger')
      .subscribe(r => {
        if (r) {
          this._purposes.splice(index, 1);
          this._carBodyPurposes.splice(index, 1);
          this.service.deletePurpose(p.id)
            .subscribe(() => {
              this.service.invalidateCache();
            }, error => this.handleHttpError(error));
        }
      });
  }

  removeItemFlowAttr(index: number, p: PurposeSetting) {
    this.dialogService.submitDialog('pages.settings.submit-delete-attr', 'danger')
      .subscribe(r => {
        if (r) {
          const offset = this._carBodyPurposes.length;
          this._purposes.splice(offset + index, 1);
          this._itemFlowPurposes.splice(index, 1);
          this.service.deletePurpose(p.id)
            .subscribe(() => {
              this.service.invalidateCache();
            }, error => this.handleHttpError(error));
        }
      });
  }

  ngOnInit(): void {
    this.loadAttributes();
  }

  loadAttributes() {
    this.service.invalidateCache();
    this.service.loadAttributesForPurpose(this.purposeName)
      .subscribe(value => {
        this._purposes = value;
        this._carBodyPurposes = this._purposes.filter(p => p.entity === this.CAR_BODY_ENTITY);
        this._itemFlowPurposes = this._purposes.filter(p => p.entity === this.ITEM_FLOW_ENTITY);
      }, error => this.errorHandler.handleHttpError(error));
  }

  handleHttpError(error: any) {
    this.loadAttributes();
    this.errorHandler.handleHttpError(error);
  }

}
