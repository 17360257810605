import {Injectable} from '@angular/core';
import {SkwApiService} from 'skw-ui-bootstrap';
import {Observable, of, ReplaySubject} from 'rxjs';
import {tap} from 'rxjs/operators';
import {SkwLanguageService} from 'skw-ui-translation';
import {SkwAuthService} from 'skw-ui-authentication';

export class SequencingSettings {
    id: number;
    settingKey: string;
    value: string | number;
}

@Injectable({
  providedIn: 'root'
})
export class SequencingSettingsService {

  readonly ORDER_STABILITY: string = 'orderStability';
  readonly TEAR_OFF_PREVENTION: string = 'tearOffPrevention';
  readonly COLOR_BLOCKS: string = 'colorBlocks';
  readonly PKNR: string = 'pknr';
  readonly MIN_COLOR_BLOCK_TIMEOUT: string = 'minColorBlockTimeout';
  readonly MAX_SEQUENCE_DISTANCE: string = 'maxSequenceDistance';
  readonly SETTINGS_SYNCED: string = 'settingSynced';
  readonly PROPORTION_CONTROL_RESET_ENABLED: string = 'proportionControlResetEnabled';
  readonly PROPORTION_CONTROL_SELECTED_ATTRIBUTE_ID: string = 'proportionControlSelectedAttributeID';
  readonly PROPORTION_CONTROL_N50_SUB_TARGET: string =  "proportionControlN50SubTarget";

  public settings = new ReplaySubject<{ [key: string]: SequencingSettings[] }>(1);
  public orderStability = new ReplaySubject<number>(1);
  public tearOffPrevention = new ReplaySubject<number>(1);
  public colorBlocks = new ReplaySubject<number>(1);
  public pknr = new ReplaySubject<number>(1);
  public minColorBlockTimeout = new ReplaySubject<number>(1);
  private cachedResult: { [key: string]: SequencingSettings[] };


  constructor(private apiService: SkwApiService,
              private authService: SkwAuthService,
              private translationService: SkwLanguageService) {
    this.authService.authObservable().subscribe(r => {
      // initially load item flow attributes if this service is injected
      if (r) {
        this.loadAll().subscribe();
      }
    });
  }

  loadAll(): Observable<{ [key: string]: SequencingSettings[] }> {
    if (typeof this.cachedResult !== 'undefined') {
            return of(this.cachedResult);
        }
        return this.apiService.get<{ [key: string]: SequencingSettings[] }>('/settings/sequencing/')
            .pipe(
                tap(v => {
                    this.updateSettings(v);
                })
            );
    }

    updateSettings(settings: { [key: string]: SequencingSettings[] }) {
        this.cachedResult = settings;
        this.settings.next(settings);
        this.orderStability.next(settings[this.ORDER_STABILITY][0].value as number);
        this.tearOffPrevention.next(settings[this.TEAR_OFF_PREVENTION][0].value as number);
        this.colorBlocks.next(settings[this.COLOR_BLOCKS][0].value as number);
        this.pknr.next(settings[this.PKNR][0].value as number);
        this.minColorBlockTimeout.next(settings[this.MIN_COLOR_BLOCK_TIMEOUT][0].value as number);
    }

    saveAll(settings: SequencingSettings[]) {
        return this.apiService.post('/settings/sequencing/', settings)
            .pipe(tap(() => this.invalidateCache()));
    }

    invalidateCache() {
        this.cachedResult = undefined;
    }

}
