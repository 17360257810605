import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {MfwItem, MfwLock} from 'skw-ui-modules';

@Component({
  selector: 'mfw-item-locks-info',
  templateUrl: './mfw-item-locks-info.component.html',
  styleUrls: ['./mfw-item-locks-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MfwItemLocksInfoComponent {
  @Input() item: MfwItem;
  @Output() unlock = new EventEmitter<MfwLock>();
  @Output() relock = new EventEmitter<MfwLock>();


  @Output()
  onNavigatedToRule = new EventEmitter();

  navigatedToRule() {
    this.onNavigatedToRule.emit();
  }
}
