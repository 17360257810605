import {BrowserModule, HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {PagesModule} from './pages/pages.module';
import {APP_BASE_HREF} from '@angular/common';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {
  SKW_AUTH_HTTP_ERROR_STRATEGY,
  SkwAuthenticationModule,
  SkwAuthHttpErrorInterceptor,
  SkwAuthHttpErrorReAuthStrategy,
  SkwAuthorizationModule,
  SkwJwtHttpInterceptor,
  SkwUserModule
} from 'skw-ui-authentication';
import {SkwApiBootstrapModule} from 'skw-ui-bootstrap';
import {SkwLanguageModule} from 'skw-ui-translation';
import {SkwLayoutModule, SkwNotificationModule, SkwScreenModule, SkwVersionModule} from 'skw-ui-layout';
import {
  SkwClipboardModule,
  SkwConfigLocalStoragePersistenceProperties,
  SkwConfigPersistenceModule,
  SkwCorporateModule,
  SkwDialogModule,
  SkwErrorModule,
  SkwSnackBarModule
} from 'skw-ui-components';
import {SkwWebsocketModule} from 'skw-ui-messaging';
import {
  MFW_AREA_DEFAULT_CONFIG,
  MFW_ELEVATOR_DEFAULT_CONFIG,
  MFW_ITEM_DEFAULT_CONFIG,
  MFW_ITEM_STATUS_LOGIC_PROVIDER,
  MFW_ROLLER_BELT_DEFAULT_CONFIG,
  MFW_SPOT_DEFAULT_CONFIG,
  MFW_STATUS_DEFAULT_CONFIG,
  MFW_TRAVERSING_CARRIAGE_DEFAULT_CONFIG,
  MfwAreaConfig,
  MfwElevatorModule,
  MfwLabelModule,
  MfwRollerBeltConfig,
  MfwSpotConfig,
  MfwSpotModule,
  MfwStatusConfig,
  MfwTraversingCarriageConfig
} from 'skw-ui-modules';
import {SystemStatusModule} from './system-status/system-status.module';
import {HeaderToolbarPortalComponent} from './system-status/header-toolbar-portal/header-toolbar-portal.component';
import {ErrorsModule} from './pages/errors/errors.module';
import {MfwItemStatusLogicProviderService} from './mfw-components/mfw-item/mfw-item-status-logic-provider.service';
import {GestureConfig} from '@angular/material';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    PagesModule,
    SystemStatusModule,
    SkwApiBootstrapModule.forRoot({}),
    SkwLanguageModule.forRoot({
      defaultLanguage: 'de',
      supportedLanguages: [
        {name: 'Deutsch', id: 'de', icon: 'flag-icon flag-icon-de'},
        {name: 'English', id: 'en', icon: 'flag-icon flag-icon-gb'}
      ],
      languageCookieName: 'fss-language'
    }),
    SkwAuthenticationModule.forRoot({
      tokenCookieName: 'fss-token',
      loginApiPath: '/api/v1/users/authenticate',
      loginRedirectPath: '/login',
      successRedirect: '/pages/home',
      persistToken: true,
      enableUrlTokenAuth: true,
      tokenUrlAllowedUsers: []
    }),
    SkwAuthorizationModule.forRoot({
      authorizationApiPath: '/api/v1/authorization/endpoints'
    }),
    SkwUserModule.forRoot({
      userApiPath: '/api/v1/users/current'
    }),
    SkwCorporateModule.forRoot({
      appName: 'AUDI FSS',
      corporateName: 'skillworks'
    }),
    SkwLayoutModule.forRoot({
      enableLanguageSelect: true,
      enableNotificationCenter: true,
      headerToolbarComponent: HeaderToolbarPortalComponent,
      sidebarMode: 'collapsible',
      enableUserDeeplink: true
    }),
    SkwSnackBarModule.forRoot({}),
    SkwErrorModule.forRoot({}),
    SkwWebsocketModule.forRoot({
      active: true,
      websocketPath: '/ws'
    }),
    SkwConfigPersistenceModule.forRoot({
      type: 'local-storage',
      localStorageName: 'audi-pls-ui-config'
    } as SkwConfigLocalStoragePersistenceProperties),
    SkwVersionModule,
    SkwNotificationModule.forRoot({
      severityColorDefault: 'primary',
      severityStatusMapping: [
        ['error', 'danger'],
        ['warn', 'warning'],
        ['error_recoverable', 'warning'],
        ['info', 'primary']
      ],
      bellRingingDefaultTime: 28000
    }),
    SkwClipboardModule.forRoot({
      showGlobalResultMessage: true
    }),
    SkwScreenModule.forRoot({
      fullscreenUrlParam: 'fullscreen',
      fullscreenSidebarMode: 'hideable',
      fullscreenMode: 'browser+app',
      fullscreenBrowserListening: true
    }),
    SkwDialogModule.forRoot({
      width: '600px'
    }),
    ErrorsModule
  ],
  providers: [
    {provide: APP_BASE_HREF, useValue: '/'},
    // Automatically handle authentication 401 status codes to ask for login.
    // Be aware, that this interceptor must be provided FIRST BEFORE JWT_HTTP-interceptor!!!!
    {provide: HTTP_INTERCEPTORS, useClass: SkwAuthHttpErrorInterceptor, multi: true},
    // used authentication error strategy (e.g. logout user)
    {provide: SKW_AUTH_HTTP_ERROR_STRATEGY, useClass: SkwAuthHttpErrorReAuthStrategy},
    // Automatically attach auth token (even if request failed the first time, so we
    // add this interceptor as LAST INTERCEPTOR!!!!)
    {provide: HTTP_INTERCEPTORS, useClass: SkwJwtHttpInterceptor, multi: true},
    // default mfw-module configurations
    {
      provide: MFW_AREA_DEFAULT_CONFIG, useValue: {
        id: undefined,
        labelVerticalPosition: 'bottom',
        clickable: true,
        operationModeIconMapping: {
          automatic: 'fa fa-sync',
          manual: 'fa fa-hand-paper',
          emergency: 'fa fa-first-aid',
          setup: 'fa fa-hourglass',
          stop: 'fa fa-stop-circle',
          unknown: 'fa fa-question'
        },
        operationModeColorMapping: {
          manual: 'warning',
          unknown: 'warning'
        },
        statusConfig: {
          statusColorMapping: {
            ok: 'default',
            unknown: 'danger-light'
          }
        }
      } as MfwAreaConfig
    },
    {
      provide: MFW_STATUS_DEFAULT_CONFIG, useValue: {
        statusColorMapping: {
          unknown: 'danger'
        }
      } as MfwStatusConfig
    },
    {provide: MFW_ITEM_DEFAULT_CONFIG, useValue: {}},
    {
      provide: MFW_ROLLER_BELT_DEFAULT_CONFIG, useValue: {
        id: undefined,
        clickable: true,
        lockable: true,
        resettable: true,
        hasItemReady: false, // default
        hasItemStatus: false // default
      } as MfwRollerBeltConfig
    },
    {
      provide: MFW_SPOT_DEFAULT_CONFIG, useValue: {
        id: undefined,
        clickable: true,
        hasItemReady: false,
        hasItemStatus: false, // currently no need to display long runner on spots
        resettable: true
      } as MfwSpotConfig
    },
    {provide: MFW_ELEVATOR_DEFAULT_CONFIG, useValue: {clickable: true}},
    {
      provide: MFW_TRAVERSING_CARRIAGE_DEFAULT_CONFIG, useValue: {
        id: undefined,
        clickable: true,
        lockable: true,
        hasItemReady: true,
        hasItemStatus: true,
        resettable: true
      } as MfwTraversingCarriageConfig
    },
    {provide: MFW_ITEM_STATUS_LOGIC_PROVIDER, useClass: MfwItemStatusLogicProviderService},
    {provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig} // TODO remove this with hammerjs after update to angular > v9
  ],
  entryComponents: [HeaderToolbarPortalComponent],
  bootstrap: [AppComponent],
  exports: [MfwSpotModule, MfwElevatorModule, MfwLabelModule]
})
export class AppModule {
}
