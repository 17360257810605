import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MfwTraversingCarriageConfig, MfwTraversingCarriageModel} from 'skw-ui-modules';
import {MatDialogRef} from '@angular/material/dialog';
import {MfwModuleModalComponent} from '../mfw-module-modal/mfw-module-modal.component';
import {Subscription} from 'rxjs';
import {HttpCallOptions, SkwApiService} from 'skw-ui-bootstrap';
import {SkwDialogService, SkwErrorHandlerService, SkwSelectModel, SkwSnackBarService} from 'skw-ui-components';
import {SystemHealthStatusService} from '../../system-status/header-toolbar-portal/system-health-status.service';

@Component({
  selector: 'app-mfw-transfer-car-modal',
  templateUrl: './mfw-transfer-car-modal.component.html',
  styleUrls: ['./mfw-transfer-car-modal.component.scss']
})
export class MfwTransferCarModalComponent implements OnInit, OnDestroy {

  @Input() layoutId: string;
  @Input() model: MfwTraversingCarriageModel;
  @Input() config: MfwTraversingCarriageConfig;
  @Input() dialogRef: MatDialogRef<MfwModuleModalComponent>;

  shortInfo: string;
  attributes: string[][];

  systemStateIsActive: boolean;
  private $systemStatusSubscription: Subscription;
  possibleParkPositions: SkwSelectModel<string>[] = [];
  parkPosition: string = null;

  constructor(private api: SkwApiService,
              private snackBar: SkwSnackBarService,
              private errorHandler: SkwErrorHandlerService,
              private dialogService: SkwDialogService,
              public statusService: SystemHealthStatusService,
              private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.$systemStatusSubscription = this.statusService.observable()
      .subscribe(v => {
        this.systemStateIsActive = this.isSystemActive(v);
      });

    // load all possible park positions for this transfer car
    this.api.get<string[]>(`/process/module/${this.layoutId}/${this.config.id}/parkPositions`)
      .subscribe(result => {
          this.possibleParkPositions = result.map(name => {
            return {value: name, viewValue: name, disables: false} as SkwSelectModel<string>;
          });
          this.loadParkPosition();
        },
        error => this.errorHandler.handleHttpError(error));
  }

  /**
   * Loads the currently set park position of the movingf module.
   */
  loadParkPosition() {
    this.api.get<any>(`/process/module/${this.layoutId}/${this.config.id}/parkPosition`)
      .subscribe(result => {
          this.parkPosition = result.position === null ? undefined : result.position;
          this.cdr.detectChanges();
        },
        error => this.errorHandler.handleHttpError(error));
  }

  ngOnDestroy(): void {
    this.$systemStatusSubscription.unsubscribe();
  }

  isSystemActive(systemStatus): boolean {
    const status = systemStatus.find(s => s.systemId === this.layoutId);
    if (!status) {
      console.error('No system status for ' + this.layoutId + ' Available: ', systemStatus)
    }
    return status && status.plsStatus === 'ACTIVE';
  }


  onSetLockState(lockDirection: string, value: boolean) {
    if (lockDirection === 'out') {
      this.model.locks.out = value;
    } else {
      this.model.locks.in = value;
    }


    this.dialogService.submitDialog('pages.traversing-carriage-modal.change.' + lockDirection + value, 'danger', {
      name: this.config.displayName
    })
      .subscribe(result => {
          if (result) {
            this.setLockState(this.config.id, lockDirection, value).subscribe(() => {
              this.snackBar.showInfo('messages.lock-changed');
            }, error => {
              this.dialogRef.close();
              this.errorHandler.handleHttpError(error);
            });
          } else {
            // dialog got canceled => reset toggle state
            if (lockDirection === 'out') {
              this.model.locks.out = !value;
            } else {
              this.model.locks.in = !value;
            }
          }
        }
      );
  }

  setLockState(moduleId: string, lockDirection: string, value: boolean) {
    return this.api.post<void>(`/process/managelocks/${this.layoutId}/module/${moduleId}/lockState/${lockDirection}`, null,
      {
        params:
          {
            value: `${value}`
          }
      });
  }

  /**
   * Sends a request to the facade to trigger the change of a park position of a moving module.
   * Please be aware, that there is no actual feedback, if the change failed.
   * However, the current park position will be reloaded afterwards.
   *
   * @param selectedParkPosition The selected park position
   */
  parkPositionChange(selectedParkPosition: string) {

    var options: HttpCallOptions = {};
    if (selectedParkPosition) {
      options = {params: {position: selectedParkPosition}}
    }

    this.api.post<void>(`/process/module/${this.layoutId}/${this.config.id}/parkPosition`,
      null, options)
      .subscribe(result => {
          this.snackBar.showInfo('pages.traversing-carriage-modal.park-position-change');
          setTimeout(() => {
            this.loadParkPosition();
          }, 1000);
        },
        error => this.errorHandler.handleHttpError(error));
  }
}
