import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {WebsocketEventService} from '../../api/websocket/websocket-event.service';
import {SkwDialogService, SkwSnackBarService} from 'skw-ui-components';
import {SkwApiService} from 'skw-ui-bootstrap';
import {ActivatedRoute, Router} from '@angular/router';
import {
  MfwItemService,
  MfwModuleClickEvent,
  MfwModuleClickService,
  MfwModulesType,
  MfwSystemConfig,
  MfwSystemModuleType,
  SystemComponent
} from 'skw-ui-modules';
import {forkJoin, Subscription} from 'rxjs';
import {timeout} from 'rxjs/operators';
import {MfwModuleModalComponent} from '../../mfw-components/mfw-module-modal/mfw-module-modal.component';
import {SystemHealthStatusService} from '../../system-status/header-toolbar-portal/system-health-status.service';
import {ItemModalService} from '../../mfw-components/mfw-item/item-modal.service';

const HTTP_TIMEOUT = 20000;

export interface EventWithLayout extends MfwModuleClickEvent {
  layoutId: string;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit, OnDestroy {

  private $itemClickSubscription: Subscription;
  private $mfwModuleClickSubscription: Subscription;
  private $moduleUpdateSubscription: Subscription;
  private $itemUpdateSubscription: Subscription;
  private $routeParamSubscription: Subscription;

  private systemModelLoadingSubscription: Subscription;

  layoutId: string;
  notInitialized = true;
  initializationError = false;

  // We have to buffer events while loading the system layout state because the system status is old after
  // receiving it and the events must be applied then.
  private _notInitializedEventBuffer = [];

  systemConfig: MfwSystemConfig = {
    modules: [],
    moduleSize: 70,
    scale: 1
  };
  @ViewChild('system') system: SystemComponent;

  constructor(private changeDetectorRef: ChangeDetectorRef,
              private wsEventService: WebsocketEventService,
              private apiService: SkwApiService,
              private snackBarService: SkwSnackBarService,
              private itemService: MfwItemService,
              private mfwModuleClickService: MfwModuleClickService,
              private systemHealthStatusService: SystemHealthStatusService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private itemModalService: ItemModalService,
              private dialog: SkwDialogService) {

    this.$moduleUpdateSubscription = this.wsEventService.onModuleUpdateEvent().subscribe(event => {
      try {
        if (['module-update'].includes(event.name)) {
          const moduleId = event.moduleId;
          const module = event.payload;

          if (this.notInitialized) {
            // Buffer events until layout is initialized!
            this._notInitializedEventBuffer.push(() => {
              this.system.updateModule(moduleId, module);
            });
          } else {
            this.system.updateModule(moduleId, module);
            this.changeDetectorRef.detectChanges();
          }
        }
      } catch (e) {
        console.error(e);
      }
    });

    this.$itemUpdateSubscription = this.wsEventService.onItemUpdateEvent()
      .subscribe(event => {
        try {
          this.system.updateItem(event.itemId, event.payload);
          this.changeDetectorRef.detectChanges();
        } catch (err) {
          console.error('Failed to update item on Dashboard: ' + err)
        }
      });

    // Register item click listener to open manual targeting modal.
    this.$itemClickSubscription = this.itemService.onClickObservable().subscribe(item => {
      this.itemModalService.openItemModal(item.item, this.layoutId);
    });

    // Register click listener for all MfModules
    this.$mfwModuleClickSubscription = this.mfwModuleClickService.onClickObservable().subscribe(event => {
      if (event.type === 'subsystem' as MfwModulesType) {
        this.router.navigateByUrl(`/pages/dashboard/${event.config.id}`);
      } else {
        // append layout id info to published event
        const eventWithLayout = event as EventWithLayout;
        eventWithLayout.layoutId = this.layoutId;

        this.dialog.componentDialog(MfwModuleModalComponent, {
          data: eventWithLayout,
          width: '800px',
          maxWidth: '100%'
        });
      }
    });
  }

  ngOnInit(): void {
    if (this.$routeParamSubscription) {
      this.$routeParamSubscription.unsubscribe();
    }
    this.$routeParamSubscription = this.activatedRoute.params.subscribe(params => {
      if (this.systemModelLoadingSubscription) {
        // unsubscribe from previous requests to fix
        // request result overlapping
        this.systemModelLoadingSubscription.unsubscribe();
      }

      this.initializationError = false;
      this.notInitialized = true;
      this.layoutId = params.id;
      // empty system first to prevent flickering while loading
      this.systemConfig = {
        modules: [],
        moduleSize: 70,
        scale: 1
      };
      this.changeDetectorRef.detectChanges();

      // load layout configuration and model in parallel requests to improve performance
      this.systemModelLoadingSubscription = forkJoin(
        this.apiService.get<MfwSystemModuleType[]>(`/process/plantLayout/${this.layoutId}`),
        this.apiService.get<Map<string, any>>(`/plant/${this.layoutId}`)
      ).pipe(timeout(HTTP_TIMEOUT))
        .subscribe(([config, model]) => {
          if (!config) {
            this.snackBarService.showInfoAndAction('pages.dashboard.system-not-configured', 'actions.back')
              .subscribe(() => this.router.navigateByUrl('/'));
            return;
          }
          this.systemConfig = {
            modules: config,
            moduleSize: 70,
            scale: 1
          };
          this.system.ngAfterViewInit();
          this.changeDetectorRef.detectChanges();

          const map = new Map(Object.entries(model));
          map.forEach((module, id) => this.system.updateModule(id, module));
          // now we are ready and can apply all buffered events (cached while the system layout was loading)
          // to update the retrieved layout
          this._notInitializedEventBuffer.forEach(e => e());
          this._notInitializedEventBuffer = [];
          this.notInitialized = false;
          this.changeDetectorRef.detectChanges();
        }, error => {
          this.errorHandler();
        });
    });
  }

  private errorHandler() {
    // empty system to prevent elements from being clickable without being visible
    this.systemConfig = {
      modules: [],
      moduleSize: 70,
      scale: 1
    };
    this.initializationError = true;
    this.changeDetectorRef.detectChanges();
    // trigger health status check to ensure sync
    this.systemHealthStatusService.load();
  }

  ngOnDestroy(): void {
    this.$moduleUpdateSubscription.unsubscribe();
    this.$itemUpdateSubscription.unsubscribe();
    this.$itemClickSubscription.unsubscribe();
    this.$mfwModuleClickSubscription.unsubscribe();
    this.$routeParamSubscription.unsubscribe();
    if (typeof this.systemModelLoadingSubscription !== 'undefined') {
      this.systemModelLoadingSubscription.unsubscribe();
    }
  }

}
