import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DisplayAttrSettingComponent} from './display-attr-setting/display-attr-setting.component';
import {
  SkwAlertModule,
  SkwButtonModule,
  SkwCardModule,
  SkwEditableModule,
  SkwFormModule,
  SkwHintModule,
  SkwInputModule,
  SkwListModule,
  SkwLoadingModule,
  SkwTableModule
} from 'skw-ui-components';
import {SkwLanguageModule} from 'skw-ui-translation';
import {SettingsComponent} from './settings.component';
import {SkwMasterDetailLayoutModule, SkwPageLayoutModule} from 'skw-ui-layout';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {DisplayAttrOverviewComponent} from './display-attr-overview/display-attr-overview.component';
import {TargetingSettingComponent} from './targeting-setting/targeting-setting.component';
import {LayerSettingComponent} from './layer-setting/layer-setting.component';
import {AdvancedSettingsComponent} from './advanced-settings/advanced-settings.component';
import {SkwTabsModule} from '../../skw-tabs/skw-tabs.module';
import {SequencingSettingComponent} from './sequencing-setting/sequencing-setting.component';
import {MatSliderModule} from '@angular/material/slider';

@NgModule({
  declarations: [
    DisplayAttrSettingComponent,
    SettingsComponent,
    DisplayAttrOverviewComponent,
    TargetingSettingComponent,
    LayerSettingComponent,
    AdvancedSettingsComponent,
    SequencingSettingComponent,
    LayerSettingComponent
  ],
  exports: [
    DisplayAttrSettingComponent,
    SettingsComponent,
    DisplayAttrOverviewComponent,
    TargetingSettingComponent,
    SequencingSettingComponent,
    LayerSettingComponent
  ],
  imports: [
    CommonModule,
    SkwInputModule,
    SkwFormModule,
    SkwLanguageModule,
    SkwPageLayoutModule,
    DragDropModule,
    SkwLoadingModule,
    SkwButtonModule,
    SkwHintModule,
    SkwAlertModule,
    SkwListModule,
    SkwEditableModule,
    SkwMasterDetailLayoutModule,
    SkwTableModule,
    SkwCardModule,
    SkwTabsModule,
    MatSliderModule
  ]
})
export class SettingsModule {
}
