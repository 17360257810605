import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ListOperand} from '../../condition-model';

@Component({
  selector: 'app-rule-operand-list',
  templateUrl: './rule-operand-list.component.html',
  styleUrls: ['./rule-operand-list.component.scss']
})
export class RuleOperandList {
  @Input() editMode: boolean;

  @Input() operand: ListOperand;
  @Output() operandChange: EventEmitter<ListOperand> = new EventEmitter<ListOperand>();

  listChanged($event: string) {
    this.operand.value = $event.split(',').map(s => s.trim());
    this.operandChange.emit(this.operand);
  }
}
