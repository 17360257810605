import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ConditionGroupModel} from '../condition-model';
import {SkwSelectModel} from 'skw-ui-components';
import {RulesService} from '../../rules.service';

@Component({
  selector: 'app-rule-condition-group',
  templateUrl: './rule-condition-group.component.html',
  styleUrls: ['./rule-condition-group.component.scss']
})
export class RuleConditionGroupComponent {
  @Input() editMode: boolean;
  @Input() condition: ConditionGroupModel;

  @Output() conditionChange: EventEmitter<ConditionGroupModel> = new EventEmitter<ConditionGroupModel>();
  coalitionTypes: SkwSelectModel<string>[];


  constructor(rulesService: RulesService) {
    // load operatorTypes
    rulesService.getRuleDefinitionsModel().subscribe(value => {
      this.coalitionTypes = value.coalitionTypes.map(ot => {
        return {
          value: ot,
          viewValue: `pages.rules.detail.logicElements.${ot}`
        } as SkwSelectModel<string>;
      });
    });
  }


}
