import {Component, OnDestroy, OnInit} from '@angular/core';
import {WebsocketEventService} from './api/websocket/websocket-event.service';
import {Subscription} from 'rxjs';
import {NotificationCenterService} from './pages/errors/notification-center.service';
import {SkwVersionService} from 'skw-ui-layout';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit, OnDestroy {

  private $websocketSubscription: Subscription;
  private $notificationWebsocketSubscription: Subscription;

  constructor(private websocket: WebsocketEventService,
              private versionService: SkwVersionService,
              private fssErrorsService: NotificationCenterService) {
  }

  ngOnInit(): void {
    // This is only a temporary solution until this functionality is moved into our library.
    // Show update available dialog.
    this.$websocketSubscription = this.websocket.onWebsocketEvent()
      .subscribe(event => {
        if (event.name === 'UpdateAvailable') {
          this.versionService.publishUpdate();
        }
      });
    // ... and this too :-)
    this.$notificationWebsocketSubscription = this.websocket.onNotificationEvent()
      .subscribe(event => {
        this.fssErrorsService.onNewNotification(event);
      });
  }

  ngOnDestroy(): void {
    this.$websocketSubscription.unsubscribe();
    this.$notificationWebsocketSubscription.unsubscribe();
  }

}
