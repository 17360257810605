import {Pipe, PipeTransform} from '@angular/core';
import {SkwTranslatableValue} from 'skw-ui-translation';

@Pipe({
  name: 'skwAuditAction'
})
export class AuditActionPipe implements PipeTransform {

  transform(value: string): SkwTranslatableValue {
    if (!value) {
      return value;
    }
    return {
      default: value,
      key: `audit.actions.${value.toLocaleUpperCase()}`
    };
  }

}
