import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuditHistoryComponent} from './audit-history/audit-history.component';
import { AuditActionPipe } from './audit-action.pipe';
import {SkwLanguageModule} from 'skw-ui-translation';
import {SkwHintModule} from 'skw-ui-components';

@NgModule({
  declarations: [AuditHistoryComponent, AuditActionPipe],
  exports: [AuditHistoryComponent],
  imports: [
    CommonModule,
    SkwLanguageModule,
    SkwHintModule
  ]
})
export class SkwAuditModule {
}
