import {Injectable} from '@angular/core';
import {SkwApiService} from 'skw-ui-bootstrap';
import {Observable, of, ReplaySubject} from 'rxjs';
import {tap} from 'rxjs/operators';
import {SkwLanguageService} from 'skw-ui-translation';
import {SkwAuthService} from 'skw-ui-authentication';

export class TargetingSettings {
  id?: number;
  settingKey: string;
  value: string | number;
}

export class ForceDeliverSettings {

  /** Id / Name of the system. */
  systemId: string;

  /** PLS status of the system. */
  status: string;

  /** Mapped status color according to the PLS status. */
  statusColor: string;

  /** Current setting value, whether or not the deliver to an unreachable layer shall be forced. */
  forceDeliver: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class TargetingSettingsService {

  readonly ALT_SUBTARGET: string = 'altSubTarget';
  readonly REROUTE_ON_FILLING_LEVEL: string = 'rerouteOnFillingLevel';
  readonly REROUTE_ON_LAYER_BLOCKED: string = 'rerouteOnLayerBlocked';
  readonly REROUTE_ON_LOCKED_BUT_WFB_FULL: string = 'rerouteOnLockedButWFBFull';
  readonly OVERLOAD_LAYER_STATUS_180: string = 'overloadLayerStatus180';
  readonly OVERLOAD_LAYER_STATUS_280: string = 'overloadLayerStatus280';
  readonly OVERLOAD_LAYER_STATUS_380: string = 'overloadLayerStatus380';
  readonly OVERLOAD_LAYER_STATUS_480: string = 'overloadLayerStatus480';
  readonly FILLING_LEVEL: string = 'fillingLevel';
  readonly REQUEST_COLOR_ADMIN_ON_UNLOCK_BY_RULE: string = 'requestColorAdministrationOnUnlockByRule';
  readonly IS_COLOR_ADMIN_SERVICE_MUTED: string = 'isColorAdminServiceMuted';

  private cachedResult: { [key: string]: TargetingSettings[] };

  public settings = new ReplaySubject<{ [key: string]: TargetingSettings[] }>(1);
  public rerouteOnFillingLevel = new ReplaySubject<boolean>(1);
  public rerouteOnLayerBlocked = new ReplaySubject<boolean>(1);
  public rerouteOnLockedButWfbFull = new ReplaySubject<boolean>(1);

  constructor(private apiService: SkwApiService,
              private authService: SkwAuthService,
              private translationService: SkwLanguageService) {
    this.authService.authObservable().subscribe(r => {
      // initially load item flow attributes if this service is injected
      if (r) {
        this.loadAll().subscribe();
      }
    });
  }

  loadAll(): Observable<{ [key: string]: TargetingSettings[] }> {
    if (typeof this.cachedResult !== 'undefined') {
      return of(this.cachedResult);
    }
    return this.apiService.get<{ [key: string]: TargetingSettings[] }>('/settings/targeting/')
      .pipe(
        tap(v => {
          this.updateSettings(v);
        })
      );
  }

  updateSettings(settings: { [key: string]: TargetingSettings[] }) {
    this.cachedResult = settings;
    this.settings.next(settings);
    this.rerouteOnFillingLevel.next(settings[this.REROUTE_ON_FILLING_LEVEL][0].value === 'true');
    this.rerouteOnLayerBlocked.next(settings[this.REROUTE_ON_LAYER_BLOCKED][0].value === 'true');
    this.rerouteOnLockedButWfbFull.next(settings[this.REROUTE_ON_LOCKED_BUT_WFB_FULL][0].value === 'true');
  }

  saveAll(settings: TargetingSettings[]) {
    return this.apiService.post('/settings/targeting/', settings)
      .pipe(tap(() => this.invalidateCache()));
  }

  invalidateCache() {
    this.cachedResult = undefined;
    this.loadAll();
  }

  /**
   * Special handling for forceDeliver settings, as they are only needed in system 085.
   * @param settings the force deliver settings
   */
  saveForceDeliverSettings(settings: ForceDeliverSettings[]) {
    return this.apiService.post('/settings/targeting/forceDeliver', settings)
      .pipe(tap(() => this.invalidateCache()));
  }
}
