import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {SkwDialogService, SkwSelectModel, SkwSnackBarService} from 'skw-ui-components';
import {DashboardToolbarService} from './dashboard-toolbar.service';
import {SkwTranslatableValue} from 'skw-ui-translation';
import {ItemFlowSettingsService} from '../../settings/item-flow-settings.service';
import {LaneFillingDialogComponent} from '../../system-modes/lane-filling/lane-filling-dialog.component';
import {SystemHealthStatusService} from '../../../system-status/header-toolbar-portal/system-health-status.service';
import {map} from 'rxjs/operators';
import {Observable, Subscription} from 'rxjs';
import {AcceptItemsComponent} from '../../system-modes/accept-items/accept-items.component';
import {LockSupplyLineComponent} from '../../system-modes/lock-supply-line/lock-supply-line.component';
import {SnapshotDialogComponent} from '../../snapshot/snapshot-dialog/snapshot-dialog.component';
import {ReleaseOverflowCarbodiesDialogComponent} from '../../../audi-common/release-overflow-carbodies-dialog/release-overflow-carbodies-dialog.component';
import {EmergencyTransferalDialogComponent} from '../emergency-transferal-dialog/emergency-transferal-dialog.component';

@Component({
  selector: 'app-dashboard-toolbar',
  templateUrl: './dashboard-toolbar.component.html',
  styleUrls: ['./dashboard-toolbar.component.scss']
})
export class DashboardToolbarComponent implements OnDestroy {

  private $systemStatusSubscription: Subscription;

  laneFillingMode$: Observable<boolean>;
  blockLayer$: Observable<boolean>;
  systemStateIsActive: boolean;
  hasOverflowCarbodies: boolean;

  constructor(
    private service: DashboardToolbarService,
    private dialogService: SkwDialogService,
    private systemHealthStatusService: SystemHealthStatusService,
    private itemFlowSettingsService: ItemFlowSettingsService, // important to inject here because we need to instantiate it
    private snackBarService: SkwSnackBarService
  ) {
    this.service.getAvailableDisplayedItemAttributes()
      .subscribe(r => {
        this.displayedAttributeValues = r.map(a => {
          return {
            value: a.value,
            viewValue: {key: `item-flow-attributes.${a.viewValue}`, default: a.viewValue} as SkwTranslatableValue,
            disabled: a.disabled
          } as SkwSelectModel<[string, string]>;
        });
      });
    this.displayedAttribute = this.service.getCurrentDisplayedItemAttribute();


  }

  @Input() set systemId(value: string) {
    this._systemId = value;

    this.laneFillingMode$ = this.systemHealthStatusService.observable()
      .pipe(map(r =>
        !r ? undefined : r.find(el => el.systemId === value).laneFillingMode
      ));

    this.blockLayer$ = this.systemHealthStatusService.observable()
      .pipe(map(r =>
        !r ? undefined : r.find(el => el.systemId === value).blockLayer
      ));

    // close existing subscription and subscribe to current layer status
    if (this.$systemStatusSubscription && !this.$systemStatusSubscription.closed) {
      this.$systemStatusSubscription.unsubscribe();
    }
    this.$systemStatusSubscription = this.systemHealthStatusService.observable()
      .subscribe(v => {

        if (v == null) {
          return;
        }

        this.systemStateIsActive = v.filter(s => s.systemId === this._systemId)[0].plsStatus === 'ACTIVE';
        const ctrlLocks = v.find(s => s.systemId === this._systemId).ctrlLocks;
        this.hasOverflowCarbodies = ctrlLocks && ctrlLocks.length > 0;
      });
  }

  // displayed Attribute
  displayedAttribute: [string, string];
  displayedAttributeValues: SkwSelectModel<[string, string]>[];

  _systemId: string;

  ngOnDestroy(): void {
    this.$systemStatusSubscription.unsubscribe();
  }

  @Output() refresh = new EventEmitter<any>();
  displayedAttributeEqualsFn = (o1: [string, string], o2: [string, string]) => !o1 || !o2 ? o1 === o2 : o1[0] === o2[0] && o1[1] === o2[1];

  onDisplayedAttributeSelect($event: [string, string]) {
    this.service.onSelectDisplayedItemAttribute($event);
  }

  openSystemModeBSLDialog() {
    this.dialogService.componentDialog(LaneFillingDialogComponent, {
      data: this._systemId
    });
  }

  openLockBSLDialog() {
    this.dialogService.componentDialog(LockSupplyLineComponent, {
      data: this._systemId,
      width: '1300px'
    });
  }

  openSystemModeAcceptItemsDialog() {
    this.dialogService.componentDialog(AcceptItemsComponent, {
      data: this._systemId
    });
  }

  openSnapshotDialog() {
    this.dialogService.componentDialog(SnapshotDialogComponent, {
      width: '600px'
    });
  }

  releaseOverflowLocks() {
    this.dialogService.componentDialog(ReleaseOverflowCarbodiesDialogComponent, {
      data: this._systemId
    });
  }

  openEmergencyModes() {
    this.dialogService.componentDialog(EmergencyTransferalDialogComponent, {
      data: this._systemId
    });
  }
}
