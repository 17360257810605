import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {RulesService} from '../../../pages/rules/rules.service';
import {Router} from '@angular/router';

@Component({
  selector: 'mfw-item-param-issuer',
  templateUrl: './mfw-item-param-issuer.component.html',
  styleUrls: ['./mfw-item-param-issuer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MfwItemParamIssuerComponent {

  _raw: string;
  _value: string;
  _type: string;

  @Input() set value(value: string) {
    this._raw = value;
    if (!value) {
      return;
    }
    const match = value.match(/(\w+):\s(.+)/);
    if (match) {
      this._type = match[1];
      this._value = match[2];
    }
  }

  @Output()
  onNavigatedToRule = new EventEmitter();


  constructor(private serv: RulesService,
              private router: Router) {
  }

  gotoRule() {
    this.serv.getRuleByRuleKey(this._value)
      .subscribe((result) => {
        this.router.navigateByUrl('/pages/rules/' + result.id);
        this.onNavigatedToRule.emit();
      });
  }
}
