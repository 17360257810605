import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {PagesComponent} from './pages.component';
import {SkwAuthRoutingGuard} from 'skw-ui-authentication';
import {DashboardComponent} from './dashboard/dashboard.component';
import {SimulationComponent} from './simulation/simulation.component';
import {RulesComponent} from './rules/rules.component';
import {MdsCarbodyComponent} from './mds/mds-carbody/mds-carbody.component';
import {MdsCodemappingComponent} from './mds/mds-codemapping/mds-codemapping.component';
import {CarBodySearchComponent} from './carbodysearch/car-body-search.component';
import {RuleEditComponent} from './rules/rule-edit/rule-edit.component';
import {TranslationcheckComponent} from './translationcheck/translationcheck.component';
import {HomeComponent} from './home/home.component';
import {SettingsComponent} from './settings/settings.component';
import {SkwUnsavedFormGuard} from 'skw-ui-components';
import {RuleCategoryComponent} from "./rules/rule-edit/rule-category/rule-category.component";
import {KvsOrderListComponent} from './kvs-order-list/kvs-order-list.component';

const routes: Routes = [
  {
    path: 'pages',
    component: PagesComponent,
    children: [
      {
        path: 'home',
        component: HomeComponent,
        canActivate: [SkwAuthRoutingGuard]
      }, {
        path: 'dashboard/:id',
        component: DashboardComponent,
        canActivate: [SkwAuthRoutingGuard]
      }, {
        path: 'simulation',
        component: SimulationComponent,
        canActivate: [SkwAuthRoutingGuard]
      }, {
        path: 'mds/carbody',
        component: MdsCarbodyComponent,
        canActivate: [SkwAuthRoutingGuard]
      }, {
        path: 'mds/carbody/:id',
        component: MdsCodemappingComponent,
        canActivate: [SkwAuthRoutingGuard]
      }, {
        path: 'settings/purpose',
        component: SettingsComponent,
        canActivate: [SkwAuthRoutingGuard]
      }, {
        path: 'rules',
        component: RulesComponent,
        canActivate: [SkwAuthRoutingGuard]
      }, {
        path: 'rules/:id',
        component: RuleEditComponent,
        canActivate: [SkwAuthRoutingGuard],
        canDeactivate: [SkwUnsavedFormGuard]
      }, {
        path: 'rulecategories',
        component: RuleCategoryComponent,
        canActivate: [SkwAuthRoutingGuard],
      }, {
        path: 'carbodysearch',
        component: CarBodySearchComponent,
        canActivate: [SkwAuthRoutingGuard]
      }, {
        path: 'translationcheck',
        component: TranslationcheckComponent,
        canActivate: [SkwAuthRoutingGuard]
      }, {
        path: 'kvs',
        component: KvsOrderListComponent,
        canActivate: [SkwAuthRoutingGuard]
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class PagesRoutingModule {

  constructor() {
  }
}
