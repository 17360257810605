import {Injectable} from '@angular/core';
import {MfwItem} from 'skw-ui-modules';
import {MfwItemModalComponent} from './mfw-item-modal/mfw-item-modal.component';
import {SkwDialogRef, SkwDialogService} from 'skw-ui-components';

@Injectable({
  providedIn: 'root'
})
export class ItemModalService {

  constructor(private dialog: SkwDialogService) {
  }

  openItemModal(item: MfwItem, system: string): SkwDialogRef<any> {
    return this.dialog.componentDialog(MfwItemModalComponent, {
      data: {
        item,
        layoutId: system
      },
      width: '800px',
      maxWidth: '100%',
    });
  }
}
