import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, of, Subject, timer} from 'rxjs';
import {SimulationControlService, SimulationServiceStatus} from './simulation-control.service';
import {indicate, SkwErrorHandlerService, SkwSelectModel} from 'skw-ui-components';
import {catchError, switchMap, takeUntil, tap} from 'rxjs/operators';
import {SystemHealthStatusService} from '../../../system-status/header-toolbar-portal/system-health-status.service';

@Component({
  selector: 'app-simulation-control',
  templateUrl: './simulation-control.component.html',
  styleUrls: ['./simulation-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimulationControlComponent implements OnInit, OnDestroy {

  loading$ = new Subject<boolean>(); // emits on data loading
  private refresh$ = new Subject<boolean>(); // emits on every refresh needed (boolean whether a loader should be shown)
  private destroy$ = new Subject(); // emits on component destroy
  allLayersInitialized = false;

  status$: Observable<SimulationServiceStatus> = this.refresh$
    .pipe(
      // load simulation status on every time the refresh$ observable emits
      switchMap(showLoader => {
        if (!showLoader) {
          return this.service.status()
            .pipe(
              tap(this.updateSpeed()),
              catchError(e => {
                // return empty object in case of error
                return of({} as SimulationServiceStatus);
              }),
              takeUntil(this.destroy$)
            );
        }
        return this.service.status()
          .pipe(
            indicate(this.loading$),
            tap(this.updateSpeed()),
            catchError(e => {
              // return empty object in case of error
              return of({} as SimulationServiceStatus);
            }),
            takeUntil(this.destroy$)
          );
      }),
      // but only until this component is destroyed
      takeUntil(this.destroy$)
    );

  availableSpeedFactors: SkwSelectModel<number>[] = [
    {value: 1, viewValue: '1x'},
    {value: 2, viewValue: '2x'},
    {value: 3, viewValue: '3x'},
    {value: 5, viewValue: '5x'},
    {value: 7, viewValue: '7x'},
    {value: 10, viewValue: '10x'},
    {value: 20, viewValue: '20x'},
    // {value: -1, viewValue: '∞'},
  ];
  speedFactor: number;

  constructor(private service: SimulationControlService,
              private cdr: ChangeDetectorRef,
              private errorHandlerService: SkwErrorHandlerService,
              private systemHealthStatusService: SystemHealthStatusService) {
  }

  init() {
    this.subscribeAndRefreshStatus(this.service.init());
  }

  start() {
    this.subscribeAndRefreshStatus(this.service.start(this.speedFactor));
  }

  stop() {
    this.subscribeAndRefreshStatus(this.service.stop());
  }

  initItems() {
    this.subscribeAndRefreshStatus(this.service.initItems());
  }

  private updateSpeed() {
    // We only update the speed factor if the simulation is not currently stopped. If stopped, the user may
    // change the input field!
    return (status: SimulationServiceStatus) => {
      if (status.running || typeof this.speedFactor === 'undefined') {
        this.speedFactor = status.speed;
      }
    };
  }

  private subscribeAndRefreshStatus<T>(observable: Observable<T>) {
    observable
      .pipe(indicate(this.loading$))
      .subscribe(() => this.refresh$.next(true), error => this.errorHandlerService.handleHttpError(error));
  }

  refresh() {
    // manually refresh status and show loading indication
    this.refresh$.next(true);
  }

  ngOnInit(): void {
    // refresh simulation status every 2 seconds
    timer(0, 2000)
      .pipe(takeUntil(this.destroy$))
      // next(false) to don't show loading indication
      .subscribe(() => this.refresh$.next(false));

    this.systemHealthStatusService.observable()
      .pipe(takeUntil(this.destroy$))
      .subscribe(systemState => {
        let initState = true;
        systemState.forEach(state => {
          // both have to be either fully activated or UNKNOWN (when not even started)
          if (!((state.spsStatus === 'LOGISTIC_MODE' || state.spsStatus === 'UNKNOWN') &&
            (state.plsStatus === 'ACTIVE' || state.plsStatus === 'UNKNOWN'))) {
            initState = false;
          }
        });
        this.allLayersInitialized = initState;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
