import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {SearchService} from './search.service';
import {indicate, SkwErrorHandlerService, SkwFilterElement, SkwSnackBarService} from 'skw-ui-components';
import {SearchParameter, SearchParameterFilter} from './search-parameter';
import {SkwTranslateValue} from 'skw-ui-translation';
import {WebsocketEventService} from '../../api/websocket/websocket-event.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {PurposeSettingsService} from '../settings/purpose-settings.service';
import {ItemFlowSettingsService} from '../settings/item-flow-settings.service';

@Component({
  selector: 'app-car-body-search',
  templateUrl: './car-body-search.component.html',
  styleUrls: ['./car-body-search.component.scss']
})
export class CarBodySearchComponent implements OnInit, OnDestroy {
  items = [];
  activeFilters: SkwFilterElement[] = [];
  query: string;
  aggregations;
  result;
  scrollId: string;
  numResults: number;

  refreshAvailable = false;
  private $subscription: Subscription;
  $loading = new BehaviorSubject(true);

  constructor(
    private searchService: SearchService,
    private errorHandlerService: SkwErrorHandlerService,
    private eventService: WebsocketEventService,
    private changeDetection: ChangeDetectorRef,
    private purposeSettingsService: PurposeSettingsService,
    private itemFlowSettingsService: ItemFlowSettingsService, // important to inject here because we need to instantiate it
    private snackBar: SkwSnackBarService
  ) {
  }

  ngOnInit() {
    this.$subscription = this.eventService.onItemUpdateEvent()
      .subscribe(event => {
        if (!this.result || !this.items) {
          return;
        }
        const updatedItems = this.result.hits
          .filter(h => h.id === event.itemId);
        if (updatedItems.length === 0) {
          // no changes in this search result
          return;
        }
        this.refreshAvailable = true;
      });

    // Await loading some cached data to avoid multiple requests from every search result card.
    this.purposeSettingsService.loadAttributesForPurpose(PurposeSettingsService.DISPLAYED_IN_SEARCH_CARD)
      .subscribe(r => {
        // initial search AFTER we know our displayed properties
        this.doSearch();
      });
  }

  showResults(results: any) {
    this.result = results;

    const items = [];
    for (const hit of results.hits) {
      items.push(hit.source);
    }
    this.items = items;
    this.aggregations = results.aggregations;
    this.scrollId = results.scrollId;
    this.numResults = results.total;
  }

  private addToResults(additionalResults: any) {
    // just add the new items to our items array
    const additionalItems = [];
    for (const hit of additionalResults.hits) {
      additionalItems.push(hit.source);
    }
    this.items = [...this.items, ...additionalItems];

    // and set the new scrollId if you need to continue from there
    this.scrollId = additionalResults.scrollId;
  }

  removeFilter(el: SkwFilterElement) {
    return this.onFilterToggle(el.name, el.value, false);
  }

  onFilterToggle(aggregationKey: string, bucketKey: string | number, checked: boolean) {
    if (checked) {
      // make sure filter is set on "activeFilters"
      if (!this.isFilterActive(aggregationKey, bucketKey)) {
        const stripedAggregationKey = this.stripAggregationKey(aggregationKey);
        // can't use Array.push, because deep changes are not watched by angular. Have to recreate the array.
        this.activeFilters = [...this.activeFilters, {
          name: aggregationKey,
          viewName: {
            key: `item-flow-attributes.${stripedAggregationKey}`,
            default: stripedAggregationKey
          } as SkwTranslateValue,
          value: bucketKey,
          viewValue: {key: `item.attributeValues.${bucketKey}`, default: bucketKey} as SkwTranslateValue
        }];
      }
    } else {
      // remove the filter!
      this.activeFilters = this.activeFilters.filter(e => e.name !== aggregationKey && e.value !== bucketKey);
    }
    this.doSearch();
  }

  stripAggregationKey(aggregationKey: string) {
    return aggregationKey
      .replace('itemFlow.', '')
      .replace('CarBody.', '')
      .replace('CarBodyHistory.', '');
  }

  private search() {
    this.refreshAvailable = false;
    const param = {
      query: this.query,
      pcsTermsFilters: this.collectActiveFilters()
    } as SearchParameter;

    return this.searchService.search(param);
  }

  doSearch() {
    this.search()
      .pipe(indicate(this.$loading))
      .subscribe(resp => this.showResults(resp),
        error => this.errorHandlerService.handleHttpError(error));
  }

  loadMoreResults() {
    this.searchService
      .scroll(this.scrollId)
      .subscribe(resp => this.addToResults(resp),
        error => this.errorHandlerService.handleHttpError(error));
  }

  private collectActiveFilters() {
    const filters = [] as SearchParameterFilter[];
    this.activeFilters.forEach(e => {
      const existingFilter = filters.find(f => f.name === e.name);
      if (!existingFilter) {
        filters.push({name: e.name, terms: [e.value]});
      } else {
        existingFilter.terms.push(e.value);
      }
    });
    return filters.length === 0 ? null : filters;
  }

  isFilterActive(aggregationKey: string, bucketKey: string | number) {
    return this.activeFilters.find(e => e.name === aggregationKey && e.value === bucketKey);
  }

  ngOnDestroy(): void {
    this.$subscription.unsubscribe();
  }

  onInteraction() {
    this.search()
      // without error handling!
      .subscribe(resp => {
        this.showResults(resp);
        this.changeDetection.detectChanges();
      }, error => {
        this.snackBar.showError('item-search.refresh-error');
      });
  }
}
