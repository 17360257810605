import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Rule, RulesService} from '../../rules.service';
import {SkwSelectModel} from 'skw-ui-components';
import {EventType} from '../condition-model';

@Component({
  selector: 'app-rule-trigger',
  templateUrl: './rule-trigger.component.html',
  styleUrls: ['./rule-trigger.component.scss']
})
export class RuleTriggerComponent {
  @Input() set ruleContext(value: Rule) {
    // load eventTypes
    this.rulesService.getRuleDefinitionsModel().subscribe(context => {
      this.triggerTypes = context.eventTypes.map(v => {
        return {
          value: v,
          viewValue: 'pages.rules.eventTypes.' + v.name + '.name',
          // disable all triggers which disallow conditions if there are already conditions configured:
          disabled: !v.conditionsAllowed && value && value.serializedJson && value.serializedJson.condition
            && value.serializedJson.condition.conditions.length > 0
        } as SkwSelectModel<EventType>;
      });
    });
  }

  @Input() trigger: EventType;
  @Output() triggerChange: EventEmitter<EventType> = new EventEmitter<EventType>();
  @Input() editMode: boolean;

  triggerTypes: SkwSelectModel<EventType>[];
  equalsFunction = (o1: EventType, o2: EventType) => o1.name === o2.name;

  constructor(private rulesService: RulesService) {
  }

  changeTrigger($event: EventType) {
    this.triggerChange.emit($event);
  }
}
