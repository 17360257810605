import {Injectable} from '@angular/core';
import {SkwSelectModel} from 'skw-ui-components';
import {MfwItem, MfwItemParameterGroup, MfwItemService, MfwItemSimpleParameter} from 'skw-ui-modules';
import {PurposeSettingsService} from '../../settings/purpose-settings.service';
import {map, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ItemFlowSettingsService} from '../../settings/item-flow-settings.service';
import {SkwTranslatableValue} from 'skw-ui-translation';
import {formatDate} from '@angular/common';

// This function extracts an item attribute and returns its value.
// The returned object is a translatable subject which allows to translate any value if necessary.
export function mds(group: string, name: string, item: MfwItem): SkwTranslatableValue {
  // This only works in our specific AUDI FSS scenario!!
  const g = (item.parameters as MfwItemParameterGroup[])
    .find(p => p.name === group);
  if (!g) {
    return '???';
  }
  const param = g.parameters
    .map(p => p as MfwItemSimpleParameter)
    .find(p => p.key === name);
  if (!param) {
    return '???';
  }
  if (group === 'ItemFlow' && name === 'initialized' && param.value) {
    // Handle special case to format the timestamp 'initialized':
    const date = new Date(param.value.split('[')[0]); // remove '[Timezone]' at the end if required
    if (date instanceof Date && !isNaN(date.getTime())) {
      return {
        key: 'xxx', // no need for a translation
        default: formatDate(date, 'dd. | HH:mm', 'en-US')
      };
    }
  }
  // Handle all other attributes with simple translation:
  return {
    key: `item.attributeValues.${param.value}`,
    default: param.value === undefined || !param.value.trim().length ? '-' : param.value
  };
}

@Injectable({
  providedIn: 'root'
})
export class DashboardToolbarService {

  private readonly DEFAULT_ATTRIBUTE: [string, string] = ['CarBody', 'KID7'];
  private _currentDisplayedItemAttribute: [string, string] = this.DEFAULT_ATTRIBUTE;

  constructor(private purposeSettingsService: PurposeSettingsService,
              private itemFlowSettingsService: ItemFlowSettingsService,
              private itemService: MfwItemService) {
  }

  getCurrentDisplayedItemAttribute(): [string, string] {
    return this._currentDisplayedItemAttribute;
  }

  getAvailableDisplayedItemAttributes(): Observable<SkwSelectModel<[string, string]>[]> {
    return this.purposeSettingsService.loadAttributesForPurpose(PurposeSettingsService.DISPLAYED_IN_SYSTEM_OVERVIEW)
      .pipe(
        map(r => r.map(p => {
          return {value: [p.entity, p.attributeName], viewValue: p.attributeName} as SkwSelectModel<[string, string]>;
        })),
        tap(r => r.splice(0, 0, {value: this.DEFAULT_ATTRIBUTE, viewValue: this.DEFAULT_ATTRIBUTE[1]}))
      );
  }

  onSelectDisplayedItemAttribute(attribute: [string, string]) {
    this._currentDisplayedItemAttribute = attribute;
    // If the selected entry is our default attribute, we can simply use the shortInfo property
    // of the MfwItem.
    if (attribute[0] === this.DEFAULT_ATTRIBUTE[0] && attribute[1] === this.DEFAULT_ATTRIBUTE[1]) {
      this.itemService.updateItemConfig({
        dynamicShortInfo: item => item.shortInfo
      });
    } else {
      // ...if not, we have to find the selected item attribute:
      this.itemService.updateItemConfig({
        dynamicShortInfo: item => mds(attribute[0], attribute[1], item)
      });
    }
  }

}
