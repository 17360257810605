import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {MfwItem} from 'skw-ui-modules';

@Component({
  selector: 'app-item-ready-icon',
  templateUrl: './item-ready-icon.component.html',
  styleUrls: ['./item-ready-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemReadyIconComponent {
  @Input() showText = false;

  _itemReady: boolean;

  @Input() set itemReady(value: boolean) {
    this._itemReady = value;
  }

  _hasItems: boolean;

  @Input() set items(value: MfwItem[]) {
    this._hasItems = !!value && value.length > 0;
  }
}
