import {Component, Input, OnInit} from '@angular/core';
import {SkwApiService} from 'skw-ui-bootstrap';
import {SkwErrorHandlerService} from 'skw-ui-components';

@Component({
  selector: 'app-sequence-info',
  templateUrl: './sequence-info.component.html',
  styleUrls: ['./sequence-info.component.scss']
})
export class SequenceInfoComponent implements OnInit {
  sequenceData: any;
  selectedMove: any;

  @Input()
  systemId: string;

  @Input()
  moduleId: string;

  constructor(private api: SkwApiService,
              private errorHandler: SkwErrorHandlerService) {
  }

  ngOnInit() {
  }

  loadSequenceData() {
    if (!this.systemId || !this.moduleId)
      return;

    this.api.get<any>(`/process/module/${this.systemId}/${this.moduleId}/sequence`)
      .subscribe(result => this.sequenceData = result,
        error => this.errorHandler.handleHttpError(error))
  }

  selectMove($event: any) {
    this.selectedMove = $event;
  }
}
