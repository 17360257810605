import {Component, Input, OnInit} from '@angular/core';
import {MfwStandaloneLockConfig, MfwStandaloneLockModel} from 'skw-ui-modules';
import {MatDialogRef} from '@angular/material/dialog';
import {MfwModuleModalComponent} from '../mfw-module-modal/mfw-module-modal.component';
import {SystemHealthStatusService} from '../../system-status/header-toolbar-portal/system-health-status.service';
import {Subscription} from 'rxjs';
import {SkwDialogService, SkwErrorHandlerService, SkwSnackBarService} from 'skw-ui-components';
import {SkwApiService} from 'skw-ui-bootstrap';

@Component({
  selector: 'app-mfw-transition-modal',
  templateUrl: './mfw-transition-modal.component.html',
  styleUrls: ['./mfw-transition-modal.component.scss']
})
export class MfwTransitionModalComponent implements OnInit {


  @Input() layoutId: string;
  @Input() model: MfwStandaloneLockModel;
  @Input() config: MfwStandaloneLockConfig;
  @Input() dialogRef: MatDialogRef<MfwModuleModalComponent>;
  private $systemStatusSubscription: Subscription;
  systemStateIsActive: boolean;

  constructor(
    private statusService: SystemHealthStatusService,
    private snackBarService: SkwSnackBarService,
    private apiService: SkwApiService,
    private dialogService: SkwDialogService,
    private errorHandler: SkwErrorHandlerService) {
  }

  ngOnInit() {
    this.$systemStatusSubscription = this.statusService.observable()
      .subscribe(v => {
        this.systemStateIsActive = this.isSystemActive(v);
      });
  }

  isSystemActive(systemStatus): boolean {
    return systemStatus.filter(s => s.systemId === this.layoutId)[0].plsStatus === 'ACTIVE';
  }

  onSetLockState(value: boolean) {
    this.dialogService.submitDialog(`pages.standalone-lock-modal.${value ? 'lock' : 'release'}-trigger-confirmation`, 'danger', {
      name: this.config.displayName
    }).subscribe(result => {
      if (result) {
        this.onSubmitLockChange(value);
      } else {
        this.model.locked = !value;
      }
    });
  }

  onSubmitLockChange(value: boolean) {
    this.apiService.post<void>(`/process/managelocks/${this.layoutId}/transition/${this.config.id}`, null,
      {
        params:
          {
            value: `${value}`
          }
      }).subscribe(
      result => this.snackBarService.showInfo(`pages.standalone-lock-modal.${value ? 'lock' : 'release'}-triggered-notification`),
      error => this.errorHandler.handleHttpError(error));
  }
}
