import {Rule} from '../rules.service';
import {ComponentType} from '@angular/cdk/typings/portal';
import {InjectionToken} from '@angular/core';
import {RuleDefinitionsModel} from '../rule-definitions-model';

export const RULE_MODEL = new InjectionToken<RuleTemplateModel>('RULE_MODEL');
export const WIZARD_DATA = new InjectionToken<any>('WIZARD_DATA');

export abstract class RuleTemplateModel {
    name: string;
    description: string;
    ruleKey: string;
    priority: number;
    ruleCategory: string;
    reevaluateLocks = true;
}

export interface RuleTemplateWizardConfig {
    templateName: string;
    data?: any;
}

export interface RuleTemplate<T> {
    /** A unique technical name to identify the rule template and allow translation. */
    templateName: string;
    componentType: ComponentType<any>;

    createRule(model: T, ruleDefinitionsModel: RuleDefinitionsModel): Rule;
}
