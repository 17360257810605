import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {SkwAudit} from '../audit';
import {SkwPage} from 'skw-ui-components';

@Component({
  selector: 'skw-audit-history',
  templateUrl: './audit-history.component.html',
  styleUrls: ['./audit-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuditHistoryComponent {
  @Input() values: SkwAudit[];
  @Input() dateFormat = 'dd.MM.yyyy HH:mm';

  @Input() set pagedValues(value: SkwPage<SkwAudit>) {
    this.values = value.content;
  }
}
