import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {indicate, SkwErrorHandlerService, SkwSelectModel, SkwSnackBarService} from 'skw-ui-components';
import {SimulationControlService, SimulationServiceStatus} from '../simulation-control/simulation-control.service';
import {map} from 'rxjs/operators';
import {SystemHealthStatusService} from '../../../system-status/header-toolbar-portal/system-health-status.service';
import {Observable, Subject} from 'rxjs';

@Component({
  selector: 'app-simulation-sps-status',
  templateUrl: './simulation-sps-status.component.html',
  styleUrls: ['./simulation-sps-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimulationSpsStatusComponent {

  loading$ = new Subject<boolean>(); // emits on data loading
  @Input() status: SimulationServiceStatus;

  availableSystems: Observable<SkwSelectModel<string>[]> = this.systemHealthStatusService.observable()
    .pipe(map(systemState => systemState.map(s => ({
      value: s.systemId,
      viewValue: `navigation.overview.${s.systemId}`
    } as SkwSelectModel<string>))));

  availableSpsStatus: SkwSelectModel<number>[] = [
    {value: 0, viewValue: 'pages.health.operation-mode.not_ready'},
    {value: 1, viewValue: 'pages.health.operation-mode.emergency_mode'},
    {value: 2, viewValue: 'pages.health.operation-mode.logistic_mode'},
    {value: 3, viewValue: 'pages.health.operation-mode.preparing_for_logistic'}
  ];

  _systemId: string;
  _spsStatus: number;

  constructor(private service: SimulationControlService,
              private snackBar: SkwSnackBarService,
              private errorHandler: SkwErrorHandlerService,
              private systemHealthStatusService: SystemHealthStatusService) {
  }

  updateSpsStatus() {
    this.service.updateSpsStatus(this._systemId, this._spsStatus)
      .pipe(indicate(this.loading$))
      .subscribe(() => {
        this.snackBar.showInfo('pages.simulation.actions-sps-status-success');
      }, error => this.errorHandler.handleHttpError(error));
  }

}
