import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MfwItem} from 'skw-ui-modules';
import {SkwApiService} from 'skw-ui-bootstrap';
import {SkwErrorHandlerService, SkwSelectModel, SkwSnackBarService} from 'skw-ui-components';
import {SkwTranslatableValue} from 'skw-ui-translation';

@Component({
  selector: 'app-manual-targeting-form',
  templateUrl: './manual-targeting-form.component.html',
  styleUrls: ['./manual-targeting-form.component.scss']
})
export class ManualTargetingFormComponent {

  selectedTarget: string;
  @Input() layoutId: string;
  targets: SkwSelectModel<string>[];
  @Output() select = new EventEmitter<any>();

  @Input() set item(value: MfwItem) {
    this._item = value;
    this.api.get<string[]>(`/process/assigntarget/${this.layoutId}/reachableTargets`, {
      params: {
        itemId: this._item.id,
        source: 'RB002' // FIXME
      }
    }).subscribe(r => this.targets = r.map(t => {
      return {
        value: t,
        viewValue: {key: 'item.attributeValues.' + t, default: t} as SkwTranslatableValue
      } as SkwSelectModel<string>;
    }));
  }

  private _item: MfwItem;

  constructor(private api: SkwApiService,
              private snackBar: SkwSnackBarService,
              private errorHandler: SkwErrorHandlerService) {
  }

  onSelectTarget() {
    if (!this.selectedTarget) {
      return;
    }
    this.api.post(`/process/assigntarget/${this.layoutId}`, undefined, {
      params: {
        itemId: this._item.id,
        targetId: this.selectedTarget
      }
    }).subscribe(() => {
      this.snackBar.showInfo('item-modal.successfully-set');
      this.select.emit();
    }, error => this.errorHandler.handleHttpError(error));
  }
}
