import {Component, DoCheck, EventEmitter, Input, Output} from '@angular/core';
import {Action, ActionType, EventType, Operand, ParameterType} from '../condition-model';
import {SkwSelectModel} from 'skw-ui-components';
import {RulesService} from '../../rules.service';
import {ParameterizedComponent} from '../parameterized-component';

@Component({
  selector: 'app-rule-action',
  templateUrl: './rule-action.component.html',
  styleUrls: ['./rule-action.component.scss']
})
export class RuleActionComponent extends ParameterizedComponent implements DoCheck {

  _action: Action;
  parameters: ParameterType[] = [];

  private previousActionOperands = {};
  private oldActionName: string;

  private oldAction: Action;

  @Input() expertMode: boolean;
  @Input() triggerContext: EventType;
  @Input() editMode: boolean;

  @Input()
  set action(action: Action) {
    this._action = action;
    this.updateOldAction(action); // for ngDoCheck
    this.parameters = this.extractAndSortParameters(action.actionType);
    // create a new set of default values
    if (!this._action.operands || this._action.operands.length === 0) {
      // this is necessary for new actions
      const result: Operand[] = [];
      this.fillOperandsWithDefaultValues(result, this.parameters);
      this._action.operands = result;
    }
  }

  private updateOldAction(action: Action) {
    this.oldAction = {
      actionType: action.actionType,
      operands: action.operands
    } as Action;
  }

  @Output() actionChange: EventEmitter<Action> = new EventEmitter<Action>();

  actionTypes: SkwSelectModel<ActionType>[];
  equalsFunction = (o1: ActionType, o2: ActionType) => o1.name === o2.name;

  constructor(private rulesService: RulesService) {
    super();

    // load actionTypes
    rulesService.getRuleDefinitionsModel().subscribe(value => {
      this.actionTypes = value.actionTypes.map(v => {
        return {
          value: v,
          viewValue: 'pages.rules.actionTypes.' + v.name + '.name'
        } as SkwSelectModel<ActionType>;
      });
    });
  }

  changeActionType($event: ActionType) {
    this._action.actionType = $event;
    this.parameters = this.extractAndSortParameters($event);

    // if there was a change, try to restore old values
    if (this.oldActionName != $event.name) {
      // save previous operands
      if (this.editMode) {
        this.previousActionOperands[this.oldActionName] = this._action.operands;
      }

      if (this.previousActionOperands[$event.name]) {
        // restore previous value if present
        this._action.operands = this.previousActionOperands[$event.name];
      } else {
        // create a new set of default values
        const result: Operand[] = [];
        this.fillOperandsWithDefaultValues(result, this.parameters);
        this._action.operands = result;
      }

      // for the next time
      this.oldActionName = $event.name;
    }
  }

  // we need this because angular does NOT detect when internal values have changed... not even for view rendering.
  ngDoCheck(): void {
    if (!this.editMode && this.oldAction) {
      if (this.oldAction.actionType !== this._action.actionType || this.oldAction.operands !== this._action.operands) {
        this.updateOldAction(this._action);
        this.parameters = this.extractAndSortParameters(this._action.actionType);
      }
    }
  }

  onActionChange($event: Action) {
    this.actionChange.emit($event);
  }
}
