import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {CarbodyPropertyCondition, EventType} from '../../condition-model';
import {SkwSelectModel} from 'skw-ui-components';
import {RulesService} from '../../../rules.service';
import {OPERATOR_TYPES} from '../rule-condition-operators';
import {MdsFieldDefinition} from '../../../rule-definitions-model';
import {RuleEditService} from '../../rule-edit.service';
import {Subscription} from 'rxjs';
import {SkwTranslatableValue} from 'skw-ui-translation';
import {Kid14} from '../../../../../audi-common/kid14';

@Component({
  selector: 'app-rule-condition-car-body-property',
  templateUrl: './rule-condition-car-body-property.component.html',
  styleUrls: ['./rule-condition-car-body-property.component.scss']
})
export class RuleConditionCarBodyPropertyComponent implements OnInit, OnDestroy {

  private $subscription: Subscription;

  @Input() editMode: boolean;
  @Output() conditionChange = new EventEmitter<CarbodyPropertyCondition>();

  _condition: CarbodyPropertyCondition;
  _conditionViewValue: string; // separate as we load all value mappings first
  _attributeValues: SkwSelectModel<string>[] = [];
  _boolAttributeValues: SkwSelectModel<string>[] = [
    {value: 'true', viewValue: 'pages.mds.referenceFieldValues.true'},
    {value: 'false', viewValue: 'pages.mds.referenceFieldValues.false'}
  ];
  _hasAttributeError: boolean;

  private _triggerContext: EventType;
  private identityAttributes: string[] = [];
  isIdentityAttribute: boolean;
  incompatibleLength: boolean;
  allValid: boolean;

  @Input()
  set triggerContext(value: EventType) {
    this._triggerContext = value;
    this.validateCondition();
  }

  @Input()
  set condition(cond: CarbodyPropertyCondition) {
    this._condition = cond;

    this.rulesService.getRuleDefinitionsModel().subscribe(value => {
      // get all mds fields from this definitionsmodel
      this.mdsEntityAttributes = value.mdsEntityAttributes;
      this.identityAttributes = value.identityAttributes;
      this.mdsEntityAttributeNames = value.mdsEntityAttributes.map(fieldDefinition => {
        return {
          value: fieldDefinition,
          viewValue: {
            key: 'pages.mds.entityTypesPrefix.' + fieldDefinition.entityTypeName,
            args: {name: fieldDefinition.attributeName}
          } as SkwTranslatableValue
        } as SkwSelectModel<MdsFieldDefinition>;
      });

      // find the selected attribute for the skw-select-input
      const selectModel = this.mdsEntityAttributes.find(x => x.id === cond.attributeId);
      this.selectedAttribute = selectModel ? selectModel : undefined;
      this.isIdentityAttribute = selectModel ? this.identityAttributes.includes(selectModel.entityTypeName + '.' + selectModel.attributeName) : false;
      if (this.isIdentityAttribute && this._condition.value) {

        if (this.selectedAttribute && !this.selectedAttribute.simpleField) {
          const len = this.selectedAttribute.stopIndex - this.selectedAttribute.startIndex;
          this.incompatibleLength = this._condition.value.split(',').some(kid => kid.length > len);
        } else {
          this.incompatibleLength = false;
        }

        this.allValid = !this._condition.value.split(',').map(kid => new Kid14(kid))
          .some(kid => !kid.isValid())
      }

      this.loadAttributeValues();

      this.validateCondition();
    });
  }

  selectedAttributeViewName: string;
  selectedAttribute: MdsFieldDefinition;

  mdsEntityAttributes: MdsFieldDefinition[];
  mdsEntityAttributeNames: SkwSelectModel<MdsFieldDefinition>[];

  // hardcoded, because we certainly know which operators we allow
  operatorTypes = OPERATOR_TYPES;

  constructor(private rulesService: RulesService,
              private ruleEditService: RuleEditService,
              private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.$subscription = this.ruleEditService.changeObservable().subscribe(() => {
      this.condition = this._condition;
      this.updateConditionViewValue();
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.$subscription.unsubscribe();
  }

  setAttributeReference($event: MdsFieldDefinition) {
    this.selectedAttribute = $event ? $event : undefined;
    this.loadAttributeValues();

    if (this.selectedAttribute) {
      this._condition.attributeId = this.selectedAttribute.id;
      this._condition.attributeName = this.selectedAttribute.attributeName;
      this._condition.entityTypeName = this.selectedAttribute.entityTypeName;

      if (this.selectedAttribute.reference !== undefined) {
        // if this is a bool reference field, we always use the equals operator!!
        this._condition.operator.type = 'EqualsOperator';
        this._condition.value = 'false';
      } else {
        this._condition.value = undefined;
      }
      this.ruleEditService.emitUpdateRule();
    }
  }

  private loadAttributeValues() {
    if (!this.selectedAttribute) {
      return;
    }
    this.rulesService.getCarBodyAttributeValuesFromId(this.selectedAttribute.entityTypeName, this.selectedAttribute.id)
      .subscribe(r => {
        if (!r) {
          this._attributeValues = [];
          return;
        }
        this._attributeValues = r.map(a => {
          return {
            value: a.value.toString(),
            viewValue: a.viewValue
          };
        });
        this.updateConditionViewValue();
        this.cdr.markForCheck();
      });
  }

  private updateConditionViewValue() {
    if (!this._attributeValues || !this._condition) {
      return;
    }
    const splitValue = this._condition.value ? this._condition.value.split(',') : undefined;
    if (splitValue) {
      this._conditionViewValue = splitValue.map(el => {
        const val = this._attributeValues.find(v => el === v.value);
        return !val ? el : val.viewValue;
      }).join(',');
    } else {
      this._conditionViewValue = this._condition.value;
    }
  }

  private validateCondition() {
    // check whether trigger has itemId to reference car body property
    this.rulesService.getRuleDefinitionsModel().subscribe(value => {
      if (!this._triggerContext) {
        this._hasAttributeError = true;
        return;
      }
      const trigger = value.eventTypes.find(t => t.name === this._triggerContext.name);
      if (!trigger) {
        this._hasAttributeError = true;
        return;
      }
      this._hasAttributeError = !this._triggerContext || !trigger.supportedAttributes.itemId;
    });
  }

  conditionValueChange($event: string) {
    this._condition.value = $event;
    this.ruleEditService.emitUpdateRule();
  }

  onInvalid($event: boolean) {
    if ($event) {
      this._condition.invalid = true;
    } else {
      this._condition.invalid = undefined;
    }
  }
}
