import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {AccessLevel, Rule, RuleCategory, RulesService} from './rules.service';
import {
  indicate,
  prepare,
  SkwDialogService,
  SkwErrorHandlerService,
  SkwFilterElement,
  SkwSnackBarService,
  TableSelectRequest
} from 'skw-ui-components';
import {MasterDetailComponent, SkwScrollingService} from 'skw-ui-layout';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {RuleProcessService} from './rule-process.service';
import {RuleExportImportService} from './export-import/rule-export-import.service';
import {SkwFacetFilter, SkwFacetResult, SkwFacetSearchFactory, SkwFacetSearchService} from '../../skw-search/skw-facet-search';
import {SkwTranslatableValue} from 'skw-ui-translation';
import {RuleTemplateWizardService} from './rule-templates/rule-template-wizard.service';
import {RuleTemplateKnrLockWizardConfig} from './rule-templates/rule-template-knr-lock/rule-template-knr-lock.component';
import {SkwSearchFacetsComponent} from '../../skw-search/skw-search-facets/skw-search-facets.component';
import {CopyRuleDialogComponent} from './rule-edit/copy-rule-dialog/copy-rule-dialog.component';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'app-rules',
  templateUrl: './rules.component.html',
  styleUrls: ['./rules.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RulesComponent implements OnInit, OnDestroy {

  facetSearchObject: SkwFacetSearchFactory<Rule>;
  rules: Rule[] = []; // filtered rules
  ruleCategories: RuleCategory[]; // rule category list for tab view
  facets: SkwFacetResult<Rule>[] = [];

  loading$ = new BehaviorSubject<boolean>(true);
  bulkLoading$ = new Subject<boolean>();
  private rulesSubscription: Subscription;
  allCount: number; // all rules without the filter
  private facetsSubscription: Subscription;

  searchString: string;
  multiSelectActive = false; // if this is true, checkboxes are shown
  selectedRules = TableSelectRequest.instance();
  selectedRuleIds = [];

  accessLevel = AccessLevel;

  templates = this.templateService.listTemplates();

  @ViewChild('masterDetail') layout: MasterDetailComponent<Rule>;
  @ViewChild('facetSearch') facetSearch: SkwSearchFacetsComponent<Rule>;
  activatedColumns = ['ruleKey', 'priority', 'name', 'description', 'trigger', 'actions', 'enabled'];

  constructor(public rulesService: RulesService,
              private snackBar: SkwSnackBarService,
              private errorHandlerService: SkwErrorHandlerService,
              private dialogService: SkwDialogService,
              private ruleProcessService: RuleProcessService,
              private router: Router,
              private exportImportService: RuleExportImportService,
              searchService: SkwFacetSearchService,
              private cdr: ChangeDetectorRef,
              private activatedRoute: ActivatedRoute,
              private scrollingService: SkwScrollingService,
              @Inject(DOCUMENT) private document: Document,
              private templateService: RuleTemplateWizardService) {
    this.facetSearchObject = searchService.get<Rule>()
      .addPropertyValue(rule => rule.name, undefined, false)
      .addPropertyValue(rule => rule.ruleKey, undefined, false)
      .addPropertyValue(rule => rule.description, undefined, false)
      .addPropertyValue(rule => rule.ruleCategory, rule => !rule.ruleCategory ? '-' : rule.ruleCategory, true,
        'pages.rules.tableCategoryName')
      .addPropertyValue(rule => rule.accessLevel, rule => `pages.rules.tableHeaderAccessLevels.${rule.accessLevel}`,
        false, 'pages.rules.tableHeaderAccessLevel')
      .addPropertyValue(rule => rule.enabled, rule => `pages.rules.detail.enabled.${rule.enabled}`,
        true, 'pages.rules.tableHeaderActive')
      .addPropertyValue(rule => rule.type, rule => `pages.rules.tableHeaderTypes.${rule.type}`,
        false, 'pages.rules.tableHeaderType')
      .addPropertyValue(rule => rule.trigger, rule => `pages.rules.eventTypes.${rule.trigger}.name`,
        true, 'pages.rules.tableHeaderTrigger')
      .addPropertyValues(rule => rule.actions, rule => this.transformActions(rule.actions),
        true, 'pages.rules.tableHeaderActions')
      .addPropertyValues(rule => RulesComponent.extractTargets(rule),
        rule => RulesComponent.extractTargets(rule).map(t => {
          return {key: 'item.attributeValues.' + t, default: t} as SkwTranslatableValue;
        }),
        true, 'pages.rules.edit.SetTargetAction.targetId');

    this.rulesSubscription = this.facetSearchObject.filteredValuesObservable()
      .subscribe(r => {
        // this loading is in combination with detect changes and set timeout a trick to show a loader while the table is rendered
        this.loading$.next(true);
        setTimeout(() => {
          this.rules = r
            .filter(rule => rule.trigger !== 'ReevluateLocksEvent')
            .map(rule => {
              // rules without prio action will be displayed as '-', set to -1 to sort below all other
              rule.priority = rulesService.hasPrioAction(rule) ? rule.priority : -1;
              return rule;
            });
          this.selectedRuleIds = this.getSelectedRuleIds();
          this.loading$.next(false);
          this.cdr.markForCheck();
        });
      });
    this.facetsSubscription = this.facetSearchObject.facetsObservable()
      .subscribe(r => {
        this.facets = r;
        this.cdr.markForCheck();
      });

    this.scrollingService.rememberScrollPositionOnTransition('/pages/rules', '/pages/rules',
      // accessing the table viewport using document is not an angular way to go, but this workaround
      // was the simplest and fastest way to solve AUDIPLSEXT-1236:
      () => [0, this.document.getElementsByClassName('skw-table-viewport')[0].scrollTop],
      position => this.document.getElementsByClassName('skw-table-viewport')[0].scrollTop = position[1]);
  }

  /**
   * Extract all targets which are used in a SetTarget action of the given rule.
   * If no action of this type is used, an empty list is returned.
   */
  private static extractTargets(rule: Rule): string[] {
    if (!rule || !rule.serializedJson || !rule.serializedJson.actions) {
      return [];
    }
    const actions = rule.serializedJson.actions;
    return actions
      .filter(a => a.actionType.name === 'SetTarget')
      .map(a => {
        const param = a.actionType.parameters.targetId;
        if (!param) {
          return undefined;
        }
        const operand = a.operands[param.ordinal];
        if (!operand) {
          return undefined;
        }
        return operand.value;
      })
      .filter(v => !!v);
  }

  transformActions(actions: string[]): SkwTranslatableValue[] {
    return actions.map(a => {
      return {key: 'pages.rules.actionTypes.' + a + '.name', default: a} as SkwTranslatableValue;
    });
  }

  ngOnInit() {
    const routeFilter = this.activatedRoute.snapshot.queryParams.filter;
    if (routeFilter) {
      this.searchString = routeFilter;
      this.rulesService.filter.searchString = routeFilter;
    } else {
      this.searchString = this.rulesService.filter.searchString;
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: {
          filter: this.searchString
        },
        queryParamsHandling: 'merge'
      });
    }
    this.facetSearchObject.submitSearchString(this.searchString);
    this.facetSearchObject.submitFacetFilter(this.rulesService.filter.facets);
    this.loadRules();
    this.loadRuleCategories();
  }

  ngOnDestroy() {
    this.rulesSubscription.unsubscribe();
    this.facetsSubscription.unsubscribe();
  }

  onSelect($event: TableSelectRequest) {
    if (!this.multiSelectActive) {
      this.openDetail($event.last.id);
    } else {
      this.selectedRules = $event;
      this.selectedRuleIds = this.getSelectedRuleIds();
    }
  }

  toggleMultiSelect() {
    this.multiSelectActive = !this.multiSelectActive;
    this.selectedRules = TableSelectRequest.instance();
    this.selectedRuleIds = this.getSelectedRuleIds();
  }

  onRefreshClick() {
    this.loadRules();
    this.loadRuleCategories();
  }

  createRule(template: string) {
    if (!template) {
      this.router.navigate(['/pages/rules/xx'], {queryParams: {newRule: true}} as NavigationExtras);
      return;
    }
    this.templateService.openTemplateWizard({
      templateName: 'knr-lock',
      data: undefined
    } as RuleTemplateKnrLockWizardConfig);
  }

  private openDetail(id: number) {
    this.router.navigateByUrl('/pages/rules/' + id);
  }

  onFilter($event) {
    this.searchString = $event;
    // update query parameters without reloading the page
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        filter: $event
      },
      queryParamsHandling: 'merge'
    });
    this.rulesService.filter.searchString = $event;
    this.facetSearchObject.submitSearchString($event);
  }

  onFacetSearch($event: SkwFacetFilter<Rule>[]) {
    this.rulesService.filter.facets = $event;
    this.facetSearchObject.submitFacetFilter($event);
  }

  removeFilter($event: SkwFilterElement) {
    // remove the filter!
    this.rulesService.filter.filters = this.rulesService.filter.filters.filter(e => !(e.name === $event.name && e.value === $event.value));
    this.rulesService.filter.facets =
      this.rulesService.filter.facets.filter(e => !(e.property.name === $event.name && e.value === $event.value));
    this.facetSearchObject.submitFacetFilter(this.rulesService.filter.facets);
  }

  private loadRules() {
    this.rulesService.listRules()
      .pipe(
        prepare(() => this.loading$.next(true))
        // the loader must be closed after rendering manually, see callback of the facet search object!
      )
      .subscribe(a => {
        this.allCount = a.allCount;
        this.selectedRuleIds = this.getSelectedRuleIds();
        this.facetSearchObject.submitValues(a.result);
        this.cdr.detectChanges();
        this.scrollingService.restoreScrollPosition();
      }, error => this.errorHandlerService.handleHttpError(error));
  }

  private loadRuleCategories() {
    this.rulesService.listRuleCategories()
      .subscribe(a => {
        this.ruleCategories = a;
        this.cdr.detectChanges();
      }, error => this.errorHandlerService.handleHttpError(error));
  }

  /**
   * In some cases the user can additionally release all locks set by a rule.
   * We have to check first whether a rule is a locking rule with
   * RulesService.hasReleaseLocksOption and then open a dialog to let the user
   * decide whether the locks should be released or not.
   */
  private releaseRuleLocks(rules: Rule[]) {
    // One of the rules has a lock action defined:
    if (RulesService.hasReleaseLocksOptions(rules)) {
      this.dialogService.yesNoDialog('pages.rules.releaseLocksOption', 'pages.rules.releaseLocksOptionHeader')
        .subscribe(value => {
          if (value) {
            this.rulesService.releaseRuleLocks(rules.map(r => r.id))
              .subscribe(() => {
                this.snackBar.showInfo('pages.rules.successfullyReleasedLocks');
              }, error => this.errorHandlerService.handleHttpError(error));
          }
        });
    }
  }

  enableRule($event: boolean, rule: Rule) {
    const ruleNowDisabled = rule.enabled === true && $event === false;
    const ruleNowEnabled = rule.enabled === false && $event === true;
    rule.enabled = $event;
    this.rulesService.setEnabled([rule.id], $event)
      .pipe(indicate(this.loading$))
      .subscribe(() => {
          if (ruleNowDisabled) {
            this.releaseRuleLocks([rule]);
          }
          if (ruleNowEnabled) {
            this.reevaluateLocks([rule]);
          }
          this.loadRules();
        },
        error => this.errorHandlerService.handleHttpError(error));
  }

  openImportDialog() {
    this.exportImportService.openImportDialog()
      .subscribe(r => {
        if (r) {
          this.onRefreshClick();
        }
      });
  }

  enableRules(enable: boolean) {
    // filter rules which are already in the enabled state
    const rules = this.rules
      .filter(r => this.selectedRuleIds.indexOf(r.id) !== -1)
      .filter(r => r.enabled !== enable);

    if (!rules || rules.length === 0) {
      this.snackBar.showInfo('pages.rules.all-rules-enabled');
      return;
    }

    const ruleNowDisabled = rules.length > 0 && enable === false;
    const ruleNowEnabled = rules.length > 0 && enable === true;

    this.ruleProcessService.changeRuleActiveState(rules, enable)
      .pipe(indicate(this.bulkLoading$))
      .subscribe(() => {
          if (ruleNowDisabled) {
            this.releaseRuleLocks(rules);
          }
          if (ruleNowEnabled) {
            this.reevaluateLocks(rules);
          }
          this.loadRules();
        },
        error => this.errorHandlerService.handleHttpError(error));
  }

  private reevaluateLocks(rules: Rule[]) {

    const lockRules = rules
      .filter(r => RulesService.hasReleaseLocksOption(r) && RulesService.hasNewToSystemEvent(r))
      .map(r => r.id);

    if (lockRules.length > 0) {
      this.dialogService.yesNoDialog(`pages.reevaluate-lock-modal.textMulti`,
        'pages.reevaluate-lock-modal.header', 'primary', {count: lockRules.length})
        .subscribe(result => {
          if (result) {
            this.snackBar.showInfo('Karossensperren werden hinzugefügt')
            this.rulesService.reevaluateLocks(lockRules).subscribe(
              next => this.snackBar.showInfo('Karossensperren wurden hinzugefügt'),
              error => this.errorHandlerService.handleHttpError(error)
            );
          }
        });
    }
  }

  deleteRules(rules: Rule[]) {
    if (!rules || rules.length === 0) {
      this.snackBar.showInfo('pages.rules.no-rules-selected');
      return;
    }
    this.ruleProcessService.deleteRules(rules)
      .pipe(indicate(rules.length > 1 ? this.bulkLoading$ : this.loading$))
      .subscribe(deleted => {
        if (deleted) {
          this.loadRules();
          this.snackBar.showInfo('pages.rules.successfullyDeleted');
        }
      }, error => this.errorHandlerService.handleHttpError(error));
  }


  copyRule(ruleIds: number[]) {
    // do dialog for multi rule copy
    if (ruleIds.length > 1) {
      this.rulesService.copyRules(ruleIds)
        .pipe(indicate(ruleIds.length > 1 ? this.bulkLoading$ : this.loading$))
        .subscribe(() => {
          this.loadRules();
          this.snackBar.showInfo('pages.rules.successfullyCopied');
        }, error => this.errorHandlerService.handleHttpError(error));
      return;
    }

    // dialog for single rule copy
    this.rulesService.getUniqueRuleKey(ruleIds[0]).subscribe(([key]) => {
      this.dialogService.componentDialog(CopyRuleDialogComponent, {
        data: {ruleKey: key, originalRuleKey: ruleIds[0]},
        maxWidth: '500px'
      })
        .afterClosed()
        .subscribe(({copyRule, ruleKey}) => {
            if (copyRule) {
              this.rulesService.copyRule(ruleIds[0], ruleKey)
                .subscribe((r) => {
                  this.loadRules();
                  this.snackBar.showInfo('pages.rules.successfullyCopied');
                }, error => this.errorHandlerService.handleHttpError(error));
            }
          }
        );
    }, error => this.errorHandlerService.handleHttpError(error));
  }

  copyRules() {
    if (!this.selectedRuleIds || this.selectedRuleIds.length === 0) {
      this.snackBar.showInfo('pages.rules.no-rules-selected');
      return;
    }
    this.dialogService.yesNoDialog('pages.rules.submit-copy-all', undefined, undefined, {
      count: this.selectedRuleIds.length
    })
      .subscribe(r => {
        if (r) {
          this.copyRule(this.selectedRuleIds);
        }
      });
  }

  exportRule(id: number) {
    this.exportImportService.exportRules([id])
      .subscribe(() => {
      }, error => this.errorHandlerService.handleHttpError(error));
  }

  private getSelectedRuleIds(): number[] {

    if (!this.multiSelectActive) {
      return [];
    }

    const selection = this.selectedRules.selected ? this.selectedRules.selected : [];
    if (this.selectedRules.inverted) {
      return this.rules
        .map(r => r.id)
        .filter(r => selection.indexOf(`${r}`) === -1);
    }
    return this.rules
      .map(r => r.id)
      .filter(r => selection.indexOf(`${r}`) !== -1);
  }

  exportRules() {
    // avoid sending all identifiers if all rules was selected
    const idParams = (this.selectedRuleIds.length === this.allCount) ? undefined : this.selectedRuleIds;
    this.exportImportService.exportRules(idParams)
      .subscribe(() => {
      }, error => this.errorHandlerService.handleHttpError(error));
  }

  deleteSelectedRules() {
    this.deleteRules(this.rules
      .filter(r => this.selectedRuleIds.indexOf(r.id) !== -1));
  }

  openRuleCategoryCreateDialog() {
    this.router.navigateByUrl('pages/rulecategories');
  }
}
