import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SequencerInfoComponent} from './sequencer-info/sequencer-info.component';
import {
  SkwAlertModule,
  SkwBadgeModule,
  SkwCollapseModule,
  SkwFormModule,
  SkwHintModule,
  SkwIconModule,
  SkwListModule
} from 'skw-ui-components';
import {MfwItemModule} from 'skw-ui-modules';
import {SkwPageLayoutModule} from 'skw-ui-layout';
import {SkwLanguageModule} from 'skw-ui-translation';

const COMPONENTS = [
  SequencerInfoComponent
];

@NgModule({
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
  imports: [
    CommonModule,
    SkwBadgeModule,
    MfwItemModule,
    SkwListModule,
    SkwPageLayoutModule,
    SkwCollapseModule,
    SkwLanguageModule,
    SkwIconModule,
    SkwHintModule,
    SkwFormModule,
    SkwAlertModule
  ]
})
export class SequencerModule {
}
