import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {RulesService} from '../../rules.service';

/**
 * This is a simple submit dialog for delete a rule which
 * allows the user to do some additional actions e.g. release
 * some locks.
 */
@Component({
  selector: 'release-rule-logs-dialog',
  templateUrl: './release-rule-locks-dialog.component.html',
  styleUrls: ['./release-rule-locks-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReleaseRuleLocksDialogComponent {

  requestColorAdmin: boolean;

  constructor(private dialogRef: MatDialogRef<ReleaseRuleLocksDialogComponent>) {
    this.requestColorAdmin = true;
  }

  close(value: boolean) {
    this.dialogRef.close({
      copyRule: value,
      requestColorAdmin: this.requestColorAdmin
    });
  }

}
