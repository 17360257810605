import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MfwRollerBeltConfig, MfwRollerBeltModel} from 'skw-ui-modules';
import {SkwApiService} from 'skw-ui-bootstrap';
import {SkwDialogService, SkwErrorHandlerService, SkwSnackBarService} from 'skw-ui-components';
import {MatDialogRef} from '@angular/material/dialog';
import {MfwModuleModalComponent} from '../mfw-module-modal/mfw-module-modal.component';
import {SystemHealthStatusService} from '../../system-status/header-toolbar-portal/system-health-status.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-mfw-spot-modal',
  templateUrl: './mfw-spot-modal.component.html',
  styleUrls: ['./mfw-spot-modal.component.scss']
})
export class MfwSpotModalComponent implements OnInit, OnDestroy {

  @Input() layoutId: string;
  @Input() model: MfwRollerBeltModel;
  @Input() config: MfwRollerBeltConfig;
  @Input() dialogRef: MatDialogRef<MfwModuleModalComponent>;
  attributes: string[][];

  systemStateIsActive: boolean;
  private $systemStatusSubscription: Subscription;

  constructor(private api: SkwApiService,
              private snackBar: SkwSnackBarService,
              private errorHandler: SkwErrorHandlerService,
              private dialogService: SkwDialogService,
              public statusService: SystemHealthStatusService) {
  }

  ngOnInit(): void {
    this.$systemStatusSubscription = this.statusService.observable()
      .subscribe(v => {
        this.systemStateIsActive = this.isSystemActive(v);
      });
  }

  ngOnDestroy(): void {
    this.$systemStatusSubscription.unsubscribe();
  }

  isSystemActive(systemStatus): boolean {
    return systemStatus.filter(s => s.systemId === this.layoutId)[0].plsStatus === 'ACTIVE';
  }


  onSetLockState(lockDirection: string, value: boolean) {
    if (lockDirection === 'out') {
      this.model.locks.out = value;
    } else {
      this.model.locks.in = value;
    }


    this.dialogService.submitDialog('pages.spot-modal.change.' + lockDirection + value, 'danger', {
      name: this.config.displayName
    })
      .subscribe(result => {
          if (result) {
            this.setLockState(this.config.id, lockDirection, value).subscribe(() => {
              this.snackBar.showInfo('messages.lock-changed');
            }, error => {
              this.dialogRef.close();
              this.errorHandler.handleHttpError(error);
            });
          } else {
            // dialog got canceled => reset toggle state
            if (lockDirection === 'out') {
              this.model.locks.out = !value;
            } else {
              this.model.locks.in = !value;
            }
          }
        }
      );
  }

  setLockState(moduleId: string, lockDirection: string, value: boolean) {
    return this.api.post<void>(`/process/managelocks/${this.layoutId}/module/${moduleId}/lockState/${lockDirection}`, null,
      {
        params:
          {
            value: `${value}`
          }
      });
  }
}
