import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SkwApiService} from 'skw-ui-bootstrap';
import {SkwErrorHandlerService, SkwSnackBarService} from 'skw-ui-components';
import {SystemHealthStatusService} from '../../../system-status/header-toolbar-portal/system-health-status.service';
import {
  ForceDeliverSettings,
  TargetingSettings,
  TargetingSettingsService
} from '../../settings/targeting-settings.service';
import {SystemHealthStatus} from '../../../system-status/header-toolbar-portal/system-health-status';
import {Subscription} from 'rxjs';


@Component({
  selector: 'app-emergency-transferal-dialog',
  templateUrl: './emergency-transferal-dialog.component.html',
  styleUrls: ['./emergency-transferal-dialog.component.scss']
})
export class EmergencyTransferalDialogComponent implements OnInit, OnDestroy {

  private readonly _ERROR_STATES = ['NOT_READY', 'UNKNOWN'];
  private readonly _SUCCESS_STATE = 'ACTIVE';

  forceDeliverSettings: ForceDeliverSettings[] = [];
  targetingSettings: { [key: string]: TargetingSettings[] };
  dirty: boolean;
  private healthStatusSubscription$: Subscription;

  constructor(private dialogRef: MatDialogRef<EmergencyTransferalDialogComponent>,
              private targetingSettingsService: TargetingSettingsService,
              private apiService: SkwApiService,
              private errorHandler: SkwErrorHandlerService,
              private snackBarService: SkwSnackBarService,
              private systemHealthStatusService: SystemHealthStatusService,
              @Inject(MAT_DIALOG_DATA) public data: string) {
  }


  /**
   * Upon initialization subscribe to the system health status observable.
   * Please not, that this is a long lasting subscription, which doesn't complete after the first emit.
   */
  ngOnInit() {
    this.healthStatusSubscription$ = this.systemHealthStatusService.observable()
      .subscribe(result => this.loadForceDeliveryStatusTable(result),
        error => this.snackBarService.showError('Preparing the status failed'));
  }

  ngOnDestroy() {
    if (this.healthStatusSubscription$)
      this.healthStatusSubscription$.unsubscribe();
  }

  loadForceDeliveryStatusTable(healthState: SystemHealthStatus[]) {
    try {
      this.targetingSettingsService.loadAll()
        .subscribe(settings => {
          this.targetingSettings = {};

          //deep copy object to make it editable
          Object.keys(settings).forEach(k => this.targetingSettings[k] = settings[k].map(v => {
            return {...v};
          }));

          // create none existing settings to avoid null pointer / undefined
          this.createSettingIfNotExists(this.targetingSettingsService.OVERLOAD_LAYER_STATUS_180, 'false');
          this.createSettingIfNotExists(this.targetingSettingsService.OVERLOAD_LAYER_STATUS_280, 'false');
          this.createSettingIfNotExists(this.targetingSettingsService.OVERLOAD_LAYER_STATUS_380, 'false');
          this.createSettingIfNotExists(this.targetingSettingsService.OVERLOAD_LAYER_STATUS_480, 'false');

          // Combining the two results into an array of relevant data.
          this.forceDeliverSettings = healthState
            .filter(status => status.systemId !== '085')
            .map(status => {
              return {
                systemId: status.systemId,
                status: status.plsStatus,
                statusColor: this.getStatusColor(status),
                forceDeliver: this.targetingSettings['overloadLayerStatus' + status.systemId][0].value === 'true'
              } as ForceDeliverSettings;
            })
            .sort((a, b) => a.systemId.localeCompare(b.systemId));
        });
    } catch (exception) {
      // Catch the exception to avoid unsubscription from the system health state observable
      console.error('[ForceDeliverSetting]: An error occurred while updating the sliders:' + exception);
    }

  }

  getStatusColor(status: SystemHealthStatus) {
    if (this._ERROR_STATES.includes(status.plsStatus))
      return 'danger';

    return this._SUCCESS_STATE === status.plsStatus ? 'success' : 'warning';
  }

  close(b: boolean) {
    this.dialogRef.close();
  }


  createSettingIfNotExists(key: string, defaultValue: string | number) {
    if (!this.targetingSettings[key]) {
      this.targetingSettings[key] = [{
        settingKey: key,
        value: defaultValue
      }];
    }
  }

  apply() {

    this.targetingSettingsService.saveForceDeliverSettings(this.forceDeliverSettings)
      .subscribe(() => this.snackBarService.showInfo('messages.success-saved'),
        error => this.errorHandler.handleHttpError(error));

  }
}
