import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {HEALTH_STATUS_MAPPING, healthColorMapping, SystemHealthStatus} from '../header-toolbar-portal/system-health-status';
import {SystemHealthStatusService} from '../header-toolbar-portal/system-health-status.service';

@Component({
  selector: 'app-system-health-status',
  templateUrl: './system-health-status.component.html',
  styleUrls: ['./system-health-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SystemHealthStatusComponent {

  _status: SystemHealthStatus;
  _statusColor: 'warn' | 'error' | 'info';
  _plsIcon: undefined | string;
  _spsIcon: undefined | string;

  constructor(private systemHealthStatusService: SystemHealthStatusService) {
  }

  @Input() set status(value: SystemHealthStatus) {
    this._status = value;

    this._plsIcon = HEALTH_STATUS_MAPPING[value.plsStatus];
    this._spsIcon = HEALTH_STATUS_MAPPING[value.spsStatus];
    this._statusColor = healthColorMapping(value);
  }

}
