import {ChangeDetectionStrategy, Component} from '@angular/core';
import {SnapshotService} from '../snapshot.service';
import {indicate, SkwErrorHandlerService, SkwSnackBarService} from 'skw-ui-components';
import {Subject} from 'rxjs';
import {MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-take-snapshot',
  templateUrl: './take-snapshot.component.html',
  styleUrls: ['./take-snapshot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TakeSnapshotComponent {
  loading$ = new Subject<boolean>();
  snapshotComment: string;

  constructor(private service: SnapshotService,
              private snackBar: SkwSnackBarService,
              private errorHandlerService: SkwErrorHandlerService,
              public dialogRef: MatDialogRef<TakeSnapshotComponent>) {
  }

  takeSnapshot() {
    if (!this.snapshotComment) {
      return;
    }
    this.service.takeSnapshot(this.snapshotComment)
      .pipe(indicate(this.loading$))
      .subscribe(() => {
        this.dialogRef.close(true);
        this.snackBar.showInfo('snapshot.taken-success');
      }, error => this.errorHandlerService.handleHttpError(error));
  }
}
