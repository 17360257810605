import {Component, OnInit} from '@angular/core';
import {PurposeSettingsService} from '../purpose-settings.service';
import {MdsService} from '../../mds/mds.service';
import {ItemFlowSettings, ItemFlowSettingsService} from '../item-flow-settings.service';
import {BehaviorSubject, forkJoin} from 'rxjs';
import {indicate} from 'skw-ui-components';

@Component({
  selector: 'display-attr-overview',
  templateUrl: './display-attr-overview.component.html',
  styleUrls: ['./display-attr-overview.component.scss']
})
export class DisplayAttrOverviewComponent implements OnInit {

  purposes: string[]; // this is lazy initialized to show up when all data is loaded
  carBodyAttributes: string[];
  itemFlowAttributes: ItemFlowSettings[];

  $loading = new BehaviorSubject(true);

  constructor(private itemFlowService: ItemFlowSettingsService,
              private mdsService: MdsService) {
  }

  ngOnInit() {
    forkJoin(
      this.mdsService.listAttributes('CarBody'),
      this.itemFlowService.loadAll()
    )
      .pipe(indicate(this.$loading))
      .subscribe(value => {
        this.carBodyAttributes = value[0].map(e => e.name);
        this.itemFlowAttributes = value[1];
        this.purposes = [
          PurposeSettingsService.DISPLAYED_IN_SYSTEM_OVERVIEW,
          PurposeSettingsService.DISPLAYED_IN_SEARCH_CARD
        ];
      });
  }

}
