import {NgModule} from '@angular/core';
import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {SkwLoginPageComponent} from 'skw-ui-authentication';

const routes: Routes = [
  {path: 'login', component: SkwLoginPageComponent},
  {
    path: '',
    redirectTo: 'pages/home',
    pathMatch: 'full'
  },
];

const config: ExtraOptions = {
  useHash: true,
  enableTracing: false // disable in production!
};

@NgModule({
  imports: [
    RouterModule.forRoot(routes, config)
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
