import {Component, Inject, Input} from '@angular/core';
import {MFW_AREA_DEFAULT_CONFIG, MfwAreaConfig, MfwAreaModel} from 'skw-ui-modules';
import {MatDialogRef} from '@angular/material/dialog';
import {MfwModuleModalComponent} from '../mfw-module-modal/mfw-module-modal.component';

@Component({
  selector: 'app-mfw-area-modal',
  templateUrl: './mfw-area-modal.component.html',
  styleUrls: ['./mfw-area-modal.component.scss']
})
export class MfwAreaModalComponent {

  @Input() layoutId: string;
  @Input() model: MfwAreaModel;
  @Input() config: MfwAreaConfig;
  @Input() dialogRef: MatDialogRef<MfwModuleModalComponent>;

  constructor(
    @Inject(MFW_AREA_DEFAULT_CONFIG) public defaultConfig: MfwAreaConfig
  ) {
  }

}
