import {Directive, Input, TemplateRef} from '@angular/core';
import {SkwTranslatableValue} from 'skw-ui-translation';
import {SkwTabRenderMode} from './skw-tabs';

/**
 * This directive marks a <code>ng-template</code> as tab.
 * Additionally some input parameters are provided to customize the
 * tab. The only required parameter is the <code>skwTabLabel</code>
 * which is used to display the tab label in the tab select header.
 */
@Directive({
  selector: '[skwTab]'
})
export class SkwTabDirective {

  /**
   * This is a {@link SkwTranslatableValue} which is internally translated
   * automatically. Use this to specify the tabs label displayed in the
   * tab select list header.
   */
  @Input() skwTabLabel: SkwTranslatableValue;

  /**
   * If this is true, the tab is disabled and can't be selected.
   * Default is false (tab is active and selectable).
   */
  @Input() skwTabDisabled = false;
  /**
   * This defines how the tab is rendered. See {@link SkwTabRenderMode} for
   * more information about this. Default value is 'on-active' which
   * only renders this tab if it is active and selected.
   */
  @Input() skwTabRenderMode: SkwTabRenderMode = 'on-active';

  constructor(public templateRef: TemplateRef<any>) {
  }

}
