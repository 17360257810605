import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {Rule} from '../../rules.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-change-rule-active-state-dialog',
  templateUrl: './change-rule-active-state-dialog.component.html',
  styleUrls: ['./change-rule-active-state-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeRuleActiveStateDialogComponent {


  constructor(private dialogRef: MatDialogRef<ChangeRuleActiveStateDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { rules: Rule[], activate: boolean }) {
  }

  close(value: boolean) {
    this.dialogRef.close({
      apply: value
    });
  }

}
