import {Injectable} from '@angular/core';
import {SkwApiService} from 'skw-ui-bootstrap';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AcceptItemsServiceService {


  constructor(private api: SkwApiService) {
  }

  updateMode(server: string, active: boolean): Observable<void> {
    return this.api.post<void>(`/process/changeMode/${server}/blockLayer`, undefined, {
      params: {
        active: `${active}`
      }
    });
  }
}
