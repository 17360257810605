import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {SkwSelectModel} from "skw-ui-components";
import {MdsEntityAttribute, MdsFieldDefinition} from "../../../rules/rule-definitions-model";

@Component({
  selector: 'choose-attribute-dialog',
  templateUrl: './choose-attribute-dialog.component.html',
  styleUrls: ['./choose-attribute-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChooseAttributeDialogComponent {

  mdsEntityAttributes: SkwSelectModel<MdsEntityAttribute>[];
  selectedAttribute: MdsEntityAttribute;

  constructor(private dialogRef: MatDialogRef<ChooseAttributeDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { mdsEntityAttributes: SkwSelectModel<MdsEntityAttribute>[] }) {
    this.mdsEntityAttributes = data.mdsEntityAttributes;
  }

  close(value: boolean) {
    this.dialogRef.close({
      applyValue: value,
      selectedAttribute: this.selectedAttribute
    });
  }

  setAttributeReference($event: MdsEntityAttribute) {
    this.selectedAttribute = $event ? $event : undefined;
  }

}
