import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  NgZone,
  Output,
  QueryList,
  Renderer2
} from '@angular/core';
import {SkwSidebarDirective} from './skw-sidebar.directive';
import {SkwConfigPersistenceService, SkwLogger, SkwLoggerService} from 'skw-ui-components';
import {SkwConfigPersistenceConnection} from 'skw-ui-components/skw/config-persistence/skw-config-persistence.connection';
import {DomSanitizer} from '@angular/platform-browser';

export class SkwSidebarConfig {
  collapsed: boolean;
  activeSidebars: number[];
  width: number;
}

@Component({
  selector: 'skw-sidebar-layout',
  templateUrl: './skw-sidebar-layout.component.html',
  styleUrls: ['./skw-sidebar-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SkwSidebarLayoutComponent implements AfterContentInit {
  @ContentChildren(SkwSidebarDirective) sidebarViews: QueryList<SkwSidebarDirective>;
  private _activeSidebarIndexes: number[] = [0]; // open first per default
  _activeSidebars: SkwSidebarDirective[] = []; // this is an array to allow multiple active sidebars in future

  _collapsed = false;
  /**
   * This is used to set the current or default collapsed state from outside. True is collapse, false is not.
   * Be aware that this is overwritten by the persisted config if you use the {@link SkwSidebarLayoutComponent#configPersistenceId}.
   * This can be used as two way binding!
   */
  @Input() set collapsed(value: boolean) {
    this._collapsed = value;
  }

  /** Whether the sidebar is resizable by the user. */
  @Input() resizable = true;

  /** Set the currently active sidebar if multiple sidebars are defined. The index of the configured sidebar is passed. */
  @Input() set activeSidebar(value: number) {
    this._activeSidebarIndexes = [value];
    this.renderActiveSidebars();
  }

  /** If this is false the sidebar isn't collapsible. */
  @Input() collapsible = true;
  /**
   * This emits if the collapse state of the sidebar changes. This can be used to transform other components based on this state.
   * This can be used as two way binding!
   */
  @Output() collapsedChange = new EventEmitter<boolean>();

  _defaultSidebarWidth = 200;
  private readonly MIN_WIDTH = 100;
  private pressed = false;
  private resizableFnMousemove: () => void;
  private resizableFnMouseup: () => void;
  private _config: SkwSidebarConfig = new SkwSidebarConfig();
  private _configPersistence: SkwConfigPersistenceConnection<SkwSidebarConfig>;
  private readonly logger: SkwLogger;

  constructor(
    private renderer: Renderer2,
    private zone: NgZone,
    private cdr: ChangeDetectorRef,
    loggerService: SkwLoggerService,
    private configPersistenceService: SkwConfigPersistenceService
  ) {
    this.logger = loggerService.forClass('SkwSidebarLayoutComponent');
  }

  _sidebarWidth = this._defaultSidebarWidth;

  /**
   * Set the default sidebar width. If this component is resizable by the user and the state is persisted,
   * this is overwritten.
   * @param value sidebar with in pixels without unit
   */
  @Input() set sidebarWidth(value: number) {
    this._defaultSidebarWidth = value;
    if (!this._config.width || !this.resizable) {
      this._sidebarWidth = this._defaultSidebarWidth;
    }
  }

  /**
   * Allow automatic setting persistence by the given id. This identifier is unique to persist the settings of this component.
   * @param componentId id of the component with optional version e.g. componentXYZ:2
   */
  @Input() set configPersistenceId(componentId: string) {
    this.logger.debug(`Config persistence is activated for ${componentId}`);
    this._configPersistence = this.configPersistenceService.connectionFor<SkwSidebarConfig>(componentId);
    this._configPersistence.getConfig()
      .subscribe(c => {
        if (c) {
          this._config = c;
          if (c.width && this.resizable) {
            this._sidebarWidth = c.width;
          }
          if (typeof c.collapsed !== 'undefined' && this.collapsible) {
            this._collapsed = c.collapsed;
          }
          if (c.activeSidebars) {
            this._activeSidebarIndexes = c.activeSidebars;
            this.renderActiveSidebars();
          }
          this.cdr.markForCheck(); // (not detectChanges but markForCheck) to trigger recalculation of template
        }
      });
  }

  resize(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation(); // called to not trigger sorting click event!
    this.pressed = true;
    const startX = event.pageX;
    const startWidth = this._sidebarWidth ? this._sidebarWidth : this._defaultSidebarWidth;
    this.resizableFnMousemove = this.renderer.listen('document', 'mousemove', (e) => {
      if (this.pressed) {
        this.zone.runOutsideAngular(() => {
          this._sidebarWidth = Math.max(this.MIN_WIDTH, startWidth + (e.pageX - startX));
          this.cdr.markForCheck(); // (not detectChanges but markForCheck) to trigger recalculation of template
        });
      }
    });
    this.resizableFnMouseup = this.renderer.listen('document', 'mouseup', () => {
      if (this.pressed) {
        this.pressed = false;
        if (this._configPersistence) {
          this._config.width = this._sidebarWidth;
          this._configPersistence.persistConfig(this._config);
        }
        this.resizableFnMousemove();
        this.resizableFnMouseup();
      }
    });
  }

  resizeDefault() {
    this._sidebarWidth = this._defaultSidebarWidth;
    if (this._configPersistence) {
      this._config.width = undefined;
      this._configPersistence.persistConfig(this._config);
    }
  }

  toggleSidebar(sidebar: SkwSidebarDirective) {
    const index: number = this.sidebarViews
      .map((i, idx) => [i, idx] as [SkwSidebarDirective, number])
      .find(([i, idx]) => i === sidebar)[1];
    const oldCollapseValue = this._collapsed;
    if (!this._collapsed && this._activeSidebarIndexes && this._activeSidebarIndexes.indexOf(index) !== -1) {
      this._collapsed = true;
    } else {
      this._activeSidebarIndexes = [index];
      this._collapsed = false;
    }
    this.renderActiveSidebars();
    if (this._configPersistence) {
      this._config.collapsed = this._collapsed;
      this._config.activeSidebars = this._activeSidebarIndexes;
      this._configPersistence.persistConfig(this._config);
    }
    if (this._collapsed !== oldCollapseValue) {
      this.collapsedChange.emit(this._collapsed);
    }
  }

  closeSidebar() {
    this._collapsed = true;
    if (this._configPersistence) {
      this._config.collapsed = true;
      this._configPersistence.persistConfig(this._config);
    }
    this.collapsedChange.emit(this._collapsed);
  }

  contentWidth() {
    if (!(this.sidebarViews && this.sidebarViews.length)) {
      return '100%';
    }
    const sidebarVisible = !this._collapsed || !this.collapsible;
    const sidebarTabsVisible = this.collapsible && (this._collapsed || this.sidebarViews.length > 1);
    const result = 'calc(100%'
      + (sidebarVisible ? ` - ${this._sidebarWidth}px` : '')
      + (sidebarTabsVisible ? ' - 2em' : '')
      + ')';
    return result;
  }

  private renderActiveSidebars() {
    if (!this.sidebarViews || !this._activeSidebarIndexes) {
      return;
    }
    if (!this.collapsible) {
      // if not collapsible -> show all
      this._activeSidebars = this.sidebarViews.toArray();
      return;
    }
    this._activeSidebars = this._activeSidebarIndexes.map(i => this.sidebarViews.toArray()[i]).filter(s => !!s);
    if (this._activeSidebars.length === 0) {
      // this can occur if there are sidebars persisted which aren't yet there or the configuration changed, simply open the first
      this._activeSidebars = [this.sidebarViews.first];
    }
  }

  ngAfterContentInit(): void {
    this.renderActiveSidebars();
    this.sidebarViews.changes.subscribe(v => {
      this.renderActiveSidebars();
      this.cdr.detectChanges();
    });
    this.cdr.detectChanges();
  }
}
