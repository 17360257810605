import {Injectable} from '@angular/core';
import {SkwApiService} from 'skw-ui-bootstrap';
import {MfwItem, MfwLock} from 'skw-ui-modules';
import {Observable} from 'rxjs';

export class DashboardInfo {
  layers: DashboardLayerInfo[];
  lockedItems: DashboardItemInfo[];
  errors: any[];
  genericError: boolean;
}

export class DashboardItemInfo {
  itemId: string;
  system: string;
  locks: MfwLock[];
}

export class DashboardLayerInfo {
  system: string;
  itemCount: number;
  lockedCount: number;
}

@Injectable({
  providedIn: 'root'
})
export class HomeDashboardService {
  constructor(private apiService: SkwApiService) {
  }

  getModel(): Observable<DashboardInfo> {
    return this.apiService.get<DashboardInfo>(`/dashboard/model`);
  }

  getItem(id: string): Observable<MfwItem> {
    return this.apiService.get<MfwItem>(`/dashboard/item/${id}`);
  }

  setError(value: boolean) {
    return this.apiService.post<boolean>(`/dashboard/error`, null, {params: {value: '' + value}});
  }
}
