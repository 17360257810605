import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Rule, RulesService} from '../../rules.service';

/**
 * This is a simple submit dialog for delete a rule which
 * allows the user to do some additional actions e.g. release
 * some locks.
 */
@Component({
  selector: 'app-delete-rule-dialog',
  templateUrl: './delete-rule-dialog.component.html',
  styleUrls: ['./delete-rule-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteRuleDialogComponent {

  // additionally there is sometimes an option to release all locks
  // which are set by this rule
  _hasReleaseLocksOption = RulesService.hasReleaseLocksOptions(this.data.rules);
  _lockedCount = this.ruleService.getCountLocked(this.data.rules.map(r => r.id));

  constructor(private dialogRef: MatDialogRef<DeleteRuleDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { rules: Rule[] },
              private ruleService: RulesService) {
  }

  close(value: boolean) {
    this.dialogRef.close({
      deleteRule: value
    });
  }

}
