import {Injectable} from '@angular/core';
import {SkwApiService} from 'skw-ui-bootstrap';
import {Observable, ReplaySubject} from 'rxjs';
import {SkwSystemEnvironmentMarkService} from 'skw-ui-layout';
import {SkwAuthHttpErrorDoNotReplayHeader, SkwAuthorizationService, SkwAuthService} from 'skw-ui-authentication';
import {distinctUntilChanged, tap} from 'rxjs/operators';

export class SimulationServiceStatus {
  /** True if the simulation future event queue and the core is initialized. */
  simInitialized: boolean;
  /** True if all input items and the db is initialized. */
  itemsInitialized: boolean;
  /** True if pls service is initialized. */
  plsInitialized: boolean;
  /** True if the simulation can load new data and can be initialized again. */
  itemsDirty: boolean;
  /** True if all init flags are true! */
  initialized: boolean;
  /** True if the simulation is currently running automatically. */
  running: boolean;
  /** True if the simulation has processed min. one step and isn't initialized anymore. */
  dirty: boolean;
  /** True if there are simulation results to download. */
  hasResults: boolean;
  /** Timestamp when simulation was started (first step made). */
  startedTimestamp: Date;
  /** Id of simulation run. */
  simulationRun: number;
  /** Whether the simulation is initialized with a snapshot (true) or empty (false). */
  snapshotUsed: boolean;
  /** Current time in simulation. */
  currentTime: number;
  /** Simulation speed e.g. 10 means ten times faster than realtime. */
  speed: number;
  /** Can hold an error message. If this is null, the simulation has no errors! */
  errorMessage: string;
  /** Current simulation settings. */
  settings: SimulationSettings;
}

export class SimulationSettings {
  /**
   * All module which are entry modules. The configured items are evenly distributed on all modules. If you only want
   * all items to appear on a specific module, you can only configure one.
   */
  entryModules: string[];
  /** Time until item is ready on a LB. */
  lbItemReadyTime: number;
  /** Time until item is ready on a RB. */
  rbItemReadyTime: number;
  /** Time for a VW pickup process. */
  vwPickupTime: number;
  /** Time for a VW deliver process. */
  vwDeliverTime: number;
  /** Movement time for a VW which process a MOVE transport order. */
  vwMoveTransportOrderTime: number;
  /** Max simulation waiting time if nothing happens during a long time period. */
  maxWaitingTime: number;
  /** Processing time in PVC until the item enters the system again. */
  pvcProcessingTime: number;
  /** Time a target system needs to take over an item. */
  outboundTime: { [name: string]: number };
}

@Injectable({
  providedIn: 'root'
})
export class SimulationControlService {
  private _simulationActive = new ReplaySubject<boolean>(1);
  private _simulationStatus = new ReplaySubject<SimulationServiceStatus>(1);

  constructor(private apiService: SkwApiService,
              private authService: SkwAuthService,
              private authorizationService: SkwAuthorizationService,
              private environmentMarkService: SkwSystemEnvironmentMarkService) {
    this.authService.authObservable()
      .subscribe(userIsAuthenticated => this.checkSimulationStatusWhenAuthenticated(userIsAuthenticated));
  }

  private checkSimulationStatusWhenAuthenticated(userIsAuthenticated: boolean) {
    if (!userIsAuthenticated)
      return;

    this.authorizationService.isAuthorized("simulation")
      .subscribe(
        (isAuthorized) => {
          if (isAuthorized) {
            this.checkSimulationStatus();
          } else {
            this.onSimulationNotAvailable();
          }
        },
        () => this.onSimulationNotAvailable())
  }

  private checkSimulationStatus(): void {
    this.status()
      .subscribe(() => this.onSimulationAvailable(),
        () => this.onSimulationNotAvailable());
  }

  private onSimulationAvailable(): void {
    // request status as boolean
    this._simulationActive.next(true);
    // enable system marker to notify the user about this is the simulation environment
    this.environmentMarkService.setMarkerText('envs.simulation');
    this.environmentMarkService.setMarkerColor('#aa62ec');
    this.environmentMarkService.enableMarker();
  }

  private onSimulationNotAvailable(): void {
    this._simulationActive.next(false);
    this.environmentMarkService.disableMarker();
  }

  init(): Observable<void> {
    return this.apiService.get('/discreteSim/init');
  }

  initSnapshot(startDate: number): Observable<void> {
    return this.apiService.get('/discreteSim/initSnapshot', {
      params: {
        startDate: `${startDate}`
      }
    });
  }

  initItems(): Observable<void> {
    return this.apiService.get('/discreteSim/initItems');
  }

  status(): Observable<SimulationServiceStatus> {
    return this.apiService.get<SimulationServiceStatus>('/discreteSim/status', {
      headers: {
        // don't replay this request in case of error
        SkwAuthHttpErrorDoNotReplayHeader,
      }
    }).pipe(tap(r => this._simulationStatus.next(r)));
  }

  start(speed: number) {
    return this.apiService.get('/discreteSim/run', {
      params: {speed: `${speed}`}
    });
  }

  stop() {
    return this.apiService.get('/discreteSim/stop');
  }

  /** Returns a HOT observable with the simulation active status. */
  isSimulationActive(): Observable<boolean> {
    return this._simulationActive.asObservable().pipe(distinctUntilChanged());
  }

  /** Returns the current status as hot observable (not distinct until changed). */
  statusObservable(): Observable<SimulationServiceStatus> {
    return this._simulationStatus.asObservable();
  }

  /**
   * Update the sps status manually. This event is immediately injected into the simulation and immediately executed.
   *
   * @param systemId layer
   * @param status   new status
   */
  updateSpsStatus(systemId: string, status: number) {
    return this.apiService.post('/discreteSim/updateSpsStatus', undefined, {
      params: {
        systemId,
        status: `${status}`
      }
    });
  }

  updateSettings(settings: SimulationSettings) {
    return this.apiService.post<SimulationSettings>('/discreteSim/settings', settings);
  }
}
