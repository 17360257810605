import {Injectable} from '@angular/core';
import {SkwApiService} from 'skw-ui-bootstrap';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SchedulerService {

  constructor(private api: SkwApiService) {
  }

  getSchedulerState(system: string, scheduler: string): Observable<any> {
    return this.api.get<any>(`/scheduler/${system}/${scheduler}`);
  }

}
