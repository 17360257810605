import { ChangeDetectionStrategy, Component } from '@angular/core';
import {KvsNotificationService} from "./kvs-notification.service";
import {Router} from "@angular/router";

@Component({
  selector: 'kvs-notification-icon',
  templateUrl: './kvs-notification-icon.component.html',
  styleUrls: ['./kvs-notification-icon.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KvsNotificationIcon {

  count$ = this.service.countObservable();

  constructor(public service: KvsNotificationService,
              private router: Router) {
  }

  routeToKvs() {
    this.router.navigateByUrl('/pages/kvs');
  }
}
