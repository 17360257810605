import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ItemProcessService} from '../../../mfw-components/mfw-item/item-process.service';
import {Subject} from 'rxjs';
import {ListAction, SkwDialogService, SkwErrorHandlerService, SkwSnackBarService} from 'skw-ui-components';
import {CarBodyAttrsModalComponent} from '../car-body-attrs-modal/car-body-attrs-modal.component';
import {PurposeSettingsService} from '../../settings/purpose-settings.service';
import {MfwLock} from 'skw-ui-modules';
import {
  RuleTemplateKnrLock,
  RuleTemplateKnrLockWizardConfig
} from '../../rules/rule-templates/rule-template-knr-lock/rule-template-knr-lock.component';
import {RuleTemplateWizardService} from '../../rules/rule-templates/rule-template-wizard.service';
import {SkwLanguageService} from 'skw-ui-translation';

@Component({
  selector: 'app-car-body-card',
  templateUrl: './car-body-card.component.html',
  styleUrls: ['./car-body-card.component.scss']
})
export class CarBodyCardComponent {
  _item: any;
  @Output() interaction = new EventEmitter();
  $loading = new Subject<boolean>();
  readonly carBodyAttributesActions: ListAction[];

  _filteredCarBodyAttributes: any[];
  _filteredItemFlowAttributes: any[];

  template = new RuleTemplateKnrLock();

  constructor(private itemProcessService: ItemProcessService,
              private purposeSettingsService: PurposeSettingsService,
              private templateService: RuleTemplateWizardService,
              private snackBarService: SkwSnackBarService,
              private errorHandler: SkwErrorHandlerService,
              private modal: SkwDialogService,
              private languageService: SkwLanguageService) {
    this.carBodyAttributesActions = [
      {
        icon: 'fa fa-expand-alt',
        click: () => {
          this.modal.componentDialog(CarBodyAttrsModalComponent, {
            data: this._item.CarBody,
            minWidth: '600px'
          });
        }
      } as ListAction
    ];

    this.purposeSettingsService.loadAttributesForPurpose(PurposeSettingsService.DISPLAYED_IN_SEARCH_CARD)
      .subscribe(value => {
        this._filteredCarBodyAttributes = [];
        this._filteredItemFlowAttributes = [];
        value.forEach(a => {
          // Be aware of naming differences:
          // - backend -> ItemFlow
          // - search result -> itemFlow
          if (a.entity === 'ItemFlow') {
            this._filteredItemFlowAttributes.push(a.attributeName);
          }
          if (a.entity === 'CarBody') {
            this._filteredCarBodyAttributes.push(a.attributeName);
          }
        });
      });
  }

  @Input() set item(value: any) {
    this._item = value;
  }

  unlockItem($event: MfwLock) {
    this.itemProcessService
      .doUnlockItem('280', this._item.CarBody.KID14, $event, this.$loading)
      .subscribe(() => this.interaction.emit());
  }

  createRule(template: string, id: string) {
    if (!template) {
      return;
    }
    this.templateService.openTemplateWizard({
      templateName: 'knr-lock',
      data: {attributeValue: id}
    } as RuleTemplateKnrLockWizardConfig);
  }

  togglePriority(_item: any) {

    const position = this._item.position;
    var system = '280';
    if (position && position.length == 8) {
      const layer = position.substr(0, 3);
      if (['085', '180', '280', '380', '480'].includes(layer)) {
        system = layer;
      }
    }

    this.itemProcessService.doPrioritizeItem(system, this._item.CarBody.KID14, this._item.itemFlow.priority !== 'true', this.$loading)
      .subscribe((result) =>
          this.languageService.get('pages.search.prio-change-triggered').subscribe(text =>
            this.snackBarService.showInfo(text)
          ),
        (error) => this.errorHandler.handleHttpError(error));
  }
}
