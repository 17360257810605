import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Action, EventType, LiteralOperand} from '../../condition-model';
import {Subscription} from 'rxjs';
import {RulesService} from '../../../rules.service';
import {RuleEditService} from '../../rule-edit.service';
import {SkwSelectModel} from 'skw-ui-components';

@Component({
  selector: 'app-rule-action-send-jms-message',
  templateUrl: './rule-action-send-jms-message.component.html',
  styleUrls: ['./rule-action-send-jms-message.component.scss']
})
export class RuleActionSendJmsMessageComponent implements OnInit {
  @Input() editMode: boolean;
  @Input() triggerContext: EventType;

  $subscription: Subscription;

  _action: Action;

  _propKvsSystemIdx: number;
  _propKvsSystemValue: string;

  @Input()
  set action(action: Action) {
    this._action = action;

    this._propKvsSystemIdx = (action.actionType.parameters as any).kvsSystem.ordinal;
    this._propKvsSystemValue = (this._action.operands[this._propKvsSystemIdx] as LiteralOperand).value.toString();

  }

  @Output() actionChange: EventEmitter<Action> = new EventEmitter<Action>();
  allQueueNames: SkwSelectModel<string>[] = [{
    viewValue: 'KVS 251',
    value: '251'
  }, {
    viewValue: 'KVS 351',
    value: '351'
  }];
  selectedQueueName: string;
  messageText: string;

  constructor(private rulesService: RulesService,
              private ruleEditService: RuleEditService,
              private cdr: ChangeDetectorRef) {
  }

  ngOnDestroy() {
    this.$subscription.unsubscribe();
  }

  ngOnInit() {
    this.$subscription = this.ruleEditService.changeObservable()
      .subscribe(s => {
        this.action = this._action;
      });
  }


  queueNameValueChange($event: string) {
    this.selectedQueueName = $event;
    (this._action.operands[this._propKvsSystemIdx] as LiteralOperand).value = $event;
    this.ruleEditService.emitUpdateRule();
    this.actionChange.emit(this._action);

  }

}
