import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Action, EventType, LiteralOperand} from '../../condition-model';
import {Observable, Subscription} from 'rxjs';
import {RulesService} from '../../../rules.service';
import {RuleEditService} from '../../rule-edit.service';
import {SkwSelectModel} from 'skw-ui-components';

@Component({
  selector: 'app-rule-action-prioritize-item',
  templateUrl: './rule-action-prioritize-item.component.html',
  styleUrls: ['./rule-action-prioritize-item.component.scss']
})
export class RuleActionPrioritizeItemComponent implements OnInit, OnDestroy {

  @Input() editMode: boolean;
  @Input() triggerContext: EventType;

  $subscription: Subscription;

  _action: Action;

  _selectableRules: Observable<SkwSelectModel<string>[]>;

  @Input()
  set action(action: Action) {
    this._action = action;
  }

  @Output() actionChange: EventEmitter<Action> = new EventEmitter<Action>();

  constructor(private rulesService: RulesService,
              private ruleEditService: RuleEditService,
              private cdr: ChangeDetectorRef) {
  }

  ngOnDestroy() {
    this.$subscription.unsubscribe();
  }

  ngOnInit() {
    this._selectableRules = this.rulesService.getRules();
    this.$subscription = this.ruleEditService.changeObservable()
      .subscribe(s => {
        this.action = this._action;
      });
  }

}
