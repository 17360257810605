import {AfterContentInit, ChangeDetectionStrategy, Component, ContentChildren, Input, QueryList, TemplateRef} from '@angular/core';
import {MfwItemParameter} from 'skw-ui-modules';
import {CustomItemParameterDirective} from './custom-item-parameter.directive';

@Component({
  selector: 'mfw-item-parameter',
  templateUrl: './item-parameter.component.html',
  styleUrls: ['./item-parameter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemParameterComponent implements AfterContentInit {

  @Input() parameters: MfwItemParameter[];
  @Input() showEmptyParameters = true;
  @ContentChildren(CustomItemParameterDirective) customParameters: QueryList<CustomItemParameterDirective>;

  _customParameters: { [name: string]: TemplateRef<any> };

  constructor() {
  }

  ngAfterContentInit(): void {
    this.inflateParameterMap();
  }

  private inflateParameterMap() {
    this._customParameters = {};
    this.customParameters.forEach(el => {
      this._customParameters[el.mfwCustomItemParameter] = el.templateRef;
    });
  }

}
