import {Injectable} from '@angular/core';
import {SkwApiService} from 'skw-ui-bootstrap';
import {Observable} from 'rxjs';

export class MdsFieldDefinition {
  id: number;
  name: string;
  entity: string;
  sourceField: string;
  isSimpleField: boolean;
  startIndex: number;
  stopIndex: number;
  active: boolean;
  isReferenceField: boolean;
  reference: string;
  numberOfMappings: number;
  description: string;
}

export class MdsCodeMapping {
  id: number;
  name: string;
  value: string;
  mdsField: number;
}

export class MdsResultSet {
  result: MdsCodeMapping[];
  tooLarge: boolean;
  size: number;
}

@Injectable({
  providedIn: 'root'
})
export class MdsService {

  constructor(private api: SkwApiService) {
  }

  getAttribute(id: number): Observable<MdsFieldDefinition> {
    return this.api.get<MdsFieldDefinition>(`/process/mds/field/${id}`);
  }

  addAttribute(model: MdsFieldDefinition): Observable<void> {
    return this.api.post<void>(`/process/mds/fields`, model);
  }

  updateAttribute(model: MdsFieldDefinition): Observable<void> {
    return this.api.put<void>(`/process/mds/field`, model);
  }

  removeAttribute(id: number): Observable<void> {
    return this.api.delete<void>(`/process/mds/field/${id}`);
  }

  listAttributes(entity: string, searchString?: string): Observable<MdsFieldDefinition[]> {
    const options = !searchString ? undefined : {params: {searchString}};
    return this.api.get<MdsFieldDefinition[]>(`/process/mds/${entity}/fields`, options);
  }

  listBaseAttributes(entity: string): Observable<string[]> {
    return this.api.get<string[]>(`/process/mds/${entity}/baseAttributes`);
  }

  listBaseAttributesExamples(entity: string, baseAttributeName: string): Observable<string[]> {
    return this.api.get<string[]>(`/process/mds/${entity}/examples`, {
      params: {
        baseAttributeName,
        count: '3'
      }
    });
  }

  getBaseAttributeType(entity: string, baseAttributeName: string): Observable<string[]> {
    return this.api.get<string[]>(`/process/mds/${entity}/type`, {
      params: {
        baseAttributeName
      }
    });
  }

  addMapping(model: MdsCodeMapping): Observable<void> {
    return this.api.post<void>(`/process/mds/field/${model.mdsField}/mappings`, model);
  }

  updateMapping(model: MdsCodeMapping): Observable<void> {
    return this.api.put<void>(`/process/mds/field/${model.mdsField}/mapping`, model);
  }

  removeMapping(model: MdsCodeMapping): Observable<void> {
    return this.api.delete<void>(`/process/mds/field/${model.mdsField}/mapping/${model.id}`);
  }

  listMappings(fieldId: number, searchString?: string): Observable<MdsResultSet> {
    const options = !searchString ? undefined : {params: {searchString}};
    return this.api.get<MdsResultSet>(`/process/mds/field/${fieldId}/mappings`, options);
  }

  listAttributeValues(entity: string, attributeName: string): Observable<string[]> {
    return this.api.get<any[]>(`/process/mds/${entity}/${attributeName}/values`);
  }

  getMappingForAttributeValue(entityName: string, fieldName: string, value: string): Observable<MdsCodeMapping> {
    return this.api.get<MdsCodeMapping>(`/process/mds/${entityName}/mapping`, {
      params: {
        fieldName,
        value
      }
    });
  }
}
