import {Injectable} from '@angular/core';
import {SkwApiService} from 'skw-ui-bootstrap';
import {Observable, of} from 'rxjs';
import {tap} from 'rxjs/operators';

export class PurposeSetting {
  id: number;
  purposeKey: string;
  entity: string;
  attributeName: string;
  ordinal: number;
  valid: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class PurposeSettingsService {
  public static readonly DISPLAYED_IN_SYSTEM_OVERVIEW: string = 'displayedInSystemOverview';
  public static readonly DISPLAYED_IN_SEARCH_CARD: string = 'displayedInSearchCard';

  private cachedPurposes: { [purpose: string]: PurposeSetting[] } = {};

  constructor(private apiService: SkwApiService) {
  }

  loadAttributesForPurpose(purpose: string): Observable<PurposeSetting[]> {
    if (this.cachedPurposes[purpose]) {
      return of(this.cachedPurposes[purpose]);
    }
    return this.apiService.get<PurposeSetting[]>('/settings/purpose/attributes', {
      params: {
        purpose
      }
    }).pipe(tap(r => this.cachedPurposes[purpose] = r));
  }

  loadPurposeForAttribute(entity: string, attributeName: string): Observable<string[]> {
    return this.apiService.get<string[]>('/settings/purpose/purposes', {
      params: {
        entity,
        attributeName
      }
    });
  }

  invalidateCache() {
    this.cachedPurposes = {};
  }

  addPurpose(purpose: PurposeSetting): Observable<PurposeSetting> {
    return this.apiService.post<PurposeSetting>('/settings/purpose', purpose);
  }

  deletePurpose(id: number): Observable<void> {
    return this.apiService.delete(`/settings/purpose/${id}`);
  }

  updatePurpose(id: number, purpose: PurposeSetting): Observable<void> {
    return this.apiService.put(`/settings/purpose/${id}`, purpose);
  }

  updatePurposeOrder(orderedList: PurposeSetting[]): Observable<void> {
    return this.apiService.post('/settings/purpose/order', orderedList);
  }

}
