import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy} from '@angular/core';
import {SequencerService} from '../sequencer.service';
import {Subscription} from 'rxjs';
import {SequencerModel} from '../../../api/websocket/sequencer-update-event';

@Component({
  selector: 'app-sequencer-info',
  templateUrl: './sequencer-info.component.html',
  styleUrls: ['./sequencer-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SequencerInfoComponent implements OnDestroy {
  private subscription: Subscription;
  readonly MAX_DISPLAYED = 15;
  model: SequencerModel;
  collapsed = true;

  @Input() set system(value: string) {
    this.ngOnDestroy();
    this.subscription = this.service.getModelObservable(value)
      .subscribe(r => {
        this.model = r;
        this.cdr.markForCheck();
      });
  }

  constructor(private service: SequencerService,
              private cdr: ChangeDetectorRef) {
  }

  ngOnDestroy(): void {
    if (typeof this.subscription !== 'undefined') {
      this.subscription.unsubscribe();
    }
  }
}
