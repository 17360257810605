import {Component, EventEmitter, Inject, Input, Output} from '@angular/core';
import {MfwItem} from 'skw-ui-modules';
import {SkwApiService} from 'skw-ui-bootstrap';
import {SkwDialogRef, SkwErrorHandlerService, SkwSelectModel, SkwSnackBarService} from 'skw-ui-components';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-unlock-item-form',
  templateUrl: './unlock-item-form.component.html',
  styleUrls: ['./unlock-item-form.component.scss']
})
export class UnlockItemFormComponent {

  requestColorAdmin: boolean;

  constructor(public dialogRef: MatDialogRef<any>,
              @Inject(MAT_DIALOG_DATA) public data: { id: string, lockReason: string }) {
    this.requestColorAdmin = true;
  }

  confirmDialog() {
    this.dialogRef.close({
      confirmChange: true,
      requestColorAdmin: this.requestColorAdmin
    });
  }

  closeDialog() {
    this.dialogRef.close({
      confirmChange: false,
      requestColorAdmin: false
    });
  }
}
