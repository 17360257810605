import {Injectable} from '@angular/core';
import {SystemHealthStatus} from './system-health-status';
import {combineLatest, Observable, ReplaySubject, Subject} from 'rxjs';
import {SkwApiService} from 'skw-ui-bootstrap';
import {WebsocketEventService} from '../../api/websocket/websocket-event.service';
import {indicate} from 'skw-ui-components';
import {SkwAuthorizationService} from 'skw-ui-authentication';
import {map} from 'rxjs/operators';
import {DashboardItemInfo} from '../../pages/home/home-dashboard.service';
import {TargetingSettingsService} from '../../pages/settings/targeting-settings.service';

@Injectable({
  providedIn: 'root'
})
export class SystemHealthStatusService {
  private currentStatus: SystemHealthStatus[];
  public $loading = new Subject<boolean>();
  private $status = new ReplaySubject<SystemHealthStatus[]>(1);

  private $ctrlLocks = new ReplaySubject<Map<string, DashboardItemInfo[]>>(1);

  private $healthStatus = new ReplaySubject<SystemHealthStatus[]>(1);

  constructor(private api: SkwApiService,
              private authorizationService: SkwAuthorizationService,
              private wsEventService: WebsocketEventService,
              private targetingSettingsService: TargetingSettingsService) {
    this.load();

    // Note: No unsubscribe on destroy needed here as this is a singleton service
    this.wsEventService.onSystemStatusEvent().subscribe(event => {
      try {
        if (['system-status'].includes(event.name)) {
          const systemId = event.payload.systemId;
          if (this.currentStatus) {
            this.currentStatus = this.currentStatus
              .map(s => s.systemId === systemId ? event.payload : s);
          }
          this.$status.next(this.currentStatus);
        }
        if (['setting-changed'].includes(event.name)) {
          targetingSettingsService.updateSettings(event.payload);
        }
        if (['ctrl-count'].includes(event.name)) {
          this.$ctrlLocks.next(event.payload);
        }
      } catch (e) {
        console.error(e);
      }
    });

    // Refresh on authorization changes as different user sees different status
    this.authorizationService.authorizedActionsObservable()
      .subscribe(r => {
        if (r.length > 0) {
          this.load();
        }
      });

    combineLatest(
      this.$status.asObservable(),
      this.$ctrlLocks.asObservable()
    ).pipe(map(([status, ctrlLocks]) => {
      if (!status) {
        return [];
      }
      return status.map(s => {
        if (!s || !ctrlLocks) { // status and ctrl locks could be null/undefined
          return s;
        }
        s.ctrlLocks = ctrlLocks[s.systemId];
        return {...s}; // create a new object to trigger change detection
      });
    })).subscribe(r => this.$healthStatus.next(r));
  }

  observable(): Observable<SystemHealthStatus[]> {
    // combine status and ctrl locks into a single status
    return this.$healthStatus.asObservable();
  }

  systemObservable(system: string) {
    return this.$status.asObservable()
      .pipe(map(s =>
        s.find(q => q.systemId === system)
      ));
  }

  load() {
    this.api.get<SystemHealthStatus[]>('/system/health')
      .pipe(indicate(this.$loading))
      .subscribe(r => {
        this.currentStatus = r;
        this.$status.next(this.currentStatus);
      }, error => this.$status.next(null));
    this.loadCtrlLocks();
    this.loadTargetingSettings();
  }

  loadCtrlLocks() {
    this.api.get<Map<string, DashboardItemInfo[]>>('/system/health/ctrlLocks')
      .pipe(indicate(this.$loading))
      .subscribe(r => {
        this.$ctrlLocks.next(r);
      }, error => this.$ctrlLocks.next(null));
  }

  loadTargetingSettings() {
    this.targetingSettingsService.loadAll();
  }
}
