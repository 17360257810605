import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {RuleConstants} from '../../rule-definitions-model';
import {Rule, RulesService} from '../../rules.service';
import {map} from 'rxjs/operators';
import {SKW_DIALOG_DATA} from 'skw-ui-components';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-add-action-modal',
  templateUrl: './add-action-modal.component.html',
  styleUrls: ['./add-action-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddActionModalComponent {

  actionTypes$: Observable<string[]> = this.rulesService.getRuleDefinitionsModel()
    .pipe(
      map(context => context.eventTypes.find(e => e.name === this.rule.serializedJson.trigger.name)),
      map(
        eventType => {
          return [
            RuleConstants.SetItemFlowPropertyAction,
            RuleConstants.AddAllowedOutletsAction,
            RuleConstants.RequestAllowedOutletsAction,
            RuleConstants.SetTargetAction,
            RuleConstants.SetSubTargetAction,
            RuleConstants.StoreAndLockAction,
            RuleConstants.ReleaseItemLockAction,
            RuleConstants.RouteToStorageAction,
            RuleConstants.FireSetAllowedOutletsAction,
            // RuleConstants.AddSequentialScoreModifierAction,
            // RuleConstants.AddPropagationTimeScoreModifierAction,
            RuleConstants.AddConsecutiveForbiddenScoreModifierAction,
            RuleConstants.AddColorBlockScoreModifierAction,
            RuleConstants.SendJmsMessageAction,
            RuleConstants.SetItemDistanceAction,
            RuleConstants.SetTimeDistanceAction,
            RuleConstants.PrioritizeItemAction
            // RuleConstants.AdvancedAction
          ] // filter allowed actions depending on the rule trigger
            .filter(e => eventType.allowedActions.indexOf(e) !== -1);
        })
    );

  constructor(public dialogRef: MatDialogRef<AddActionModalComponent>,
              @Inject(SKW_DIALOG_DATA) public rule: Rule,
              private rulesService: RulesService) {
  }

  chooseAction(action: string) {
    this.dialogRef.close(action);
  }
}
