import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {MfwLockIssuer} from 'skw-ui-modules';
import {RulesService} from '../../../pages/rules/rules.service';
import {Router} from '@angular/router';

@Component({
  selector: 'mfw-item-locks-issuer',
  templateUrl: './mfw-item-locks-issuer.component.html',
  styleUrls: ['./mfw-item-locks-issuer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MfwItemLocksIssuerComponent {
  _issuer: MfwLockIssuer;

  @Input() set issuer(value: MfwLockIssuer) {
    this._issuer = value;
  }

  @Output()
  onNavigatedToRule = new EventEmitter();

  constructor(private serv: RulesService,
              private router: Router) {
  }

  gotoRule() {
    this.serv.getRuleByRuleKey(this._issuer.identifier)
      .subscribe((result) => {
        this.router.navigateByUrl('/pages/rules/' + result.id);
        this.onNavigatedToRule.emit();
      });
  }

}
