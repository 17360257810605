import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

/**
 * This service is used to set the valid flag of a surrounding wizard.
 * Should be used in rule template components in combination with the skw-form.
 */
@Injectable({
  providedIn: 'root'
})
export class RuleTemplateWizardValidationService {
  private wizardStepValid = new BehaviorSubject<boolean>(true);

  setValid(valid: boolean) {
    // set valid flag in next change detection cycle to avoid the expression
    // changed after checked error as this is emitted by a child component
    // which is computed after the parent component was checked
    setTimeout(() => this.wizardStepValid.next(valid));
  }

  validObservable() {
    return this.wizardStepValid.asObservable();
  }

}
