import {Injectable} from '@angular/core';
import {SkwApiService} from 'skw-ui-bootstrap';
import {Observable} from 'rxjs';
import {SkwUserService} from 'skw-ui-authentication';
import {switchMap} from 'rxjs/operators';

export class Snapshot {
  id: number;
  created: Date;
  creator: string;
  createdTime: number;
  comment: string;
}

@Injectable({
  providedIn: 'root'
})
export class SnapshotService {
  constructor(private apiService: SkwApiService,
              private userService: SkwUserService) {
  }

  restoreSnapshot(id: number): Observable<Snapshot> {
    return this.apiService.post<Snapshot>(`/snapshot/restore/${id}`, undefined);
  }

  takeSnapshot(comment: string): Observable<Snapshot> {
    return this.userService.getUser().pipe(
      switchMap(user =>
        this.apiService.post<Snapshot>('/snapshot/take', undefined, {
          params: {
            creator: user.name,
            comment
          }
        }))
    );
  }

  listSnapshots(): Observable<Snapshot[]> {
    return this.apiService.get<Snapshot[]>('/snapshot');
  }

  deleteSnapshot(id: number): Observable<Snapshot> {
    return this.apiService.delete<Snapshot>(`/snapshot/${id}`);
  }
}
