import {ChangeDetectionStrategy, Component} from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {Subject} from 'rxjs';
import {Snapshot, SnapshotService} from '../snapshot.service';
import {indicate, SkwDialogService, SkwErrorHandlerService, SkwSnackBarService} from 'skw-ui-components';
import {TakeSnapshotComponent} from '../take-snapshot/take-snapshot.component';

@Component({
  selector: 'app-snapshot-dialog',
  templateUrl: './snapshot-dialog.component.html',
  styleUrls: ['./snapshot-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SnapshotDialogComponent {
  loading$ = new Subject<boolean>();
  snapshots: Snapshot[] = [];

  constructor(private service: SnapshotService,
              private snackBar: SkwSnackBarService,
              private dialogService: SkwDialogService,
              private errorHandlerService: SkwErrorHandlerService,
              public dialogRef: MatDialogRef<SnapshotDialogComponent>) {
    this.listSnapshots();
  }

  listSnapshots() {
    this.service.listSnapshots()
      .pipe(indicate(this.loading$))
      .subscribe(r => this.snapshots = r, error => this.errorHandlerService.handleHttpError(error));
  }

  takeSnapshot() {
    this.dialogService.componentDialog(TakeSnapshotComponent)
      .afterClosed()
      .subscribe(result => {
        if (result === true) {
          this.listSnapshots();
        }
      });
  }

  deleteSnapshot(id: number) {
    this.dialogService.submitDialog('snapshot.submit-delete', 'danger')
      .subscribe(r => {
        if (r) {
          this.service.deleteSnapshot(id)
            .pipe(indicate(this.loading$))
            .subscribe(() => {
              this.listSnapshots();
              this.snackBar.showInfo('snapshot.deleted-success');
            }, error => this.errorHandlerService.handleHttpError(error));
        }
      });
  }

}
