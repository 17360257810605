import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {indicate, SkwErrorHandlerService, SkwSnackBarService} from 'skw-ui-components';
import {BehaviorSubject} from 'rxjs';
import {TargetingSettings, TargetingSettingsService} from '../targeting-settings.service';

@Component({
  selector: 'layer-setting',
  templateUrl: './layer-setting.component.html',
  styleUrls: ['./layer-setting.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayerSettingComponent implements OnInit {
  targetingSettings: { [key: string]: TargetingSettings[] };

  $loading = new BehaviorSubject(true);
  dirty = false;

  constructor(public service: TargetingSettingsService,
              private cdr: ChangeDetectorRef,
              private errorHandler: SkwErrorHandlerService,
              private snackBar: SkwSnackBarService) {
  }

  ngOnInit() {
    this.reloadTargetingSettings();
  }

  saveTargetingSettings() {
    this.service.saveAll(Object.values(this.targetingSettings).reduce((a, b) => a.concat(b), []))
      .pipe(indicate(this.$loading))
      .subscribe(() => this.snackBar.showInfo('messages.success-saved'), error => this.errorHandler.handleHttpError(error));
    this.dirty = false;
  }

  reloadTargetingSettings() {
    this.service.loadAll()
      .pipe(indicate(this.$loading))
      .subscribe(value => {
        this.targetingSettings = {};
        Object.keys(value).forEach(k => this.targetingSettings[k] = value[k].map(v => {
          return {...v};
        })); // deep copy object to make it editable
        this.cdr.markForCheck();
      }, error => this.errorHandler.handleHttpError(error));
  }
}
