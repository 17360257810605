import {ChangeDetectionStrategy, Component} from '@angular/core';
import {Observable, of, Subject, timer} from 'rxjs';
import {SimulationControlService, SimulationServiceStatus} from '../simulation-control/simulation-control.service';
import {catchError, map, switchMap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {indicate} from 'skw-ui-components';
import {SystemHealthStatusService} from '../../../system-status/header-toolbar-portal/system-health-status.service';

@Component({
  selector: 'app-simulation-toolbar',
  templateUrl: './simulation-toolbar.component.html',
  styleUrls: ['./simulation-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimulationToolbarComponent {

  loading$ = new Subject<boolean>();

  status$: Observable<SimulationServiceStatus> =
    timer(0, 15000)
      .pipe(
        // load simulation status on every time the refresh$ observable emits
        switchMap(
          () => this.service.status().pipe(catchError(() => {
            // return empty object in case of error
            return of({} as SimulationServiceStatus);
          }))
        )
      );

  plsNotReady$ = this.systemHealthStatusService.observable()
    .pipe(map(systemState => {
      let initState = true;
      systemState.forEach(state => {
        // both have to be either fully activated or UNKNOWN (when not even started)
        if (!((state.spsStatus === 'LOGISTIC_MODE' || state.spsStatus === 'UNKNOWN') &&
          (state.plsStatus === 'ACTIVE' || state.plsStatus === 'UNKNOWN'))) {
          initState = false;
        }
      });
      return !initState;
    }));

  constructor(private service: SimulationControlService,
              private systemHealthStatusService: SystemHealthStatusService,
              private router: Router) {
  }

  start(status: SimulationServiceStatus) {
    this.service.start(status.speed).pipe(indicate(this.loading$));
  }

  stop() {
    this.service.stop().pipe(indicate(this.loading$));
  }

  openSimPage() {
    this.router.navigateByUrl('/pages/simulation');
  }
}
