import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';

@Component({
  selector: 'app-background-drawer',
  templateUrl: './background-drawer.component.html',
  styleUrls: ['./background-drawer.component.scss']
})
export class BackgroundDrawerComponent implements AfterViewInit {

  @ViewChild('canvas') canvas: ElementRef<HTMLCanvasElement>;

  constructor() {
  }

  ngAfterViewInit() {
    const ctx = this.canvas.nativeElement.getContext('2d');
    ctx.lineWidth = 2;
    ctx.strokeStyle = '#808080';
    ctx.fillStyle = '#ffffff';
    ctx.beginPath();
    // E4
    ctx.moveTo(185, 10);
    ctx.lineTo(810, 360);
    ctx.lineTo(1300, 360);
    ctx.lineTo(675, 10);
    ctx.lineTo(185, 10);
    // E3
    ctx.moveTo(435, 150);
    ctx.lineTo(185, 150);
    ctx.lineTo(810, 500);
    ctx.lineTo(1300, 500);
    ctx.lineTo(1030, 360);
    // E2
    ctx.moveTo(435, 290);
    ctx.lineTo(185, 290);
    ctx.lineTo(810, 640);
    ctx.lineTo(1300, 640);
    ctx.lineTo(1030, 500);
    // E1
    ctx.moveTo(435, 430);
    ctx.lineTo(185, 430);
    ctx.lineTo(810, 780);
    ctx.lineTo(1300, 780);
    ctx.lineTo(1030, 640);
    ctx.stroke();
  }

}
