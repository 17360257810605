import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MdsCarbodyComponent} from './mds-carbody/mds-carbody.component';
import {MdsCodemappingComponent} from './mds-codemapping/mds-codemapping.component';
import {SkwLanguageModule} from 'skw-ui-translation';
import {SkwMasterDetailLayoutModule, SkwPageLayoutModule} from 'skw-ui-layout';
import {
  SkwAlertModule,
  SkwButtonModule,
  SkwCardModule,
  SkwCollapseModule,
  SkwEditableModule,
  SkwFormModule,
  SkwIconModule,
  SkwTableModule
} from 'skw-ui-components';
import {SkwAuthorizationModule} from 'skw-ui-authentication';
import {RouterModule} from '@angular/router';

@NgModule({
  declarations: [
    MdsCarbodyComponent,
    MdsCodemappingComponent,
  ],
  imports: [
    CommonModule,
    SkwLanguageModule,
    SkwPageLayoutModule,
    SkwButtonModule,
    SkwMasterDetailLayoutModule,
    SkwTableModule,
    SkwAlertModule,
    SkwFormModule,
    SkwEditableModule,
    SkwAuthorizationModule,
    SkwIconModule,
    SkwCardModule,
    RouterModule,
    SkwCollapseModule,
  ]
})
export class MdsModule {
}
