import {Component, Inject, OnInit} from '@angular/core';
import {SkwErrorHandlerService, SkwSelectModel, SkwSnackBarService} from 'skw-ui-components';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SkwApiService} from 'skw-ui-bootstrap';
import {SystemHealthStatusService} from '../../system-status/header-toolbar-portal/system-health-status.service';
import {Subscription} from 'rxjs';
import {SystemHealthStatus} from '../../system-status/header-toolbar-portal/system-health-status';

@Component({
  selector: 'app-release-overflow-carbodies-dialog',
  templateUrl: './release-overflow-carbodies-dialog.component.html',
  styleUrls: ['./release-overflow-carbodies-dialog.component.scss']
})
export class ReleaseOverflowCarbodiesDialogComponent implements OnInit {

  selectedLayer: string;
  layers: SkwSelectModel<string>[] = [
    {value: '280', viewValue: 'Ebene 2'},
    {value: '380', viewValue: 'Ebene 3'},
    {value: '480', viewValue: 'Ebene 4'}
  ];
  private $systemStatusSubscription: Subscription;

  constructor(private dialogRef: MatDialogRef<ReleaseOverflowCarbodiesDialogComponent>,
              private apiService: SkwApiService,
              private errorHandler: SkwErrorHandlerService,
              private snackBarService: SkwSnackBarService,
              private systemHealthStatusService: SystemHealthStatusService,
              @Inject(MAT_DIALOG_DATA) public data: string) {
    if (data) {
      this.selectedLayer = data;
    } else {
      this.$systemStatusSubscription = this.systemHealthStatusService.observable()
        .subscribe(v => {

          if (v == null) {
            return;
          }

          this.layers = [
            {
              value: '280',
              viewValue: 'Ebene 2',
              disabled: !this.canLayerReleaseOverflowItems(v.find(s => s.systemId === '280'))
            },
            {
              value: '380',
              viewValue: 'Ebene 3',
              disabled: !this.canLayerReleaseOverflowItems(v.find(s => s.systemId === '380'))
            },
            {
              value: '480',
              viewValue: 'Ebene 4',
              disabled: !this.canLayerReleaseOverflowItems(v.find(s => s.systemId === '480'))
            }
          ];
        });
    }

  }

  canLayerReleaseOverflowItems(v: SystemHealthStatus) {
    return v.plsStatus === 'ACTIVE' && v.ctrlLocks && v.ctrlLocks.length > 0;
  }

  ngOnInit() {
  }

  close(value: boolean) {
    if (value) {
      this.apiService.post(`/process/lock/${this.selectedLayer}/unlock-overflow-items`, null)
        .subscribe(result => {
            this.snackBarService.showInfo('messages.release-overflow-items.releasing');
            this.dialogRef.close({});
          },
          error => this.errorHandler.handleHttpError(error))
    } else {
      this.dialogRef.close({});
    }
  }
}
