import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RuleTemplateKnrLockComponent} from './rule-template-knr-lock/rule-template-knr-lock.component';
import {RuleTemplateWizardComponent} from './rule-template-wizard.component';
import {
  SkwAlertModule,
  SkwButtonModule,
  SkwDialogModule,
  SkwEditableModule,
  SkwFormModule,
  SkwHintModule,
  SkwInputModule,
  SkwProcessModule
} from 'skw-ui-components';
import {SkwLanguageModule} from 'skw-ui-translation';

@NgModule({
  declarations: [
    RuleTemplateWizardComponent,
    RuleTemplateKnrLockComponent
  ],
  imports: [
    CommonModule,
    SkwHintModule,
    SkwDialogModule,
    SkwLanguageModule,
    SkwProcessModule,
    SkwFormModule,
    SkwInputModule,
    SkwButtonModule,
    SkwEditableModule,
    SkwAlertModule
  ],
  entryComponents: [
    RuleTemplateWizardComponent,
    RuleTemplateKnrLockComponent
  ]
})
export class RuleTemplatesModule {
}
