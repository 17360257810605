import {Injectable, TemplateRef} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RuleEditService {

  private version = 0;
  private $changeObservable = new BehaviorSubject(0);

  private alerts: TemplateRef<any>[] = [];
  private alertsObservable = new BehaviorSubject<TemplateRef<any>[]>([]);

  addAlert(template: TemplateRef<any>) {
    this.alerts.push(template);
    this.alertsObservable.next(this.alerts);
  }

  removeAlert(template: TemplateRef<any>) {
    this.alerts = this.alerts.filter(a => a !== template);
    this.alertsObservable.next(this.alerts);
  }

  alertObservable() {
    return this.alertsObservable.asObservable();
  }

  /** Emit an update, that all listening components are rerendered. */
  emitUpdateRule() {
    this.version++;
    this.$changeObservable.next(this.version);
  }

  /** Listen for rule edit changes. */
  changeObservable() {
    return this.$changeObservable.asObservable();
  }

}
