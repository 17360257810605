import {Component, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {
  DebounceHelper,
  indicate,
  SkwDialogService,
  SkwErrorHandlerService,
  SkwSelectModel,
  SkwSnackBarService,
  TableSelectRequest
} from 'skw-ui-components';
import {MasterDetailComponent} from 'skw-ui-layout';
import {MdsCodeMapping, MdsFieldDefinition, MdsResultSet, MdsService} from '../mds.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-mds-codemapping',
  templateUrl: './mds-codemapping.component.html',
  styleUrls: ['./mds-codemapping.component.scss']
})
export class MdsCodemappingComponent implements OnInit {

  loading$ = new Subject<boolean>();
  mappings: MdsCodeMapping[];
  mappingsResultSize: number;
  mappingsResultTooLargeError = false;
  createNewMode = false;
  mdsFieldId: number;

  // form model
  mdsCodeMapping: MdsCodeMapping;
  searchString: string;

  mdsField: MdsFieldDefinition = new MdsFieldDefinition();
  attributeValues: SkwSelectModel<string>[] = [];
  @ViewChild('masterDetail') layout: MasterDetailComponent<MdsCodeMapping>;
  private searchDebounce: DebounceHelper<string>;

  constructor(private router: Router,
              public route: ActivatedRoute,
              private mdsService: MdsService,
              private snackBar: SkwSnackBarService,
              private errorHandlerService: SkwErrorHandlerService,
              private dialogService: SkwDialogService) {
    this.searchDebounce = new DebounceHelper<string>(v => {
      this.searchString = v;
      this.loadAttributes();
    });
    this.mdsFieldId = Number(this.route.snapshot.paramMap.get('id'));
    this.searchDebounce.configureDebounceMillis(300);
  }

  ngOnInit() {
    this.loadAttributes();
    this.loadField();
  }

  onSelect($event: TableSelectRequest) {
    const element: MdsCodeMapping = $event.last;
    this.createNewMode = false;
    if (!element) {
      this.closeDetail();
    } else {
      this.openDetail(element);
    }
  }

  onNewClick() {
    this.createNewMode = true;
    this.openDetail({
      id: undefined,
      name: undefined,
      value: undefined,
      mdsField: this.mdsField.id
    } as MdsCodeMapping);
  }

  onSave() {
    if (!this.mdsCodeMapping.name) {
      this.snackBar.showError('Kein gültiger Name angegeben!');
      return;
    }
    if (!this.mdsCodeMapping.value) {
      this.snackBar.showError('Kein gültiger Code-Wert angegeben!');
      return;
    }

    if (this.createNewMode || this.mdsCodeMapping.id === undefined) {
      this.mdsService.addMapping(this.mdsCodeMapping)
        .subscribe(() => {
          this.closeDetail();
          this.loadAttributes();
          this.snackBar.showInfo('Wurde erfolgreich erstellt!');
        }, error => this.errorHandlerService.handleHttpError(error));
    } else {
      this.mdsService.updateMapping(this.mdsCodeMapping)
        .subscribe(() => {
          this.closeDetail();
          this.loadAttributes();
          this.snackBar.showInfo('Wurde erfolgreich geändert!');
        }, error => this.errorHandlerService.handleHttpError(error));
    }
  }

  onDelete() {
    this.dialogService.submitDialog('pages.mds.submitDeleteMappingMsg', 'danger', {
      name: this.mdsCodeMapping.name,
      value: this.mdsCodeMapping.value
    })
      .subscribe(result => {
        if (result) {
          this.mdsService.removeMapping(this.mdsCodeMapping)
            .subscribe(() => {
              this.closeDetail();
              this.loadAttributes();
              this.snackBar.showInfo('Wurde erfolgreich zurückgesetzt!');
            }, error => this.errorHandlerService.handleHttpError(error));
          this.closeDetail();
        }
      });
  }

  onAbort() {
    this.closeDetail();
  }

  onRefreshClick() {
    this.loadAttributes();
  }

  onFilter($event: string) {
    this.searchDebounce.debounceNext($event);
  }

  routeBack() {
    this.router.navigateByUrl('/pages/mds/carbody');
  }

  private loadAttributes() {
    this.mappings = undefined;
    this.mappingsResultTooLargeError = false;
    this.mdsService.listMappings(this.mdsFieldId, this.searchString)
      .pipe(indicate(this.loading$))
      .subscribe((a: MdsResultSet) => {
        if (a.tooLarge) {
          this.mappingsResultTooLargeError = true;
        }
        this.mappingsResultSize = a.size;
        this.mappings = a.result;
      }, error => this.errorHandlerService.handleHttpError(error));
  }

  private openDetail(element: MdsCodeMapping) {
    this.mdsCodeMapping = {...element};
    this.layout.openDetail(this.mdsCodeMapping);
  }

  private closeDetail() {
    this.mdsCodeMapping = undefined;
    this.layout.closeDetail();
  }

  private loadField() {
    this.mdsService.getAttribute(this.mdsFieldId)
      .pipe(indicate(this.loading$))
      .subscribe(a => {
        this.mdsField = a;
        this.loadAttributeValues();
      }, error => this.errorHandlerService.handleHttpError(error));
  }

  private loadAttributeValues() {
    this.mdsService.listAttributeValues(this.mdsField.entity, this.mdsField.name)
      .subscribe(a => {
        this.attributeValues = a.map(attr => {
          const v = typeof attr === 'undefined' ? '' : attr.toString();
          return {
            value: v,
            viewValue: v
          } as SkwSelectModel<string>;
        });
      });
  }
}

