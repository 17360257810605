import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SkwWebsocketModule} from 'skw-ui-messaging';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SkwWebsocketModule
  ]
})
export class WebsocketEventModule { }
