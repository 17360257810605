import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ErrorNotification, ErrorNotificationPage} from '../notification';
import {BehaviorSubject, Subscription} from 'rxjs';
import {indicate, SkwDialogService, SkwStatusColor} from 'skw-ui-components';
import {INotificationCenterService} from './notification-center-service.interface';

@Component({
  selector: 'app-notification-center',
  templateUrl: './notification-center.component.html',
  styleUrls: ['./notification-center.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationCenterComponent implements OnInit, OnDestroy {

  notifications: ErrorNotificationPage;
  page = 0;
  moreToLoad: number;
  $loading = new BehaviorSubject<boolean>(true);
  private $subscription: Subscription;

  constructor(public dialogRef: MatDialogRef<NotificationCenterComponent>,
              private cdr: ChangeDetectorRef,
              private dialogService: SkwDialogService,
              @Inject(MAT_DIALOG_DATA) private fssNotificationService: INotificationCenterService) {
  }

  markAsRead(index: number, el: ErrorNotification) {
    el.unread = false;
    this.notifications.unreadNotifications--;
    this.fssNotificationService.markAsRead(el)
      .subscribe(r => {
        if (!r) {
          // if request failed, we just reset this notification
          el.unread = true;
          this.notifications.unreadNotifications++;
          this.cdr.detectChanges();
        }
      });
  }

  markAllAsRead() {
    this.dialogService.submitDialog('pages.notification.submit-mark-all-read', 'danger')
      .subscribe(r => {
        if (r) {
          // Save old values to allow reset if request fails.
          const oldUnread = this.notifications.unreadNotifications;
          const oldNotifications = this.notifications.content;
          this.notifications.unreadNotifications = 0;
          this.notifications.content = this.notifications.content.map(e => {
            const copy = {...e};
            copy.unread = false;
            return copy;
          });
          this.cdr.markForCheck();
          this.fssNotificationService.markAllAsRead()
            .subscribe(res => {
              if (!res) {
                // reset everything if request failed
                this.notifications.unreadNotifications = oldUnread;
                this.notifications.content = oldNotifications;
                this.cdr.markForCheck();
              }
            });
        }
      });
  }

  loadNotifications() {
    this.fssNotificationService.getNotifications(this.page)
      .pipe(indicate(this.$loading))
      .subscribe(n => {
        this.notifications = n;
        this.page = n.number;
        this.cdr.detectChanges();
      });
  }

  ngOnInit(): void {
    this.$subscription = this.fssNotificationService.getMoreToLoadObservable()
      .subscribe(n => {
        this.moreToLoad = n;
        this.cdr.detectChanges();
      });
    this.loadNotifications();
  }

  ngOnDestroy(): void {
    this.$subscription.unsubscribe();
  }

  pageChange($event: number) {
    this.page = $event;
    this.loadNotifications();
  }

  badgeColor(severity: string): SkwStatusColor {
    const s = severity ? severity.toUpperCase() : '';
    switch (s) {
      case 'ERROR':
        return 'danger';
      case 'ERROR_RECOVERABLE':
      case 'WARN':
        return 'warning';
      default:
        return 'primary';
    }
  }
}
