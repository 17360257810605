import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Action, EventType, Operand} from '../../condition-model';
import {SkwSelectModel} from 'skw-ui-components';
import {RulesService} from '../../../rules.service';
import {Subscription} from 'rxjs';
import {RuleEditService} from '../../rule-edit.service';

@Component({
  selector: 'app-rule-action-add-allowed-outlets',
  templateUrl: './rule-action-add-allowed-outlets.component.html',
  styleUrls: ['./rule-action-add-allowed-outlets.component.scss']
})
export class RuleActionAddAllowedOutletsComponent implements OnInit, OnDestroy {
  @Input() editMode: boolean;
  @Input() triggerContext: EventType;

  $subscription: Subscription;

  _action: Action;

  _propValueIdx: number;

  @Input()
  set action(action: Action) {
    this._action = action;

    this._propValueIdx = (action.actionType.parameters as any).outlets.ordinal;
  }


  @Output() actionChange: EventEmitter<Action> = new EventEmitter<Action>();

  moduleIds: SkwSelectModel<string>[];

  constructor(private rulesService: RulesService,
              private ruleEditService: RuleEditService,
              private cdr: ChangeDetectorRef) {
  }

  ngOnDestroy() {
    this.$subscription.unsubscribe();
  }

  ngOnInit() {
    this.rulesService.getItemFlowPropValues('outlet')
      .subscribe(result => this.moduleIds = result.map(r => {
        return {
          value: r,
          viewValue: r
        } as SkwSelectModel<string>;
      }));
    this.$subscription = this.ruleEditService.changeObservable()
      .subscribe(s => {
        this.action = this._action;
      });
  }

  operandChange($event: Operand) {
    this._action.operands[this._propValueIdx] = $event;
    this.ruleEditService.emitUpdateRule();
    this.actionChange.emit(this._action);
  }
}
