import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-sequence-info-item-details',
  templateUrl: './sequence-info-item-details.component.html',
  styleUrls: ['./sequence-info-item-details.component.scss']
})
export class SequenceInfoItemDetailsComponent implements OnInit {

  @Input()
  selectedMove: any;
  collapsed = true;

  constructor() {
  }

  ngOnInit() {
  }

}
