import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';

/**
 * This pipe is only used to convert date strings e.g. 2021-02-01T11:28:03.28871+01:00[Europe/Berlin]
 * into a readable date. If the given string isn't long enough or does not represent a date, this
 * pipe only returns the input value. Internally the angular core {@link DatePipe} is used to handle
 * timezones.
 */
@Pipe({
  name: 'dateString'
})
export class DateStringPipe implements PipeTransform {

  constructor(private datePipe: DatePipe) {
  }

  transform(value: any, args?: any): any {
    if (typeof value !== 'string' || value.length < 25) {
      return value;
    }
    const iso = value.substr(0, 19);
    const timezone = value.substr(value.indexOf('+', 19), 6);
    const parsed = Date.parse(iso + timezone);
    if (isNaN(parsed)) {
      return value;
    }
    return this.datePipe.transform(new Date(parsed), 'dd.MM.yyyy HH:mm:ss');
  }

}
