import {Directive, ElementRef, Input, Renderer2} from '@angular/core';

@Directive({
  selector: '[appModuleSize]'
})
export class ModuleSizeDirective {

  @Input() set appModuleSize(value: number) {
    if (value >= 0) {
      this.renderer.setStyle(this.elementRef.nativeElement, 'min-width', `${value}px`);
      this.renderer.setStyle(this.elementRef.nativeElement, 'max-width', `${value}px`);
    }
  }

  constructor(private renderer: Renderer2,
              private elementRef: ElementRef) {
  }

}
