import {Component, OnDestroy} from '@angular/core';
import {
  SidebarService,
  SkwAuthNavigationItem,
  SkwChildNavigationItem,
  SkwExternalNavigationItem,
  SkwGroupNavigationItem,
  SkwNavigationItem
} from 'skw-ui-layout';
import {SystemHealthStatusService} from '../system-status/header-toolbar-portal/system-health-status.service';
import {Subscription} from 'rxjs';
import {SimulationControlService} from './simulation/simulation-control/simulation-control.service';
import {SkwApiService} from 'skw-ui-bootstrap';

@Component({
  selector: 'app-pages',
  template: '<skw-layout></skw-layout>',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnDestroy {

  // These are initial navigation entries. They are updated
  // dynamically --> see constructor
  private topNavigation = [
    new SkwNavigationItem(
      'navigation.home',
      '/pages/home',
      'fa fa-home'
    ),
    new SkwGroupNavigationItem(
      'navigation.layers',
      'fa fa-layer-group',
      false // group should be always open and not collapsible
      // all children are appended dynamically with health status check see in constructor
    ),
    new SkwAuthNavigationItem(
      'navigation.rules',
      '/pages/rules',
      'fa fa-stream',
      ['rule-read']
    ),
    new SkwNavigationItem(
      'navigation.search',
      '/pages/carbodysearch',
      'fa fa-search'
    ),
    new SkwNavigationItem(
      'navigation.kvs',
      '/pages/kvs',
      'fa fa-palette'
    )
  ];

  private bottomNavigation = [
    new SkwAuthNavigationItem(
      'navigation.mds.carbody',
      '/pages/mds/carbody',
      'fa fa-database',
      ['mds-read', 'mds-edit']
    ),
    new SkwAuthNavigationItem(
      'navigation.settings',
      '/pages/settings/purpose',
      'fa fa-cog',
      ['settings-edit']
    ),
    new SkwExternalNavigationItem(
      'navigation.help',
      '/help/Benutzerhandbuch/Einfuehrung.html',
      'fa fa-question-circle'
    )
  ];

  private $healthStatusSubscription: Subscription;
  private $simulationServiceSubscription: Subscription;

  constructor(private simulationService: SimulationControlService,
              private systemHealthStatusService: SystemHealthStatusService,
              private sidebarService: SidebarService,
              private apiService: SkwApiService) {
    // inflate navigation items initially
    this.sidebarService.setTopNavigation(this.topNavigation);
    this.sidebarService.setBottomNavigation(this.bottomNavigation);

    // find parent element for dynamic layers
    const navParentElement = this.topNavigation.find(n => n.title === 'navigation.layers');
    // update system entries according to their health status
    this.$healthStatusSubscription = systemHealthStatusService.observable()
      .subscribe(s => {
        // empty navigation entries first (with splice because they are readonly)
        navParentElement.children.splice(0, navParentElement.children.length);
        if (!s) {
          // request failed
          return;
        }
        s.forEach(c => {
          const navEntry = new SkwChildNavigationItem(
            `navigation.overview.${c.systemId}`,
            `/pages/dashboard/${c.systemId}`
          );
          navEntry.disabled = c.plsStatus === 'UNKNOWN';
          // append system entries
          navParentElement.children.push(navEntry);
        });
        this.sidebarService.setTopNavigation([...this.topNavigation]);
      });

    // check for available simulation and append nav entry
    this.$simulationServiceSubscription = simulationService.isSimulationActive()
      .subscribe(r => {
        if (r) {
          // append navigation entry if simulation is active
          this.topNavigation = [
            ...this.topNavigation,
            new SkwAuthNavigationItem(
              'navigation.simulation',
              '/pages/simulation',
              'fa fa-play-circle',
              ['simulation'])
          ];
          this.sidebarService.setTopNavigation(this.topNavigation);
        } else {
          // remove navigation entry if simulation isn't active
          this.topNavigation = this.topNavigation.filter(n => n.link !== '/pages/simulation');
          this.sidebarService.setTopNavigation(this.topNavigation);
        }
      });

    this.apiService.getBootstrap().subscribe(
      bootstrap => {
        var grafanaLink = bootstrap.getParameter('grafana-url') as string;
        if (grafanaLink) {
          this.bottomNavigation.push(
            new SkwExternalNavigationItem(
              'navigation.grafana',
              grafanaLink,
              'fa fa-chart-area'
            ));
        }

        var kibanaLink = bootstrap.getParameter('kibana-url') as string;
        if (kibanaLink) {
          this.bottomNavigation.push(
            new SkwExternalNavigationItem(
              'navigation.kibana',
              kibanaLink,
              'fa fa-file',
              ['module-admin']
            ));
        }
      });
  }

  ngOnDestroy(): void {
    this.$healthStatusSubscription.unsubscribe();
    this.$simulationServiceSubscription.unsubscribe();
  }

}
