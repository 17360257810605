import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {indicate, SkwErrorHandlerService, SkwSnackBarService} from 'skw-ui-components';
import {BehaviorSubject} from 'rxjs';
import {SequencingSettings, SequencingSettingsService} from '../sequencing-settings.service';

@Component({
  selector: 'sequencing-setting',
  templateUrl: './sequencing-setting.component.html',
  styleUrls: ['./sequencing-setting.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SequencingSettingComponent implements OnInit {
  sequencingSettings: { [key: string]: SequencingSettings[] };

  $loading = new BehaviorSubject(true);
  dirty = false;
  syncedSettings = false;

  constructor(public service: SequencingSettingsService,
              private cdr: ChangeDetectorRef,
              private errorHandler: SkwErrorHandlerService,
              private snackBar: SkwSnackBarService) {
  }

  ngOnInit() {
    this.reloadTargetingSettings();
  }

  saveSequencingSettings() {
    if (this.sequencingSettings[this.service.SETTINGS_SYNCED] === undefined) {
      this.sequencingSettings[this.service.SETTINGS_SYNCED] = [new SequencingSettings()];
      this.sequencingSettings[this.service.SETTINGS_SYNCED][0].settingKey = this.service.SETTINGS_SYNCED;
    }
    this.sequencingSettings[this.service.SETTINGS_SYNCED][0].value = String(this.syncedSettings);

    this.service.saveAll(Object.values(this.sequencingSettings).reduce((a, b) => a.concat(b), []))
      .pipe(indicate(this.$loading))
      .subscribe(() => this.snackBar.showInfo('messages.success-saved'), error => this.errorHandler.handleHttpError(error));
    this.dirty = false;
  }

  colorBlocksSettingChanged(colorBlocks: number) {
    this.dirty = true;
    if (this.syncedSettings) {
      this.sequencingSettings[this.service.PKNR][0].value = 2 - colorBlocks;
    }
  }

  toggleSync() {
    this.syncedSettings = !this.syncedSettings;
    if (this.syncedSettings) {
      this.dirty = true;
      this.sequencingSettings[this.service.PKNR][0].value = 2 - (this.sequencingSettings[this.service.COLOR_BLOCKS][0].value as number);
    }
  }

  reloadTargetingSettings() {
    this.service.loadAll()
      .pipe(indicate(this.$loading))
      .subscribe(value => {
        this.sequencingSettings = {};
        Object.keys(value).forEach(k => this.sequencingSettings[k] = value[k].map(v => {
          return {...v};
        })); // deep copy object to make it editable
        if (this.sequencingSettings[this.service.SETTINGS_SYNCED] !== undefined
          && this.sequencingSettings[this.service.SETTINGS_SYNCED][0].value === 'true') {
          this.syncedSettings = true;
        }
        this.cdr.markForCheck();
        console.log(this.sequencingSettings[this.service.SETTINGS_SYNCED]);
      }, error => this.errorHandler.handleHttpError(error));

  }

  loadDefaults() {
    this.dirty = true;
    this.syncedSettings = true;
    this.sequencingSettings[this.service.COLOR_BLOCKS][0].value = 1.0;
    this.sequencingSettings[this.service.PKNR][0].value = 1.0;
    this.sequencingSettings[this.service.TEAR_OFF_PREVENTION][0].value = 1.0;
    this.sequencingSettings[this.service.ORDER_STABILITY][0].value = 1.0;
  }
}
