import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TakeSnapshotComponent} from './take-snapshot/take-snapshot.component';
import {SkwButtonModule, SkwDialogModule, SkwFormModule, SkwHintModule, SkwInputModule, SkwLoadingModule} from 'skw-ui-components';
import {SkwLanguageModule} from 'skw-ui-translation';
import { SnapshotDialogComponent } from './snapshot-dialog/snapshot-dialog.component';

@NgModule({
  declarations: [TakeSnapshotComponent, SnapshotDialogComponent],
  imports: [
    CommonModule,
    SkwDialogModule,
    SkwButtonModule,
    SkwLanguageModule,
    SkwLoadingModule,
    SkwHintModule,
    SkwInputModule,
    SkwFormModule
  ],
  entryComponents: [TakeSnapshotComponent, SnapshotDialogComponent]
})
export class SnapshotModule {
}
