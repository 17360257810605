import {Injectable} from '@angular/core';
import {RuleTemplate, RuleTemplateWizardConfig} from './rule-template';
import {SkwDialogService} from 'skw-ui-components';
import {RuleTemplateKnrLock} from './rule-template-knr-lock/rule-template-knr-lock.component';
import {RuleTemplateWizardComponent} from './rule-template-wizard.component';

@Injectable({
  providedIn: 'root'
})
export class RuleTemplateWizardService {

  private readonly templates: RuleTemplate<any>[] = [
    new RuleTemplateKnrLock()
  ];

  constructor(private dialogService: SkwDialogService) {
  }

  listTemplates(): { templateName: string }[] {
    return this.templates;
  }

  /**
   * Open the template wizard with the passed template by name and the given data.
   *
   * @param config a specialised config for each template type
   */
  openTemplateWizard<T extends RuleTemplateWizardConfig>(config: T) {
    const template = this.templates.find(t => t.templateName === config.templateName);
    if (template) {
      this.dialogService.componentDialog(RuleTemplateWizardComponent, {
        data: {template, data: config.data},
        disableClose: true
      });
    }
  }

}
