import {Directive, ElementRef, Input, Renderer2} from '@angular/core';

@Directive({
  selector: '[appModulePos]'
})
export class ModulePosDirective {

  @Input() set appModulePos(value: [number, number]) {
    this.renderer.setStyle(this.elementRef.nativeElement, 'top', `${value[0]}px`);
    this.renderer.setStyle(this.elementRef.nativeElement, 'left', `${value[1]}px`);
  }

  constructor(private renderer: Renderer2,
              private elementRef: ElementRef) {
  }

}
