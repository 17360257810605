import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, Pipe, PipeTransform} from '@angular/core';
import {FileItem, FileLikeObject, FileUploader} from 'ng2-file-upload';
import {FileUploaderOptions, FilterFunction, Headers} from 'ng2-file-upload/file-upload/file-uploader.class';
import {SkwSnackBarService} from 'skw-ui-components';

/** File upload configuration. */
export interface SkwFileUploadOptions {
  url: string;
  multiple: boolean;
  allowedMimeType?: string[];
  /** This filter checks for allowed file extensions. */
  allowedFileExtensions?: string[];
  // allowedFileType?: string[]; => we don't use this property in our api
  autoUpload?: boolean;
  isHTML5?: boolean;
  filters?: FilterFunction[];
  headers?: Headers[];
  method?: string;
  authToken?: string;
  /** Max allowed file size in bytes. */
  maxFileSize?: number;
  queueLimit?: number;
  removeAfterUpload?: boolean;
  disableMultipart?: boolean;
  itemAlias?: string;
  authTokenHeader?: string;
  additionalParameter?: {
    [key: string]: any;
  };
  parametersBeforeFiles?: boolean;
  formatDataFunction?: () => any;
  formatDataFunctionIsAsync?: boolean;
}

@Component({
  selector: 'skw-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SkwFileUploadComponent {

  /** Emits if a file is selected or removed. Returns the current queue size. */
  @Output() fileSelected = new EventEmitter<number>();
  @Output() response = new EventEmitter<any>();
  @Output() failure = new EventEmitter<any>();
  @Output() customAddingFileFailed = new EventEmitter<any>();

  uploader: FileUploader;
  _hasBaseDropZoneOver = false;
  private _options: FileUploaderOptions;
  _multiple = false;
  _allowedFileExtensions;

  constructor(private cdr: ChangeDetectorRef,
              private snackBar: SkwSnackBarService) {
  }

  @Input()
  set config(options: SkwFileUploadOptions) {
    if (typeof options === 'undefined') {
      return;
    }
    const o: FileUploaderOptions = {...options};
    // our multiple property
    this._multiple = options.multiple;
    if (!this._multiple) {
      o.queueLimit = 1;
    }
    // add our custom filters
    if (typeof o.filters === 'undefined') {
      o.filters = []; // for adding filters later
    }
    if (typeof options.allowedFileExtensions !== 'undefined') {
      this._allowedFileExtensions = options.allowedFileExtensions;
      o.filters.push({
        name: 'extensions',
        fn: (item: any): boolean => {
          if (!item.name) {
            return false;
          }
          const fileExtension = item.name.slice(item.name.lastIndexOf('.') + 1).toLowerCase();
          return options.allowedFileExtensions.find(i => i.toLowerCase() === fileExtension) !== undefined;
        }
      });
    }

    this._options = o;
    this.reset();
  }

  /** Upload all selected files in queue! */
  public uploadAll() {
    if (!this._options) {
      throw Error('You have to pass the [config] attribute to skw-file-upload!');
    }
    this.uploader.uploadAll();
  }

  /** Reset the upload input field and the uploader. */
  public reset() {
    if (this.uploader) {
      this.uploader.cancelAll();
      this.uploader.clearQueue();
      this.uploader.destroy();
    }
    this.uploader = new FileUploader(this._options);
    this.uploader.response.subscribe(r => this.response.emit(JSON.parse(r)), error => this.failure.emit(error));
    this.uploader.onWhenAddingFileFailed = (item, filter, options) => this._onWhenAddingFileFailed(item, filter, options);
    this.cdr.detectChanges();
  }

  /** Called when adding item failed because of specified limitations e.g. max upload size. */
  _onWhenAddingFileFailed(item: FileLikeObject, filter: any, options: FileUploaderOptions) {
    if (this.customAddingFileFailed.observers.length > 0) {
      this.customAddingFileFailed.emit({item, filter, options});
      return;
    }
    switch (filter.name) {
      case 'fileSize':
        this.snackBar.showError('file-upload.errors.fileSize',
          {size: skwFormatFileSize(item.size), maxFileSize: skwFormatFileSize(options.maxFileSize)});
        break;
      case 'fileType':
      case 'mimeType':
        this.snackBar.showError('file-upload.errors.mimeType',
          {type: item.type, allowedTypes: options.allowedMimeType.join(',')});
        break;
      case 'extensions':
        this.snackBar.showError('file-upload.errors.extensions', {allowedExtensions: this._allowedFileExtensions.join(',')});
        break;
      case 'queueLimit':
        this.snackBar.showError('file-upload.errors.queueLimit', {queueLimit: options.queueLimit});
        break;
      default:
        this.snackBar.showError(`[skw-file-upload] Unknown error (filter is ${filter.name})`);
    }
  }

  _fileOverBase(e: any): void {
    this._hasBaseDropZoneOver = e;
  }

  _onFileSelected($event: File[]) {
    this.fileSelected.emit(this.uploader.queue.length);
  }

  _onError($event: any) {
    this.failure.emit($event);
  }

  _removeItem(item: FileItem) {
    item.remove();
    this.fileSelected.emit(this.uploader.queue.length);
  }
}

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param decimals Number of decimal places to display.
 *
 * @return Formatted string.
 */
function skwFormatFileSize(bytes, si = true, decimals = 2) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' Byte';
  }

  const units = si
    ? ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** decimals;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


  return bytes.toFixed(decimals) + ' ' + units[u];
}

@Pipe({
  name: 'skwFormatFileSize',
  pure: true
})
export class SkwFormatFileSizePipe implements PipeTransform {

  transform(value: number, options?: { decimals: number, si: boolean }): any {
    if (typeof options === 'undefined') {
      return skwFormatFileSize(value);
    }
    return skwFormatFileSize(value, options.si, options.decimals);
  }

}
