import {ChangeDetectorRef, Component, Inject, Injector} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialogRef} from '@angular/material';
import {RULE_MODEL, RuleTemplate, RuleTemplateModel, RuleTemplateWizardConfig, WIZARD_DATA} from './rule-template';
import {RulesService} from '../rules.service';
import {SkwErrorHandlerService, SkwSelectModel, SkwSnackBarService} from 'skw-ui-components';
import {RuleTemplateWizardValidationService} from './rule-template-wizard-validation.service';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-rule-template-wizard',
  templateUrl: './rule-template-wizard.component.html',
  styleUrls: ['./rule-template-wizard.component.scss']
})
export class RuleTemplateWizardComponent {

  templateModel: RuleTemplateModel;

  wizardTemplate: RuleTemplate<any>;
  wizardInjector: Injector;

  selectableRuleCategory$ = new Subject<SkwSelectModel<string>[]>();

  // no quotation marks are allowed here
  nameValidation: (v: string) => string[] = v => {
    const result = [];
    if (v.includes("\"")) {
      result.push('quotations-found');
    }
    return result;
  };

  constructor(private ruleService: RulesService,
              public validationService: RuleTemplateWizardValidationService,
              private dialogRef: MatDialogRef<RuleTemplateWizardComponent>,
              private injector: Injector,
              private cdr: ChangeDetectorRef,
              private errorHandlerService: SkwErrorHandlerService,
              private snackBar: SkwSnackBarService,
              private router: Router,
              @Inject(MAT_DIALOG_DATA) public data: { template: RuleTemplate<any>, data: RuleTemplateWizardConfig }) {

    this.templateModel = {} as RuleTemplateModel;
    this.wizardTemplate = data.template;
    this.wizardInjector = Injector.create({
      providers: [
        {provide: RULE_MODEL, useValue: this.templateModel},
        {provide: WIZARD_DATA, useValue: this.data}
      ],
      parent: this.injector
    });

    this.ruleService
      .listRuleCategories()
      .subscribe(ruleCategoryArray => {
        let ruleCategory = ruleCategoryArray.map(ruleCategory => {
          const skwSelectableRuleCategory: SkwSelectModel<string> = ({
            value: ruleCategory.categoryName,
            viewValue: ruleCategory.categoryName
          });
          return skwSelectableRuleCategory;
        });
        this
          .selectableRuleCategory$
          .next(ruleCategory);
      });
  }


  abort() {
    this.dialogRef.close();
  }

  createRule() {
    this.ruleService.getRuleDefinitionsModel().subscribe(rulesDefinitionModel => {
      this.ruleService.addRule(this.wizardTemplate.createRule(this.templateModel, rulesDefinitionModel))
        .subscribe(r => {
          if (this.templateModel.reevaluateLocks === true) {
            this.ruleService.reevaluateLock(r.id).subscribe(
              next => this.snackBar.showInfo(`Karossensperren wurden hinzugefügt`),
              error => this.errorHandlerService.handleHttpError(error)
            );
          }
          this.router.navigate(['/pages/rules/', r.id]);
          this.dialogRef.close();
        }, error => this.errorHandlerService.handleHttpError(error));
    });
  }
}
