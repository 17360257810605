import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {DashboardItemInfo, DashboardLayerInfo, HomeDashboardService} from './home-dashboard.service';
import {
  indicate,
  SkwDialogService,
  SkwErrorHandlerService,
  SkwSnackBarService,
  SkwStatusColor,
  TableSelectRequest
} from 'skw-ui-components';
import {BehaviorSubject} from 'rxjs';
import {ItemModalService} from '../../mfw-components/mfw-item/item-modal.service';
import {SkwUserService} from 'skw-ui-authentication';
import {ReleaseOverflowCarbodiesDialogComponent} from '../../audi-common/release-overflow-carbodies-dialog/release-overflow-carbodies-dialog.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent implements OnInit {

  lockedItems: DashboardItemInfo[] = [];
  layers: DashboardLayerInfo[] = [];

  $loading = new BehaviorSubject<boolean>(true);
  $user = this.userService.userObservable();
  errors: any[];
  activeColumns: string[];

  isGenericError = false;

  constructor(private userService: SkwUserService,
              private dashboardService: HomeDashboardService,
              private errorHandler: SkwErrorHandlerService,
              private itemModalService: ItemModalService,
              private cdr: ChangeDetectorRef,
              private dialogService: SkwDialogService,
              private snackBarService: SkwSnackBarService) {
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.dashboardService.getModel()
      .pipe(indicate(this.$loading))
      .subscribe(r => {
        this.layers = r.layers;
        this.lockedItems = r.lockedItems;
        this.errors = r.errors;
        this.isGenericError = r.genericError;
        this.cdr.markForCheck();
      }, error => this.errorHandler.handleHttpError(error));
  }

  onSelectItem($event: TableSelectRequest) {
    if ($event.selected.length !== 1) {
      return;
    }
    const selected = $event.selected[0];
    const system = this.lockedItems.find(l => l.itemId === selected).system;
    this.dashboardService.getItem(selected)
      .pipe(indicate(this.$loading))
      .subscribe(item => {
        this.itemModalService.openItemModal(item, system)
          .afterClosed().subscribe(() => this.loadData());
      });
  }

  badgeColor(severity: string): SkwStatusColor {
    const s = severity ? severity.toUpperCase() : '';
    switch (s) {
      case 'ERROR':
        return 'danger';
      case 'ERROR_RECOVERABLE':
      case 'WARN':
        return 'warning';
      default:
        return 'primary';
    }
  }

  releaseOverflowLocks() {
    this.dialogService.componentDialog(ReleaseOverflowCarbodiesDialogComponent, {
      data: null
    });
  }

  openErrorDialog() {
    this.dialogService.yesNoDialog('pages.home.generic-error.' + (this.isGenericError ? 'un' : '') + 'set-error-text',
      'pages.home.generic-error.' + (this.isGenericError ? 'un' : '') + 'set-error', 'danger', {}, {})
      .subscribe(result => {
        if (result) {
          this.dashboardService.setError(!this.isGenericError);
          this.snackBarService.showInfo('pages.home.generic-error.' + (this.isGenericError ? 'un' : '') + 'set-error-snack');
          this.isGenericError = !this.isGenericError;
          this.cdr.detectChanges();
        }
      });
  }
}
