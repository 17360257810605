import {Injectable} from '@angular/core';
import {SkwApiService} from 'skw-ui-bootstrap';
import {Observable, Subject} from 'rxjs';
import {indicate, SkwDialogService, SkwErrorHandlerService, SkwSnackBarService} from 'skw-ui-components';
import {MfwLock} from 'skw-ui-modules';

@Injectable({
  providedIn: 'root'
})
export class ItemProcessService {

  constructor(private apiService: SkwApiService,
              private snackBar: SkwSnackBarService,
              private dialogService: SkwDialogService,
              private errorHandler: SkwErrorHandlerService) {
  }

  unlockItem(layoutId: string, itemId: string, lockId: number): Observable<any> {
    return this.apiService.post(`/process/lock/${layoutId}/unlock`, undefined, {
      params: {
        itemId,
        lockId: `${lockId}`
      }
    });
  }

  relockItem(layoutId: string, itemId: string, lockId: number): Observable<any> {
    return this.apiService.post(`/process/lock/${layoutId}/relock`, undefined, {
      params: {
        itemId,
        lockId: `${lockId}`
      }
    });
  }

  doUnlockItem(layoutId: string, itemId: string, lock: MfwLock, $loading: Subject<boolean>): Observable<any> {
    const observeResult = new Subject();
    this.dialogService.submitDialog('item.process.item-unlock-dialog', 'danger', {
      id: itemId,
      lockReason: lock.reason
    })
      .subscribe(result => {
        if (result) {
          this.unlockItem(layoutId, itemId, lock.id)
            .pipe(indicate($loading))
            .subscribe(r => {
                this.snackBar.showInfo('item.process.item-unlocked');
                observeResult.next(r);
                observeResult.complete();
              },
              error => {
                this.errorHandler.handleHttpError(error);
                observeResult.error(error);
              });
        }
      });
    return observeResult.asObservable();
  }

  doRelockItem(layoutId: string, itemId: string, lock: MfwLock, $loading: Subject<boolean>): Observable<any> {
    const observeResult = new Subject();
    this.dialogService.submitDialog('item.process.item-relock-dialog', 'danger', {
      id: itemId,
      lockReason: lock.reason
    })
      .subscribe(result => {
        if (result) {
          this.relockItem(layoutId, itemId, lock.id)
            .pipe(indicate($loading))
            .subscribe(r => {
                this.snackBar.showInfo('item.process.item-relocked');
                observeResult.next(r);
                observeResult.complete();
              },
              error => {
                this.errorHandler.handleHttpError(error);
                observeResult.error(error);
              });
        }
      });
    return observeResult.asObservable();
  }

  doPrioritizeItem(layoutId: string, itemId: string, prioritized: boolean, $loading: Subject<boolean>) {
    return this.apiService.post(`/process/prioritize/${layoutId}`, undefined, {
      params: {
        itemId: itemId,
        prioritize: `${prioritized}`
      }
    });
  }
}
