import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-car-body-attrs-modal',
  templateUrl: './car-body-attrs-modal.component.html',
  styleUrls: ['./car-body-attrs-modal.component.scss']
})
export class CarBodyAttrsModalComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
  }
}
