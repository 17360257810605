import {Injectable} from '@angular/core';
import {SkwApiService} from 'skw-ui-bootstrap';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LaneFillingService {

  constructor(private api: SkwApiService) {
  }

  updateMode(server: string, laneFillingMode: boolean, unlockAllLaneExits: boolean): Observable<void> {
    return this.api.post<void>(`/process/lanefilling/${server}`, undefined, {
      params: {
        laneFillingModeStateToSet: `${laneFillingMode}`,
        unlockAllLaneExits: `${unlockAllLaneExits}`
      }
    });
  }

}
