import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CountFreeModulesCondition, IsModuleLockedCondition} from '../../condition-model';
import {SkwSelectModel} from 'skw-ui-components';

@Component({
  selector: 'app-rule-condition-count-free-modules',
  templateUrl: './rule-condition-count-free-modules.component.html',
  styleUrls: ['./rule-condition-count-free-modules.component.scss']
})
export class RuleConditionCountFreeModulesComponent {
  @Input() editMode: boolean;
  @Output() conditionChange = new EventEmitter<IsModuleLockedCondition>();

  _tagValues: SkwSelectModel<string>[] = [
    {value: 'WFBSpot', viewValue: 'item.attributeValues.WFBSpot'},
    {value: 'BYPASS', viewValue: 'item.attributeValues.BYPASS'}
  ];

  _lessOrEqualsValues: SkwSelectModel<boolean>[] = [
    {value: true, viewValue: 'pages.rules.edit.CountFreeModulesCondition.lessOrEquals'},
    {value: false, viewValue: 'pages.rules.edit.CountFreeModulesCondition.moreOrEquals'}
  ];


  _condition: CountFreeModulesCondition;

  @Input()
  set condition(cond: CountFreeModulesCondition) {
    this._condition = cond;
  }

}
