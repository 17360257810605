import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Action, EventType, LiteralOperand} from '../../condition-model';
import {Subscription} from 'rxjs';
import {RulesService} from '../../../rules.service';
import {RuleEditService} from '../../rule-edit.service';
import {SkwAutocompleteModel} from 'skw-ui-components';
import {MdsEntityAttribute, MdsFieldDefinition} from '../../../rule-definitions-model';
import {SkwTranslatableValue} from 'skw-ui-translation';

@Component({
  selector: 'app-rule-action-add-consecutive-forbidden-score-modifier',
  templateUrl: './rule-action-add-consecutive-forbidden-score-modifier.component.html',
  styleUrls: ['./rule-action-add-consecutive-forbidden-score-modifier.component.scss']
})
export class RuleActionAddConsecutiveForbiddenScoreModifierComponent implements OnInit, OnDestroy {

  @Input() editMode: boolean;
  @Input() triggerContext: EventType;

  $subscription: Subscription;

  _action: Action;

  _attributeIdFirst: number;
  _attributeIdFirstIdx: number;
  _valueFirst: any;
  _valueFirstIdx: any;
  _attributeIdSecond: number;
  _attributeIdSecondIdx: number;
  _valueSecond: any;
  _valueSecondIdx: any;

  mdsEntityAttributes: MdsFieldDefinition[];
  mdsEntityAttributeNames: SkwAutocompleteModel<MdsFieldDefinition>[];

  selectedAttributeFirst: MdsFieldDefinition;
  selectedAttributeSecond: MdsFieldDefinition;

  mdsAttributeValuesFirst: SkwAutocompleteModel<string>[];
  mdsAttributeValuesSecond: SkwAutocompleteModel<string>[];

  @Input()
  set action(action: Action) {
    this._action = action;

    this._attributeIdFirstIdx = (action.actionType.parameters as any).attributeIdFirst.ordinal;
    this._attributeIdFirst = (this._action.operands[this._attributeIdFirstIdx] as LiteralOperand).value as number;

    this._attributeIdSecondIdx = (action.actionType.parameters as any).attributeIdSecond.ordinal;
    this._attributeIdSecond = (this._action.operands[this._attributeIdSecondIdx] as LiteralOperand).value as number;

    this._valueFirstIdx = (action.actionType.parameters as any).valueFirst.ordinal;
    this._valueFirst = (this._action.operands[this._valueFirstIdx] as LiteralOperand).value;

    this._valueSecondIdx = (action.actionType.parameters as any).valueSecond.ordinal;
    this._valueSecond = (this._action.operands[this._valueSecondIdx] as LiteralOperand).value;

    this.rulesService.getRuleDefinitionsModel().subscribe(value => {
      this.mdsEntityAttributes = value.mdsEntityAttributes;
      this.mdsEntityAttributeNames = value.mdsEntityAttributes.map(fieldDefinition => {
        return {
          value: fieldDefinition,
          viewValue: {
            key: 'pages.mds.entityTypesPrefix.' + fieldDefinition.entityTypeName,
            args: {name: fieldDefinition.attributeName}
          } as SkwTranslatableValue
        } as SkwAutocompleteModel<MdsFieldDefinition>;
      });
      const selectModelFirst = this.mdsEntityAttributes.find(x => x.id === this._attributeIdFirst);
      this.selectedAttributeFirst = selectModelFirst ? selectModelFirst : undefined;
      const selectModelSecond = this.mdsEntityAttributes.find(x => x.id === this._attributeIdSecond);
      this.selectedAttributeSecond = selectModelSecond ? selectModelSecond : undefined;

      this.loadAttributeValues();
    });
  }


  @Output() actionChange: EventEmitter<Action> = new EventEmitter<Action>();

  constructor(private rulesService: RulesService,
              private ruleEditService: RuleEditService,
              private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.$subscription = this.ruleEditService.changeObservable().subscribe(s => {
      this.action = this._action;
    });
  }

  ngOnDestroy() {
    this.$subscription.unsubscribe();
  }

  private loadAttributeValues() {
    if (this.selectedAttributeFirst) {
      this.rulesService.getCarBodyAttributeValuesFromId(this.selectedAttributeFirst.entityTypeName, this.selectedAttributeFirst.id)
        .subscribe(r => {
          if (!r) {
            this.mdsAttributeValuesFirst = [];
            return;
          }
          this.mdsAttributeValuesFirst = r.map(a => {
            return {
              value: a.value.toString(),
              viewValue: a.viewValue
            };
          });
          this.cdr.markForCheck();
        });
    }
    if (this.selectedAttributeSecond) {
      this.rulesService.getCarBodyAttributeValues(this.selectedAttributeSecond.entityTypeName, this.selectedAttributeSecond.attributeName)
        .subscribe(r => {
          if (!r) {
            this.mdsAttributeValuesSecond = [];
            return;
          }
          this.mdsAttributeValuesSecond = r.map(a => {
            return {
              value: a.value.toString(),
              viewValue: a.viewValue
            };
          });
          this.cdr.markForCheck();
        });
    }
  }

  operandFirstAttrChange($event: MdsFieldDefinition) {
    this._attributeIdFirst = !$event ? undefined : $event.id;
    this.loadAttributeValues();

    (this._action.operands[this._attributeIdFirstIdx] as LiteralOperand).value = this._attributeIdFirst;
    this.ruleEditService.emitUpdateRule();
    this.actionChange.emit(this._action);
  }

  operandFirstValueChange($event: string) {
    this._valueFirst = $event;
    const selectModel = this.mdsAttributeValuesFirst.find(x => x.viewValue === this._valueFirst);
    const value = selectModel ? selectModel.value : $event;
    this.loadAttributeValues();

    (this._action.operands[this._valueFirstIdx] as LiteralOperand).value = value;
    this.ruleEditService.emitUpdateRule();
    this.actionChange.emit(this._action);

  }

  operandSecondAttrChange($event: MdsFieldDefinition) {
    this._attributeIdSecond = !$event ? undefined : $event.id;
    this.loadAttributeValues();

    (this._action.operands[this._attributeIdSecondIdx] as LiteralOperand).value = this._attributeIdSecond;
    this.ruleEditService.emitUpdateRule();
    this.actionChange.emit(this._action);
  }

  operandSecondValueChange($event: string) {
    this._valueSecond = $event;
    const selectModel = this.mdsAttributeValuesSecond.find(x => x.viewValue === this._valueSecond);
    const value = selectModel ? selectModel.value : $event;
    this.loadAttributeValues();

    (this._action.operands[this._valueSecondIdx] as LiteralOperand).value = value;
    this.ruleEditService.emitUpdateRule();
    this.actionChange.emit(this._action);
  }
}
