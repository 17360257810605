import {Injectable} from '@angular/core';
import {Rule, RulesService} from './rules.service';
import {DeleteRuleDialogComponent} from './rule-edit/delete-rule-dialog/delete-rule-dialog.component';
import {SkwDialogService} from 'skw-ui-components';
import {Subject} from 'rxjs';
import {
  ChangeRuleActiveStateDialogComponent
} from './rule-edit/change-rule-active-state-dialog/change-rule-active-state-dialog.component';

/**
 * This class contains business logic for complex processes e.g. deletion of a rule.
 * To avoid circular dependencies, this is separated from the {@link RulesService} which is called internally
 * as api service.
 */
@Injectable({
  providedIn: 'root'
})
export class RuleProcessService {

  constructor(private rulesService: RulesService,
              private dialogService: SkwDialogService) {
  }

  deleteRules(model: Rule[]) {
    const result = new Subject<boolean>();
    this.dialogService.componentDialog(DeleteRuleDialogComponent, {
      data: {rules: model},
      maxWidth: '500px'
    })
      .afterClosed()
      .subscribe(({deleteRule, requestColorAdmin}) => {
          if (deleteRule) {
            this.rulesService.removeRules(model.map(r => r.id), requestColorAdmin)
              .subscribe(() => {
                result.next(true);
                result.complete();
              }, error => result.error(error));
          } else {
            result.next(false);
            result.complete();
          }
        }
      );
    return result.asObservable();
  }



  changeRuleActiveState(model: Rule[], active: boolean) {
    const result = new Subject<boolean>();
    this.dialogService.componentDialog(ChangeRuleActiveStateDialogComponent, {
      data: {rules: model, activate: active},
      maxWidth: '500px'
    })
      .afterClosed()
      .subscribe(({apply}) => {
          if (apply) {
            this.rulesService.setEnabled(model.map(r => r.id), active)
              .subscribe(() => {
                result.next(true);
                result.complete();
              }, error => result.error(error));
          } else {
            result.next(false);
            result.complete();
          }
        }
      );
    return result.asObservable();
  }

}
