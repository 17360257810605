import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SkwFileUploadComponent, SkwFormatFileSizePipe} from './file-upload/file-upload.component';
import {FileUploadModule} from 'ng2-file-upload';
import {SkwLanguageModule} from 'skw-ui-translation';
import {SkwButtonModule} from 'skw-ui-components';

@NgModule({
  declarations: [
    SkwFileUploadComponent,
    SkwFormatFileSizePipe
  ],
  imports: [
    CommonModule,
    FileUploadModule,
    SkwLanguageModule,
    SkwButtonModule
  ],
  exports: [
    SkwFileUploadComponent
  ],
})
export class SkwFileUploadModule {
}
