import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {indicate, SkwErrorHandlerService, SkwSnackBarService} from 'skw-ui-components';
import {BehaviorSubject} from 'rxjs';
import {TargetingSettings, TargetingSettingsService} from '../targeting-settings.service';

@Component({
  selector: 'targeting-setting',
  templateUrl: './targeting-setting.component.html',
  styleUrls: ['./targeting-setting.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TargetingSettingComponent implements OnInit {


  targetingSettings: { [key: string]: TargetingSettings[] };

  rerouteOnSystemError: boolean;
  rerouteOnEmergencyMode: boolean;
  rerouteOnFillingLevel: boolean;
  rerouteOnLayerBlocked: boolean;
  rerouteOnLockedButWFBFull: boolean;

  $loading = new BehaviorSubject(true);
  dirty = false;

  private altTargetValues = {
    280: [
      {value: '380_LL', viewValue: 'DL3'}],
    380: [
      {value: '280_LL', viewValue: 'DL2'}],
    480: [
      {value: '280_LL', viewValue: 'DL2'},
      {value: '380_LL', viewValue: 'DL3'}]
  };

  public altSubTargetValues = {
    NoTarget: [
      {value: '280', viewValue: '280'},
      {value: '380', viewValue: '380'},
      {value: '480', viewValue: '480'}],
    280: [
      {value: '380', viewValue: '380'},
      {value: '480', viewValue: '480'}],
    380: [
      {value: '280', viewValue: '280'},
      {value: '480', viewValue: '480'}],
    480: [
      {value: '280', viewValue: '280'},
      {value: '380', viewValue: '380'}]
  };

  constructor(public service: TargetingSettingsService,
              private cdr: ChangeDetectorRef,
              private errorHandler: SkwErrorHandlerService,
              private snackBar: SkwSnackBarService) {
  }

  ngOnInit() {
    this.reloadTargetingSettings();
  }

  saveTargetingSettings() {
    this.targetingSettings[this.service.REROUTE_ON_FILLING_LEVEL][0].value = this.rerouteOnFillingLevel ? 'true' : 'false';
    this.targetingSettings[this.service.REROUTE_ON_LAYER_BLOCKED][0].value = this.rerouteOnLayerBlocked ? 'true' : 'false';
    this.targetingSettings[this.service.REROUTE_ON_LOCKED_BUT_WFB_FULL][0].value = this.rerouteOnLockedButWFBFull ? 'true' : 'false';

    this.dirty = false;
    this.service.saveAll(Object.values(this.targetingSettings).reduce((a, b) => a.concat(b), []))
      .pipe(indicate(this.$loading))
      .subscribe(() => this.snackBar.showInfo('messages.success-saved'), error => this.errorHandler.handleHttpError(error));
  }

  createSettingIfNotExists(key: string, defaultValue: string | number) {
    if (!this.targetingSettings[key]) {
      this.targetingSettings[key] = [{
        settingKey: key,
        value: defaultValue
      }];
    }
  }

  reloadTargetingSettings() {
    this.service.loadAll()
      .pipe(indicate(this.$loading))
      .subscribe(value => {
        this.targetingSettings = {};
        Object.keys(value).forEach(k => this.targetingSettings[k] = value[k].map(v => {
          return {...v};
        })); // deep copy object to make it editable
        this.rerouteOnFillingLevel = this.targetingSettings[this.service.REROUTE_ON_FILLING_LEVEL][0].value === 'true';
        this.rerouteOnLayerBlocked = this.targetingSettings[this.service.REROUTE_ON_LAYER_BLOCKED][0].value === 'true';
        this.rerouteOnLockedButWFBFull = this.targetingSettings[this.service.REROUTE_ON_LOCKED_BUT_WFB_FULL][0].value === 'true';
      });
  }
}
