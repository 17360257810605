import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LaneFillingDialogComponent} from './lane-filling/lane-filling-dialog.component';
import {SkwButtonModule, SkwDialogModule, SkwHintModule, SkwInputModule, SkwLoadingModule} from 'skw-ui-components';
import {SkwLanguageModule} from 'skw-ui-translation';
import {AcceptItemsComponent} from './accept-items/accept-items.component';
import {LockSupplyLineComponent} from './lock-supply-line/lock-supply-line.component';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
  declarations: [LaneFillingDialogComponent, AcceptItemsComponent, LockSupplyLineComponent],
  imports: [
    CommonModule,
    SkwInputModule,
    SkwButtonModule,
    SkwDialogModule,
    SkwLoadingModule,
    SkwLanguageModule,
    SkwHintModule,
    MatTooltipModule
  ],
  entryComponents: [LaneFillingDialogComponent, AcceptItemsComponent, LockSupplyLineComponent]
})
export class SystemModesModule {
}
