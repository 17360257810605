import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {indicate, SkwErrorHandlerService} from 'skw-ui-components';
import {LockSupplyLineService, SupplyLineLocks} from './lock-supply-line.service';

export class SupplyLineLockModel {
  system: string;
  bslNumber: string;
  in: ModuleLockModel;
  out: ModuleLockModel;
}

export class ModuleLockModel {
  module: string;
  locked: boolean;
}

@Component({
  selector: 'app-lock-supply-line',
  templateUrl: './lock-supply-line.component.html',
  styleUrls: ['./lock-supply-line.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LockSupplyLineComponent {
  loading$ = new BehaviorSubject<boolean>(true);


  model: SupplyLineLocks[] = [];

  supplyLines: SupplyLineLocks[] = [];
  bypassAndWFB: SupplyLineLocks[] = [];
  inLines: SupplyLineLocks[] = [];
  outLines: SupplyLineLocks[] = [];
  pvcReturn: SupplyLineLocks[] = [];

  he012: SupplyLineLocks[] = [];
  he112: SupplyLineLocks[] = [];
  he212: SupplyLineLocks[] = [];

  constructor(public dialogRef: MatDialogRef<LockSupplyLineComponent>,
              private service: LockSupplyLineService,
              private errorHandlerService: SkwErrorHandlerService,
              @Inject(MAT_DIALOG_DATA) public system: string) {

    this.service.getSupplyLines(this.system)
      .pipe(indicate(this.loading$))
      .subscribe(r => {

        this.supplyLines = r.filter(e => (e.moduleIn || e.moduleOut) && (e.lineName.indexOf('supplyLine') !== -1 || e.lineName.indexOf('returnline') !== -1 || e.lineName.indexOf('RB702') !== -1));
        this.bypassAndWFB = r.filter(e => (e.moduleIn || e.moduleOut) && (e.lineName.indexOf('bypass') !== -1 || e.lineName.indexOf('WFB') !== -1 || e.lineName.indexOf('VW038') !== -1));
        this.inLines = r.filter(e => (
          e.lineName.indexOf('RB002') !== -1
          || e.lineName.indexOf('RB006') !== -1
          || e.lineName.indexOf('RB010') !== -1
          || e.lineName.indexOf('RB944') !== -1
          || e.lineName.indexOf('HE938') !== -1
          || e.lineName.indexOf('VW014') !== -1));
        this.outLines = r.filter(e => (
          e.lineName.indexOf('Fueller') !== -1
          || e.lineName.indexOf('RB810') !== -1));

        this.pvcReturn = r.filter(e => (
          e.lineName.indexOf('RB902') !== -1
          || e.lineName.indexOf('RB936') !== -1));

        this.he012 = r.filter(e => (
          e.lineName.indexOf('HE012') !== -1));
        this.he112 = r.filter(e => (
          e.lineName.indexOf('HE112') !== -1));
        this.he212 = r.filter(e => (
          e.lineName.indexOf('HE212') !== -1));

        this.model = r;
      }, error => this.errorHandlerService.handleHttpError(error));
  }

  save() {
    this.service.updateSupplyLines(this.system, [...this.model])
      .pipe(indicate(this.loading$))
      .subscribe(() => this.dialogRef.close(), error => this.errorHandlerService.handleHttpError(error));
  }

  lockAll($event: boolean, segments: SupplyLineLocks[], side: string) {
    const lockMapping = m => {
      if (m.moduleIn && side === 'in') {
        m.moduleInLocked = $event;
      }
      if (m.moduleOut && side === 'out') {
        m.moduleOutLocked = $event;
      }
      if (m.moduleTransition) {
        m.moduleTransitionLocked = $event;
      }
      return m;
    };

    segments.forEach(lockMapping);
  }
}
