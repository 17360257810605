import {Injectable} from '@angular/core';
import {SkwApiService} from 'skw-ui-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class RuleImportService {
  constructor(private apiService: SkwApiService) {
  }

  importRules(importId: string, deleteUntouched: boolean) {
    return this.apiService.post('/process/managerules/rules/processImport', undefined, {
      params: {
        importId,
        deleteUntouched: `${deleteUntouched}`
      }
    });
  }
}
