import {Injectable} from '@angular/core';
import {SkwApiService} from "skw-ui-bootstrap";
import {BehaviorSubject, Observable} from "rxjs";
import {map} from "rxjs/operators";
import {MfwItemAttrValueService} from "skw-ui-modules";


export class MdsAttrMapping {
  /** Name of the field e.g. CarBody.ML */
  field: string;
  /** Display name for the value e.g. ML1 */
  displayName: string;
  /** Value of the field which should be replaced by display value e.g. 01 */
  value: string;
}

@Injectable({
  providedIn: 'root'
})
export class MfwItemAttrValueApiService implements MfwItemAttrValueService {

  private _mappings = new BehaviorSubject<{ [field: string]: { [value: string]: MdsAttrMapping } }>({});

  constructor(private api: SkwApiService) {
    this.refreshTranslations();
  }

  /**
   * Refresh the translations and emit update to all subscribed pipes.
   */
  refreshTranslations() {
    this.api.get<{ [field: string]: { [value: string]: MdsAttrMapping } }>('/process/mds/field/mappings')
      .subscribe(r => {
        this._mappings.next(r);
      });
  }

  /** A HOT observable translating the given attribute with the given value.
   *
   * @param key the attribute key
   * @param value the value to be translated
   */
  translateValue(key: string, value: string): Observable<string> {
    return this._mappings.pipe(
      map(mappings => {
        const field = mappings[key];
        if (!field) {
          return value;
        }
        const mapping = field[`${value}`];
        if (!mapping) {
          return value;
        }
        return mapping.displayName;
      })
    )
  }
}
