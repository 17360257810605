import {ChangeDetectionStrategy, Component} from '@angular/core';
import {LayerStatsService} from './layer-stats.service';
import {TargetingSettings, TargetingSettingsService} from '../../settings/targeting-settings.service';

@Component({
  selector: 'app-layer-stats',
  templateUrl: './layer-stats.component.html',
  styleUrls: ['./layer-stats.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayerStatsComponent {

  $stats = this.layerStatsService.asObservable();
  $settings = this.service.loadAll();

  constructor(private layerStatsService: LayerStatsService,
              private service: TargetingSettingsService) {
  }

  extractFillingLevel(system: string, settings: { [key: string]: TargetingSettings[] }): number {
    if (!settings) {
      return 100;
    }
    const key = this.service.FILLING_LEVEL + system;
    if (!settings[key] || !settings[key][0]) {
      return 100;
    }
    return settings[key][0].value as number;
  }

}
