import {Component} from '@angular/core';
import {RulesService} from '../rules/rules.service';
import {RuleDefinitionsModel} from '../rules/rule-definitions-model';
import {ParameterType} from '../rules/rule-edit/condition-model';

@Component({
  selector: 'app-translationcheck',
  templateUrl: './translationcheck.component.html',
  styleUrls: ['./translationcheck.component.scss']
})
export class TranslationcheckComponent {
  ruleDefinitionsModel: RuleDefinitionsModel;
  parameterTypes: ParameterType[];

  constructor(rulesService: RulesService) {
    rulesService.getRuleDefinitionsModel().subscribe(value => {
      this.ruleDefinitionsModel = value;

      // extract parameterTypes
      this.parameterTypes = [];
      for (const queryType of value.queryTypes) {
        for (const p in queryType.parameters) {
          this.parameterTypes.push(queryType.parameters[p]);
        }
      }
      for (const actionType of value.actionTypes) {
        for (const p in actionType.parameters) {
          this.parameterTypes.push(actionType.parameters[p]);
        }
      }


    });
  }


}
