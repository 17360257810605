import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Operand, ParameterType, QueryOperand, QueryType} from '../../condition-model';
import {deepCopy, SkwSelectModel} from 'skw-ui-components';
import {RulesService} from '../../../rules.service';
import {ParameterizedComponent} from '../../parameterized-component';

@Component({
  selector: 'app-rule-operand-query',
  templateUrl: './rule-operand-query.component.html',
  styleUrls: ['./rule-operand-query.component.scss']
})
export class RuleOperandQueryComponent extends ParameterizedComponent {
  @Input() editMode: boolean;
  @Output() operandChange: EventEmitter<QueryOperand> = new EventEmitter<QueryOperand>();

  _operand: QueryOperand;

  private previousQueryOperands = {};
  private oldQueryName: string;

  @Input()
  set operand(operand: QueryOperand) {
    this._operand = operand;
    this.oldQueryName = RuleOperandQueryComponent.queryName(operand.query);
    this.parameters = this.extractAndSortParameters(operand.query);
  }

  parameters: ParameterType[] = [];

  queryTypes: SkwSelectModel<QueryType>[];
  equalsFunction = (o1: QueryType, o2: QueryType) => (o1.providerName === o2.providerName && (o1.name === o2.name));

  constructor(private rulesService: RulesService) {
    super();

    // load actionTypes
    rulesService.getRuleDefinitionsModel().subscribe(value => {
      this.queryTypes = value.queryTypes.map(v => {
        return {
          value: v,
          viewValue: 'pages.rules.queryTypes.' + v.name + '.name'
        } as SkwSelectModel<QueryType>;
      });
    });
  }

  changeQueryType($event: QueryType) {
    this._operand.query = $event;
    this.parameters = this.extractAndSortParameters($event);

    const newQueryName = RuleOperandQueryComponent.queryName($event);

    // if there was a change, try to restore old values
    if (this.oldQueryName != newQueryName) {
      // save previous operands
      if (this.editMode) {
        this.previousQueryOperands[this.oldQueryName] = this._operand.params;
      }

      if (this.previousQueryOperands[newQueryName]) {
        // restore previous value if present
        this._operand.params = this.previousQueryOperands[newQueryName];
      } else {
        // create a new set of default values
        const result: Operand[] = [];
        this.fillOperandsWithDefaultValues(result, this.parameters);
        this._operand.params = result;
      }

      // for the next time
      this.operandChange.emit(deepCopy(this._operand));
      this.oldQueryName = newQueryName;
    }
  }

  private static queryName(query: QueryType): string {
    return query.providerName + query.name;
  }
}

