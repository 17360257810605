import {Directive, Input, TemplateRef} from '@angular/core';

@Directive({
  selector: '[mfwCustomItemParameter]'
})
export class CustomItemParameterDirective {

  @Input() mfwCustomItemParameter: string;

  constructor(public templateRef: TemplateRef<any>) {
  }

}
