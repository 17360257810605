import {Injectable} from '@angular/core';
import {WebsocketEventService} from '../../api/websocket/websocket-event.service';
import {SkwApiService} from 'skw-ui-bootstrap';
import {SequencerModel} from '../../api/websocket/sequencer-update-event';
import {BehaviorSubject, Observable, ReplaySubject, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SequencerService {
  private models: { [system: string]: ReplaySubject<SequencerModel> } = {};

  constructor(private wsService: WebsocketEventService,
              private apiService: SkwApiService) {
  }

  /** Get a hot observable for sequencer state. */
  getModelObservable(system: string): Observable<SequencerModel> {
    if (typeof this.models[system] === 'undefined') {
      // If we are not already observing the sequencer state
      // for a system, we initialize it now.
      const newSubject = new ReplaySubject<SequencerModel>(1);
      this.models[system] = newSubject;
      this.apiService.get<SequencerModel>(`/process/sequencer/${system}`)
        .subscribe(r => newSubject.next(r));
      this.wsService.onSequencerEvent()
        .subscribe(r => {
          if (r.payload.instance === system) {
            newSubject.next(r.payload);
          }
        });
    }
    return this.models[system].asObservable();
  }
}
