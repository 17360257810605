import {Component, EventEmitter, Input, Output} from '@angular/core';
import {EventAttributeOperand, EventType} from '../../condition-model';
import {SkwSelectModel} from 'skw-ui-components';
import {RulesService} from '../../../rules.service';

@Component({
  selector: 'app-rule-operand-eventattribute',
  templateUrl: './rule-operand-eventattribute.component.html',
  styleUrls: ['./rule-operand-eventattribute.component.scss']
})
export class RuleOperandEventattributeComponent {
  @Input() editMode: boolean;

  private _triggerContext: EventType;
  _eventAttributes: SkwSelectModel<string>[];

  @Input()
  set triggerContext(value: EventType) {
    this._triggerContext = value;
    this.calcAvailableAttributes();
  }

  @Input() operand: EventAttributeOperand;
  @Output() operandChange: EventEmitter<EventAttributeOperand> = new EventEmitter<EventAttributeOperand>();

  change($event: string) {
    this.operand.attributeName = $event;
    this.operandChange.emit(this.operand);
  }

  constructor(private rulesService: RulesService) {
  }

  calcAvailableAttributes() {
    if (!this._triggerContext) {
      return;
    }
    this.rulesService.getRuleDefinitionsModel().subscribe(value => {
      const trigger = value.eventTypes.find(t => t.name === this._triggerContext.name);

      this._eventAttributes = Object.values(trigger.supportedAttributes)
        .map(attr => {
          return {
            value: attr.name,
            viewValue: `pages.rules.eventParameters.${attr.name}`
          } as SkwSelectModel<string>;
        });
    });
  }
}
