import {Injectable} from '@angular/core';
import {MfwItem, MfwItemStatusLogicProvider, MfwModuleWithItemsModel, MfwStatusColor} from 'skw-ui-modules';
import {Observable, of} from 'rxjs';
import {delay, switchMap} from 'rxjs/operators';
import {UiSettingsService} from '../../pages/settings/ui-settings.service';

const STATUS_COLOR = 'warning';

/**
 * Implementation for displaying an item status on a module. This implements the {@link MfwItemStatusLogicProvider}
 * to mark items which are a long time on a module.
 */
@Injectable()
export class MfwItemStatusLogicProviderService implements MfwItemStatusLogicProvider {

  constructor(private uiSettings: UiSettingsService) {
  }

  /**
   * This function implements the logic for all item status colors. Current logic:
   * Mark the item with a 'danger' color if the item is on module a very long time
   * configured with MAX_SECONDS_ITEM_ON_MODULE_UNTIL_WARN in the ui settings DB.
   * @param module the module this item is on
   * @param item the item on the module to be colored
   */
  itemStatusColor(module: MfwModuleWithItemsModel, item: MfwItem): Observable<MfwStatusColor> {
    return this.uiSettings.getMaxSecondsItemOnModuleUntilWarn().pipe(switchMap(secondsUntilWarn => {
      if (typeof module.itemOnModuleSince === 'undefined') {
        return of(undefined);
      }
      const onModuleSince = new Date(module.itemOnModuleSince);
      const now = new Date();
      const expirationDelay = secondsUntilWarn * 1000 - (now.getTime() - onModuleSince.getTime());
      if (expirationDelay <= 0) {
        // The maximum time on module is expired. We can directly mark this item:
        return of(STATUS_COLOR as MfwStatusColor);
      }
      // Mark the item in future when the expiration time is reached:
      return of(STATUS_COLOR as MfwStatusColor).pipe(delay(expirationDelay));
    }));
  }

}
