import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {Snapshot, SnapshotService} from '../../snapshot/snapshot.service';
import {Subject} from 'rxjs';
import {indicate, SkwErrorHandlerService, SkwSnackBarService} from 'skw-ui-components';
import {switchMap} from 'rxjs/operators';
import {SimulationControlService} from '../simulation-control/simulation-control.service';

@Component({
  selector: 'app-simulation-snapshots',
  templateUrl: './simulation-snapshots.component.html',
  styleUrls: ['./simulation-snapshots.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimulationSnapshotsComponent implements OnInit {

  loading$ = new Subject<boolean>();
  snapshots: Snapshot[] = [];

  constructor(private snapshotService: SnapshotService,
              public simulationService: SimulationControlService,
              private errorHandlerService: SkwErrorHandlerService,
              private snackBar: SkwSnackBarService) {
  }

  listSnapshots() {
    this.snapshotService.listSnapshots()
      .pipe(indicate(this.loading$))
      .subscribe(s => this.snapshots = s, error => this.errorHandlerService.handleHttpError(error));
  }

  initSimulationWithSnapshot(id: number) {
    this.snapshotService.restoreSnapshot(id)
      .pipe(
        indicate(this.loading$),
        switchMap(snapshot => this.simulationService.initSnapshot(snapshot.createdTime))
      )
      .subscribe(() => this.snackBar.showInfo('pages.simulation.snapshot-initialized'),
        error => this.errorHandlerService.handleHttpError(error));
  }

  ngOnInit(): void {
    this.listSnapshots();
  }

}
