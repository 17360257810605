import {DashboardItemInfo} from '../../pages/home/home-dashboard.service';

export class SystemHealthStatus {
  systemId: string;
  spsStatus: 'UNKNOWN' | 'NOT_READY' | 'EMERGENCY_MODE' | 'LOGISTIC_MODE' | 'PREPARING_FOR_LOGISTIC';
  plsStatus: 'UNKNOWN' | 'NOT_READY' | 'IDLE' | 'ACTIVE' | 'ACTIVATING';
  laneFillingMode: boolean;
  blockLayer: boolean;
  ctrlLocks: DashboardItemInfo[];

  /**
   * This mode is set - only in system 085 - when the delivery towards a unreachable layer is forced.
   */
  forceDeliver?: boolean;
}

export const HEALTH_STATUS_MAPPING = {
  UNKNOWN: 'fa-question-circle',
  NOT_READY: 'fa-times-circle',
  EMERGENCY_MODE: 'fa-hand-paper',
  IDLE: 'fa-pause-circle',
  LOGISTIC_MODE: 'fa-play-circle',
  PREPARING_FOR_LOGISTIC: 'fa-arrow-alt-circle-up',
  ACTIVATING: 'fa-arrow-alt-circle-up',
  ACTIVE: 'fa-check-circle'
};

export function healthColorMapping(value: SystemHealthStatus) {
  if (!value || value.plsStatus === 'UNKNOWN' || value.spsStatus === 'UNKNOWN') {
    // status check failed
    return 'error';
  }

  if (value.spsStatus === 'EMERGENCY_MODE'
    || value.spsStatus === 'PREPARING_FOR_LOGISTIC') {
    // emergency mode, pls doesn't matter
    return 'warn';
  }

  if (value.spsStatus === 'NOT_READY'
    || value.plsStatus === 'NOT_READY') {
    // sps or pls aren't ready
    return 'error';
  }

  if (value.spsStatus === 'LOGISTIC_MODE' && value.plsStatus === 'IDLE') {
    // all fine but we are idle
    return 'warn';
  }

  if (value.laneFillingMode === true) {
    // special process active - should only occur if everything is fine
    return 'info';
  }

  if (value.blockLayer === true) {
    // special process active - should only occur if everything is fine
    return 'warn';
  }

  if (value.spsStatus === 'LOGISTIC_MODE' && value.plsStatus === 'ACTIVE') {
    // all fine
    return undefined;
  }

  // a state we didn't thought about
  return 'warn';
}
