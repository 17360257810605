import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Action, EventType, LiteralOperand} from '../../condition-model';
import {SkwSelectModel} from 'skw-ui-components';
import {RulesService} from '../../../rules.service';
import {Subscription} from 'rxjs';
import {RuleEditService} from '../../rule-edit.service';
import {SkwTranslatableValue} from 'skw-ui-translation';

@Component({
  selector: 'app-rule-action-set-target',
  templateUrl: './rule-action-set-target.component.html',
  styleUrls: ['./rule-action-set-target.component.scss']
})
export class RuleActionSetTargetComponent implements OnInit, OnDestroy {
  @Input() editMode: boolean;
  @Input() triggerContext: EventType;

  $subscription: Subscription;

  _action: Action;

  TARGETN50A80 = 'N50_A80';

  _propValueIdx: number;
  _propValueIdx3: number;

  moduleIds: SkwSelectModel<string>[];
  subTargetIds: SkwSelectModel<string>[];


  @Output() actionChange: EventEmitter<Action> = new EventEmitter<Action>();


  @Input()
  set action(action: Action) {
    this._action = action;
    this._propValueIdx = (action.actionType.parameters as any).targetId.ordinal;
    this._propValueIdx3 = (action.actionType.parameters as any).subTargetId.ordinal;


    const target = (this._action.operands[this._propValueIdx] as any).value;
    const subtarget = (this._action.operands[this._propValueIdx3] as any).value;

    var invalid = false;
    if (target === this.TARGETN50A80 && (!subtarget || subtarget.length === 0)) {
      invalid = true;
    } else {
      invalid = !(target && target.length > 0);
    }

    if (this._action.invalid !== invalid) {
      this._action.invalid = invalid;

      this.ruleEditService.emitUpdateRule();
      this.actionChange.emit(this._action);
    }
  }

  constructor(private rulesService: RulesService,
              private ruleEditService: RuleEditService,
              private cdr: ChangeDetectorRef) {
  }

  ngOnDestroy() {
    this.$subscription.unsubscribe();
  }

  ngOnInit() {
    this.rulesService.getItemFlowPropValues('target')
      .subscribe(result => this.moduleIds = result.map(r => {
        return {
          value: r,
          viewValue: {key: 'item.attributeValues.' + r, default: r} as SkwTranslatableValue
        } as SkwSelectModel<string>;
      }));
    this.rulesService.getViaSubTargets()
      .subscribe(result => this.subTargetIds = result.map(r => {
        return {
          value: r,
          viewValue: r
        } as SkwSelectModel<string>;
      }));
    this.$subscription = this.ruleEditService.changeObservable()
      .subscribe(s => {
        this.action = this._action;
      });
  }


  targetChange(target: string) {
    (this._action.operands[this._propValueIdx] as any).value = target;


    if (!target || target.length === 0) {
      this._action.invalid = true;

    }

    // if the target gets changed to something different than N50A80 reset the subTarget to empty
    else if (target !== this.TARGETN50A80) {
      this._action.operands[this._propValueIdx3] = {type: 'LiteralOperand', value: ''} as LiteralOperand;
      this._action.invalid = false;
    } else {
      const subtarget = (this._action.operands[this._propValueIdx3] as any).value;
      this._action.invalid = !(subtarget && subtarget.length > 1);
    }

    this.ruleEditService.emitUpdateRule();
    this.actionChange.emit(this._action);

  }

  subTargetChanged(subtarget: string) {
    if (this._action.operands[this._propValueIdx3] == null) {
      this._action.operands[this._propValueIdx3] = {type: 'LiteralOperand', value: subtarget} as LiteralOperand;
    } else {
      (this._action.operands[this._propValueIdx3] as LiteralOperand).value = subtarget;
    }

    const target = (this._action.operands[this._propValueIdx] as any).value;

    if (target === this.TARGETN50A80 && (!subtarget || subtarget.length === 0)) {
      this._action.invalid = true;
    } else {
      this._action.invalid = !(target && target.length > 0);
    }

    this.ruleEditService.emitUpdateRule();
    this.actionChange.emit(this._action);
  }
}
