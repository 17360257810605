import {Directive, OnDestroy, TemplateRef, ViewContainerRef} from '@angular/core';
import {SimulationControlService} from './simulation-control/simulation-control.service';
import {Subscription} from 'rxjs';

/**
 * Use this to hide/show components which are only visible is simulation is active.
 * Usage: <element *simulationActive></element>
 */
@Directive({
  selector: '[simulationActive]'
})
export class SimulationActiveDirective implements OnDestroy {
  private readonly subscription: Subscription;

  constructor(private simulationService: SimulationControlService,
              private _viewContainerRef: ViewContainerRef,
              private _templateRef: TemplateRef<any>) {
    this.subscription = this.simulationService.isSimulationActive()
      .subscribe(active => {
        if (active) {
          // only render view if simulation is active
          _viewContainerRef.createEmbeddedView(_templateRef);
        } else {
          _viewContainerRef.clear();
        }
      });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
