import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NotificationCenterComponent} from './notification-center/notification-center.component';
import {SkwLanguageModule} from 'skw-ui-translation';
import {SkwBadgeModule, SkwButtonModule, SkwDialogModule, SkwLoadingModule, SkwPaginationModule} from 'skw-ui-components';
import {MatTooltipModule} from '@angular/material';

@NgModule({
  declarations: [NotificationCenterComponent],
    imports: [
        CommonModule,
        SkwLanguageModule,
        SkwButtonModule,
        SkwBadgeModule,
        SkwLoadingModule,
        MatTooltipModule,
        SkwPaginationModule,
        SkwDialogModule,
    ],
  entryComponents: [NotificationCenterComponent]
})
export class ErrorsModule {
}
