export class Kid14 {

  value: string;

  constructor(value: string) {
    this.value = value;
  }

  isValid(): boolean {
    if (!this.value || this.value.length !== 14 && this.value.length !== 7)
      return false;

    if (this.value.length === 7) {
      return (this.sumEanCode(this.value) + this.generateCheckDigit(this.value)) % 10 === 0;
    } else if (this.value.length == 14) {
      let digit = this.value.charCodeAt(13) - '0'.charCodeAt(0);
      let kid7 = this.value.substring(6, 13);
      return (this.sumEanCode(kid7) + digit) % 10 === 0;
    }

    return false;
  }

  generateCheckDigit(kid: string) {
    const sumEanCode = this.sumEanCode(kid);
    if (sumEanCode % 10 == 0) {
      return 0;
    }
    return 10 - (sumEanCode % 10);
  }

  sumEanCodeForKid13() {
    let kid7 = this.value.substring(6, 13);
    return this.sumEanCode(kid7);
  }

  sumEanCode(kid7: string) {
    let sum = 0;

    for (let i = 1; i <= 7; i++) {
      let factor = (i % 2 == 0) ? 1 : 3;
      sum += (kid7.charCodeAt(i - 1) - '0'.charCodeAt(0)) * factor;
    }

    return sum;
  }
}
