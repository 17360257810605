import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {CarbodyPropertyCheckCondition, EventType} from '../../condition-model';
import {SkwSelectModel} from 'skw-ui-components';
import {RulesService} from '../../../rules.service';
import {MdsEntityAttribute} from '../../../rule-definitions-model';
import {CHECK_OPERATOR_TYPES} from '../rule-condition-operators';
import {SkwTranslatableValue} from 'skw-ui-translation';
import {Subscription} from 'rxjs';
import {RuleEditService} from '../../rule-edit.service';

@Component({
  selector: 'app-rule-condition-car-body-property-check',
  templateUrl: './rule-condition-car-body-property-check.component.html',
  styleUrls: ['./rule-condition-car-body-property-check.component.scss']
})
export class RuleConditionCarBodyPropertyCheckComponent implements OnInit, OnDestroy {
  private $subscription: Subscription;

  @Input() editMode: boolean;
  @Output() conditionChange = new EventEmitter<CarbodyPropertyCheckCondition>();

  _condition: CarbodyPropertyCheckCondition;
  _attributeValues: SkwSelectModel<MdsEntityAttribute>[] = [];
  _hasAttributeError: boolean;

  private _triggerContext: EventType;

  @Input()
  set triggerContext(value: EventType) {
    this._triggerContext = value;
    this.validateCondition();
  }

  @Input()
  set condition(cond: CarbodyPropertyCheckCondition) {
    this._condition = cond;

    this.rulesService.getRuleDefinitionsModel().subscribe(value => {
      // get all mds fields from this definition model
      this.mdsEntityAttributes = value.mdsEntityAttributes.map(mdsEntityAttribute => {
        return {
          value: mdsEntityAttribute,
          viewValue: {
            key: 'pages.mds.entityTypesPrefix.' + mdsEntityAttribute.entityTypeName,
            args: {name: mdsEntityAttribute.attributeName}
          } as SkwTranslatableValue
        } as SkwSelectModel<MdsEntityAttribute>;
      });

      // find the selected attribute for the skw-select-input
      const selectModel = this.mdsEntityAttributes.find(x => x.value.id === cond.attributeId);
      this.selectedAttribute = selectModel ? selectModel.value : undefined;

      this.validateCondition();
    });
  }

  selectedAttribute: MdsEntityAttribute;

  // hardcoded, because we certainly know which operators we allow
  operatorTypes = CHECK_OPERATOR_TYPES;

  mdsEntityAttributes: SkwSelectModel<MdsEntityAttribute>[];

  constructor(private rulesService: RulesService,
              private ruleEditService: RuleEditService,
              private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.$subscription = this.ruleEditService.changeObservable().subscribe(() => {
      this.condition = this._condition;
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.$subscription.unsubscribe();
  }

  setAttributeReference($event: MdsEntityAttribute) {
    this.selectedAttribute = $event;

    if ($event) {
      this._condition.attributeId = $event.id;
      this._condition.attributeName = $event.attributeName;
      this._condition.entityTypeName = $event.entityTypeName;
      this.ruleEditService.emitUpdateRule();
    }
  }


  private validateCondition() {
    // check whether trigger has itemId to reference car body property
    this.rulesService.getRuleDefinitionsModel().subscribe(value => {
      if (!this._triggerContext) {
        this._hasAttributeError = true;
        return;
      }
      const trigger = value.eventTypes.find(t => t.name === this._triggerContext.name);
      if (!trigger) {
        this._hasAttributeError = true;
        return;
      }
      this._hasAttributeError = !this._triggerContext || !trigger.supportedAttributes.itemId;
    });
  }
}
