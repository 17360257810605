import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Action, EventType, ListOperand, LiteralOperand} from '../../condition-model';
import {RulesService} from '../../../rules.service';
import {forkJoin, Observable, Subscription} from 'rxjs';
import {RuleEditService} from '../../rule-edit.service';
import {MdsFieldDefinition} from '../../../rule-definitions-model';
import {SkwAutocompleteModel, SkwChipsModel} from 'skw-ui-components';
import {SkwLanguageService, SkwTranslatableValue} from 'skw-ui-translation';
import {getActionParamIndex, getActionParamValue} from '../rule-edit-utils';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-rule-action-add-color-block-score-modifier',
  templateUrl: './rule-action-add-color-block-score-modifier.component.html',
  styleUrls: ['./rule-action-add-color-block-score-modifier.component.scss']
})
export class RuleActionAddColorBlockScoreModifierComponent implements OnInit, OnDestroy {
  @Input() editMode: boolean;
  @Input() triggerContext: EventType;

  $subscription: Subscription;

  _action: Action;

  // selected mds attribute (1. param
  mdsEntityAttributes: MdsFieldDefinition[];
  mdsEntityAttributeNames: SkwAutocompleteModel<MdsFieldDefinition>[];
  selectedAttribute: MdsFieldDefinition;

  _attributeId: number;
  _attributeIdIdx: number;

  // selected attribute value (2. param)
  mdsAttributeValues: SkwAutocompleteModel<string>[];
  mdsAttributeViewValue: string;
  _attributeValue: any;
  _attributeValueIdx: number;

  // max length (3. param)
  _maxLength: number;
  _maxLengthIdx: number;

  // selected outlets (4. param)
  _outlets: string[] = [];
  _outletsIdx: number;
  availableOutletValues: SkwChipsModel<string>[];

  // use min length (5. param)
  _useMinBlockLength: boolean;
  _useMinBlockLengthIdx: number;

  // min length (6. param)
  _minBlockLength: number;
  _minBlockLengthIdx: number;

  // storeInWFB (7. param)
  _storeInWFB: boolean;
  _storeInWFBIdx: number;

  @Output() actionChange: EventEmitter<Action> = new EventEmitter<Action>();

  constructor(private rulesService: RulesService,
              private ruleEditService: RuleEditService,
              private languageService: SkwLanguageService,
              private cdr: ChangeDetectorRef) {
  }

  @Input()
  set action(action: Action) {
    this._action = action;

    this._attributeIdIdx = getActionParamIndex(action, 'attributeId');
    this._attributeId = getActionParamValue(action, this._attributeIdIdx) as number;

    this._attributeValueIdx = getActionParamIndex(action, 'attributeValue');
    this._attributeValue = getActionParamValue(action, this._attributeValueIdx) as string;

    this._maxLengthIdx = getActionParamIndex(action, 'maxBlockLength');
    this._maxLength = getActionParamValue(action, this._maxLengthIdx) as number;

    this._outletsIdx = getActionParamIndex(action, 'outlets');
    this._outlets = getActionParamValue(action, this._outletsIdx) as string[];

    this._useMinBlockLengthIdx = getActionParamIndex(action, 'useMinBlockLength');
    this._useMinBlockLength = getActionParamValue(action, this._useMinBlockLengthIdx) as boolean;

    this._minBlockLengthIdx = getActionParamIndex(action, 'minBlockLength');
    this._minBlockLength = getActionParamValue(action, this._minBlockLengthIdx) as number;

    this._storeInWFBIdx = getActionParamIndex(action, 'storeInWFB');
    this._storeInWFB = getActionParamValue(action, this._storeInWFBIdx) as boolean;

    console.log(action);
    console.log(this._useMinBlockLengthIdx);

    this.rulesService.getRuleDefinitionsModel().subscribe(value => {
      this.mdsEntityAttributes = value.mdsEntityAttributes;
      this.mdsEntityAttributeNames = value.mdsEntityAttributes.map(fieldDefinition => {
        return {
          value: fieldDefinition,
          viewValue: {
            key: 'pages.mds.entityTypesPrefix.' + fieldDefinition.entityTypeName,
            args: {name: fieldDefinition.attributeName}
          } as SkwTranslatableValue
        } as SkwAutocompleteModel<MdsFieldDefinition>;
      });
      const selectModel = this.mdsEntityAttributes.find(x => x.id === this._attributeId);
      this.selectedAttribute = selectModel ? selectModel : undefined;

      this.loadAttributeValues();
    });
    this.rulesService.getItemFlowPropValues('target')
      .subscribe(result => this.availableOutletValues = result.map(r => {
        return {
          value: r,
          viewValue: {key: 'item.attributeValues.' + r, default: r} as SkwTranslatableValue
        } as SkwChipsModel<string>;
      }));
  }

  ngOnDestroy() {
    this.$subscription.unsubscribe();
  }

  ngOnInit() {
    this.$subscription = this.ruleEditService.changeObservable()
      .subscribe(s => {
        this.action = this._action;
      });
  }


  operandFirstAttrChange($event: MdsFieldDefinition) {
    this._attributeId = !$event ? undefined : $event.id;
    this.loadAttributeValues();

    (this._action.operands[this._attributeIdIdx] as LiteralOperand).value = this._attributeId;
    this.ruleEditService.emitUpdateRule();
    this.actionChange.emit(this._action);
  }

  operandFirstValueChange($event: string) {
    this._attributeValue = $event;
    const selectModel = this.mdsAttributeValues.find(x => x.viewValue === this._attributeValue);
    const value = selectModel ? selectModel.value : $event;
    this.loadAttributeValues();

    (this._action.operands[this._attributeValueIdx] as LiteralOperand).value = value;
    this.ruleEditService.emitUpdateRule();
    this.actionChange.emit(this._action);

  }

  maxLengthValueChange($event: number) {
    (this._action.operands[this._maxLengthIdx] as LiteralOperand).value = $event;
    this.ruleEditService.emitUpdateRule();
    this.actionChange.emit(this._action);
  }

  outletsChange($event: string[]) {
    (this._action.operands[this._outletsIdx] as ListOperand).value = $event;
    this.ruleEditService.emitUpdateRule();
    this.actionChange.emit(this._action);
  }

  useMinBlockLengthChange($event: boolean) {
    (this._action.operands[this._useMinBlockLengthIdx] as LiteralOperand).value = $event;
    this.ruleEditService.emitUpdateRule();
    this.actionChange.emit(this._action);
  }

  storeInWFBChange($event: boolean) {
    (this._action.operands[this._storeInWFBIdx] as LiteralOperand).value = $event;
    this.ruleEditService.emitUpdateRule();
    this.actionChange.emit(this._action);
  }

  minBlockLengthChange($event: number) {
    (this._action.operands[this._minBlockLengthIdx] as LiteralOperand).value = $event;
    this.ruleEditService.emitUpdateRule();
    this.actionChange.emit(this._action);
  }

  private updateConditionViewValue(attributeValues: SkwAutocompleteModel<string>[], value: string): string {
    const splitValue = value ? value.split(',') : undefined;
    if (splitValue) {
      return splitValue.map(el => {
        const val = attributeValues.find(v => el === v.value);
        return !val ? el : val.viewValue;
      }).join(',');
    } else {
      return value;
    }
  }

  private loadAttributeValues() {
    if (this.selectedAttribute) {
      this.rulesService.getCarBodyAttributeValues(this.selectedAttribute.entityTypeName, this.selectedAttribute.attributeName)
        .subscribe(r => {
          if (!r) {
            this.mdsAttributeValues = [];
            return;
          }
          this.mdsAttributeValues = r.map(a => {
            return {
              value: a.value.toString(),
              viewValue: a.viewValue
            };
          });
          this.mdsAttributeViewValue = this.updateConditionViewValue(this.mdsAttributeValues, this._attributeValue);
          this.cdr.markForCheck();
        });
    }
  }

  translateList(values: string[]): Observable<string> {
    return forkJoin(
      values.map(v => this.languageService.get({key: 'item.attributeValues.' + v, default: v}))
    ).pipe(map((r: string[]) => r.join(', ')));
  }
}
