import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {SystemHealthStatusService} from '../../../system-status/header-toolbar-portal/system-health-status.service';
import {LaneFillingService} from './lane-filling.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {indicate, SkwErrorHandlerService} from 'skw-ui-components';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-lane-filling-dialog',
  templateUrl: './lane-filling-dialog.component.html',
  styleUrls: ['./lane-filling-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LaneFillingDialogComponent {
  systemStatus$ = this.systemHealthStatusService.systemObservable(this.system);
  unlockAllLaneExits = false;
  loading$ = new Subject<boolean>();

  constructor(public dialogRef: MatDialogRef<LaneFillingDialogComponent>,
              private systemHealthStatusService: SystemHealthStatusService,
              private laneFillingService: LaneFillingService,
              private errorHandlerService: SkwErrorHandlerService,
              @Inject(MAT_DIALOG_DATA) private system: string) {
  }

  activate() {
    this.laneFillingService.updateMode(this.system, true, false)
      .pipe(indicate(this.loading$))
      .subscribe(() => this.dialogRef.close(),
        error => this.errorHandlerService.handleHttpError(error));
  }

  deactivate() {
    this.laneFillingService.updateMode(this.system, false, this.unlockAllLaneExits)
      .pipe(indicate(this.loading$))
      .subscribe(() => this.dialogRef.close(),
        error => this.errorHandlerService.handleHttpError(error));
  }
}
