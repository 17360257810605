import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CarBodyAttrsModalComponent} from './car-body-attrs-modal/car-body-attrs-modal.component';
import {CarBodySearchComponent} from './car-body-search.component';
import {CarBodyCardComponent} from './car-body-card/car-body-card.component';
import {
  SkwAlertModule,
  SkwButtonModule,
  SkwCardModule,
  SkwDialogModule,
  SkwHintModule,
  SkwInputModule,
  SkwListModule,
  SkwLoadingModule
} from 'skw-ui-components';
import {SkwLanguageModule} from 'skw-ui-translation';
import {SkwPageLayoutModule} from 'skw-ui-layout';
import {MfwComponentsModule} from '../../mfw-components/mfw-components.module';
import {MatMenuModule} from '@angular/material/menu';
import {SkwAuthorizationModule} from 'skw-ui-authentication';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
  declarations: [
    CarBodyAttrsModalComponent,
    CarBodyAttrsModalComponent,
    CarBodySearchComponent,
    CarBodyCardComponent
  ],
  imports: [
    CommonModule,
    SkwListModule,
    SkwCardModule,
    SkwLanguageModule,
    SkwPageLayoutModule,
    SkwInputModule,
    SkwButtonModule,
    SkwAlertModule,
    SkwDialogModule,
    MfwComponentsModule,
    SkwLoadingModule,
    SkwHintModule,
    MatMenuModule,
    SkwAuthorizationModule,
    MatTooltipModule
  ],
  entryComponents: [CarBodyAttrsModalComponent]
})
export class CarBodySearchModule {
}
