import {NgModule} from '@angular/core';
import {HealthStateIconComponent} from './health-state-icon/health-state-icon.component';
import {SkwLanguageModule} from 'skw-ui-translation';
import {OperationModeIconComponent} from './operation-mode-icon/operation-mode-icon.component';
import {CommonModule} from '@angular/common';
import {ItemReadyIconComponent} from './item-ready-icon/item-ready-icon.component';
import {TransportingIconComponent} from './transporting-icon/transporting-icon.component';
import {MfwStatusModule} from 'skw-ui-modules';
import {ReleaseOverflowCarbodiesDialogComponent} from './release-overflow-carbodies-dialog/release-overflow-carbodies-dialog.component';
import {SkwBadgeModule, SkwButtonModule, SkwDialogModule, SkwFormModule, SkwInputModule} from 'skw-ui-components';
import {MatDividerModule} from '@angular/material/divider';
import {KvsNotificationIcon} from "./kvs-notification-icon/kvs-notification-icon";

const components = [
  HealthStateIconComponent,
  OperationModeIconComponent,
  ItemReadyIconComponent,
  TransportingIconComponent,
  ReleaseOverflowCarbodiesDialogComponent,
  KvsNotificationIcon
];

@NgModule({
  declarations: [...components],
  exports: [...components],
  imports: [
    CommonModule,
    SkwLanguageModule,
    MfwStatusModule,
    SkwDialogModule,
    SkwButtonModule,
    SkwFormModule,
    SkwInputModule,
    MatDividerModule,
    SkwBadgeModule
  ],
  entryComponents: [
    ReleaseOverflowCarbodiesDialogComponent
  ]
})
export class AudiCommonModule {
}
